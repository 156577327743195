// section-header styles
html[lang="ja"], html[lang="zh"]{
	.section-header {
		.section-header__title {
			&.ja-zh-ko-format {
				display: inline-block;
			}
		
			&.english-format {
				display: none;
			}
			
			&.ko-format {
				display: none;
			}
		}
	}
}

html[lang="ko"]{
	.section-header {
		.section-header__title {
			&.ko-format {
				display: inline-block;
			}
			
			&.ja-zh-ko-format {
				display: none;
			}
		
			&.english-format {
				display: none;
			}
		}
	}
}

html[lang="ar"]{
	.section-header {
		.section-header__title {
			&.english-format {
				> span {
					float: right;
				}
			}
		}
	}
}

.section-header {
	position: relative; // Object fit fix
	z-index: 1;
	text-align: center;

	img {
		max-width:100%;
	}

	@include padding(40px, 5px, 0, 5px);
	margin-bottom:38px;

	@include mobile {
		margin-bottom: 25px;
	}
	@include tablet {
		margin-bottom: 30px;
	}
	
	.section--pts & {
		padding-top:0;
	}

	+ .t-c-m {
		padding: 0 25px;

		.col-5 & {
			text-align: center;
		}
	}

	&--no-padding {
		padding-top: 0;

		.section-header__title {
			padding-bottom: 40px;

			@include mobile {
				padding-bottom: 25px;
			}
			@include tablet {
				padding-bottom: 25px;
			}
		}
	}

	&--less-padding {
		padding-top: 55px;
		@include mobile {
			padding-top: 25px;
		}
		@include tablet {
			padding-top: 35px;
		}
		.section-header__title {
			padding-bottom: 40px;

			@include mobile {
				padding-bottom: 25px;
			}
			@include tablet {
				padding-bottom: 25px;
			}
		}
	}

	&--extra-padding {
		padding-top: 150px;
		@include mobile {
			padding-top: 40px;
		}
		@include tablet {
			padding-top: 60px;
		}
	}

	&__title {
		@extend %font-sub-title;
		
		display: inline-block;
		color: #fff;
		text-align: center;
		margin: 0;

		.section--gold & {
			color: $section-gold-sub-title-color;
		}

		.section--dark-grey & {
			color: $section-dark-grey-sub-title-color;
		}

		.section--darker-grey & {
			color: $section-darker-grey-sub-title-color;
		}

		.section--blog-intro & {
			color: $section-blog-intro-sub-title-color;
		}

		.section--light-grey & {
			color: $section-light-grey-sub-title-color;
		}

		.section--lighter-grey & {
			color: $section-lighter-grey-sub-title-color;
		}

		.section--blue-blue-tone & {
			color: $section-blue-tone-sub-title-color;
		}

		.section--white & {
			color: $section-white-sub-title-color;
		}

		&--ts .section-header__title {
			@extend %t-s;
			margin-top: 6px;
		}

		&.ja-zh-format {
			display: none;
		}
		
		&.ko-format {
			display: none;
		}
	
		&.english-format {
			display: inline-block;
		}
	}

	&__text {
		@extend %font-title;
		
		display: block;
		@include margin(0, auto, 0, auto);
		color: #fff;
		text-align: center;
		max-width: 940px;

		.section--gold & {
			color: $section-gold-main-title-color;
		}

		.section--dark-grey & {
			color: $section-dark-grey-main-title-color;
		}

		.section--darker-grey & {
			color: $section-darker-grey-main-title-color;
		}

		.section--blog-intro & {
			color: $section-blog-intro-main-title-color;
		}

		.section--light-grey & {
			color: $section-light-grey-main-title-color;
		}

		.section--lighter-grey & {
			color: $section-lighter-grey-main-title-color;
		}

		.section--blue-blue-tone & {
			color: $section-blue-tone-main-title-color;
		}

		.section--white & {
			color: $section-white-main-title-color;
		}
	}

	.propertyName{
	    letter-spacing: 0.15em;
	    margin: 1em 0;
	    display: block;
	    font-size: 0.80em;
	}

	&.campaign-intro{

		h2,.subheading{
			margin-bottom:15px;
		}

	}
}
