// text-listing styles
.text-listing {
	padding: 0;
	margin: 0;
	list-style-type: none;

	&__item {
		padding-bottom: 18px;
		margin-bottom: 18px;
		border-bottom: 1px solid $f-grey-light;

		&:last-child {
			border: 0;
		}
	}

	&__link {
		display: block;
		color: inherit;
		text-decoration: none;
		transition: transform $medium;
		transform: translateX(bi-app-reverse-percentage(0));
		will-change: transform;

		&:hover, &:focus {
			transform: translateX(bi-app-reverse-percentage(.5em));
		}
	}

	&__meta {
		display: block;
		color: $t-standard-faint;
	}
}
