.hotel-directory {
	display:flex;
	position: relative;
	background: white;
	&__brands {
		display:flex;
		margin:0 auto;
		justify-content: space-evenly;
		@include mobile {
			display: none;
		}
		
		.brand {
			max-width:10%;
			padding: 15px 0;
			
			img {
				max-width:100%;
						
			}
			opacity: 0.3;	
			&.is-active {
				opacity: 1;
				border-bottom:2px solid $f-gold;				
			}
		}
	}
	
	.accordion {
		margin:0;
		
		&.destination {
			padding:20px;

			.accordion__item{
				margin:10px 0;
				border:1px solid $f-grey-light-border;
				
				@include desktop {
					margin:10px;
				}

			}

			.accordion__title {
				@extend %font-navigation;
				
				letter-spacing: 0;
				background:white;
				align-items: baseline;
				justify-content: start;
				@include padding(10px, 30px, 10px, 10px)

				.total {
					display:inline-block;
					background:$t-gold;
					border-radius:50%;
					min-width: 30px;
					min-height: 30px;
					color: #fff;
					text-align: center;
					line-height: 30px;
					font-size: 1em;
					@include margin-right(10px);
					letter-spacing: 0;
				}
				
				&:hover {
					border:0;
				}
				
				&:after {
					@include right(10px);
				}
			}
			
			.accordion__content {
				background: white;
				padding: 10px 20px;
				.list {
					@extend %font-navigation;
					
					display:flex;
					justify-content: flex-start;
					flex-wrap:wrap;
					@include desktop {
						gap: 5%;
					}
					a {
						text-transform:uppercase;
						display:inline-block;
						width:100%;
						margin-bottom:15px;
						line-height:1.5em;
						font-size:0.8em;
						
						@include desktop {
							width:30%;
						}
						@include mobile {
							width: 50%;
						}
						
						span {
							border-bottom:1px solid $f-grey-light-border;
							padding-bottom: 0px;
						}
						
						&:hover {
							span {
								border-color:darken($t-standard, 20);
							}
						}
					}
				}
			}
		}
	}

	>.col-4{
		width: 35%;
		@include tablet {
			width: 40%;
		}
		@include mobile {
			width: 100%;
		}
	}

	.hotelList{
		position: relative;
	}

	.map-wrapper{
		position: relative;
		width: 65%;
		min-height: calc(100vh - 212px);
		@include tablet {
			width: 60%;
		}
	}
	
	.back {
		@include margin(30px, 20px, 0, 20px);
		font-size:1.2em;
		font-weight:bold;
	}
	
	.summary {
		@include margin(0, 20px, 50px, 20px);
		font-size:1.2em;
		font-weight:bold;
		
		.location {
			font-size:1.2em;
			letter-spacing: 1px;
		}
	}
	
	.hotel {
		display:flex;
		flex-direction: column;
		border: 1px solid $f-grey-light-border;
		@include margin(0, 20px, 20px, 20px);
		align-items:stretch;
		background:white;
		
		@include desktop {
			flex-direction: row;
		}
		
		&__image {
			overflow: hidden;
			flex: 40%;
			img {
				width: 100%;
				height: 100%;
				font-family: "object-fit:cover;object-position:center";
				object-fit: cover;
				object-position: center;
			}
		}
		
		&__info {
			padding: 10px 15px;
			
			@include desktop {
				flex: 60%;
			}
		
			&__brand {
				height: 40px;
				width: 40px;
				border-radius: 50%;
				background-color: white;
				text-align: center;
				padding: 8px 0;
				@include float(right);
				
				.glyph-icon {
					color:#FFF;
					font-size: 24px;
				}
				
				&.AN {
					background: #a48e82;
					
					.glyph-icon {
						@extend .glyph-anantara;
					}
				}

				&.AV {
					background: #390433;
					
					.glyph-icon {
						@extend .glyph-avani;
					}
				}

				&.AVP {
					background: #1d252d;
					
					.glyph-icon {
						@extend .glyph-avani;
					}
				}

				&.OH {
					background: #003450;
					
					.glyph-icon {
						@extend .glyph-oaks;
					}
				}

				&.NH {
					background: #003963;
					
					.glyph-icon {
						@extend .glyph-nh;
					}
				}

				&.NC {
					background: #a71a2d;
					
					.glyph-icon {
						@extend .glyph-ncollection;
					}
				}
				
				&.NW {
					background: #624494;
					
					.glyph-icon {
						@extend .glyph-nhow;
					}
				}

				&.EW {
					background: #99694b;
					
					.glyph-icon {
						@extend .glyph-elewana;
					}
				}

				&.TI {
					background: #b67233;
					
					.glyph-icon {
						@extend .glyph-tivoli;
					}
				}

				&.NLD {
					background: #00b5cc;
					
					.glyph-icon {
						@extend .glyph-naladhu;
					}
				}

				&.PNIY {
					background: #c4012e;
					
					.glyph-icon {
						@extend .glyph-niyama;
					}
				}

				&.ELYW {
					background: #d6b981;
					
					.glyph-icon {
						@extend .glyph-elysia;
					}
				}
			}
			
			&__general {
				.hotel__name {
					@extend %font-alternate-header;
					
					margin:0 0 10px;
					font-size: 1em;
					line-height: 1.2em;
					font-weight:bold;
					width: calc(100% - 50px);
				}
				
				.hotel__address {
					max-width: 80%;
					display: block;
					text-decoration: underline;
					text-underline-offset: 3px;
					font-size:0.8em;
					line-height:1.5em;
					color:$f-grey-mid;
					
					i {
						@include float(left);
						margin-top: 2px;
						font-size: 1.5em;
					}
					
					span {
						display:block;
						overflow:hidden;
					}
				}
			}
			
			&__rating {
				@include margin(0px, 0, 10px, 0);
				color:$f-gold;
				display: flex;
				align-items: center;
				gap: 1.5px;
				.trip-logo {
					max-width: 22px;
					@include margin-right(4px);
					font-size: 13px;
				}
				.trip-rating {
					font-size: 11px;
				}
			}
			
			&__price {
				font-size:0.8em;
				@include margin(20px, 0, 0, 0);
				position: relative;
				
				.hotel__from__price {
					span.from {
						display:block;
						color:$f-grey-mid;
						text-transform: uppercase;
						font-size:0.7em;
						margin: 6px 0 0;
					}
					
					.currency-converter {
						.form {
							.form-main {
								&:after {
									color:$t-standard;
									border-top: 6px solid $t-standard;
								}
							}
						}
						
						select {
							color:$t-standard;
						}
					}
				}

				.item-price {
					position: absolute;
					@include right(0);
					line-height: 17px;
					margin-top: 7px;
				}
				.hotel__price__unit{
					color:$f-grey-mid;
					font-size:0.7em;
					text-transform: uppercase;
					position: absolute;
					top: 0;
					@include right(0);
					margin: 0;
				}
			}
			
			&__web {
				@include margin(10px, 0, 0, 0);
				
				.btn {
					width:100% !important;
					@include margin-right(0px);
				}
			}
		}
	}
	
	.brand {
		list-style-type: none;
		margin: 30px 30px;
		padding: 0;
		text-transform: uppercase;
		font-size: 1.3em;
		font-weight:bold;
		a {
			@extend %font-navigation;
		}
		
		ul {
			list-style-type:none;
			margin:10px 0 30px;
			padding: 0;
			font-size: 0.7em;
			font-weight:normal;
			
			li {
				margin:0 0 15px;
				font-size: 0.9em;

				a {
					@extend %font-navigation;
					
					&:hover{
						text-decoration: underline;
					}
				}
			}
		}
	}
	
	.map {
		@include desktop {
			overflow:hidden;
			height:100%;
			width:100%;
			top:0;
			position:absolute;
			left:0;
			background-position: center;
			background-repeat: no-repeat;
			background-size: cover;

			.content-block & {
				max-height: 100%;
				height: 100%;
			}
		}
	}
	
	.feature-hotels {
		text-align:center;
		margin:0 20px 30px;
		
		.section-header {
			margin-bottom:20px;
		}
	}
}

.scroll-hotels {
	position: relative;
	.map-wrapper {
		min-height: calc(100vh - 212px);
		position: fixed !important;
		@include right(0);
	}
}