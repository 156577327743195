// details styles
.booking-flexible {
	.booking-form-subgroup {
		border-top: 1px solid $f-bm-border--secondary;
	}
}

.booking-form-guest-wrapper {
	display: none;
}
