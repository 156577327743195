// tooltip styles

.tool-tip {
	position: absolute;
	@include right(-5px);
	top: calc(50% - 12px);

	cursor: help;
	-webkit-transform: translateZ(0);
	/* webkit flicker fix */
	-webkit-font-smoothing: antialiased;
	/* webkit text rendering fix */

	@include mobile {
		@include right(-15px);
	}

	&:before {
		@extend %icon-font-glyph;
		content: $glyph-info2;
		font-size: 14px;
		border: 2px solid $t-gold;
		border-radius: 50%;
		width: 24px;
		height: 24px;
		display: inline-block;
		padding-top: 2px;
		@include padding-left(2px);
		text-align: center;
	}

	&__popover {
		position: absolute;
		top: -100%;
		@include left(200%);
		display: block;
		width: 500px;
		@include padding(10px, 20px, 20px, 20px);
		margin-bottom: 15px;
		color: $t-standard;
		@include text-align(left);
		pointer-events: none;
		background: #fefefe;
		box-shadow: 0 15px 19px 1px rgba(0, 0, 0, .15);
		opacity: 0;
		transition: all .25s ease-out;
		transform: translateY(10px);

		> div:first-child {
			margin-bottom: 0.5em;
		}

		img {
			max-width: 100%;
			margin-bottom: 1em;
		}

		&:before {
			position: absolute;
			bottom: -20px;
			@include left(0);
			display: block;
			width: 100%;
			height: 20px;
			content: " ";
		}
		&:after {
			position: absolute;
			top: 30px;
			@include left(-10px);
			width: 0;
			height: 0;
			content: " ";
			border-top: solid transparent 10px;
			@include border-right(solid #fefefe 10px);
			border-bottom: solid transparent 10px;
		}
	}
	&:hover .tool-tip__popover, &:focus .tool-tip__popover {
		pointer-events: auto;
		opacity: 1;
		transform: translateY(0px);
	}
}

/* This bridges the gap so you can mouse into the tooltip without it disappearing */
/* CSS Triangles - see Trevor's post */
/* IE can just show/hide with no transition */

.lte8 .wrapper {
	.tool-tip {
		display: none;
	}
	&:hover .tool-tip {
		display: block;
	}
}
