// weibo styles
.weibo {
	position: relative;
	width: 100%;
	height: 100%;

	iframe {
		position: absolute;
		top: 0;
		@include left(0);
		@include right(0);
		background-color: $f-grey-light;
		height: 100%;
	}

	&__loading {
		position: relative;
		width: 100%;
		height: 100%;
		z-index: 2;
		opacity: 1;

		text-align: center;

		transition: opacity $long;
	}

	&__spinner {
		position: relative;
		width: 100%;
		height: 100%;
	}

	&__spin {
		position: absolute;
		top: 50%;
		@include left(50% !important);
		transform: translate(-50%, -50%);
	}

	[class^='glyph-'] {
		position: absolute;
		top: 45%;
		@include left(50% !important);
		transform: translate(-50%, -50%);

		font-size: 3em;

		animation-name: flash;
		animation-duration: 2s;
		animation-fill-mode: both;
		animation-iteration-count: 100;
	}

}

@keyframes flash {
	from, 50%, to {
		opacity: 1;
	}

	25%, 75% {
		opacity: 0;
	}
}
