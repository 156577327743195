// tripadvisor styles

.tripadvisor-sandbox {
	width: 100%;

	padding: 0;
	@include margin(0, 0, 0, -10px);
	overflow: hidden;
	border: 0;
}

// Included inline in the TA iframe. Leave here for update purposes to compile the SASS

.trip-advisor {
	@include mobile {
		margin-bottom: 16px;
	}

	&__link {
		padding: 0 3px;
		color: $t-gold;
		border-bottom: 1px solid transparent;
		transition: border $quick;

		&:hover {
			border-bottom: 1px solid $t-standard-light;
		}
	}

	&__row{
		margin-top:15px;
	}

}
