.feature-banner {
	position:relative;
	min-height: 350px;
    height: 55vh;
	
	@include landscapeTablet {
		height: 60vh;
		min-height:450px;
	}
	
	&__content {
		position: absolute;
		top: 50%;
		right: 20px;
		left: 20px;
		z-index: 1;
		text-align: center;
		-webkit-transform: translateY(-50%);
		-ms-transform: translateY(-50%);
		transform: translateY(-50%);
		
		h2 {
			margin:5px 0 15px;
		}
		
		h3 {
			margin:0;
		}
		
		img {
			max-width:100%;
		}
		
		.btn {
			margin-top:20px;
			width:auto;
			min-width:50%;
			
			@include tablet {
				min-width:auto;
			}
			
			@include desktop {
				min-width:auto;
			}
		}
		
		.slick-slide & {
			img {
				display: initial;
			}
		}
	}
	
	&--aligned-left {
		height:65vh;
		
		.feature-banner__content {
			@include right(auto);
			@include left(20px);
			text-align:initial;
		}
	}
	
	.full-bg {
		height:100%!important;
	}
}