// gallery-column styles
.gallery-column {
	position: relative;
	min-height: 550px;
	transition: width $medium;
	contain: strict;
	@include mobile {
		min-height: 400px;
	}
	@include desktop {
		&.is-active {
			width: 50%;
		}

		&.is-small {
			width: 25%;

			.gallery-column__overlay {
				background: rgba(0, 0, 0, .8);
			}

			.gallery-column__text {
				opacity: 0;
			}
		}
	}

	&__link {
		position: absolute;
		top: 0;
		@include right(0);
		bottom: 0;
		@include left(0);
		height: 100%;
		overflow: hidden;
		color: #fff;
		text-decoration: none;
	}

	&__image {
		z-index: 10;
		height: 100%;
		background-repeat: no-repeat;
		background-position: center;
		background-size: cover;
	}

	&__overlay {
		position: absolute;
		top: 0;
		@include right(0);
		bottom: 0;
		@include left(0);
		background: linear-gradient(to top, rgba(0,0,0,.7) 0%,rgba(0,0,0,.35) 30%,rgba(0,0,0,0) 50%,rgba(255,255,255,0) 100%);
		transition: background $medium;
		will-change: background;
	}

	&__text {
		position: absolute;
		@include right(0);
		bottom: 0;
		@include left(0);
		display: block;
		padding: 40px;
		pointer-events: none;
		opacity: 1;
		transition: opacity $medium;
		will-change: opacity;
	}

	&__title {
		display: block;
		margin-top: 0;
		margin-bottom: 10px;
	}

	&__sub-title {
		display: block;
	}
}
