// currency styles
.currency-converter {
	padding: 0;
	display: inline-block;
	position: relative;
	font-size: 1.125em;
	color: #fff;

	.text-reveal__from-rate & {
		font-size: 1em;
	}

	.dynamic-list__item & {
		font-size: 0.9em;

		@include mobile {
			font-size: 1.25em;
		}
	}

	.account-mobile & {
		@include float(right);
		@include margin-right(12px);
	}

	& + .item-price {
		@extend %t-m;
		font-weight: 400;
		@include margin-left(10px);
		display: inline-block;

		.text-reveal__from-rate & {
			@extend %t-s;
			letter-spacing: 0.1em !important;
			@include margin-left(8px);
			font-size: 1.1em;

			@include mobile {
				font-size: 1.2em;
			}
		}

		.dynamic-list__item & {
			@extend %t-sm;
		}
	}

	&__unit {
		position: relative;
		@include padding-right(1em);
		@include margin-right(0.35em);
		border-bottom: 1px dashed $t-standard;

		&:after {
			$triangle-size: 8px;

			content: '';
			display: block;
			position: absolute;
			top: 0.45em;
			@include right(0.25em);
			width: $triangle-size;
			@include margin-left(1em);
			border-top: $triangle-size solid $t-standard;
			@include border-right($triangle-size/3*2 solid transparent);
			@include border-left($triangle-size/3*2 solid transparent);
		}
	}

	&__amount {

	}

	.form {
		padding: 0;

		.form-main, .trigger{
			&:after {
				$triangle-size: 6px;
				content: '';
				display: block;
				position: absolute;
				top: 50%;
				@include right(5px);
				width: $triangle-size;
				@include margin-left(1em);
				border-top: $triangle-size solid #fff;
				@include border-right($triangle-size/3*2 solid transparent);
				@include border-left($triangle-size/3*2 solid transparent);

				.listing-with-gallery &, .content-intro-complex &, .dynamic-list__content &, .compare-modal__price &{
					border-top-color: $f-gold;
					top: 18px;
				}

				.is--campaign &{
					border-top-color: $f-gold;
				}

				.is-destinations &{
					border-top-color: $f-gold;
				}
			}
		}
	}

	select {
		@extend %font-body;
		
		display: block;
		font-size: 1em;
		margin-top: 7px;
		@include padding-right(15px);
		@include padding-left(5px);
		color: #fff;
		background: none;
		border: 0;
		border-bottom: 2px solid $f-gold;
		border-radius: 0;
		appearance: none;
		-webkit-appearance: none;
		-moz-appearance: none;
		position: relative;
		z-index: 1;
		.listing-with-gallery &, .content-intro-complex &, .dynamic-list__content &, .compare-modal__price & {
			color: $f-gold;
			font-size: 1.40em;
		}

		@include mobile {
			.other-offers & {
				color: $t-standard;
			}
		}

		@include mobile {
			display: block;
			font-size: 1em;
		}

		option{
			color:$t-standard;
			margin: 5px 0;
			padding: 5px;
			text-align: center;
			font-size: 1em;

			@include desktop{
				font-size: 0.9em;
			}
		}

		.is--campaign &{
			color:$t-gold;
		}
	}

	&.visually-hidden{
		display:none;
	}
}

.item-price{
	&.discounted{
		display:none;
	}
}

/*Members rate condition*/

body.is-members{
	.item-price{

		&.discounted{
			display:inline-block;
			font-size: 1.2em;
		}

		&.strike{
			display:inline-block;
			text-decoration: line-through;
			@include margin-right(8px);
			font-size: 0.9em;
		}

	}
}





