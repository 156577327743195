// text-carousel styles
// image-carousel styles

.text-carousel {
	width: 100%;
	height: 100%;

	@include desktop {
		width: calc(100% - 40px);
		padding: 0 20px;
		@include margin-left(20px);
	}

	&__link {
		position: relative;
		display: block;
		padding: 35px 22px;
		@include margin-right(35px);

		@include border-right(1px solid #eee);

		> img {
			max-width: 100%;
			margin: 0 auto;
			box-shadow: 0 15px 19px 1px rgba(0, 0, 0, .15);
		}

		@include mobile {
			@include border-right(0px);
			@include margin-left(10px);
		}
	}

	&.no-shadow {
		.simple-carousel__link {
			> img {
				box-shadow: none;
			}
		}
	}

	&__date {
		margin-bottom: 1em;
	}

	&__day {
		display: inline-block !important;
		width: auto !important;
		font-weight: 200; // Light
		line-height: 1.25;
		color: $t-gold;
	}

	&__monthyear {
		display: inline-block;
		@include padding-left(1em);
	}

	&__month {
		display: block;
		line-height: 1;
	}

	&__year {
		display: block;
	}

	&__title {

	}

	&__description {

	}
	&__ctas {
		position: absolute;
		bottom: 0;
		@include left(0);
		@include right(10px);
	}

}
