
// listing-with-gallery styles

.listing-with-gallery {
    margin-bottom: 20px;
    color: $t-standard;
    display: flex;
    flex-direction: column;
    @include desktop {
        margin-bottom: 0;
        &--less-padding {
            padding: 20px 10px;
        }        
        padding: 15px;
    } 
    
    @include tablet {
        padding: 15px;
    }

    .slick-dotted.slick-slider {
        margin-bottom: 0;
    }

    &__wrapper {
        background-color: #fff;
        box-shadow: 0 15px 19px 1px rgba(0, 0, 0, .15);
        transition: box-shadow $slow;
        display: flex;
        flex-direction: column;

        .section--white & {
            background-color: $f-grey-light-border;
        }
    }

    &__gallery {
        box-shadow: 0 15px 19px 1px rgba(0, 0, 0, .15);

        .gallery, .gallery__item {
            object-fit: cover;
            width: 100%;
            height: 100%;
        }
        .gallery__item.slick-slide {
            padding: unset !important;
        }
    }

    &:hover, &:focus {
        .listing-with-gallery__wrapper {
            box-shadow: 0 15px 19px 1px rgba(0, 0, 0, .35);
        }
    }

    &--is-carousel {
        > .slick-list > .slick-track {
            padding-bottom: 10px;
        }
        .slick {

            &-dots {
                li {
                    button {
                        &:before {
                            border-color: $t-standard-faint;
                        }
                    }
                    &.slick-active button:before {
                        background-color: $t-standard-faint;
                    }
                }
            }
            &-slide {
                padding: 0 20px;
                @include tablet {
                    padding: 0 10px !important;
                }
                @include desktop {
                    padding: unset !important;
                }
            }
        }
    }

    &--wide {
        @extend %clearfix;
        flex-direction: column;

        @include desktop {
            flex-direction: row;
        }

        .listing-with-gallery {
            &__gallery {
                @extend .col-7;
                @extend [class^="col-"];
            }

            &__wrapper {
                @extend .col-5;
                @extend [class^="col-"];

                @include tablet {
                    width: 100%;
                }
            }

            @include desktop {
                &__content {
                    @include padding-left(60px);
                    @include padding-right(60px);

                    > .t-m {
                        line-height: 1.15;
                        margin-bottom: 0.5em;
                    }
                }
                &__cta {
                    @include padding-left(20px);
                    @include padding-right(20px);
                }
            }
        }
        .icon-list .rte-block__list [class*='glyph-'] {
            font-size: 1.6em;
            vertical-align: bottom;
        }
    }

    &__content {
        flex: 1;
    }

    &__content, &__price {
        @extend %clearfix;
        @include padding(1.5em, 25px, 0, 25px);
        margin-bottom: 0;
        background-color: #fff;

        .section--white & {
            background-color: $f-grey-light-border;
        }

        @include desktop {
            padding: 1.5em 30px 0 30px;
        }

        > .listing-with-gallery__title {
            margin-bottom: 0.5em;
        }
    }

    &__date, &__location {
        margin-bottom: 0.5em;
    }

    &__date {
        @extend %ltrForce;
        @include text-align(left);
    }

    &__title {
        margin: 0;
        a:hover, a:focus {
            text-decoration: underline;
        }
    }

    &__price {
        @include desktop {
            padding-top: 1em;
            padding-bottom: 1em;
        }
        padding-top: 0.5em;
        padding-bottom: 0.8em;
        margin-bottom: 16px;
        background-color: $f-grey-faint;

        .section--white & {
            background-color: #ddd;
        }

        &-caption {
			@extend %font-alternate-body;
			
            @include margin-right(0.5em);
        }

        span {
            display: inline-block;
            padding: 0;
        }
    }

    &__cta {
        padding: 0 25px;
        width: 100%;
        overflow: hidden;
        background-color: #fff;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;


        @include desktop {
            padding: 0 30px;
        }

        .section--white & {
            background-color: $f-grey-light-border;
        }

        .btn {
            $btnSpacing: 8px;
            $space: 5px;
            // width: calc(50% - #{$btnSpacing});
            flex: 1 1 calc(50% - #{$btnSpacing});

            margin: 0;
            @include margin-right($btnSpacing);
            margin-bottom: $btnSpacing * 2;

            + .btn {
                @include margin-right(0);
                // @include margin-left($btnSpacing - $space);
            }

            &.btn--full-width {
                @include margin-left(0);
                @include margin-right(0);
            }

            .glyph-chevron-right {
                @include rtl {
                    transform: rotate(-180deg);
                }
            }
        }
    }

    .rte-block__list {
        padding-bottom: 0;
    }

    .features-special {
        margin-bottom: 20px;

        [class^='glyph'] {
            color: $f-icon-color;
        }
    }

    .feature__item {
        width: 100%;
    }

    @include mobile {
        .t-underline:after {
            margin-top: 10px;
            margin-bottom: 10px;
        }

        .feature__item {
            margin-bottom: 5px;
        }

        .features-special {
            [class^='glyph'] {
                @include margin-right(10px);
                color: $f-icon-color;
            }
        }
    }
}

.membership-item {
    padding: 20px;
    margin: 0 1% 20px;
    color: $t-standard;
    width: 23%;
    display: inline-block;
    vertical-align: top;
    text-align: center;

    &:first-child {
        @include margin-left(0);
    }

    &:last-child {
        @include margin-right(0);
    }

    ul {
        @include padding-left(20px);
    }

    @include mobile {
        margin: 0 0 20px;
        width: 100%;
    }

    @include tablet {
        font-size: 0.9em;
        width:48%
    }

    img {
        max-width: 100%;
    }

    .membership-name {
        font-size: 1.5em;
        margin: 10px 0;
        @include text-align(left);
    }

    .membership-features {
        @extend %t-c-m;
        @include text-align(left);
    }

}
