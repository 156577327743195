/* Slider */

.slick-slider {
	position: relative;
	z-index: 5;
	display: block;
	touch-action: pan-y;
	user-select: none;

	-webkit-touch-callout: none;
	-webkit-tap-highlight-color: transparent;
	
	.section--pbs > &, .section--pbm > & {
		padding-bottom:1.5em;
	}
}
.slick-list {
	position: relative;
	display: block;
	padding: 0;
	margin: 0;
	overflow: hidden;

	&:focus {
		outline: none;
	}

	&.dragging {
		cursor: pointer;
		cursor: hand;
	}
}
.slick-slider .slick-track,
.slick-slider .slick-list {
	transform: translate3d(0, 0, 0);
}

.slick-track {
	position: relative;
	top: 0;
	@include left(0);
	display: block;

	&:before,
	&:after {
		display: table;
		content: "";
	}

	&:after {
		clear: both;
	}

	.slick-loading & {
		visibility: hidden;
	}
}
.slick-slide {
	display: none;
	@include float(left);
	height: 100%;
	min-height: 1px;

	&:focus, > a:focus {
		outline: 0;
	}

	img {
		display: block;
	}

	&.slick-loading img {
		display: none;
	}

	&.dragging img {
		pointer-events: none;
	}

	.slick-initialized & {
		display: block;
	}

	.slick-loading & {
		visibility: hidden;
	}

	.slick-vertical & {
		display: block;
		height: auto;
		border: 1px solid transparent;
	}
}

.slick-arrow.slick-hidden {
	display: none;
}


@charset "UTF-8";

// Default Variables

// Slick icon entity codes outputs the following
// "\2190" outputs ascii character "←"
// "\2192" outputs ascii character "→"
// "\2022" outputs ascii character "•"

$slick-font-path: "./fonts/" !default;

$slick-font-family: "slick" !default;

$slick-loader-path: "/" !default;

$slick-arrow-color: white !default;

$slick-dot-color: white !default;

$slick-dot-color-active: $slick-dot-color !default;

$slick-prev-character: "\2190" !default;

$slick-next-character: "\2192" !default;

$slick-dot-character: "\2022" !default;

$slick-dot-size: 6px !default;

$slick-opacity-default: .75 !default;

$slick-opacity-on-hover: 1 !default;

$slick-opacity-not-active: .25 !default;

@function slick-image-url($url) {
	@if function-exists(image-url) {
		@return image-url($url);
	}
	@else {
		@return url($slick-loader-path ) $url;;
	}
}

@function slick-font-url($url) {
	@if function-exists(font-url) {
		@return font-url($url);
	}
	@else {
		@return url($slick-font-path ) $url;;
	}
}

/* Slider */

.slick-list {
	.slick-loading & {
		background: #fff center center no-repeat;
	}
}

/* Icons */
@if $slick-font-family == "slick" {
	@font-face {
		font-family: "slick";
		font-style: normal;
		font-weight: normal;

		src: slick-font-url("slick.eot");
		src: slick-font-url("slick.eot?#iefix") format("embedded-opentype"), slick-font-url("slick.woff") format("woff"), slick-font-url("slick.ttf") format("truetype"), slick-font-url("slick.svg#slick") format("svg");
	}
}

/* Arrows */

$triangle-size: 20px;

$triangle-size-small: 15px;

.slick-prev,
.slick-next {
	position: absolute;
	z-index: 19;
	top: 50%;
	display: block;
	width: 20px;
	height: 30px;
	padding: 0;
	font-size: 0;
	line-height: 0;
	color: transparent;
	cursor: pointer;
	background: transparent;
	border: none;
	outline: none;
	transform: translate(0, -50%);

	&:hover, &:focus {
		color: transparent;
		background: transparent;
		outline: none;

		&:before {
			opacity: $slick-opacity-on-hover;
		}
	}

	&.slick-disabled:before {
		opacity: $slick-opacity-not-active;
	}

	&:before {
		display: block;
		width: $triangle-size;
		color: $slick-arrow-color;
		content: "";
		opacity: $slick-opacity-default;
		transition: opacity $quick;
	}
}

.slick-prev {
	@include left(-3px);

	@include desktop {
		@include left(-25px);
	}

	&:before {
		border-top: $triangle-size-small/3*2 solid transparent;
		@include border-right($triangle-size-small solid $f-gold);
		border-bottom: $triangle-size-small/3*2 solid transparent;

		@include desktop {
			border-top: $triangle-size/3*2 solid transparent;
			@include border-right($triangle-size solid $f-gold);
			border-bottom: $triangle-size/3*2 solid transparent;
		}
	}
}

.slick-next {
	@include right(-3px);

	@include desktop {
		@include right(-25px);
	}

	&:before {
		border-top: $triangle-size-small/3*2 solid transparent;
		border-bottom: $triangle-size-small/3*2 solid transparent;
		@include border-left($triangle-size-small solid $f-gold);

		@include desktop {
			border-top: $triangle-size/3*2 solid transparent;
			border-bottom: $triangle-size/3*2 solid transparent;
			@include border-left($triangle-size solid $f-gold);
		}
	}
}

/* Dots */

.slick-dotted.slick-slider {
	@include desktop {
		margin-bottom: 30px;
	}
}

.slick-dots {
	position: absolute;
	bottom: 5px;
	left: 0;
	right: 0;
	display: block;
	width: 100%;
	padding: 0;
	margin: 0;
	text-align: center;
	list-style: none;

	li {
		position: relative;
		display: inline-block;
		width: 10px;
		height: 10px;
		padding: 0;
		margin: 0 5px;
		cursor: pointer;

		button {
			display: block;
			width: 10px;
			height: 10px;
			padding: 5px;
			font-size: 0;
			line-height: 0;
			color: transparent;
			cursor: pointer;
			background: transparent;
			border: 0;
			outline: none;

			&:hover, &:focus {
				outline: none;

				&:before {
					opacity: $slick-opacity-on-hover;
				}
			}

			&:before {
				position: absolute;
				top: 0;
				@include left(0);
				width: 7px;
				height: 7px;
				font-family: $slick-font-family;
				font-size: $slick-dot-size;
				line-height: 20px;
				color: $slick-dot-color;
				text-align: center;
				content: "";
				background-color: transparent;
				border: 2px solid $slick-dot-color;
				border-radius: 50%;
				opacity: 1;

				-webkit-font-smoothing: antialiased;
				-moz-osx-font-smoothing: grayscale;

				.section--gold & {
					border-color: #fff;
				}
				.section--dark-grey &, .events-listing & {
					border-color: $f-gold;
				}
				.section--blue-blue-tone & {
					border-color: $t-standard-faint;
				}
			}
		}

		&.slick-active button:before {
			background-color: $slick-dot-color;
			opacity: 1;

			.section--gold & {
				background-color: #fff;
			}
			.section--dark-grey &, .events-listing & {
				background-color: $f-gold;
			}
			.section--blue-blue-tone & {
				background-color: $t-standard-faint;
			}
		}
	}
}
