.notification {
    width:auto;
    padding: 0.5em 0.9em;
    border: 1px solid $f-grey-light;
    border-radius: 4px;
    background: $f-grey-faint;
    margin: 20px auto;

    @include tablet {
        margin: 0 20px;
    }

    @include desktop {
        margin: 5px 20px;
        max-width: 30em;
    }

    .content-intro-complex__text &, .content-intro__text &{

        @include tablet {
            margin: 20px 0;
            width: 100%;
        }

        @include desktop {
            margin: 20px 0 0 0;
        }

    }

    [class^="glyph-"], [class*=" glyph-"]{
        width: 18px;
        height: 18px;
        border:1px solid $t-standard-faint;
        border-radius:50%;
        @include margin-right(5px);
        padding: 3px 6px;
        font-size: 10px;
        color:$t-standard-faint;
    }

    .notification-text{
        font-size:0.83em;
        margin: 8px 0;
        padding:0;
    }
}

.smart-widget {
	padding:20px;
	background:#eee;
	min-width: 90%;
	min-height:90%;

    @include tablet {
		min-width: 560px;
		min-height:300px;
    }

    @include desktop {
		min-width: 560px;
		min-height:300px;
    }
}

.smt-modal-tripinfo, .smt-modal-mobilemap-tripinfo {
	z-index:10000!important;
}