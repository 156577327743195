.intro-video {
	position: relative;
	color: #fff;
	// contain: strict;
	min-height: 320px;

	@include mobile {
		min-height: 56.25vw;
	}

	.newsletter-video & {
		max-width: 570px;
		margin: 0 auto 20px;
	}

	&.is-fullwidth{
		min-height:56.25vw;

		@include desktop {
			min-height:46.25vw;
		}
	}

	&.is-header-banner{
		min-height:56.25vw;

		@include desktop {
			min-height:700px;
		}
	}

	.content-intro-complex--video & {

		width: 100%;
		height: 50vw;

		@include desktop {
			width: 85%;
			height: 320px;
		}
	}

	&__image {
		height: 100%;
		background-repeat: no-repeat;
		background-position: center;
		background-size: cover;
		.newsletter-video & {
			width:100%;
			position:absolute;
		}

		.is-fullwidth &{
			min-height:56.25vw;

			@include desktop {
				min-height:46.25vw;
			}
		}


		.is-header-banner &{
			min-height:56.25vw;

			@include desktop {
				min-height:700px;
			}
		}
	}

	&__video {
		position:absolute;
		top:0;
		left:0;
		bottom:0;
		right:0;
		background:#000;
		display:none;

		&.active {
			display:block;
		}
	}

	&__player {
		position: relative;
		z-index: 10;
		width: 100%;
		height: 100%;

		object-position: center center;

		.is-ie &, .is-edge & {
			height: auto;
			top: 50%;
			transform: translateY(-50%);
		}

		.is-header-banner &{
			height: 100%;
			background:#000000;
			@include desktop {
				object-fit: cover;
			}
		}
	}

	&__content{
		position: absolute;
		top: 50%;
		left:50%;
		z-index: 5;
		text-align: center;
		transform: translate(-50%,-50%);
		width:100%;
		max-width: 1320px;
		margin: 0 auto;
		padding: 0 15px;

		.t-xl{
			margin:5px 0 15px 0;
			@include mobile{
				font-size:1.4em;
			}

		}
	}

	&__play {
		display: block;
		width: 94px;
		height: 94px;
		margin:auto;
		text-align: center;
		background-color: rgba(0, 0, 0, .3);
		border-radius: 50%;
		position: relative;

		@include mobile{
			width: 74px;
			height: 74px;
		}

		&:before {
			position: absolute;
			top: 0;
			@include right(0);
			bottom: 0;
			@include left (10px);
			width: 0;
			height: 0;
			margin: auto;
			content: '';
			border-color: transparent transparent transparent #fff;
			border-style: solid;
			border-width: 23.5px 0 23.5px 40px;

			@include mobile{
				border-width: 21.5px 0 21.5px 31px;
			}
		}
	}
}
