// section-footer styles
.section-footer {
	position: relative; // Object fit fix
	z-index: 10;
	padding-top: 32px;
	padding-bottom: 98px;
	text-align: center;
	@include mobile {
		padding-top: 20px;
		padding-bottom: 50px;
	}
	@include tablet {
		padding-bottom: 70px;
	}

	&__text {
		display: block;
		padding-top: 28px;
		// color: #fff;
		color: $t-standard;
	}

	.btn {
		margin-top: 18px;

		@include mobile {
			width: 90%;
			min-width: 90%;
		}
	}
}
