// hotels styles

.footer-hotels {
    // background: #0e0e0d;
    background: #4a4a4a;
    border-top: 1px solid $f-grey-border;
    padding: 10px 0;

    @include desktop{
        padding: 20px 0;
    }
    
	.naladhu & {
		border-top:1px solid #8998a0
	}
    .brand-lockup-text {
        color: white;
        text-align: center;
        font-size: .75em;
        margin-bottom: 40px;
        line-height: 1.5em;
        padding-inline: 20px;
		
        a {
			border-bottom: 1px solid #fff;
        }
        @include tablet {
            margin-bottom: 20px;
        }
        @include mobile {
            margin-bottom: 20px;
            margin-inline: auto;
           
        }
    }
}

.footer-brand {
    list-style: none;
    padding: 0;
    width: 100%;
    margin: 0 auto;
    text-align: center;

    @include mobile{
        text-align: center;
    }

    @include tabletSmall{
        text-align: center;
    }

    @include landscapeTablet {
        display: flex;
    }
    @include desktop {
        display: flex;
    }
}

.footer-brand__item {
    width: 20%;
    float: none;
    display: inline-block;
    vertical-align: middle;
    padding: 0px;
    // @include margin(0,5px,0,5px); 

    @include mobile{
        width: 20%;
        margin-bottom: 10px;
    }

     @include tablet {
        width: 17%;
        margin-bottom: 10px;
     }

     @include landscapeTablet{
        width: 9%;
     }

    @include desktop {
        width: 10%;
    }

    img {
        // max-width: 100%;
        max-height: 100%;
        transition: all .25s;
        margin: 0 auto;
        display: block;
        filter: contrast(0%) brightness(2);
    }
}

.footer-brand__link {
    display: block;
    width: 100%;
    height: 30px;
    margin: 0 auto;
    
    position: relative;

    @include desktop{
        height: 50px;
    }

    @include tablet{
         height: 40px;
    }

    &.single {
        &:hover, &:focus, &:active {
            .img {
                filter: none;
                opacity: 1;
            }
        }
    }

    &:hover, &:focus, &:active {
        .colored {
            opacity: 1 !important;
        }

        .img {
            filter: none;
            opacity: 0;
        }
    }
}

.main-footer-brand {
    @include margin(0,auto,8px,auto);
    padding: 0;
    display:block;

    @include desktop{
        display:inline-block;
        width:125px;
        // @include margin(0,15px,0,0);
        @include border-right(1px solid $f-grey-border);
        @include padding-right(15px)
    }

    @include landscapeTablet{
        display:inline-block;
        width:105px;
        @include margin(0,10px,0,0);
        @include border-right(1px solid $f-grey-border);
    }

    .img {
        max-width: 100%;
        width: auto;
        opacity: .65;
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
        margin: auto;

        @include desktop{
            margin: 0;
        }

    }

    .colored{

        @include desktop{
            margin: 0;
        }
    }



    .footer-brand__link{
         &:hover, &:focus, &:active {
            .colored {
                opacity: 1 !important;
            }

            .img {
                opacity: 0;
            }
        }
    }

}

.footer-hotels{

    &.with-categories{
        padding: 50px 0 30px;
        margin: auto;
		text-align:center;
		
        @include desktop {
            padding-top: 70px;
        }

        .container{
            padding:40px 20px;
            border:1px solid rgba(255,255,255,0.2);
            margin: 0 20px;
			
            @include lgDesktop {
                margin: 0 auto;
            }
            @include desktop{
                padding: 40px 70px 20px;
                display: inline-block;
            }
            
        }
        .t-c-m {
            color:#fff;
            font-size:12px;
            margin-bottom: 15px;
            letter-spacing: 2px;
			white-space:nowrap;
			
			width: 100%;
			padding-bottom: 10px;
			text-align: center;
			border-bottom: 1px solid rgba(255, 255, 255, 0.2);
			
            @include desktop {
                font-size: 14px;
				width: auto;
				padding-bottom: 0;
				text-align: left;
				border-bottom: unset;
                margin-bottom: 0px;
            }
        }

        .footer-head{
            margin-bottom:20px;
        }

        .footer-brand-category {
            margin-bottom:20px;
            display: flex;
            align-items: center;
            justify-content: space-around;
            flex-direction: column;

            @include desktop{
                flex-direction: row;
                position: relative;
                padding-bottom: 20px;
				border-bottom:1px solid rgba(255,255,255,0.2);
                gap:50px;
            }

            .footer-brand {
                display: flex;
                justify-content: center;
                gap: 10px;
                flex-wrap: nowrap;
                
                &:has(> li:nth-last-child(4n):first-child) {
                    flex-wrap: wrap;
                    max-width: 350px;
                    @include no-mobile {
                        max-width: unset;
                        flex-wrap: unset;
                    }
                }
                &:has(> li:nth-last-child(5):first-child) {
                    flex-wrap: wrap;
                    gap: 5px;
                    max-width: 350px;
                    @include no-mobile {
                        max-width: unset;
                        flex-wrap: unset;
                    }
                    .footer-brand__item {
                        max-width: 100px;
                    }
                }
                @include desktop{
                    gap: 25px;
                    justify-content: flex-start;
                    flex-wrap: unset;
                }

                .footer-brand__item {
                    max-width: 110px;
                    width: 100%;

                    .footer-brand__link {
                        height:80px;
                    }

                    .img {
                        opacity: 1;
                        max-width: 100%;
                        max-height: 100%;
                        filter: contrast(0%) brightness(2);
                    }
                    .lazyload.img, img.lazyloading {
                        filter: none;
                    }

                    @include lgDesktop {
                        min-width: 220px;
                    }
                    @include desktop{
                        min-width: 180px;
                        width: 100%;
                        flex: unset;
                        margin: 0;
                    }

                }
            }

            &.head{
				max-width:unset;
                border-bottom:0;
                position: unset;
                padding-bottom: 0;

                ul{
                    justify-content: center;
                }

                .footer-brand__item{
                    position: absolute;
                    background: #4a4a4a;
                    max-width: 140px;
                    top: -13px;
                    margin: unset;
                    @include no-mobile {
                        top: -20px;
                        min-width: 200px;
                    }

                    .footer-brand__link{
                        height:33px;
                        @include no-mobile {
                            height: 50px;
                        }
                    }
                }
            }

            &.foot{
				max-width:unset;
                border-bottom:0;
                position: unset;
                padding: 0;
                margin: 0;
                
                ul{
                    justify-content: center;
                }
                .footer-brand__item{
                    position: absolute;
                    bottom:-16px;
                    background: #4a4a4a;
                    min-width:280px;
                    .footer-brand__link {
                        height: fit-content;
                    }
                }
            }

            &.no-border{
                border-bottom:0;
                padding-bottom: 0px;
            }
        }
    }
}
