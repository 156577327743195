// Badge styles

.badge {
	background-color: $f-gold;
	color: #fff;
	padding: 3px 5px;
	position: absolute;
	z-index: 6;
	max-width: 200px;

	&__parent {
		position: relative;
	}

	&--oneLine {
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}

	&--offsetLeft {
		@include left(-5px);

		.badge__parent:not(.has-border):not(.hotel-directory *) & {
			&:not(.content-intro-complex *) {
				@include left(5px);
			}
		}
	}

	&--offsetRight {
		@include right(-5px);

		.badge__parent:not(.has-border):not(.hotel-directory *) & {
			@include right(5px);
		}
	}

	&--left {
		@include left(10px);

		.badge__parent:not(.has-border):not(.hotel-directory *) & {
			@include left(20px);
		}
	}

	&--right {
		@include right(10px);

		.badge__parent:not(.has-border):not(.hotel-directory *) & {
			@include right(20px);
		}
	}

	&--top {
		top: 10px;

		.badge__parent:not(.has-border):not(.hotel-directory *) & {
			top: 20px;
		}
	}

	&--bottom {
		bottom: 10px;

		.badge__parent:not(.has-border):not(.hotel-directory *) & {
			bottom: 20px;
		}
	}

	&--large {
		padding: 5px 10px;
		display: flex;
		align-items:center;
	}

	i {
		padding-right: 0.25em;
		vertical-align: middle;
		font-size:0.9em;
	}
}