// table styles
table {
    border-top: 1px solid $f-grey-light;
    border-bottom: 1px solid $f-grey-light;
    border-collapse: collapse;
    border-spacing: 0;
    width: 100%;

    &:not(.pika-table) {
    	margin-bottom: 20px;
    }

    thead {
		th {
			background-color: $f-gold;
		}
    }

    tr.table-row {
    	border-bottom: none !important;
   	}

    tr {
    	border-bottom: 1px solid $f-grey-light;
    }

    th, td:not('.tablesaw') {
    	border-bottom: 1px solid $f-grey-light;
    }
    th, td {
        padding: 5px 10px;
        vertical-align: top;
    }

    @include mobile {
    	td.table-col {
    		width: 100% !important;
    	}

		td:first-child .js-open-details:after {
			display: inline-block;
		}

		.table-row {
			td:last-child {
				display: table-cell;
			}
		}

    }

	&.tbl {
		&-no-border {
			border:0;

			thead, tbody, tr, td, th {
				border:0;
			}
		}

		&-auto-width {
			width:auto;
		}
	}
}

.tablesaw {
	td {
		text-align: center;
	}
}

.table-hero--events table {
	border: none !important;

	&.tablesaw-stack tbody tr {
		border: 0;
	}

	.table-row {
		.tablesaw-cell-content {
			width: 100%;
			max-width: 100%;
		}

		td {
			padding: 0;
		}
	}

	.js-open-details {
		@include padding-right(30px);
		position: relative;

		&:after {
			content: '\25BC';
			display: inline-block;
			font-size: 12px;
			width: 20px;
			height: 20px;
			@include margin-left(5px);
			color: $t-gold;
			position: absolute;
			@include right(5px);
		}
	}

	.show {
		.js-open-details {
			&:after {
				content: '\25B2';
			}
		}

		+ .table-row td {
    		border-bottom: 1px solid $f-grey-light;
		}
	}

	td:only-child {
		display: table-cell;
	}

	td:last-child .js-open-details {
		text-decoration: underline;
	}

	@include mobile {
		td:last-child {
			display: block;
		}

		td:first-child .js-open-details:after {
			display: none;
		}
	}
}


.table-container {
	width: 100%;
	margin: auto;
}

.fixed {
	top: $header-height-desktop--scrolled;
	position: fixed;
	width: auto;
	z-index: 10;
	display: none;
	border: none;

	@include tablet {
		top: 60px;
	}

	@include mobile {
		position: static;
	}

	.table-preview & {
		top: 0;
	}
}

.js-print-table {
    @include desktop {
        display: none;
    }
    display: none;
}

.table-wrapper {
    margin-bottom: 50px;
    overflow-x: auto;
    overflow-y: hidden;
    position: relative;

    &.no-scrollbar {
        border: 0;
        overflow: visible;
    }

    table {
        th, td {
            min-width: 75px;
        }
    }
}

@include mobile {
	.tablesaw .table-row td {
		width: 100% !important;
	}

	.tablesaw {
		.tablesaw-cell-label,
		.tablesaw-cell-content {
			width: 50% !important;
		}

		.tablesaw-cell-label {
			i {
				display: none !important;
			}
		}
	}
}

.responsive-table-no-head td,
.responsive-table-no-head th {
    width: auto;
    display: table-cell;
    vertical-align: top;

    &:after {
        content:'';
        display: block;
        height: 0;
        clear: both;
    }
}

@include mobile {
    .responsive-table-no-head td,
    .responsive-table-no-head th {
        display: block;
        border: 0;

        &:after {
            content:'';
            display: block;
            height: 0;
            clear: both;
        }
    }
    .responsive-table.responsive-table-no-head {
        border:0;
        border-top:1px solid #562b5b;
    }
    .responsive-table-no-head td, .responsive-table-no-head th {
        padding: 15px;
        width: 100%!important;
    }
    .responsive-table.responsive-table-no-head tbody {
        border:0;
    }
    .responsive-table.responsive-table-no-head tr {
        border-bottom:1px solid #562b5b;
        display:block;
    }
    .responsive-table.responsive-table-no-head tr:nth-child(even) {
        background:transparent;
    }
    .responsive-table.responsive-table-no-head img {
        height:auto;
    }

    table.responsive-table.stacktable.large-only {
        display: none;
    }
}

.meeting-room {
	@extend %clearfix;
	position: relative;
	padding: 1.5em;
	margin-bottom: 1em;

	background-color: $f-grey-faint;
		
	.section--gold &, .section--darker-grey & {
		color:$t-standard;
	}

	.slick-dotted.slick-slider {
		margin-bottom: 0;
	}

	&__header {
		position: relative;
		min-height: 122px;

		@include tablet {
			min-height: 245px;
		}

		@include mobile {
			position: static;
			min-height: 0;
			width: 100%;
		}
	}

	&__title {
		margin-top: 0;
		margin-bottom: 0.75em;
	}

	&__size {
		margin-bottom: 3em;
		text-transform: initial;

		@include mobile {
			margin-bottom: 1em;
		}
	}

	&__toggle {
		position: absolute;
		bottom: 0;
		padding: 0;

		@include mobile {
			position: relative;
			margin-bottom: 1em;
			text-align: center;
			border: 1px solid $f-gold;
		}
	}

	&__types__title {
		margin-top: 0;
	}

	&__content {
		margin-top: 1em;
		margin-bottom: 2em;
		padding-top: 1em;

		border-top: 1px solid #eee;

		@include mobile {
			margin-top: 0;
			margin-bottom: 1em;
			padding-top: 0.25em;
			padding-bottom: 1em;
			border-top: 0;
			border-bottom: 1px solid #eee;
		}
	}

	&__image {
		display: inline-block;
		width: 35%;
		height: 185px;

		@include padding-right(1.5em);

		> img {
			max-width: 100%;
		}

		@include tablet {
			width: 50%;
		}

		@include mobile {
			width: 100%;
			margin-bottom: 1em;
			@include padding-right(0);
		}
	}

	&__text {
		display: inline-block;
		width: 63%;
		vertical-align: top;


		.no-image & {
			width: 100%;
		}

		@include tablet {
			width: 48%;
		}

		@include mobile {
			width: 100%;
		}
	}

	&__types__list {
		margin: 0;
		padding: 0;
		list-style-type: none;
	}

	&__type {
		@include float(left);
		text-align: center;
		width: 12%;

		padding: 0 8px;

		[class*="glyph-"] {
			display: block;
			font-size: 35px;

			height: 40px;
		}

		@include desktop {
			&:first-child {
				@include padding-left(0);
			}
		}

		@include tablet {
			margin-bottom: 1em;
			padding: 0 5px;
			width: 25%;
		}

		@include mobile {
			margin-bottom: 1em;
			padding: 0;

			width: 33.333333%;
		}
	}

	&__type.is-disabled {
		opacity: 0.6;

		@include mobile {
			display: none;
		}
	}

	&__ctas {
		@include tablet {
			width: 70%;
		}
		@include mobile {
			width: 100%;
			@include margin(0.5em, auto, 0.25em, auto);

			text-align: center;

			> .btn {
				max-width: 40%;
				@include margin-left(0.5em);
				@include margin-right(0.5em);
			}
		}
	}
}
