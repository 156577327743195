// Image highlight-block styles

.image-highlight {
	&-block {
		margin:40px 0 0;
		&.loyalty-carousel {
			padding: 0 25px;
		}
		
		&.partners_logo {
			margin:0;
		}

		&.partners-logo {
			flex-wrap:  wrap;

			@include desktop {
				flex-wrap: nowrap;
			}

			.image-highlight__item {
				@include mobile {
					padding: 30px 0;
					flex-basis: 40%;
				}

				@include tablet {
					flex-basis: 25%;
				}

				@include desktop {
					flex-basis: 16%;
				}
			}
		}
		
		.container--aligned-left & {
			.flex-container {
				justify-content:normal;
			}
		}
	}

	&__container{
		margin: 0 calc(30px/-2);
		.slick-track {
			margin: 0 auto;

			#investor-support.section--lighter-grey & {
				display: flex !important;
				
				.slick-slide
				{
					height: inherit !important;
				}
			}
		}
	}

	&__wrap {
		height: 100%;
	}
	
	&__link{
		display:flex;
		height: 100%;
		flex-direction: column;
		align-items: center;
		justify-content: flex-start;
	}

	&__item {
		flex:0;
		margin: 0 calc(30px/2);
		margin-bottom:20px;
		flex-basis:100%;

		img {
			max-width:80px;
			margin: 0 auto;
			
			&.partner {
				max-width:120px;
			}

			&.logo {
				max-width: 120px;
				width: 100%;
				min-height: unset;
			}
		}
		
		@include tablet {
			flex-basis:45%;
		}

		@include desktop {
			flex-basis: 20%;
		}
		
		.partners_logo & {
			flex-basis:25%;
			height:100px;
			
			img {
				max-width: 100%;
				max-height: 100%;
				position: absolute;
				top: 50%;
				left: 50%;
				-webkit-transform: translate(-50%,-50%);
				-ms-transform: translate(-50%,-50%);
				transform: translate(-50%,-50%);
			}
		}

		#investor-support.section--lighter-grey & {
			background: $section-white-bg-color;
			padding-top: 2em;
		}
	}
	
	.partners_logo & {
		&__wrap {
			position:relative;
			height:100%;
		}
	}

	&__text {
		margin:10px 20px;
		@include desktop {
			margin: 10px;
		}
	}

	&__description {
		font-size:0.9em;
	}

	&__icon{

		font-size: 2.8em;
		background: $f-gold;
		width: 60px;
		height: 60px;
		border-radius: 50%;
		display: flex;
		align-items: center;
		justify-content: center;
		padding: 12px;
		color:white;
	}
}
