// feature-destination styles
.destination-grid {
	margin: 20px auto;

	.destination {
		&-row {
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			
			@include desktop {
				flex-direction: row;
				padding: 0 7px;
			}
		}

		&-col {
			flex: 1;
			padding: 0;
			
			@include desktop {
				margin: 0 7px;
			}

			.destination-title {
				position: absolute;
				@include left(20px);
				bottom: 20px;
				color: #FFF;
				text-transform: uppercase;

				p {
					margin: 0;
				}
			
				@include lgDesktop {
					@include left(30px);
					bottom: 30px;
				}
			}
		}

		&-item {
			display: block;
			position: relative;
			height: 200px;
			width: 100%;
			margin-bottom: 14px;
			background-size: cover;
			background-position: center;
			background-repeat: no-repeat;
			border: unset;
			
			@include desktop {
				height: 260px;
			}
			
			@include lgDesktop {
				height: 400px;
			}

			&--tall {
				background-size: cover;
				background-position: center;
				background-repeat: no-repeat;
				
				@include desktop {
					height: 534px;
				}
				
				@include lgDesktop {
					height: 814px;
				}
			}
		}
	}

	&.is-scrollable{
		white-space: nowrap;
		overflow: scroll;
		scroll-snap-type: x mandatory;
		margin:0;

		.destination-col{
			scroll-snap-align: start;
			margin: 0 0.5em;
			flex:1 0 calc(50% - 2em);

			@include desktop {
				flex: 0 0 300px;
			}
			
			@include tablet {
				flex: 1 0 calc(33% - 1em);
			}

			.destination-item{
				height:350px;

				@include lgDesktop{
					height:400px;
				}
			}
		}
	}
}