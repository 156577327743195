.coordinates{
    vertical-align: top;
    @include margin-left(-2.2em);
    position: relative;
    margin-top: 8px;
    margin-bottom: 8px;
    display:flex;

    .location-content &{
        @include margin-left(0em);
        margin-top: 0px;
        margin-bottom: 0px;
    }

    &.no-offset{
        @include margin-left(0em);
    }

    .label{
       @include margin-right(4px);
        color:$t-standard;
    }

    a {
        margin:3px 0;
        border:0;
		text-decoration: underline;
		word-break:normal;

        &.open-map {
            display:block
        }
    }

    &-group{
        word-break:break-all;
    }
}