// dynamic-map styles
.map {
    background: transparent no-repeat center;
    background-size: cover;
    position: relative;
    height: calc(100vh - #{$header-height});
    box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.25);

    background: $f-grey-light-border;

    @include tablet {
        height: calc(100vh - #{$header-height--scrolled});
    }
	
	@include desktop {
        height: calc(100vh - #{$header-height-desktop--scrolled});

        .content-block & {
            max-height: 100%;
            height: 100%;
        }
    }
}

.dynamic-map {
    z-index: 18; // On top of 'back to top' but not on top of the mega menu

    background: transparent no-repeat center;
    background-size: cover;
    overflow:hidden;
    height:100%;
    width:100%;

    .map--fallback {
        display: none;
        height: 100%;
        background-size: cover;
        background-position: center;
    }

    .content-block & {
        max-height: 100%;
        height: 100%;
    }
}

.section--center-content {
    + .half-bleed {
        padding-top: #{$header-height--scrolled + 426px};

        .dynamic-map {
            top: #{$header-height--scrolled + 426px};
        }
    }

    + .full-bleed {
        .dynamic-map {
            top: 0;
        }
    }

    @include tablet {
        + .half-bleed {
            padding-top: #{$header-height--scrolled + 559px};

            .dynamic-map {
                top: #{$header-height--scrolled + 559px};
            }
        }

        + .full-bleed {
            .dynamic-map {
                top: 0;
            }
        }
    }
}

.half-bleed {
    position: absolute;
    top: #{$header-height--scrolled};
    bottom: 0;
    @include right(0);
    z-index: 21;

    .dynamic-map {
        position: absolute;
        top: #{$header-height-desktop};
        @include right(0);
        min-width: 94%;

        &.has-sticky-now {
            min-width: 0;
        }
    }

    @include mobile {
        position: relative;
        top: 0;

        .dynamic-map {
            position: relative;
            top: 0;
        }
    }

    @include tablet {
        width: 50%;
        @include padding-left(20px);

        .dynamic-map {
            width: calc(100% - 20px);
        }
    }

    @include desktop {
        top: #{$header-height-desktop--scrolled};
    }
}

.full-bleed {
    position: relative;
    @include right(0);
    z-index: 21;
    min-height: 100vh;
    height: 100%;

    .dynamic-map {
        position: relative;
        top: 0;
        @include right(0);
        min-width: 100%;

        &.has-sticky-now {
            min-width: 0;
        }
    }
}

.dynamic-map.is-destination {
	.gm-style-iw {		
		&-c {
			padding: 0;

			@include mobile {
			}
		}

		&-d {
			-ms-overflow-style: none; /* IE and Edge */
			scrollbar-width: none; /* Firefox */
			overflow-y: scroll;

			&::-webkit-scrollbar {
				display: none;
			}
		}
		
		&-ch {
			padding-top: 0px !important;
		}
		
		button.gm-ui-hover-effect {
			opacity: 1;
			z-index: 11;
			position: absolute !important;
			top: 5px;
			@include right(5px);
			background: $t-gold !important;
			width: 25px !important;
			height: 25px !important;
			display: flex !important;
			align-items: center;
			justify-content: center;
			border-radius: 50%;
			opacity: 0.8;
			&:hover {
				opacity: 1;
			}
			img {
				display: none !important;
			}
		}
	}

    .map-custom-control {
        margin: 10px 0 0;

        .map-custom-button {
            cursor: pointer;
            background: #fff;
            padding: 10px 28px;
            font-size: 1.45em;
            @extend .t-xs;
            border-radius: 4px;
            box-shadow: 3px 13px 38px rgba(0, 0, 0, 0.25);
            transition: all 0.2s ease-in-out;

            &:hover {
                background: $f-grey-dark;
                color: #fff;
                box-shadow: 3px 13px 38px rgba(0, 0, 0, 0.45);
                transition: all 0.2s ease-in-out;
            }
        }
    }
}

.dynamic-map__info {
    font-size: 16px;
    padding-bottom: 10px;
    max-width: 350px;

	.star_rating {
		display:none;
	}
	
    p {
		@extend %font-body;
        letter-spacing: 0.5;
        font-size: 13px;
    }

    .dynamic-list__image {
        overflow: hidden;
    }

    .dynamic-map__info-group {
        @include mobile {
            max-height: 25vh;
            overflow-y: scroll;
            padding-bottom: 30px;
        }
		
		.dynamic-list__text {
			position:static;
			padding: 10px 0;
		}
    }

    .dynamic-list__text {
        padding: 10px;
			
		.dynamic-list__title {
			margin-bottom:0!important;
		}
    }

    .text-cta {
        &:hover {
            transform: none;
            color: $t-standard;
        }
    }

    &-group {
        padding: 0 10px;
    }
}

.gm-ui-hover-effect > span{
    background-color: white;
    margin: 0 !important;
    width: 20px !important;
    height: 20px !important;
}
