// button styles
%btn {
	@extend %font-cta;
	
    position: relative;
    top: 0;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    min-width: 90%;
    max-width: 400px;
    width: 100%;
    margin-bottom: 1em;
    padding: 9px 20px;
    color: #000;
    text-align: center;
    text-decoration: none;
    background: $f-op50-white;
    border: 0;
    border-radius: 2px;
    transition: background-color $medium, top .1s, padding $medium;
	
	.section--gold & {
		color:$f-gold;
		background: rgba(255, 255, 255, 0.5);
	}

    @include desktop {
        min-width: 200px;
        max-width: none;
        width: auto;
        @include margin-right(0.5em);
        margin-bottom: 0.5em;
    }
    @include tablet {
        min-width: 210px;
        max-width: none;
        width: auto;
        @include margin-right(0.5em);
        margin-bottom: 0.5em;
    }
    @include mobile {
        max-width: 100%;
    }

    &:hover, &:focus {
        background: $f-op75-white;
    }

    &:active {
        top: 2px;
    }

    [class^='glyph-'] {
        position: relative;
        display: inline-block;
        height: 18px;
        @include padding-left(4px);
        vertical-align: top;
        font-size: 1.5em;
    }
}

%btn-rte {
    @extend %btn;

    font-size: (12 / $base) * 1em;
    font-weight: 400;	// Medium
    line-height: 1.5;
    text-transform: uppercase;
    letter-spacing: (250 / 1000) * 1em;
    margin: 0;
    margin-right: 8px;
    margin-bottom: 16px;
    @include padding-left(10px);
    @include padding-right(10px);
}

.btn {
    @extend %btn;

    &__spinner {
        top: 50%;
        opacity: 0;

        transition: opacity $medium;
    }

	&.is-spinning {
		&:after{
			content: '';
			display: inline-block;
			width: 18px;
			height: 18px;
			vertical-align: bottom;
			@include margin(0, 0, 0, 5px);
			border: 3px solid rgba(255,255,255,.3);
			border-radius: 50%;
			border-top-color: #fff;
			animation: spin 1s  infinite linear;
			-webkit-animation: spin 1s infinite linear;
		}
	}

	@keyframes spin {
		from { -webkit-transform: rotate(0deg); }
		to { -webkit-transform: rotate(359deg); }
	}
	@-webkit-keyframes spin {
		from { -webkit-transform: rotate(0deg); }
		to { -webkit-transform: rotate(359deg); }
	}

    &--circle {
        min-width: auto;
        max-width: none;
        border-radius: 50%;
    }

    &--gold {
        color: #fff !important;
        background-color: $f-gold;

        &:hover, &:focus {
            background: darken($f-gold, 7%);
        }

        &.btn--ghost {
            color: $t-gold !important;
            border-color: $f-gold;

            &:hover, &:focus {
                color: #fff !important;
                background-color: $f-gold;
            }
        }
    }

    &-avani-purple,
    &-sienna {
        @extend %btn;
        @extend %t-xs;
        @extend .btn--gold;
        text-decoration: none !important;

        &:hover,
        &:focus {
            text-decoration: none !important;
        }
    }

    &-avani-white {
        @extend %btn;
        @extend %t-xs;
        @extend .btn--ghost;
        text-decoration: none !important;
        border-width: 1px !important;
        border-style: solid !important;

        &:hover,
        &:focus {
            text-decoration: none !important;
        }
    }
		
	&-minor-blue {
        @extend %btn;
        @extend %t-xs;
        @extend .btn--gold;
        text-decoration: none !important;
		border: 1px solid transparent;

        &:hover,
        &:focus {
            text-decoration: none !important;
			background:#FFF;
			color:$f-gold !important;
			border: 1px solid $f-gold !important;
        }
	}
	
	&-minor-white {
        @extend %btn;
        @extend %t-xs;
        text-decoration: none !important;
		background:#FFF;
		color:$f-gold !important;
        border: 1px solid $f-gold !important;

        &:hover,
        &:focus {
            text-decoration: none !important;
			background:$f-gold !important;
			color:#FFF !important;
        }
    }
	
	&-minor-ghost-on-white {
        @extend %btn;
        @extend %t-xs;
        @extend .btn--ghost;
        text-decoration: none !important;
        border-width: 1px !important;
        border-style: solid !important;

        &:hover,
        &:focus {
            text-decoration: none !important;
        }
	}
	
	&-minor-ghost-on-blue {
        @extend %btn;
        @extend %t-xs;
        @extend .btn--ghost;
        text-decoration: none !important;
        border: 1px solid #FFF !important;
		color:#FFF !important;

        &:hover,
        &:focus {
            text-decoration: none !important;
			background:#FFF !important;
			color:$f-gold !important;
        }
	}

    &-sienna-border {
        @extend %btn;
        @extend %t-xs;
        @extend .btn--ghost;
        text-decoration: none !important;
        border-width: 1px !important;
        border-style: solid !important;

        &:hover,
        &:focus {
            text-decoration: none !important;
        }
    }

    &--grey {
        color: #fff !important;
        background-color: $f-grey-mid;

        &:hover, &:focus {
            background: darken($f-grey-mid, 7%);
        }

        &.btn--ghost {
            color: black !important;
            border-color: $f-grey-mid;

            &:hover, &:focus {
                color: #fff !important;
                background-color: $f-grey-mid;
            }
        }

        .disable-compare & {
            color: #ccc !important;
            background-color: lighten($f-grey-mid, 5%);
        }
    }

    &--gold-transparent {
        color: $t-gold;
        text-decoration: underline;

        &:hover, &:focus {
            color: darken($t-gold, 7%);
        }
    }

    &--white {
        background-color: #fff;
    }

    &--dark {
        color: $t-gold;
        background-color: $f-op35-grey-dark-border;

        &:hover, &:focus {
            background-color: darken($f-op35-grey-dark-border, 3%);
        }
    }

    &--left {
        @include text-align(left);
    }

    &--big {
        font-size: 0.9em;
    }

    &--short {
        min-width: 180px;
        @include padding-left(20px);
        @include padding-right(20px);
    }

    &--v-short {
        min-width: 135px;
        @include padding-left(10px);
        @include padding-right(10px);
    }

    &--long {
        min-width: 230px;
    }

    &--long-260 {
        min-width: 260px;
    }

    &--small {
        width: auto;
        min-width: 160px;
        @include padding(6px, 30px, 5px, 30px);
        font-weight: 700;
        border-radius: 0;
        @include mobile {
            min-width: 75px;
            @include padding(6px, 12px, 5px, 12px);
        }
    }

    &--v-small {
        width: auto;
        min-width: 0;
        @include padding(6px, 30px, 5px, 30px);
        font-weight: 700;
        border-radius: 0;
        @include mobile {
            min-width: 75px;
            @include padding(6px, 12px, 5px, 12px);
        }
    }

    &--full-width {
        min-width: 100%;
    }

    &--spacing {
        margin: 1.5em 0;

        &:only-child {
            margin: 1.5em 0;
        }
    }

    &--ghost {
        padding-top: 8px;
        padding-bottom: 8px;
        background-color: transparent;
        color: $t-standard;
        border: 1px solid $f-op50-grey-dark;

        &:hover, &:focus {
            background: $f-op75-white;
            color: $t-standard;
        }
    }

    &--more {
        margin-bottom: 0;
        padding: 15px 0;
        color: $t-gold;

        &:before {
            display: inline-block;
            @include margin-right(5px);
            font-family: 'icons' !important;
            content: $glyph-angle-double-down;

            transform: rotate(0deg);
            transition: transform $quick;

            .show-more & {
                transform: rotate(180deg);
            }
        }
    }

    &--book {
        background: transparent;
    }

    &--share {
        min-width: 0;
        padding: 9px 0;
        font-weight: 600;
        text-decoration: none;

        i {
            display: inline-block;
            @include margin-right(5px);
        }
    }

    // Move to next section arrows
    &--arrows {
        position: absolute;
        @include right(0);
        bottom: 20px;
        @include left(0);
        z-index: 30;
        width: 40px;
        height: 40px;
        margin: auto;
        color: #fff;
        transition: transform $medium;
        transform: translateY(0);
        will-change: transform;

        .section--white &, .section--light-grey & {
            color: inherit;
        }

        &:hover, &:focus {
            transform: translateY(.5em);
        }

        .glyph-thin-arrow-down {
            position: absolute;
            top: 0;
            font-size: 2.5em;

            &:last-child {
                top: 8px;
            }
        }
    }

    &--center {
        @include left(50%);
        transform: translateX(bi-app-reverse-percentage(-50%));
    }

    &--rte-gold {
        @extend %btn-rte;
        $btnSpacing: 8px;
        $space: 5px;

        color: #fff !important;
        background-color: $f-gold;

        &:hover, &:focus {
            background: darken($f-gold, 7%);
        }

        + .btn--rte-gold {
            @include margin-right(0);
            @include margin-left($btnSpacing - $space);
        }

        + .btn--rte-grey {
            @include margin-right(0);
            @include margin-left($btnSpacing - $space);
        }

        + .btn--rte-white {
            @include margin-right(0);
            @include margin-left($btnSpacing - $space);
        }
    }

    &--rte-grey {
        @extend %btn-rte;
        $btnSpacing: 8px;
        $space: 5px;

        color: #fff !important;
        background-color: $f-grey-mid;

        &:hover, &:focus {
            background: darken($f-grey-mid, 7%);
        }

        + .btn--rte-gold {
            @include margin-right(0);
            @include margin-left($btnSpacing - $space);
        }

        + .btn--rte-grey {
            @include margin-right(0);
            @include margin-left($btnSpacing - $space);
        }

        + .btn--rte-white {
            @include margin-right(0);
            @include margin-left($btnSpacing - $space);
        }
    }

    &--rte-white {
        @extend %btn-rte;
        $btnSpacing: 8px;
        $space: 5px;

        padding-top: 8px;
        padding-bottom: 8px;
        background-color: transparent;
        color: $t-standard;
        border: 1px solid $f-op50-grey-dark;

        &:hover, &:focus {
            background: $f-op75-white;
            color: $t-standard;
        }

        + .btn--rte-gold {
            @include margin-right(0);
            @include margin-left($btnSpacing - $space);
        }

        + .btn--rte-grey {
            @include margin-right(0);
            @include margin-left($btnSpacing - $space);
        }

        + .btn--rte-white {
            @include margin-right(0);
            @include margin-left($btnSpacing - $space);
        }
    }

    &--rte-ghost {
        @extend %btn-rte;
        $btnSpacing: 8px;
        $space: 5px;

        padding-top: 8px;
        padding-bottom: 8px;
        background-color: transparent;
        color: $t-standard;
        border: 1px solid $f-op50-grey-dark;

        &:hover, &:focus {
            background: $f-op75-white;
            color: $t-standard;
        }

        + .btn--rte-gold {
            @include margin-right(0);
            @include margin-left($btnSpacing - $space);
        }

        + .btn--rte-grey {
            @include margin-right(0);
            @include margin-left($btnSpacing - $space);
        }

        + .btn--rte-white {
            @include margin-right(0);
            @include margin-left($btnSpacing - $space);
        }
    }

    &--dynamic-width {
        min-width: 100%;

      @media (min-width: 376px) {
          min-width: 135px;
          padding-left: 10px ;
          padding-right: 10px ;
      }
    }

    &--lock {
		@extend %font-cta;
		
        background: $f-pale-blue;
        box-shadow: 0 7px 20px -4px rgba(24, 39, 75, 0.2), 0 5px 10px -5px rgba(24, 39, 75, 0.3);
        transition: all 0.5s ease-in-out;
        border-radius: 30px;
        padding: 4px 60px 4px 4px;
        display: inline-block;
        position: relative;
        text-transform: uppercase;
        
        span {
            background-color: $f-gold;
            border-radius: 30px;
            padding: 16px;
            display: inline-block;
            color: white;
            letter-spacing: 2px;
            direction: ltr;
            
            &::before {
                @extend %icon-font-glyph;
                content: $glyph-lock;
                color: white;
                display: inline-block;
                width: 22px;
                margin-right: 10px;
                font-size: 1.2em;
            }
        }

        &::after {
            @extend %icon-font-glyph;
            content: $glyph-unlock;
            color: $f-op50-grey-dark;
            position: absolute;
            right: 28px;
            top: 50%;
            font-size: 1.2em;
            transform: translateY(-50%);
        }
        
        &:hover {
            box-shadow: 0 7px 20px 5px rgba(24, 39, 75, 0.2), 0 5px 10px 0px rgba(24, 39, 75, 0.3);
        }
    }
}

.button {
    &-oaks {
        &-blue,
        &-blue-border,
        &-green,
        &-green-border {
            @extend %btn;
            @extend %t-xs;
            text-decoration: none !important;

            &:hover,
            &:focus {
                text-decoration: none !important;
            }
        }

        &-blue {
            @extend .btn--grey;
        }

        &-green {
            @extend .btn--gold;
        }
        
        &-blue-border,
        &-green-border {
            @extend .btn--ghost;
        }
    }
}

.t-c-m p {
    > a.btn--rte-grey, a.btn--rte-gold {
        color: #fff !important;
        border-bottom: none !important;
        text-decoration:none!important;
    }
}

.t-c-m p > a.btn--rte-white {
    color: $t-standard !important;
    border: 1px solid $f-op50-grey-dark !important;
    text-decoration:none!important;
}
