/*Announcement notice banner*/

.notice-banner {
    overflow: hidden;
    color: #fff;
    height:auto;
    position: relative;
    text-align: center;
    font-size:14px;
    transform:scaleY(1);
    transform-origin: top;
    transition: all .2s cubic-bezier(0.01, 0.38, 1, 1);

    .horizon-header & {
        @include desktop {
            z-index: 203;
        }
    }

    .is-scrolled &{
        min-height:0;
        height:0;
        transition: all .2s cubic-bezier(0.01, 0.38, 1, 1);
        transform:scaleY(0);
        transform-origin: top;
    }

    .is-scrolled.horizon & {
        height:auto;
        transform:scaleY(1);
        
        @include no-desktop {
            transform:scaleY(1) translateY(-52px);
        }
    }

    &.is-sticky{
        .is-scrolled &{
            height:auto;
            min-height:35px;
            transform:none;
        }
    }

    &.is-sticky{
        .scrolled &{
            height:auto;
            min-height:35px;
            transform:none;
        }
    }

    &.is-sticky-hidden{

        .is-scrolled &{
            min-height:0;
            height:0;
            transition: all .2s cubic-bezier(0.01, 0.38, 1, 1);
            transform:scaleY(0);
            transform-origin: top;
        }
    }

    .detail {
        margin:0;
        padding:0 20px;
        line-height: 30px;
        height:30px;
        overflow:hidden;

        &.active{
			padding:8px 20px;
            line-height:1.5em;
            height:auto;
        }
		
		p {
			margin-top:0;
		}
    }
    .js-close {
        position: absolute;
        @include right (5px);
        top:10px;
        color: #fff;
        text-decoration: none;
    }

    a{
        color:#fff;
    }

    .js-readmore{
        display:inline-block;
        font-weight:600;
        font-size:16px;
        visibility: hidden;

        &.active{
            span{
                transform: rotate(-180deg);
            }
        }
    }
}