// destination styles
$buttonHeight: 45px;

.booking-form-label {
	font-size: 13px;
}

.destination-input-select {
	@extend %font-navigation;
	
	max-width: 100%;
	width: 100%;
	min-height: 1.25em;
	padding: 7px 32px;
	margin-top: 10px;
	margin-bottom: 0;
	font-size: (17 / $base) * 1em;
	font-weight: 300;
	color: $t-bm-on-primary;
	text-align: center;
	resize: none;
	background-color: $f-bm-inputs;
	border: 0;
	border-radius: 2px;

	.is-disabled & {
		// Safari disabled styling fix
		opacity: 1;
		-webkit-text-fill-color: $t-standard;
	}

	.horizon-header & {
		@include desktop {
			color: white;
			min-height: 53px;
			border-radius: 2px;
			background-color: rgba(255, 255, 255, 0.2);
			margin-top: 0;
		}
	}

	&::placeholder {
		color: $t-bm-on-primary;
	}

	.is-gha & {
		color: $t-standard;

		&::placeholder {
			color: $t-standard-light;
		}
	}

	&#booking-dest-finish {
		padding: 7px 10px;
	}
}

.booking-destination {
	label {
		.is-gha & {
			display: none;
		}
	}

	.errmsg {
		margin-top:5px;
		margin-bottom: -5px;
		line-height: 1.15;
		display:none;
	}

	&__wrapper {
		height: 100%;
		transition: transform $slow $easeOutExpo;
		transform: translateY(0);
		will-change: transform;

		.is-gha & {
			transform: translateY(-100.4%);
			@include mobile {
				transform: translateY(0);
			}
		}
	}

	#destination-hotel{
		display:block;
		text-align:center;
		background:$f-bm-inputs;
		font-size:1.092em;
		font-weight:500;
		padding:8px 0;
		letter-spacing: 0.3px;

		.horizon & {
			background: rgba(255, 255, 255, 0.2);
			height: 100%;
			@include text-align(left);
			color: white;
			@include padding(15px,36px,15px,16px);
			text-overflow: ellipsis;
			overflow: hidden;
			white-space: nowrap;
			
			@include desktop {
				max-height: 45px;
			}
		}
	}
}

.select2-container--anantara {
	&.select2-container--open.select2-container--above {
		.select2-selection--single, .select2-selection--multiple {
			border-top-left-radius: 0;
			border-top-right-radius: 0;
		}
	}

	&.select2-container--open.select2-container--below {
		.select2-selection--single, .select2-selection--multiple {
			border-bottom-left-radius: 0;
			border-bottom-right-radius: 0;
		}
	}

	.select2-search--dropdown {
		padding: 0;
		position:relative;
		box-shadow: 0 4px 10px 0 rgba(0,0,0,.18)!important;
		border-radius:0!important;

			&:after{
				content: '\e90a';
				font-family: 'icons' !important;
			    speak: none;
			    font-style: normal;
			    font-weight: normal;
			    font-variant: normal;
			    text-transform: none;
			    line-height: 1;
			    -webkit-font-smoothing: antialiased;
			    -moz-osx-font-smoothing: grayscale;
			    display:block;
			    @include right (5px);
			    top: 12px;
			    position:absolute;
			    color:$f-grey-dark;
			    font-size: 1.5em;

			}

		.select2-search__field {
			border: 0;
			min-height: 45px;
			padding: 0 8px;
			outline: none;
			border-radius:0!important;
		}

/*IE Fix cross icon when typing */
input[type="search"].select2-search__field::-ms-clear{
	display: none;
	width:0;
	height: 0;
}

input[type="search"].select2-search__field::-ms-reveal{
	display: none;
	width:0;
	height: 0;
}

	}

	.select2-search--inline {
		.select2-search__field {
			background: transparent;
			border: none;
			outline: 0;
			box-shadow: none;
			-webkit-appearance: textfield;
		}
	}

	.select2-results > .select2-results__options {
		max-height: 50vh;
		overflow-y: auto;

		@include desktop {
			max-height: 350px;
		}
	}

	.select2-results__option {
		&[role=group] {
			padding: 0;
		}

		&[aria-disabled=true] {
			color: #999;
		}

		&[aria-selected=true] {
			background-color: $f-gold;

			.tt-country, .tt-suggestion{
				color:#fff;
				font-weight: 600;
			}
		}

		.select2-results__option {
			padding-left: 1em;

			.select2-results__group {
				padding-left: 0;
			}

			.select2-results__option {
				margin-left: -1em;
				padding-left: 2em;

				.select2-results__option {
				  margin-left: -2em;
				  padding-left: 3em;

					.select2-results__option {
						margin-left: -3em;
						padding-left: 4em;

						.select2-results__option {
							margin-left: -4em;
							padding-left: 5em;

							.select2-results__option {
								margin-left: -5em;
								padding-left: 6em;
							}
						}
					}
				}
			}
		}
	}

	.select2-results__group {
		cursor: default;
		display: block;
		padding: 6px;
	}

	.select2-selection--single {
		max-width: 100%;
		width: 100%;
		height:auto;
		font-size: (17 / $base) * 1em;
		font-weight: 300;
		color: $t-bm-on-primary;
		text-align: center;
		resize: none;
		background-color: $f-bm-inputs;
		border: 0;
		border-radius: 2px;

		.select2-selection__rendered {
			color: $t-bm-on-primary;
			line-height: 1.25em;
			padding: 7px 32px;
		}

		.select2-selection__clear {
			cursor: pointer;
			float: right;
			font-weight: bold;
		}

		.select2-selection__placeholder {
			color: $t-bm-on-primary;
		}

		.select2-selection__arrow {
			height: 26px;

			position: absolute;

			top: 1px;
			right: 1px;

			width: 20px;

			b {
				// border-color: $f-bm-button transparent transparent transparent;
				border-color: $f-bm-arrow transparent transparent transparent;
				border-style: solid;
				border-width: 10px 8px 0 8px;

				height: 0;
				left: 50%;

				margin-left: -10px;
				margin-top: -2px;

				position: absolute;

				top: 50%;
				width: 0;
			}

			/*Hide when has rooms or offers*/
			.has--item &{
				display:none;
			}
		}
	}

	&.select2-container--open {
		.select2-selection--single {
			.select2-selection__arrow {
				b {
					border-color: transparent transparent $f-bm-arrow transparent;
					border-width: 0 8px 10px 8px;
				}
			}
		}
	}

	@include rtl {
		.select2-selection--single {
			.select2-selection__clear {
				float: left;
			}

			.select2-selection__arrow {
				left: 1px;
				right: auto;
			}
		}
	}

	.select2-dropdown {
		//background-color:$f-bm-inputs;
		background-color:#fff;
		border: 0;
		border-radius: 0;
		-webkit-box-shadow: 0 2px 16px rgba(0,0,0,0.15);
	 	box-shadow: 0 2px 16px rgba(0,0,0,0.15);

		/* @include desktop{
			box-shadow: none;
		} */
	}

	.select2-results__option {
		padding:3px;

		&:first-child{
			padding-top: 8px;
		}

		&.select2-results__message{
			padding: 15px 5px;
		}

		.tt-suggestion {
			@extend %font-navigation;
	
			@include padding (5px, 10px, 5px, 45px);

			&:before {
				display:block;
				@include float(left);
			}

			&.tt-country {
				@include padding-left (5px);

				&:before {
					display: inline-block;
					width: 25px;
					font-family: 'icons';
					text-align: center;
					content: $glyph-location-pin;
					margin: 0;
				}
			}

			&.tt-state {
				@include padding-left (25px);
			}

			&.tt-city {
				@include padding-left (15px);

				&:before {
					display: inline-block;
					width: 25px;
					font-family: 'icons';
					text-align: center;
					content: $glyph-location-pin;
					margin: 0;
				}
			}

			&.gha, &.an, &.av, &.ti, &.oh, &.ew, &.nh, &.nw, &.nc, &.pq {
				@include padding-left (30px);

				&:before{
					color:#58585a;
					@extend %icon-font-glyph;
					font-size:16px;
					@include margin-right (5px);

				}

				&.t-c-s{
					font-weight: 500;
				}


				&.tt-country {
					@include padding-left (5px);
				}
			}

			&.gha {
				&:before{
					font-size: 14px;
					@include margin-right(7px);
					@include margin-left(2px);
				}
			}
		}
	}

	.select2-results__option--highlighted {
		background-color:#ddd;
		color:#FFF;

		/*.tt-country, .tt-suggestion  {
			color:#FFF;
		}*/
	}
	
	&.form {
		border:1px solid #b7b7b7;
		
		.select2-selection--single {
			background:transparent;
			text-align:left;
			
			.select2-selection__rendered {
				@include padding-left(0);
			}
		}
	}
}
