%play-button {
	position: absolute;
	top: 0;
	@include right(0);
	bottom: 0;
	@include left(0);
	z-index: 15;
	display: block;
	width: 94px;
	height: 94px;
	margin: auto;
	text-align: center;
	background-color: rgba(0, 0, 0, .3);
	border-radius: 50%;

	.section-mobile-feature-video & {
		@include right(25%);
		width: 35px;
		height: 35px;
		background-color: transparent;
		border: 1px solid $f-gold;
	}

	&:before {
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		width: 0;
		height: 0;
		margin: auto;
		content: '';
		border-color: transparent transparent transparent #fff;
		border-style: solid;
		border-width: 23.5px 0 23.5px 40px;

		.section-mobile-feature-video & {
			left: 7px; // Preserve for RTL
			border-width: 11.5px 0 11.5px 18px;
			border-left-color: $f-gold;
		}
	}
}


.social-media {
	@extend %clearfix;
	margin-bottom: 40px;
	overflow: hidden;

	$numberCols: 24;
	$column: 100/$numberCols;
	$ar: $numberCols/100;

	[class^="col-social-"] {
		@extend [class^="col-"];

        position: relative;

	    &:before{
	        display: block;
	        content: " ";
	        width: 100%;
	    }

	    > .content {
	        position: absolute;
	        top: 0;
	        left: 0;
	        right: 0;
	        bottom: 0;
	        margin: 0;
	        padding: 0;
	        z-index: 0;
	    }
	}

	.col-social {

		@for $i from 1 through $numberCols {
			&-#{$i} {
				width: 100%;

				// @include aspect-ratio(1.05, 1);
				$width: 1.05;
				$height: 1;

			    &:before{
			        padding-top: percentage($height / $width);
			    }

				&.social-media--tall {
					// @include aspect-ratio(1, 1.5);
					$width: 1;
					$height: 1.5;

				    &:before{
				        padding-top: percentage($height / $width);
				    }
				}
			}
		}

		@include tablet {
			@for $i from 1 through $numberCols/3 {
				&-#{$i} {
					width: 50%;

					// @include aspect-ratio(1, 1);
					$width: 1;
					$height: 1;

				    &:before{
				        padding-top: percentage($height / $width);
				    }

					&.social-media--tall {

						// @include aspect-ratio(1, 2);
						$width: 1;
						$height: 2;

					    &:before{
					        padding-top: percentage($height / $width);
					    }
					}
				}
			}

			@for $i from $numberCols/3 + 1 through $numberCols {
				&-#{$i} {
					width: 100%;
					// @include aspect-ratio(1, 0.75);
					$width: 1;
					$height: 0.75;

				    &:before{
				        padding-top: percentage($height / $width);
				    }

					&.social-media--tall {
						// @include aspect-ratio(1, 0.75);
						$width: 1;
						$height: 0.75;

					    &:before{
					        padding-top: percentage($height / $width);
					    }
					}
				}
			}
		}

		@include desktop {
			@for $i from 1 through $numberCols {
				&-#{$i} {
					width: ($column * $i) * 1%;
					// @include aspect-ratio(($ar * $i), 1.5);
					$width: ($ar * $i);
					$height: 1.5;

				    &:before{
				        padding-top: percentage($height / $width);
				    }

					&.social-media--tall {
						// @include aspect-ratio(($ar * $i), 3);
						$width: ($ar * $i);
						$height: 3;

					    &:before{
					        padding-top: percentage($height / $width);
					    }
					}
				}
			}
		}

	}

	// Weibo content sections
	&__content {
		@include padding(12px, 26px, 33px, 26px);
		color: $t-standard;

		&.section--dark-grey {
			color: #fff;
		}

		@include desktop {
			@include padding(33px, 26px, 33px, 43px);
		}
	}

	&__twitter {
		background-color: $f-red;

		@include mobile {
			display: none;
		}
	}

	&__insta {
		background-color: $f-grey-light;

		@include mobile {
			~ .social-media__insta, .instagram + .instagram {
				display: none;
			}
		}

		// Target specific items only when they is exactly 4 instagram images
		&:nth-child(2):nth-last-child(4),
		&:nth-child(3):nth-last-child(3),
		&:nth-child(4):nth-last-child(2),
		&:nth-child(5):nth-last-child(1) {

			@include tablet {
				+ .social-media__insta ~ .social-media__insta {
					display: none;
				}
			}
		}
	}

	&__facebook {
		background-color: $f-blue;
	}

	&__youtube {
		display: none;
		background-color: $f-grey-dark;
		@include desktop {
			display: block;
		}
	}

	&__vimeo {
		display: none;

		@include desktop {
			display: block;
		}
	}
}

.is-ie .col-social-9 {
	@include desktop {
		width: 37.4999999%;	// IE override
	}
}
