// countdown styles
.countdown {
	&--banner {
		transform: translateY(-55%);
		z-index: 10;
		position: relative;
		background: transparent;
		margin-bottom:-50px;
			
		.title {
			text-shadow: 2px 2px #333;
		}
	}
	
	&-wrapper {
		.campaign--banner & {
			color:#fff;
			margin: 1em 0;
			
			@include smallMobile{
				margin: 2em 0;	
			}
			
			@include desktop{
				margin: 3em 0;		
			}
		}

		.countdown {
			display: flex;
			width: 100%;
			max-width: 600px;
			margin: 0 auto;
			justify-content: space-evenly;
			
			.countdown--banner & {
				margin-top:10px;
				max-width: 500px;
			}

			.time {
				display:flex;
				flex-direction:column;
				font-size: 1em;
				.count {
					@extend %font-alternate-body;
					font-size: 3em;

					@include mobile{
						font-size:2.2em;
					}
				}

				.label {
					font-size: 1em;
				}
				
				.countdown--banner & {
					color:#333;
					background:$f-grey-light;
					padding:0px 10px 10px;
					border-radius:10px;
					min-width:80px;
					box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
				}
			}
		}
	}
}
