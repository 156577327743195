// form styles
.form {
	padding: 0 10px;

	.row {
		@extend %clearfix;
		margin-bottom: 0;
	}

	&__title {
		margin-top: -10px;
		margin-bottom: 10px;
		@include padding-right(0);
		font-weight: 200;
		text-transform: none;


		@include mobile {
			margin-bottom: 0;
		}

		#meeting-rfp &{
			font-size:2.2em;
			margin-bottom: 10px;
			text-transform:none;
		}

		#wedding-rfp &{
			text-transform:none;
		}
	}

	&__require {
		margin-bottom: 1em;
	}

	> .col-4 {
		@include desktop {
			padding: 0 24px;
		}
	}

	> .col-8 {
		@include desktop {
			padding: 0 24px;
		}
	}

	@include tablet {
		padding: 0 20px;

		> .col-4 {
			width: 100%;
		}
	}

	@include mobile {
		.col-4 {
			width: 100%;
		}
	}

	[class^="col-"] {
		position: relative;
	}

	&--extra-spacing {
		[class*="col-"] {
			@include desktop {
				@include padding-right(42px);
			}
		}
	}

	&__container {
		margin-bottom: 0;

		@include tablet {
			width: 75%;
		}

		@include mobile {
			width: 100%;
		}

		.row {
			margin-bottom: 22px;
		}
	}

	&__label {
		display: block;
		margin-bottom: 8px;
	}

	&__input {
		width: 100%;
		padding: 8px;
	}

	&__submit {
		@include mobile {
			width: 100%;
			margin-bottom: 18px;

			+ .btn {
				width: 100%;
				margin-bottom: 18px;
			}
		}
		+ .btn {
			@include float(right);
		}
	}

	&__link {
		display: block;
		margin-bottom: 8px;
	}

	legend {
		display: block;
		@include float(left);
		width: 100%;
		@include margin(0, 0, 5px, 0);
		@include padding(0, 10px, 1px, 10px);

		.form__title {
			margin-bottom: 16px;
		}
	}

	.has-datepicker {
		max-width: 150px;
	}

	&#meeting-rfp, &#wedding-rfp, &#spa-wellness-rfp, &#practitioner-rfp, &#dining-rfp, &#reservation-rfp {
		padding:0 5px;
	}
}

label.error, p.errmsg, .field-validation-error {
	color: $t-error;
	@extend %t-c-s;
}

.field-validation-error {
	display: inline-block;
	width: 100%;
}

input.error, textarea.error, select.error, button:not([type='submit']).error {
	outline: 2px dashed $t-error;
	outline-offset: 0;

	&:focus {
		outline: 2px dashed $t-error;
	}
}

form:not(.section-category__form,.section-category-tab__form, #booking-mask-form, #booking-mask-form--fmb) {

	fieldset {
		padding: 0;
		margin: 0 -10px;
		border: 0;

		.has-datepicker,
		.has-daterangepicker,
		.datepicker {
			max-width: 100%;
			cursor: pointer;
		}

		legend{
			font-size:1em;
		}
	}

	label {
		display: block;
		margin-bottom: 5px;

		.section--dark-grey & {
			color: #ddd;
		}

		span.required{
			font-size:1.7em;
			position:absolute;
			@include margin-left(3px);
		    display: inline-block;
		}

		&.t-sm{
			font-size: 1em;
			font-weight: 500;
		}

		a{
		.section--dark-grey & {
			color: $f-gold;
		}

		}
	}

	input,
	textarea {
		width: 100%;
		&:focus {
			outline: 0;
		}

		.section--dark-grey & {
			&::placeholder {
				color: #f9f9f9;
			}
		}

		&::placeholder {
			color: $t-standard-light;
		}
	}

	input {
		padding: 6px 5px;
		max-height: 35px;
		background-color: transparent;
		border-radius: 0;
		border: 0;
		font-weight:600;
		border: 1px solid $-form-border;
		color: $f-grey-mid;
		transition: border $quick;

		.section--dark-grey & {
			color: #f9f9f9;
			border: 1px solid $t-standard-faint;
		}

		&:focus {
			border: 1px solid $f-gold;
			outline: 0;
		}

		&.has-datepicker,
		&.has-daterangepicker,
		&.datepicker {
			position: relative;
			z-index: 1;
		}

		&::placeholder {
			color: $f-grey-mid;
		}

	}

	input:-webkit-autofill,
	textarea:-webkit-autofill,
	select:-webkit-autofill {
		-webkit-box-shadow: 0 0 0px 1000px white inset;

		.section--light-grey & {
			-webkit-box-shadow: 0 0 0px 1000px $f-grey-light-border inset;
		}

		.section--dark-grey & {
			-webkit-box-shadow: 0 0 0px 1000px $f-grey-dark inset;
			-webkit-text-fill-color: #fff;
		}
	}

	.radio-group {
		display: flex;
		margin: 8px 0;
		padding: 0 10px;
		align-items: baseline;

	}

	input[type="radio"] {
		width: auto;
		@include margin-right(5px);

		+ input + label, + label {
			 width:98%;
			margin-bottom: 0;
		}
	}

	.checkbox-group {
		display: inline-block;
		width: auto;
		@include margin(5px, 0, 5px, 0);
		padding: 0 10px;

		@include mobile {
			vertical-align: top;
			flex: 0 0 14px;
		}

		@include desktop {
			.col-6 & {
				max-width: 48.5%;
			}
		}

		&.no-padding{
			padding: 0;
			width:100%;
		}

		+ label{
			flex:1 0 0;
		}
	}

	input[type="text"] {
		// height: auto;	// Edge fix
	}
	input[type="checkbox"] {
		display: inline-block;
		width: auto;
	    margin-top: 0.3em;
		vertical-align: top;

		+ input + label, + label {
			display: inline-block;
			width: auto;
			max-width: 97%;
			vertical-align: middle;

			@include mobile{
				max-width:94%;
			}
		}
	}

	/*New Phone Number*/

	.phonenumberBox{
		position:relative;
	}

	.iti{
		width:100%;

		input[type=tel]{
			@include padding-right(6px);
    		@include padding-left(52px);
		}

		&__flag{
			background-image: url("/assets/minor/brands/images/plugins/flags.png");

			@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
				background-image: url("/assets/minor/brands/images/plugins/flags@2x.png");
			}

			&-container{
				@include left(0);
				@include right(auto);
			}
		}

		&__selected-flag {
			height: 35px;
		}

		&--container{
			position:absolute !important;
			bottom:0 !important;
			left:0 !important;
			right:0 !important;
			top:auto !important;

			ul{
				width:100%;
				white-space: normal;

				@include mobile{
					max-height:250px;
				}

				li{
					font-size:0.8em;
				}
			}
		}

		&__country-list{
			left: 0;
		}

		&__flag-box, &__country-name{
			@include margin-right(6px);
			@include margin-left(3px);
		}

		&__arrow{
			@include margin-left(6px);
		}
	}

	textarea {
		padding: 6px 5px;
		margin-bottom: 0;
		font-weight:600;
		resize: none;
		background-color: transparent;
		border: 1px solid $-form-border;
		color: $f-grey-mid;

		.section--dark-grey & {
			color: #fff;
			border: 1px solid $t-standard-faint;
		}

		&:focus {
			border: 1px solid $f-gold;
		}
	}

	select {
		width:100%;
		max-width:100%;
		padding: 6px 5px;
		max-height: 35px;
		font-size: 1em;
		background: transparent;
		font-weight:600;
		border: 1px solid $-form-border;
		color: $f-grey-mid;
		-webkit-appearance: menulist;
		-moz-appearance: menulist;
		.section--dark-grey & {
			color: #f9f9f9;
			border: 1px solid $t-standard-faint;

		}
		&:focus {
			border: 1px solid $f-gold;
		}
		&:after {
			top: 10px;
			width: 25px;
			height: 25px;
			color:$f-gold;
			font-size: 10px;
		}
		optgroup{
			color: $f-grey-mid;

		}

		option{
			color: $f-grey-dark;
			font-size: 1em;
		}

	}

	[class^="col-"] {
		padding: 0 10px;
		margin-bottom: 25px;

		@include mobile {
			width: 100%;
		}

		@include tablet {
			width: 50%;
		}

		&.full-width{
			@include tablet{
				width:100%;
			}
		}

		&.no-padding{
			padding: 0;
		}

		&.no-margin{
			margin: 0;
		}

		&.mb-10{
			margin-bottom:10px;
		}
	}

	.glyph{
		position: absolute;
		padding: 7px;
		font-size: 1.2em;
		color:$f-grey-mid;
		z-index: 0;

		@include right(10px);
	}

	.js-inline-popup-trigger{

		display: inline-block;
		@include margin-left(5px);

		.glyph{
			position: relative;
			font-size: 11px;
			background:$f-grey-mid;
			color:#fff;
			border:1px solid transparent;
			border-radius: 50%;
			width: 20px;
			height: 20px;
			padding: 3px 6px;
			line-height: 1;

			@include right(0px);
		}

		&:hover, &:focus{
			.glyph{
				color: $f-grey-mid;
				background:transparent;
				border: 1px solid $f-grey-mid;
			}
		}
	}

	.js-center-popup-trigger{

		display: inline-block;
		@include margin-left(5px);

		.glyph{
			position: relative;
			font-size: 11px;
			background:$f-grey-mid;
			color:#fff;
			border:1px solid transparent;
			border-radius: 50%;
			width: 20px;
			height: 20px;
			padding: 3px 6px;
			line-height: 1;

			@include right(0px);
		}

		&:hover, &:focus{
			.glyph{
				color: $f-grey-mid;
				background:transparent;
				border: 1px solid $f-grey-mid;
			}
		}
	}
}
/*RFP Form redesign*/
form:not(.section-category__form, #booking-mask-form, #booking-mask-form--fmb) {
	&.RFP {
		[class^="col-"] {
			margin-bottom: 25px;

			@include tablet{
				width:50%;
			}

			&.full-width{
				@include tablet{
					width:100%;
				}
			}

			&.no-padding{
				padding: 0;
			}

			&.no-margin{
				margin: 0;
			}

			&.mb-10{
				margin-bottom:10px;
			}

		}

		fieldset{
			padding:5px 0;
		}

		legend{

			.form__title{
				@extend %font-alternate-body;
				
				margin-top:10px;
				font-size: 1.2em;
				letter-spacing: 2px;
				text-transform: uppercase;
			}
		}

		input, select, textarea{
			color:$t-standard;
			margin-bottom: 0;

		&::placeholder {
				color: $t-standard-light;
				font-weight:300;
			}
		}

		input, select {
			height:35px;
			padding:5px;
		}

		.checkbox-group {
			padding: 0;
			@include margin(0, 5px, 0, 0);
			overflow: hidden;

			input{
				height:auto;
				@include float(left);
				width: 14px;
			}

			label {
				max-width:92%;
				@include margin-left (5px);
				@include float(left);

				@include tablet{
					max-width:97%;
				}

				@include desktop{
					max-width:100%;
				}

			}

			.discovery{
				@include desktop{
					max-width:96%;
				}
			}
		}

		.tel-group {
			display: inline-block;
			width: 100%;
			@include margin(0, 8px, 0, 0);
			vertical-align:middle;

			.col-2{
				width:25%;
				padding:0;
				@include margin(0, 3%, 0, 0);
			}

			.col-10{
				width:72%;
				padding:0;
				margin:0;
			}
		}

		.radio-group{

			@include padding(0, 10px, 0, 5px);

			input{
				height:auto;
			}
		}

		.password {
			position:relative;
		}
	}
}

.PasswordRevealer-trigger {
	@extend %t-s;
	@extend %t-20-ls;

	display: none;

	position: absolute;
	top: 0.4em;
	@include right(0);

	background: none;
	border: 0;
	outline: 0;

	&:active {
		bottom: 0.3em;
	}

	&:hover, &:focus {
		display: block;
	}
}

input:hover, input:focus {
	~ .PasswordRevealer-trigger {
		display: block;
	}
}





