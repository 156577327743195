// twitter styles
.twitter {
	@include padding(33px, 26px, 33px, 43px);
	color: #fff;

	@include mobile {
		padding: 20px 20px;
	}
	@include tablet {
		padding: 30px;
	}

	&__container {
	}

	&__title {
		color: inherit;
		text-decoration: none;

		&:before {
			@include padding-right(10px);
			content: $glyph-twitter;
			@extend %icon-font-glyph;
		}
	}

	&__post {
		padding-bottom: 1.5em;
		margin-bottom: 1.5em;
		border-bottom: 1px solid $f-red-tone;

		&:last-child {
			padding-bottom: 0;
			border-bottom: 0;
		}

		a {
			color: inherit;
			text-decoration: none;
		}

		img {
			width: 100%;
			max-width: 100%;
		}
	}

	&__meta {
		display: block;
		margin-top: -12px;
		color: $f-op75-white;
	}

	.nano {

		> .nano-content {
			position: inherit;
			@include padding-right(17px);
		}

		> .nano-pane {
			width: 7px;
			background: none;
			border-radius: 0;
		}
	}
}
