// map-explore styles

.location-map {
	background:transparent no-repeat center;
	background-size:cover;
	
	@include tablet {
		.col-4 {
			width: 40%;
		}

		.col-8 {
			width: 60%;
		}
	}

	h3 {
		margin: .5em 0;
	}

	.container {
		padding-bottom: 40px;
		@include desktop {
			padding-bottom: 100px;
		}
	}

	&-list {
		> li {
			padding: 10px 0;
			border-bottom: 1px solid $f-gold;
		}
	}

	.google-map,
	&-listing {
		@include mobile {
			width: 100%;
		}
		@include tablet {
			min-height: 500px;
		}
		@include desktop {
			min-height: 670px;
		}
	}

	.google-map {
		height: 300px;

		.gm-style {
			@extend .ltrForce;
		}

		.map--fallback {
			height: 100%;
			background-size: cover;
			background-position: center;
		}
	}

	&-listing {
		position: relative;
		overflow: hidden;
		background: #fff;

		&.is-selected {
			.main-content,
			.nearby-content {
				transform: translateX(bi-app-reverse-percentage(-100%));
			}

			.information-content {
				transform: translateX(bi-app-reverse-percentage(0));
			}
		}
	}

	.main-content {
		padding: 10px 20px;
		border-bottom: 1px solid $f-grey-light-border;
		transition: transform .25s ease-in-out;
		transform: translateX(bi-app-reverse-percentage(0));
		will-change: transform;
		@include desktop {
			display: block;
			padding: 20px 40px;
		}

		.location-description {
			position: relative;
			@include padding-left(1.6em);

			span {
				position: absolute;
				@include left (0);
				font-size: 1.2em;
				top: 2px;
				color:$f-icon-color;
			}
		}

		&__heading {
			margin: .5em 0;
		}
	}

	

	.nearby-content {
		padding: 10px 20px 40px;
		transition: transform .25s ease-in-out;
		transform: translateX(bi-app-reverse-percentage(0));
		will-change: transform;
		@include desktop {
			padding: 10px 40px 20px;
		}

		&__heading {
			@extend %font-alternate-header;
			
			margin: .5em 0;
		}
	}

	.location-content {
		height: 100%;
		overflow: auto;
	}

	.information-content {
		position: absolute;
		top: 0;
		bottom: 0;
		overflow: auto;
		padding: 38px 20px 20px;
		width: 100%;
		transition: transform .25s ease-in-out;
		transform: translateX(bi-app-reverse-percentage(100%));

		@include desktop {
			padding: 38px 40px 20px;
		}

		.information__image {
			max-width: calc(100% + 40px);
			margin: 0 -20px;

			@include desktop {
				max-width: calc(100% + 80px);
				margin: 0 -40px;
			}

			img {
				width: 100%;
				height: 100%;

				object-fit: cover;
				object-position: 0 0;
			}
		}

		.information__title {
			@include padding-left(25px);

			background-image: url(/assets/minor/brands/images/pin-blue.png);
			background-position: 0 5px;
			background-repeat: no-repeat;
			background-size: 16px;
		}

		.information__header {
			position: absolute;
			top: 0;
			@include left(0);
			display: block;
			width: 100%;
			padding: 0;
			background: $f-grey-light;

			.text-cta {
				padding: 10px 20px;
				color: #fff;
				@include desktop {
					padding: 10px 40px;
				}
			}
		}
	}

	.other-content {
		padding: 10px 20px;
		margin: 30px -20px 0;
		border-top: 1px solid $f-grey-light-border;

		@include desktop {
			padding: 10px 40px;
			margin: 30px -40px 0;
		}
	}

	.other-list {
		li {
			margin: 10px 0;
			font-size: (14 / $base) * 1em;

			&.is-hidden {
				display: none;
			}
		}
	}

	.location-title {
		@extend %font-body;
		
		@include padding-left(25px);

		background-image: url(/assets/minor/brands/images/pin-blue.png);
		background-position: left top;
		background-repeat: no-repeat;
		background-size: 16px;

		@include rtl {
			background-position-x: right;
		}
	}

	ul {
		padding: 0;
		margin: 0;
		list-style: none;

		li .col-2 {
			font-weight: 600;
			color: $t-standard-faint;
		}
	}

	ol {
		display: none;
		@include padding-left(30px);
		counter-reset: location-number;

		li {
			margin: 5px 0;
			font-size: (14 / $base) * 1em;
			line-height: 1.5;
			cursor:pointer;
			display: flex;
			flex-direction: row;
			flex-wrap: nowrap;
			align-items: flex-start;
			justify-content: space-between;
			
			&::before {
				counter-increment: location-number;
				content: counter(location-number) ". ";
				@include margin-right(3px);
			}
		}
	}

	button {
		display: inline-flex;
		width: 100%;
		-webkit-appearance: none;
		padding: 0;
		@include text-align(left);
		vertical-align: top;
		background: transparent;
		border: none;
		justify-content: space-between;
		align-items: center;

		&:focus {
			outline: 0;
		}
	}

	.location-title {
		display: inline-block;
		width: 80%;
		margin: 0;
		font-size: (18 / $base) * 1em;
		font-weight: 600;
	}

	i {
		@include float(right);
		// margin-top: 10px;
	}

	.js-location-list-toggle,
	.other-list {
		.col-10 {
			width: auto !important;
			float: none;
			overflow: hidden;
			flex: 2;
		}
		.col-8 {
			// width: (100/12) * 8 * 1% !important;
			float: none;
			width: auto !important;
			flex: 2;
		}
		.col-2 {
			float: none;
			width: auto !important;
			flex: 1;
			font-size: .9em;
			@include text-align(right);
			@include padding-left(10px);
			
			@include no-desktop {
				min-width: 40%;
			}
			
			@include rtl {
				float: none;
			}
		}
		.col-1 {
			width: (100/12) * 1 * 1% !important;
		}
	}
}	
div .MicrosoftMap :nth-child(2){
	z-index: 99 !important;
}
.NavBar_zoomIn{
    margin-top: 16px;
}


