// next-page styles
.homepage {
    .btn--arrows {
        width: 24px;
        height: 24px;

        .glyph-thin-arrow-down {
            font-size: 1.5em;
        }
    }
}