// Global typeahead CSS

.twitter-typeahead, .tt-hint, .tt-input, .tt-menu {
	width: 100%;
}

.twitter-typeahead {
	display: block;
}

.tt-input {
	@include direction;
	&::-ms-clear {
		display: none;
	}
}

.tt-menu {
	right: 0;
	left: 0;
	width: 440px;
	max-width: 440px;
	max-height: 300px;
	@include margin(10px, auto, 0, auto);
	overflow: visible;
	font-size: 1.1em;
	color: initial;
	text-align: left;
	background: rgba(255,255,255,.95);
	border: 1px solid $t-standard-faint;
	border-radius: 3px;
	@include mobile {
		width: 100%;
		font-size: .9em;
	}

	&:after {
		$triangleSize: 10px;
		position: absolute;
		top: -$triangleSize;
		@include left(50%);
		display: block;
		width: 0;
		height: 0;
		@include margin-left(-$triangleSize);
		content: '';
		border-right: $triangleSize solid transparent;
		border-bottom: $triangleSize solid rgba(255,255,255,.95);
		border-left: $triangleSize solid transparent;
	}
}

.tt-hint {
	color: $t-standard-faint !important;
}

.tt-dataset {
	height: 100%;
	max-height: 295px;
	padding: 15px 0;
	overflow: auto;
}

.empty-message {
	padding: 0 1em;
}

.tt-dropdown-menu, .tt-menu {
	@include text-align(left);
}

.tt-suggestion {
	@include padding(3px, 10px, 3px, 45px);
	overflow: hidden;
	line-height: 1.25;
	color: $t-standard;
	cursor: pointer;
	@include lgDesktop {
		@include padding(5px, 10px, 5px, 45px);
	}

	&.t-c-s{
		&:before{
			display:none;
		}

		&.gha:before{
			content: $glyph-discovery;
		}

		&.an:before{
			content: $glyph-anantara;
		}

		&.oh:before{
			content: $glyph-oaks;
		}

		&.av:before{
			content: $glyph-avani;
		}

		&.ti:before{
			content: $glyph-tivoli;
		}

		&.ew:before{
			content: $glyph-elewana;
		}

		&.nh:before{
			content: $glyph-nh;
		}

		&.nc:before{
			content: $glyph-ncollection;
		}

		&.nw:before{
			content: $glyph-nhow;
		}

		&.pq:before{
			content: $glyph-niyama;
		}
	}
}

.tt-country,
.tt-city {
	font-weight: bold;

	&:before {
		display: inline-block;
		width: 25px;
		font-family: 'icons';
		text-align: center;
		content: $glyph-location-pin;
	}
}

.tt-country {
	@include padding-left(10px);
	margin-top: 10px;
	color: $t-gold;
	@include lgDesktop {
		@include padding-left(20px);
	}

	&:first-child {
		margin-top: 0;
	}
}

.tt-state {
	font-weight: bold;
	@include padding-left(25px);
	@include no-desktop {
		font-size: 14px;
	}
	@include lgDesktop {
		@include padding-left(27px);
	}
}

.tt-city {
	@include padding-left(20px);
	@include lgDesktop {
		@include padding-left(35px);
	}
}

.tt-highlight {
	color: $t-standard;

	.tt-country & {
		color: darken($t-gold, 7%);
	}
}

.tt-fixed {
	position: fixed;
}

.tt-header {
	padding: 5px 0;
	font-weight: 700;
}
