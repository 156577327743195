.card {
	&__grid {
		display: flex;
		flex-wrap: wrap;
		flex-direction: row;
		justify-content: center;
		
		@include tablet {
			margin:0 -20px;
		}
	}
	
	&__item {
		padding: 0;
		margin-bottom:30px;
		display: flex;
		flex-direction: column;
		justify-content: stretch;
		flex:1 1 auto;
		
		@include tablet {
			width:50%;
			
			> div {
				margin-left:15px;
				margin-right:15px;
			}
		}
		
		@include desktop {
			width:25%;
			
			> div {
				margin-left:10px;
				margin-right:10px;
			}
		}
	}
	
	&__image {
		height:290px;
		
		a {
			position: relative;
			height: 100%;
			width: 100%;
			display: block;
			background-size: cover;
			background-position: center;
			background-repeat: no-repeat;
			border: unset;
			
			img {
				top:0;left:0;
			}
		}
	}
	
	&__container {
		display: flex;
		flex-direction:column;
		flex:1 1 auto;
	}
	
	&__content {
		background: #f8f8f8;
		position: relative;
		margin-bottom: 30px;
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;
		
		@include no-mobile {
			min-height: 135px;
		}
			
		a {
			&.btn {
				width:auto;
				min-width:auto;
			}
		}
	}
	
	&__cta {
		position: absolute;
    	bottom: -20px;
	}
	
	&__title {
		@include mobile {
			padding-bottom: 20px;
		}
	}
}