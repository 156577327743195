.number-counter {
    &-wrapper {
        border-top: 1px solid $f-grey-light-border;
        border-bottom: 1px solid $f-grey-light-border;
    }

    display: flex;
    flex-direction: row;
    justify-content: space-around;
    flex-wrap: wrap;

    .counter {
        display: flex;
        flex-direction: column;
        align-items: center;
        min-width: 130px;

        .number {
			@extend %font-numeric;
			
            color: $t-blue-faint;
        }

        .subtitle-label {
            font-size: medium;
            letter-spacing: revert;
            margin-bottom: 0.5em;
        }
    }
}