//Breadcrumb
.breadcrumb {
	display: flex;
	list-style: none;
	padding: 20px 0;
	font-size: 0.875em;
	width:100%;
	margin:0;
	flex-wrap: wrap;

	li {
		white-space:nowrap;
		font-size:0.75;

		@include desktop{
			font-size:0.875em;
		}
		
		&:not(:last-child)::after {
			content: "/";
			margin: 0 8px;
			color: $f-grey-mid;
		}
	  
		a {
			text-decoration: none;
			color: $f-grey-mid;
			
			&:hover {
				text-decoration: underline;
			}
		}
	  
		&.active {
			color: $f-gold;
		}
	}
}

  