// text-reveal-cta styles
.text-reveal {
	contain: content;
	@include aspect-ratio(1.85, 1);
	overflow: hidden;

	@include mobile {
		.other-offers &:before {
			content: none;
		}
	}


	&:not(.always-revealed).is-active, &:not(.always-revealed).is-focused {
		.text-reveal {
			&__image {
				&:before {
					transform: translateY(-40%);
				}
			}

			&__text {
				transform: translateY(0) !important;	// Important to overwrite inline style
			}
		}
	}

	&__wrapper {
		position: absolute;
		top: 0;
		@include right(0);
		bottom: 0;
		@include left(0);
		color: white;
		text-decoration: none;

		@include mobile {
			.other-offers & {
				position: static;
				background-color: white;
				color: $t-standard;
			}
		}
	}

	&__image {
		height: 100%;

		@include mobile {
			.other-offers & {
				height: calc((1/1.85) * 100vw);
			}
		}

		> img {
			width: 100%;
			height: 100%;
			object-fit: cover;
			object-position: center;
		}

		&:before {
			content: "";
			position: absolute;
			top: 0;
			@include right(0);
			bottom: -100%;
			@include left(0);
			z-index: 1;
			background: linear-gradient(to top, rgba(0,0,0,.9) 0%,rgba(0,0,0,.55) 50%,rgba(0,0,0,0) 80%,rgba(255,255,255,0) 100%);
			transition: transform $long;
			transform: translateY(-10%);
			will-change: transform;

			@include mobile {
				.other-offers & {
					content: none;
				}
			}
		}

		.text-reveal--fallback & {
			&:before {
				background: rgba(0,0,0,0.3);
			}
		}
	}


	&__text {
		position: absolute;
		bottom: 0;
		@include left(0);
		@include right(0);
		z-index: 2;
		display: block;
		// @include margin-right(-18px);
		@include padding(100px, 15px, 20px, 15px);
		// overflow-y: auto;
		-ms-overflow-style: none;
		transition: transform $slow;
		will-change: transform;

		&::-webkit-scrollbar {
			width: 18px;
			background: transparent;
		}

		@include mobile {
			@include padding(50px, 15px, 20px, 15px);

			.other-offers & {
				position: static;
				@include padding(20px, 15px, 20px, 15px);
			}
		}

		.text-reveal--fallback & {
			padding: 20px 30px;
			top: 50%;
			bottom: auto;
			transform: translateY(-50%);

			p {
				margin: 0;
			}

			@include no-desktop {
				padding: 20px;

				p {
					font-size: 1.5em;
				}
			}
		}

		.t-underline{

			&:after{
				margin-top: 10px;
				margin-bottom: 10px;

				.other-offers &{
					margin-top: 15px;
					margin-bottom: 15px;
				}
			}
		}
	}

	&--overflow {
		.text-reveal__text {
			top: 0;
		}
	}

	&__title {
		display: block;
		margin: 0 0 5px 0;
		line-height: 1.2;
		font-weight: 600;
		font-size: 1.6em;
		text-shadow: 3px 3px 8px rgba(0, 0, 0, 0.42);

		.other-offers &{

			@include mobile{
				text-shadow: none;
			}
		}

		@include feature6-col4 {
			@extend %t-m;
		}

		&__link {
			display: block;
			word-wrap: break-word;
		}

	}
	&__location{
		@extend %font-sub-title;
		color: $f-grey-faint;
	}

	&__sub-title {
		@extend %font-sub-title;
		display: block;
		margin-bottom: 15px;
		overflow: hidden;

		@include mobile{
			font-size: 0.9em;
		}

		+ .text-reveal__from-rate {
			margin-top: -15px;
		}
	}

	&__from-rate {
		display: block;
		margin-bottom: 10px;
		font-size: 1em;

		.other-offers &{
			margin-bottom: 15px;
		}

		.is--campaign &{
			font-size: 1.1em;
		}
	}

	&__benefits {
		margin: 5px 0;
		list-style: none;
		padding-inline-start: 30px;

		&Item {
			position: relative;

			&:before {
				content: $glyph-check-circle-outline;
				@extend %icon-font-glyph;
				position: absolute;
				@include left(-30px);
				top: 50%;
				transform: translateY(-50%);
				font-size: 1.2em;
				color: $t-rate;
			}
		}
	}

	&__bookingBenefits {
		list-style: none;
		color: $t-gold;
		margin: 0 0 15px;

		&Title {
			margin: 8px 0 5px;
			color: $t-gold;
			font-weight: 600;
		}

		&Item {
			position: relative;

			&:after{
				content: $glyph-thumbs-up;
				@extend %icon-font-glyph;
				position: absolute;
				@include left(-30px);
				top: 50%;
				transform: translateY(-50%);
				font-size: 1.2em;
			}
		}
	}

	&__icon {
		display: inline-block;
		@include margin-right(15px);

		&:last-child {
			@include margin-right(0);
		}

		i {
			@include margin-right(5px);
			font-size: 18px;
			vertical-align: middle;
		}
	}

	&__description {
		display: block;
		margin-bottom: 0;

		.rte-block {
			margin-top: 0;
		}

		ul {
			padding: 1.2em;
			margin: 0;
		}

		p{
			margin: 5px 0;

		}
	}

	.btn:first-of-type {
		margin-top: 10px;
	}

	.text-cta {
		@include margin-left(-25px);
		@include mobile {
			@include margin-left(-25px);
		}

		&--more-details:before {
			@include mobile {
				@include padding-right(8px);
			}
		}
	}

	.btn {
		//@include margin-right(1em);
		width: 100%;


		@include mobile {
			@include margin-right(0);
		}

		&--ghost {
			color: #fff;
			border-color: #fff;

			&:hover, &:focus {
				color: $t-standard;
			}
		}
	}

	&--fallback {
		height: 100%;
	}

	&--tall {
		position: relative;
		max-height: 100vh;

		@include desktop{
			min-height: 450px;
		}

		&:before {
			padding-top: 150%;
		}

		.col-3 & {
			.text-reveal__title {
				font-size: 1.4em;

				@include tablet{
					font-size: 1.2em;
				}
			}

			.text-reveal__text {
				@include padding(20px, 15px, 20px, 15px);
			}

			.text-reveal__image{

			&:before{
				background: linear-gradient(to top, rgba(0,0,0,.9) 0%,rgba(0,0,0,.6) 55%,rgba(0,0,0,0) 80%,rgba(255,255,255,0) 100%);
			}

			}
		}
	}

	&--feature {
		position: relative;
		min-height: 590px;

		@include mobile {
			min-height: 520px;
		}

		@include tablet{
			min-height: 560px;
		}
	}

	&--small {
		.text-reveal {
			&__title {
				line-height: 1;
				margin-bottom: 10px;

				&:after {
					content: none;
				}
			}

			&__sub-title {
				margin-bottom: 30px;
			}
		}

		&.is-active, &.is-focused {
			.text-reveal {
				&__description {
					transform: translateY(-30px);
				}
			}
		}

		.btn {
			margin-bottom: 1em;
		}
	}

	&.mobile-revealed {

		@include no-desktop {
			.text-reveal {
				&__image {
					&:before {
						bottom: 0;
						transform: none!important;
					}
				}

				&__text {
					transform: none!important;
				}
			}
		}
	}

	&.always-revealed {
		.text-reveal {
			&__image {
				&:before {
					bottom: 0;
					transform: none!important;
				}
			}
		}
	}

	&.is-card__display{
		min-height:auto;
		flex: 1 1 auto;
		@extend .flex-container;
		@extend .flex-container--column;

		&:before{
			display:none;
		}
		.text-reveal {

			&__wrapper{
				background: $f-text-reveal-cta-bg;
				color:$t-standard;
				position:relative;
				@extend .flex-container;
				@extend .flex-container--column;
				flex: 1 1 auto;


				&:before{
					display:none;
				}
			}

			&__image {
				height:220px;
				&:before{
					display:none;
				}
			}

			&__text{
				padding:15px;
				position: relative;
				@extend .flex-container;
				@extend .flex-container--column;
				flex: 1 1 auto;
				justify-content: space-between;
			}

			&__location{
				color:$t-standard-light;
			}

			&__title{
				text-shadow:none;
				@extend .t-m;
			}

			&__from-text{
				@extend .t-xs;
				text-transform: uppercase !important;
			}

			&__cta{
				@extend .flex-container;
				@extend .flex-container--column;
				margin-top:auto;
			}

		}

		.currency-converter{
			select{
				color:$t-gold;
			}

			.form-main{
				&:after{
					border-top-color:$t-gold;
				}
			}
		}

		.btn--ghost{
			color:$t-standard;
			border-color: $f-grey-mid;

			&:hover, &:focus {
                color: #fff !important;
                background-color: $f-grey-mid;
            }
		}

	}
}

// Hide a fallback container if it is the last child, on a line by itself

@include tablet {
	.col-4.col-tab-6:last-child:nth-child(odd) .text-reveal--fallback {
		display: none;
	}
}

// Hide a fallback container if it is the last child
@include mobile {
	.col-4.col-tab-6:last-child .text-reveal--fallback {
		display: none;
	}
}


.text-reveal--one-slide {
	> .col-4 {
		width: 100%;
		max-width: 750px;
		margin: 0 auto;
		float: none;
	}
}

@include mobile {
	.text-reveal--one-slide {
		.slick-slide {
			@include float(left);
		}
	}
}

.other-offers, .offers {

	.slick-dots {
		bottom: -22px;
		
		.section--pbs &, .section--pbm & {
			bottom:0;
		}
	}

	.slick-slide {
		@include border-left(10px solid transparent);
		@include border-right(10px solid transparent);
	}
}

.other-offers {
	.slick-slide {
		@include mobile {
			border: 0;
		}
	}
}

@include no-mobile {
	.offers {
		&--only-one {
			.text-reveal {
				@include aspect-ratio(1.85, 1);
				background: #222;

				&__image {
					@include float(left);
					width: (100%/3);

					&:before {
						content: none;
					}
				}
				&__text {
					bottom: auto;
					@include left(auto);
					@include float(left);
					width: ((100%/3)*2);
					padding: 10%;

					transform: translateY(0) !important;
				}
			}
		}
	}
}

.has-border {
	border: 10px solid transparent;

	@include mobile {
		@include border-left(0);
		@include border-right(0);
	}

	.other-offers &{
		border: 0;
	}
}
