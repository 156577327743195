// hotel-select styles
.hotel-select {
	margin: 40px 10px 20px;

	&__label {
		display: block;
		color: #333;
		margin-bottom: 5px;
		text-align: center;
		font-weight: 700;
		font-size: 13px;
	}

	&__input{
		max-width: 100%;
		width: 100%;
		min-height: 1.25em;
		padding: 7px 32px;
		margin-top: 10px;
		margin-bottom: 8px;
		font-size: 1.0625em;
		font-weight: 300;
		color: #333;
		text-align: center;
		resize: none;
		background-color: #ededed;
		border: 0;
		border-radius: 2px;
	}

	&__errmsg {
		margin-bottom: -10px;
    	line-height: 1.15;
		text-align: center;
		display: none;
	}
}
