// text-cta styles
.text-cta {
	position: relative;
	display: block;
	padding: 0 1.5em;
	color: $t-gold;
	text-decoration: none;
	transition: transform $medium;
	transform: translateX(bi-app-reverse-percentage(0));
	/* will-change: transform; */

	&:hover, &:focus {
		transform: translateX(bi-app-reverse-percentage(1em));
	}

	&:before, &:after {
		display: inline-block;
		content: "";
	}

	&--no-hover {
		&:hover, &:focus {
			transform: none;
		}
	}

	&--arrow {
		@include padding-left(0);

		&:before {
			@include margin-right(10px);
			content: $glyph-angle-right;
			@extend %icon-font-glyph;
			display: inline-block;

			@include rtl {
				transform: rotate(180deg);
			}
		}
	}

	&--arrow-right {
		@include padding-left(0);

		&:after {
			@include margin-left(10px);
			content: $glyph-angle-right;
			@extend %icon-font-glyph;
			display: inline-block;

			@include rtl {
				transform: rotate(180deg);
			}
		}
	}

	&--circle {
		@include padding-left(0);

		&:before {
			content: "";
			display: inline-block;
			width: 8px;
			height: 8px;
			@include margin-right(10px);

			border: 1.5px solid #fff;
			border-radius: 50%;
		}
	}

	&--explore {
		$triangle-size: 6px;
		vertical-align: middle;
		color: rgba(255,255,255,0.9);

		&:before, &:after {
			vertical-align: middle;
			border-top: $triangle-size solid transparent;
			border-bottom: $triangle-size solid transparent;
		}

		&:before {
			@include padding-right(.5em);
			@include border-left($triangle-size solid $t-gold);
		}

		&:after {
			@include padding-left(.25em);
			@include border-right($triangle-size solid $t-gold);
		}
	}

	&--more-details {
		$triangle-size: 17px;
		padding: 0;

		&:before {
			@include padding-right(18px);
			vertical-align: middle;
			border-top: $triangle-size solid transparent;
			border-bottom: $triangle-size solid transparent;
			@include border-left($triangle-size*1.45 solid $t-gold);
		}
	}

	&--back {
		@include padding-left(0);

		&:hover, &:focus {
			transform: translateX(bi-app-reverse-percentage(-1em));
		}

		&:before {
			font-family: 'icons' !important;
			content: $glyph-chevron-left;
		}
	}

	&--disabled {
		color: lighten($t-gold, 25%);
		transition: all $quick;

		&:hover, &:focus {
			top: 0;
			transform: translateX(bi-app-reverse-percentage(0));
			cursor: not-allowed;
		}
	}

	&--circle-arrow {

		&:hover {
			text-decoration: none;
		}

		[class^="glyph-"] {
			position: relative;
			display: inline-block;
			background: $f-gold;
			width: 16px;
			height: 16px;
			line-height: 12px;
			@include margin-left(0.5em);

			@at-root .t-xs#{&} {
				margin-top: -4px;
			}

			color: #fff;
			text-align: center;
			border-radius: 50%;
			vertical-align: middle;

			&:before {
				position: absolute;
				top: 0;
				@include left(0);
				@include right(0);
				width: 16px;
				height: 16px;
				font-size: 16px;
				line-height: 1;
				vertical-align: middle;
			}
		}
	}
}
