// marketing-message styles
.inline-message {
	padding: 1.5em 0;
	@include text-align(right);

	opacity: 0;
	transform: translateX(bi-app-reverse-percentage(-30px));
	transition: opacity $long, transform $long;

	&.is-active {
		opacity: 1;
		transform: translateX(bi-app-reverse-percentage(0));
	}
}


.marketing-message {
	max-width: 1025px;
	padding: 33px 25px 22px;
	@include margin-left(auto);
	@include margin-right(auto);
	opacity: 0;
	transform: translateX(bi-app-reverse-percentage(-30px));
	transition: opacity $long, transform $long;
	border: 1px solid $f-gold;
	border-radius: 5px;
	float: none;
	text-align: center;

	@include mobile {
		@include padding-left(0);
		@include padding-right(0);
	}

	&.is-active {
		opacity: 1;
		transform: translateX(bi-app-reverse-percentage(0));
	}

	&__wrapper {
		position: relative;
		padding: 0 20px;
		max-width: 600px;
		@include margin-left(auto);
		@include margin-right(auto);

		[class^='glyph']:before {
			position: absolute;
			vertical-align: top;
			font-size: 4em;

			top: -15px;
			@include left(0);
			@include right(0);
		}
	}

	&__title {
		margin-bottom: 0.5em;

		p {
			margin: 0;
		}
	}

	&__description {
	}

	ul{
		list-style-position: inside;
	}

	&.privilege-signup{
		margin: 25px 0;
		
		@include desktop {
			margin: 25px auto;
		}
	}
}
