// social-mobile-message styles

	#wechat_message, #property_wechat_message,.wechat-popup{
   		display: none;
   		padding:20px 10px;
        height:100%;

        @include tablet{
            width: 500px;
            max-width:600px;
            padding: 20px;
        }
	}

	.tooltip-content-mobile {
		width:100%;
		overflow: hidden;

         @include desktop{
        	display: none;
        }

		&__item {
			@include left(0);
            width: 100%;
            position: relative;
            text-align: center;
            color:#fff;
            line-height: 1.5;

        }        
	}
