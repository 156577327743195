// detail-block styles
.detail-block {
	@extend %clearfix;

	border-bottom: 1px solid $f-grey-light;
	margin-top: 40px;
	margin-bottom: 30px;
	padding-bottom: 30px;

	.col-4{
		@include padding-right(1em);
	}

	.col-8 {
		margin-bottom: 15px;
		@include padding-right(1em);
	}

	&:last-child {
		border-bottom: 0;
		margin-bottom: 0;
		padding-bottom: 0;
	}

	&__location {
		padding-right: 10px;
		letter-spacing: 1.5px;
		margin-bottom:10px;
	}


	&__info {
		padding-right: 10px;

		@include mobile {
			margin-top: 0;
		}

		// For breaking email addresses
		> a {
			word-break: break-word;
			word-break: break-all;
		}
	}

	&__title {
		margin-bottom: 10px;
		line-height: 1;

		word-break: break-word;
		// word-break: break-all;
		hyphens: auto;
	}

	&__detail {
		margin-bottom: 25px;

		@include mobile {
			padding-top: 0 !important;	// Overwrite inline CSS
		}
	}

	&__cta{
		margin:10px 0;

		a.text-cta {
			padding:0;
			font-weight:600;
			display:inline-block;
			align-items: center;
			text-decoration:none;
			border-bottom: 1px solid $t-gold;

			.glyph-chevron-right {
				@include rtl {
					transform: rotate(180deg);
				}
			}
		}
	}

	@include mobile {
		margin-bottom: 0;
		padding-bottom: 10px;
	}
}
