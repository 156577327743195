.spec-block {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
	
	@include tablet {
		flex-direction: row;
	}
	
	@include desktop {
		flex-direction: row;
	}

    &__item {
        padding: 0 10px;
		margin: 0 0 15px;
        
        @include no-mobile {
            padding: 0 35px;
        }
		
		@include tablet {
			margin: 0;
		}
    }

    &__name,
    &__number {
        margin: 0;
    }

    &__number {
        font-weight: bold;
        text-transform: lowercase;
        white-space: nowrap;
    }

    &__unit {
        text-transform: lowercase;
        margin-left: 10px;
    }

    &--withIcon {
        display: flex;
        flex-direction: row;
        align-items: center;

        .spec-block {
            &__fontIcon {
                font-size: 50px;
                color: $t-standard-light;

                @include margin-right(10px);
            }

            &__name {
                letter-spacing: 1px;
            }

            &__number {
                line-height: 0.9em;
            }
        }
    }
}