// gallery-item styles

.grid {
	$numberCols: 12;
	$column: 100/$numberCols;

	margin: 0 0.5em;
	overflow: hidden;

	&.grid--coral {
		.col-4 {
			@include padding(0, 15px, 0, 0);

			@include mobile {
				padding: 0;
			}
		}
	}

	&-sizer {
		width: ($column * 6) * 1%;
		height: 0;

		@include tablet {
			width: $column * 1%;
		}

		@include desktop {
			width: $column * 1%;
		}
	}

	&-item {
		@include aspect-ratio(1.85, 1);

		display: block;
		width: 100%;
		max-width: 100%;
		@include float(left);
		overflow: hidden;

		border-bottom: 1.5em solid transparent;
		@include border-left(0.75em solid transparent);
		@include border-right(0.75em solid transparent);

		.grid--coral & {
			border-bottom: 0;
			@include border-left(0);
			@include border-right(0);

			@include desktop {
				border-bottom: 1.5em solid transparent;
			}
		}

		.mobile-no-border & {
			@include mobile {
				border: 0;

				.grid-item__offscreen {
					@include left(15px);
				}
			}
		}

		&.grid-item--column-sizing {
			width: 100%;
			@include aspect-ratio(1.5, 1);
		}

		&.grid-item--title-visible {
			& .grid-item__offscreen {
				transform: translateY(0%);
			}

			& .grid-item__image {
				&:before {
					transform: translateY(-20%);
				}
			}
		}

		& .grid-item__offscreen {
			.social-share {
				margin-bottom: 1em;

				&__item {

					font-size: 20px;
					width: 42px;
					height: 42px;
					padding-top: 9px;
					color: #fff;
					border-color: #fff;

					&:hover, &:focus {
						background-color: #fff;
						color: $t-standard;
					}

					@include mobile {
						font-size: 18px;
						width: 36px;
						height: 36px;
						padding-top: 7px;
					}
				}
			}
		}

		@include tablet {
			width: $column*6 * 1%;
		}

		@include desktop {
			width: $column*6 * 1%;

			@for $i from 1 through $numberCols {
				&[data-width="grid-item--width#{$i}"] {
					width: ($column * $i) * 1%;
				}
			}

			&[data-width="grid-item--width3"] {
				height: 185px;
			}

			&[data-width="grid-item--width4"] {
				height: 243px;
			}

			&[data-width="grid-item--width6"] {
				height: 370px;
			}

			&[data-width="grid-item--width8"] {
				height: 486px;
			}
		}

		.content img {
			width: 100%;
			height: 100%;
			object-fit: cover;

			transform: scale(1);
			transition: transform $slow;
			will-change: transform;
		}

		&.mobile-revealed {
			@include mobile {
				.grid-item {
					&__image {
						&:before {
							transform: translateY(-25%);
						}
					}

					&__offscreen {
						transform: translateY(0);
					}
				}
			}
		}

		&:hover {
			.content img {
				transform: scale(1.05);
			}

			.grid-item {
				&__image {
					&:before {
						transform: translateY(-25%);
					}
				}

				&__offscreen {
					transform: translateY(0);
				}
			}
		}

		&__link {
			overflow: hidden;
		}

		&__image {
			height: 100%;

			&:before {
				content: "";
				position: absolute;
				top: 0;
				@include right(0);
				bottom: -100%;
				@include left(0);
				z-index: 1;
				background: linear-gradient(to top, rgba(0,0,0,.9) 0%,rgba(0,0,0,.65) 25%,rgba(0,0,0,0) 55%,rgba(255,255,255,0) 100%);
				transition: transform $long;
				transform: translateY(-5%);
				will-change: transform;
			}
		}

		&__video {
			display: none;
		}

		.video_playBtn{
			display: block;
			width: 75px;
			height: 75px;
			margin:auto;
			text-align: center;
			background-color: rgba(0, 0, 0, .3);
			border-radius: 50%;
			position: absolute;
			top:50%;
			left:50%;
			transform: translate(-50%,-50%);
	
			&:before {
				position: absolute;
				top: 0;
				bottom: 0;
				left:50%;
				transform: translateX(-35%);
				width: 0;
				height: 0;
				margin: auto;
				content: '';
				border-color: transparent transparent transparent #fff;
				border-style: solid;
				border-width: 20px 0 20px 32px;
	
			}
		}

		&__offscreen {
			position: absolute;
			bottom: 16px;
			@include left(28px);
			z-index: 5;
			display: block;
			pointer-events: none;
			transform: translateY(200%);
			transition: transform $slow;
			will-change: transform;
		}

		&__clone {
			@include left(0);
			opacity: 0;
			position: absolute;
			top: 0;
			z-index: 200;

			img {
				width: 100%;
				height: 100%;
				object-fit: cover;
				object-position: 60% center;

				transform: scale(1.05);
				transition: transform $slow;
				will-change: transform;

				@include mobile {
					object-fit: contain;
				}

				@include landscapeMobile {
					object-fit: cover;
				}

				@include tablet {
					object-fit: contain;
				}

				@include landscapeTablet {
					object-fit: cover;
				}
			}

			&:before {
				padding-top: 0;
			}
		}

		&__external-cap {
			@include float(left);
			display: block;
			padding-bottom: 0.5em;

			p {
				@include margin(4px, 0, 12px, 0);
			}
		}
	}
}

#dynamic-gallery {
	opacity: 0;

	&.is-active {
		position: absolute;
		top: 0;
		bottom: 0;
		@include left(0);
		@include right(0);
	}

	&.is-changing {
		&:after {
			opacity: 1;
		}
	}

	&:after {
		content: "";
		display: block;
		position: absolute;
		z-index: 9999;
		top: 0;
		bottom: 0;
		@include left(0);
		@include right(0);
		opacity: 0;

		background-color: #f1f1f1;
		pointer-events: none;

		transition: opacity $quick;
	}
}

.gallery-modal {
	position: fixed;
	top: 0;
	bottom: 0;
	z-index: 200;

	width: calc(100vw - (100vw - 100%));;
	height: 100vh;
	background-color: #fff;


	&--fullscreen {

		/* Arrows */

		$triangle-size: 25px;

		$triangle-size-small: 14px;

		.slick-prev,
		.slick-next {
			z-index: 250;
			width: $triangle-size-small*3;
			height: $triangle-size-small*3;

			background-color: $f-black-overlay;

			@include desktop {
				width: $triangle-size*3;
				height: $triangle-size*3;
			}

			@include mobile {
				top: 34.5%;
			}

			@include landscapeMobile {
				top: 50%;
			}

			@include tablet {
				top: 45%;
			}

			@include landscapeTablet {
				top: 50%
			}

			&:before {
				display: inline;
				width: $triangle-size;
				color: $slick-arrow-color;
				content: "";
				opacity: $slick-opacity-default;
				transition: opacity $quick;
			}
		}

		.slick-prev {
			@include left(0);

			&:before {
				display: block;
				@include padding-left(20px);
				border-top: $triangle-size-small/3*2 solid transparent;
				@include border-right($triangle-size-small solid #fff);
				border-bottom: $triangle-size-small/3*2 solid transparent;

				@include desktop {
					border-top: $triangle-size/3*2 solid transparent;
					@include border-right($triangle-size solid #fff);
					border-bottom: $triangle-size/3*2 solid transparent;
				}

				@include tablet {
					@include padding-left(14px);
				}

				@include mobile {
					@include padding-left(12px);
				}
			}
		}

		.slick-next {
			@include right(0);

			&:before {
				display: block;
				@include margin-left(25px);
				border-top: $triangle-size-small/3*2 solid transparent;
				border-bottom: $triangle-size-small/3*2 solid transparent;
				@include border-left($triangle-size-small solid #fff);

				@include desktop {
					border-top: $triangle-size/3*2 solid transparent;
					border-bottom: $triangle-size/3*2 solid transparent;
					@include border-left($triangle-size solid #fff);
				}

				@include tablet {
					@include margin-left(15px);
				}

				@include mobile {
					@include margin-left(15px);
				}
			}
		}
	}

	&__close {
		position: absolute;
		top: 0;
		@include right(0);
		z-index: 250;

		width: 54px;
		height: 54px;

		background-color: rgba(0,0,0,0.45);
		font-size: 49px;
		font-weight: 100;
		line-height: 1;
		text-align: center;

		@include mobile {
			width: 42px;
			height: 42px;

			font-size: 35px;
		}
	}

	&__container {

	}

	&__item {
		position: relative;
		height: 100vh;
	}

	&__image {
		.content > img {
			width: calc(100vw - (100vw - 100%));
			height: 100vh;

			object-fit: cover;
			object-position: 60% center;

			@include mobile {
				object-fit: contain;
				object-position: 60% 26%;
			}

			@include landscapeMobile {
				object-fit: cover;
			}

			@include tablet {
				object-fit: contain;
				object-position: 60% 26%;
			}

			@include landscapeTablet {
				object-fit: cover;
			}
		}

		iframe {
			position: absolute;
		}
	}

	&__links {

	}

	&__content, &__social {
		position: absolute;
		bottom: 5%;

		padding: 2em;

		background-color: $f-black-overlay;

		@include landscapeMobile {
			display: none;
		}
	}

	&__social {
		padding-top: 1.3em;
		padding-bottom: 1.3em;
	}

	&__controls {
		@include padding-left(16px);
	}

	&__next, &__previous {
		display: inline-block;
		padding: 0 2px;

		[class^='glyph-'] {
			font-size: 1.8em;
			vertical-align: middle;
		}
	}

	&__social {
		@include right(0);
		color: #fff;

		.social-share {
			margin-bottom: 1em;

			@include no-desktop {
				margin-bottom: 2em;
			}

			&__item {

				font-size: 20px;
				width: 42px;
				height: 42px;
				padding-top: 9px;
				color: #fff;
				border-color: #fff;

				&:hover, &:focus {
					background-color: #fff;
					color: $t-standard;
				}

				@include mobile {
					font-size: 18px;
					width: 36px;
					height: 36px;
					padding-top: 7px;
				}
			}
		}
	}

	&__title,
	&__category {
		display: block;
	}

	&__category {
		margin-bottom: .5em;
	}

	@include tablet {
		.content {
		    margin-top: 0;
		}

		&__content, &__social {
			bottom: 6%;
			width: 50%;
		}

		&__content {
			padding-bottom: 44px;
			padding-top: 10px;
		}

		&__social {
			background-color: rgba(0, 0, 0, 0.7);
			padding-bottom: 0;
			padding-top: 15px;
		}
	}

	@include mobile {
		.content {
		    margin-top: 0;
		}

		&__content, &__social {
			bottom: 0%;
			width: 100%;
		}

		&__content {
			padding-bottom: 130px;
			padding-top: 10px;
		}

		&__social {
			padding-bottom: 0;
			padding-top: 15px;
		}
	}

}
