// table-hero styles


.table-hero {
    text-align: center;
    color: $t-standard;

    .table-wrapper {
        border: 0;
    }

    &--wedding {
        td {
            &:first-child {
                width: 35%;
            }
        }
    }

    &__wrapper {
        margin-bottom: 50px;
    }

    table {
        border: 1px solid $t-standard-light;
        margin-bottom: 0;

        &.no-border{
            border: 1px solid transparent;
        }
    }

    th {
        padding: 0;
        text-align: center;
        font-weight: 400;
        border-bottom: 0;
    }

    td {
        line-height: 1.25;
        font-weight: 400;

        &:first-child {
            width: 200px;
            position: relative;

            @include mobile {
                width: 100%;
            }
        }
    }

    thead {
        td,th {
            position: relative;
            background-color: $f-gold;
            color: #fff;
            padding: 12px;

            i {
                font-size: 40px;
            }

            span {
                display: block;
            }
        }
    }

    tbody {
        tr {
            &:nth-child(even) {
                td {
                    background-color: $f-grey-faint;
                }
            }

        }

        td {
            @extend %t-c-m;

            background-color: #fff;
            border: 0;
            padding: 10px;

            vertical-align: middle;

            [class^='glyph-'] {
                font-size: 22px;

                &.small{
                    font-size: 16px;
                }
            }
        }
    }

    a {
        color: $f-gold;
    }

    .btn {
        margin: 0;
    }

    &--banner {
        @include aspect-ratio(1.85, 1);

        height: 250px;
        overflow: hidden;
        margin-top: 30px;
        position: relative;
        width: 100%;

        &:first-child {
            margin-top: 0;
        }

        &:before {
            content: "";
            position: absolute;
            top: 0;
            bottom: 0;
            @include left(0);
            @include right(0);
            z-index: 1;
            display: block;
            background-color: rgba(0, 0, 0, 0.4);
        }

        img {
            object-fit: cover;
            width: 100%;
            height: 100%;
        }
    }

    &--title {
        color: #fff;
        position: absolute;
        top: 50%;
        @include right(0);
        @include left(0);
        text-align: center;
        transform: translateY(-50%);
        z-index: 10;
        text-shadow: 0 0 10px #000;
    }

    /*Country Row*/
    tr.country{

        b.tablesaw-cell-label {
            display: none;
        }

        td {
			@extend %font-alternate-body;
            background:#cccccc;
            color:$f-grey-dark;
            font-weight: 400;
            letter-spacing: 2px;
            font-size: 0.92em;
            text-transform: uppercase;
        }
    }

}
