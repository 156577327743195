// page-announcements styles

	#page-announcement-container {
		display:none;
	}

	.page-announcement {
		@include left(20px);
		overflow: hidden;
		padding: 0;
        @include padding-right(20px);

		&__container {
            width: 100%;
            height: 100%;
			overflow: hidden;
            background: #fff;

            @include no-mobile {
                width: 600px; // same as js that limit width to 600px

                &:after {
                    content: '';
                    display: block;
                    padding-top: 65%;
                }
            }
		}

		&__item {
			bottom: 4px;
			@include left(0);
            max-width: 300px;
            min-height: 250px;
            padding: 20px;
            position: relative;

            @include no-mobile {
                position: absolute;
            }

			&:before {
				padding: 0;
            }
		}

		&__image {
			width: 100%;
			height: 100%;
            object-fit: cover;
            object-position: center 60%;
            position: absolute;
            top: 0;

			&:after {
				content: '';
				display: block;
				position: absolute;
				top: 0;
				left: 0;
				right: 0;
				bottom: 0;
			}
		}
    }
