//
// nanoScrollerJS (Sass)
// --------------------------------------------------
/** initial setup **/
$nanoClass: "nano" !default;
$paneClass: "nano-pane" !default;
$sliderClass: "nano-slider" !default;
$contentClass: "nano-content" !default;

.#{$nanoClass} {
    width: 100%;
    height: 100%;
    position: relative;
    overflow: hidden;
    -ms-overflow-style: none;

    .#{$contentClass} {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        // overflow: auto;
        overflow-x: hidden;

        &:focus {
            outline: thin dotted;
        }

        // Fix for Webkit on OSX so the scroll bar is always shown when overflow:scroll is set.
      /*   &::-webkit-scrollbar {
            display: none;
            -webkit-appearance: none;
            width: 15px;
        } */
    }

    &.is-booking-mask {
        .#{$contentClass} {
            background: rgba(0,0,0,.7);
        }
    }

    > .#{$paneClass} {
        width: 10px;
        background: rgba(0,0,0,.25);
        position: absolute;
        top: 0;
        @include right(0);
        bottom: 0;
        transition: .2s;
        border-radius: 5px;
        visibility: hidden\9; // Target only IE7 and IE8 with this hack
        opacity: .01;

        > .#{$sliderClass} {
            background: #444;
            background: rgba(0,0,0,.5);
            position: relative;
            margin: 0 1px;
            border-radius: 3px;
        }
    }
}

.has-scrollbar > .#{$contentClass} {
    .is-ie & {
        // overflow: scroll !important;
        // margin-right: -17px !important;
    }
}

.has-scrollbar > .#{$contentClass}::-webkit-scrollbar {
    display: block;
}

.#{$paneClass} {
    .#{$nanoClass}:hover > &,
    &.active,
    &.flashed {
        visibility: visible\9; // Target only IE7 and IE8 with this hack
        opacity: 0.99;
    }
}
