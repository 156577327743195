// promo styles

.booking-promo {
	margin-bottom: 0;
	transition: margin $long $easeOutExpo;

	&.is-active {
		@include mobile {
			margin-bottom: 42px;
		}
	}
	
	.horizon & {
		.booking-form-subgroup {
			display: flex;
			height: 53px;
			margin-top: 6px;
			@include desktop {
				flex-direction: column;
				justify-content: center;
				align-items: center;
			}
			@include no-desktop {
				margin-top: 0px;
			}
		}
	}
}

.promo {
	&-btn {
		display: block;
		@include padding(14px, 2px, 13px, 21px);

		@include left(0);

		&:active {
			@include left(-2px);
		}

		@media (max-height: $bpShortWindowBookingMask) {
			padding-top: 6px;
			padding-bottom: 6px;
		}
	}

	&__confirm {
		position: absolute;
		top: 9px;
		@include right(22px);
		text-transform: uppercase;

		@media (max-height: $bpShortWindowBookingMask) {
			top: 0;
		}

		.horizon & {
			color: $t-standard;
			@include desktop {
				color: white;
			}

			@include no-desktop {
				flex: 1;
				top: 50%;
				transform: translateY(-50%);
			}

			@include desktop {
				position: static;
				max-width: 80px;
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;
			}
		}
	}

	&__container {
		$triangle-size: 15px;
		position: absolute;
		top: 0;
		z-index: 5;
		width: calc(100% - 38px);
		@include padding(0, 10px, 0, 10px);
		background-color: #fff;

		transition: transform $long $easeOutExpo;
		transform: translateX(bi-app-reverse-percentage(0)) translateY(0);
		@include desktop {
			width: auto;
			min-width: 240px;
			@include padding(8px, 10px, 9px, 10px);
		}
		@include tablet {
			width: auto;
			min-width: 220px;
			@include padding(8px, 10px, 9px, 10px);
		}

		@include mobile {
			@include left(19px);
		}

		.horizon & {
			@include desktop {
				right:0;
				left:auto;
			}
		}

		&:after {
			position: absolute;
			top: 5px;
			@include right(0);
			display: block;
			width: $triangle-size;
			content: '';
			border-top: $triangle-size solid transparent;
			@include border-right($triangle-size solid $f-bm-bg--primary);
			border-bottom: $triangle-size solid transparent;
			@include mobile {
				content: none;
			}
		}

		.booking-promo.is-active & {
			box-shadow: $f-minimal-box-shadow;
			transform: translateX(bi-app-reverse-percentage(-100%));
			@include mobile {
				box-shadow: none;
				transform: translateX(bi-app-reverse-percentage(0)) translateY(75%);
			}

			@media (max-device-width: 320px) {
				display:flex;
				padding:0;
			}

			.horizon & {
				transform: none;
			}
		}

		.booking-select {
			display: block;
			width: 92%;
			margin: 8px 0;
			font-size: (14 / $base) * 1em;
			background-color: transparent;
			border: 0;
			outline: unset;

			@include mobile {
				@include float(left);
				width: 40%;
				margin: 15px 0;
			}

			@media (max-height: $bpShortWindowBookingMask) {
				margin: 7px 0;
			}
		}

		.booking-code {
			width: 90%;
			padding: 6px 0;
			@include margin-right(8px);
			margin-bottom: 3px;
			@include margin-left(8px);
			font-size: (14 / $base) * 1em;
			font-weight: 300;
			border: 0;
			border-bottom: 1px solid $t-standard-on-gold;
			outline: unset;

			&::placeholder {
				color: $t-standard-faint;
			}

			.booking-promo.is-active & {
				@include mobile {
					@include float(left);
					width: auto;
					padding: 15px 0;
				}

				@media (max-height: $bpShortWindowBookingMask) {
					padding: 8px 0;
				}
			}
		}
	}
}
