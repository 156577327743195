// app-banner styles

.app-section{
    align-items: center;
    margin:10px 0;

    .app-image{
        width: 75px;
        display: flex;
        align-items: center;
        justify-content: center;
        @include margin-right(5px);

        @include mobile{
            margin: 5px 0;
        }

        img{
            width:100%;
            height: auto;
        }
    }

    .app-right-container{
        width: calc(100% - 80px);

        .text-group{
            font-size:0.9em;
            a{
                text-decoration: underline;
    
                i{
                    font-size:12px;
                }
            }
        }

        .app-links{

            margin-top:10px;

            a:first-child{
                @include margin-right(10px);
            }
    
            img{
                max-height:35px;
                width:auto;
            }
        }
    }

    footer &{
        color:#fff;
    }

}