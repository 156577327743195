.alternateDates {
	position: relative;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;

	@include mobile {
        justify-content: start;
	}

    &__or {
        position: relative;
        // border-top: 1px solid $f-grey-light;
        display: block;
        margin: 40px 0;
        color: $t-standard;

        &::after,
        &::before {
            content: '';
            position: absolute;
            background: $f-grey-light;
            height: 1px;
            top: 50%;
            transform: translateY(-50%);
            width: calc(50% - 30px);
            color: $t-standard;
        }

        &::before {
            left: 0;
        }

        &::after {
            right: 0
        }

        // &::after {
        //     content: attr(data-separator);
        //     content: '';
        //     padding: 0 10px;
        //     position: absolute;
        //     background: #eee;
        //     top: -12px;
        //     left: 50%;
        //     transform: translateX(-50%);
        //     color: $t-standard;
        // }
    }

    &__wrapper {
		text-align:center;
    }

    &__title {
        color: $t-standard;
        margin-top: 0;
    }

	&__loading {
		min-width: 165px;
		padding: 10px;
		margin: 0 auto;
		flex: 1 auto;
		box-sizing: border-box;
		text-align: center;
		background-color: #f9f9f9;
		color: $t-standard;
        display: none;
	}

    &__option {
        width: 220px;
        padding: 10px;
        margin: 5px;
        box-sizing: border-box;
        text-align: center;
        border: 1px solid $f-bm-border--primary;
		background-color: $f-bm-bg--primary;
        color: $t-standard;
        display: block;

        @include rtl {
            margin: 10px;
        }

        @include mobile {
            width: 100%;
            display: flex;
        }

        &:hover {
            background-color: #d6d6d6;
        }
    }

    &__optionLink {
        display: inline;
        color: $t-standard;

        &:hover {
            text-decoration: none !important;
        }

        @include mobile {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            align-items: end;
            width: 100%;
        }
    }
	.currency-converter select {
		color:$t-gold;
	}

	.currency-converter .form .form-main:after, .currency-converter .form .trigger:after {
		border-top-color:$t-gold;
	}

    &__from,
    &__price {
        @include mobile {
            @include text-align(left);
        }
    }

    &__col {
        width: 100%;
        display: block;

        @include mobile {
            width: 50%;
            height: 100%;
            min-width: 130px;
            display: inline-flex;
            flex-direction: column;
            box-sizing: border-box;
        }

        @include smallMobile {
            width: calc(50% - 10px);
        }

        &:nth-child(2) {
            @include mobile {
                min-width: 0;
                @include padding-left(10px);
            }
        }
    }

    &__dates {
        display: flex;
        position: relative;
        align-items: center;
        justify-content: space-between;
        background-color: $f-bm-inputs;
        box-sizing: border-box;
        flex-grow: 1;

        &::before {
            content: "";
            position: absolute;
            height: 100%;
            width: 1px;
            transform: translateX(-50%);
            left: 50%;
            top: 0;
            background-color: $f-bm-border--primary;
        }

        &::after {
            content: attr(data-separator);
            position: absolute;
            text-align: center;
            font-size: 0.625em;
            padding: 2px 0;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            background-color: $f-bm-inputs;
            color: $t-standard;
        }

        &Group {
            position: relative;
            width: 50%;
            padding: 0px 5px;
        }

        &Number {
            color: $t-bm-on-primary;

            @include smallMobile {
                font-size: 1em;
            }

            .is-gha & {
                color: $t-standard;
            }
        }

        &Month {
            color: $t-bm-on-primary;

            @include smallMobile {
                font-size: 0.7em;
                line-height: 1.2em;
            }

            &.ja-zh-format {
                padding-top: 10px;
                margin-bottom: -10px;
            }

            &.ko-format {
                padding-top: 10px;
                margin-bottom: -10px;
            }
        }

        &Day {
            font-weight: 500;
            color: $t-bm-on-primary--subtle;
            padding-bottom: 8px;

            @include smallMobile {
                font-size: 0.55em;
            }
        }
    }

    &__los {
        display: block;
        font-size: 0.875em;
        width: 100%;
        padding: 5px 0;
        background-color: $f-grey-dark;
        color: white;
    }

    &__from {
        font-size: 0.7em;
        padding: 15px 0 5px;
        text-align: center;
        display: block;
        color: $t-standard;

        @include mobile {
            @include text-align(left);
            padding: 0 5px;
        }
    }

    &__price {
        display: block;
        font-size: 1em;
        line-height: 1.2em;
        padding: 0 5px;
        color: $t-gold;
        margin: 0 0 10px;
        text-align: center;
        flex-grow: 1;

        @include mobile {
            @include text-align(left);
            padding: 0 0 5px;
        }

        @include smallMobile {
            font-size: 0.9em;
        }

        &Number {
            display:block;
            font-size: 1.02em;
            font-weight: 700;
            color: $t-standard;
        }
    }

    .btn {
        width: 100% !important;
        min-width: 0;
        margin: 0;
        padding-left: 5px;
        padding-right: 5px;
    }

	&--hidden {
		position: absolute;
		overflow: hidden;
		clip: rect(0 0 0 0);
		height: 1px;
		width: 1px;
		margin: -1px;
		padding: 0;
		border: 0;
	}

	&--center {
		justify-content: center;
	}
}

// #alternateDates .option .book-btn {
//     padding: 10px;
//     font-weight: bold;
//     border-radius: 4px;
//     transition: background-color 0.4s;
//     background: #b3a258;
//     color: white;
//     display: block;
//     margin-top: 10px;
// }
