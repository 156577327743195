// vimeo styles
.vimeo {
	position: relative;
	color: #fff;
	// contain: strict;
	min-height: 320px;

	@include mobile {
		min-height: 56.25vw;
	}

	.newsletter-video & {
		max-width: 570px;
		margin: 0 auto;
	}

	.gallery-modal__image & {
		position: absolute;
		height: 100vh;
		width: 100%;
	}

	.section-mobile-video & {
		min-height: 70vh;
	}

	.section-mobile-feature-video & {
		min-height: 50vh;

		@include landscapeTablet{
			display: none;
		}

		@include landscapeMobile{
			min-height:70vh;
		}

		@include iphoneX{
			min-height:80vh;
		}
	}

	.content-intro-complex--vimeo & {

		width: 100%;
		height: 50vw;

		@include desktop {
			width: 85%;
			height: 320px;
		}

		iframe {
			width: 100%;
			height: 100%;

			.section--light-grey &{
				background-color:$f-grey-light-border;
			}

			.section--white &{
				background-color:#fff;
			}
		}
	}

	.banner-logo {
		top: 15vh;
		bottom: auto;
		max-width: 205px;

		@include landscapeMobile {
			display: none;
		}

		@include iphoneX {
			display: none;
		}
	}

	&.is-active {
		.vimeo {
			&__overlay {
				transform: translateY(-50%);
			}

			&__text {
				transform: translateY(50%) !important;  // Important to overwrite inline style
			}

			&__description {
				transform: translateY(-30px);
			}
		}
	}

	&__link {
		position: absolute;
		top: 0;
		@include right(0);
		bottom: 0;
		@include left(0);
		height: 100%;
		overflow: hidden;
		color: #fff;
		text-decoration: none;
		cursor: pointer;
	}

	&__image {
		z-index: 10;
		height: 100%;
		background-repeat: no-repeat;
		background-position: center;
		background-size: cover;
	}

	&__overlay {
		position: absolute;
		top: 0;
		@include right(0);
		bottom: -100%;
		@include left(0);
		background: linear-gradient(to top, rgba(0,0,0,.9) 0%,rgba(0,0,0,.65) 60%,rgba(0,0,0,0) 70%,rgba(255,255,255,0) 100%);
		transition: transform $long;
		transform: translateY(10%);
		will-change: transform;
	}

	&__text {
		position: absolute;
		top: 0;
		@include right(0);
		bottom: 0;
		@include left(0);
		display: block;
		padding: 33px 43px;
		pointer-events: none;
		transition: transform $slow;
		will-change: transform;
		@include mobile {
			padding: 30px 20px;
		}
		@include tablet {
			padding: 30px;
		}
	}

	&__title {
		display: block;
		margin-top: 0;
		margin-bottom: 43px;
		color: inherit;
		text-decoration: none;
		@include mobile {
			margin-bottom: 30px;
		}
		@include tablet {
			margin-bottom: 38px;
		}

		.section-mobile-video & {
			padding: 0 1em;
			margin-top: 10vh;
			text-align: center;

			&:before {
				content: none;
			}
		}

		.section-mobile-feature-video & {
			position: relative;
			z-index: 15;
			max-width: 150px;
			max-height: 3em;
			margin: auto auto auto 0;
			top: 50%;
			@include left (50%);
			transform:translate(-50%,-50%);
			@include margin(35px, auto, auto, 0);
			text-align: center;

			@include rtl {
				transform:translate(50%,-50%);
			}

			&:before {
				content: none;
			}
		}

		&:before {
			@include padding-right(10px);
			content: $glyph-youtube;
			@extend %icon-font-glyph;
		}
	}

	&__description {
		display: block;
		transition: transform $slow;
		transform: translateY(0);
		will-change: transform;
	}

	&__player, .youku__player {
		position: relative;
		z-index: 17;
		display: none;
		height: 100%;

		iframe {
			width: 100%;
			height: 100%;
			background-color: #333;
		}
	}

	&__play {
		position: absolute;
		top: 0;
		@include right(0);
		bottom: 0;
		@include left(0);
		z-index: 15;
		display: block;
		width: 94px;
		height: 94px;
		margin: auto;
		text-align: center;
		background-color: rgba(0, 0, 0, .3);
		border-radius: 50%;

		.section-mobile-feature-video & {
			@include right(auto);
			@include left(50%);
			transform: translateX(-50%);
			width: 35px;
			height: 35px;
			background-color: transparent;
			border: 1px solid #fff;

			@include rtl {
				transform: translateX(50%);
			}
		}

		&:before {
			position: absolute;
			top: 0;
			@include right(0);
			bottom: 0;
			left: 0;
			width: 0;
			height: 0;
			margin: auto;
			content: '';
			border-color: transparent transparent transparent #fff;
			border-style: solid;
			border-width: 23.5px 0 23.5px 40px;

			.section-mobile-feature-video & {
				left: 5px; // Preserve for RTL
				border-width: 11.5px 0 11.5px 18px;
				border-left-color: #fff;

				@include rtl {
					left: 10px;
				}
			}
		}
	}
}
