// gallery-simple styles
.gallery-slide {
	padding: 0;
	margin: 0;
	list-style: none;

	.btn--ghost {
		@include mobile {
			color: $f-grey-faint;
			border-color: $f-grey-faint;

			&:hover {
				background-color: lighten($t-standard, 10%);
			}
		}
	}

	&__image {
		@include aspect-ratio(1.85, 1);	// Styling for pre-initialisation of the JS. Prevent FOUC
	}

	img {
		width: 100%;
		height: 100%;
		max-width: 100%;

		object-fit: cover;
		object-position: center 60%;
	}

	&__text {
		margin: 0 5%;
		@include margin-right(-17px);
		@include mobile {
			position: static;
		}
	}

	&__title {
		display: inline-block;
		@include margin(30px, 20px, 10px, 0);

		.carousel-tabs--stacked & {
			@include margin(30px, 0, 10px, 0);
		}
	}

	&__description {

		width: 100%;
		font-size: .9em;
		line-height: 1.8;
		margin: 0 0 20px;
		// @include padding-right(1em);
		// @include border-right(1px solid $f-grey-light-border);

		.carousel-tabs--stacked & {
			@include border-right(0);
		}

		@include mobile {
			@include border-right(none);
		}

		> p {
			@include margin(0, 0, 20px, 0);
		}

		.section--light-grey & {
			// @include border-right(1px solid #fff);
		}
		
		.section--light-grey .carousel-tabs & {
			@include border-right(0);
		}
	}

	&__ctas {
		margin-bottom: 30px;

		.carousel-tabs--stacked & {
			width: 100%;
		}
	}

	&.slick-slider {
		height: 100%;
	}

	.slick {
		&-list , &-track, &-slide {
			height: 100%;
		}

		&-slide {
			content: none;
			height: auto;
			
			&.has-overlay {
				.content {
					&:before {
						content: "";
						position: absolute;
						top: 0;
						bottom: 0;
						@include left(0);
						@include right(0);
						z-index: 1;
						display: block;

						background-color: $f-black-overlay-light;
					}
				}
			}
		}

		&-arrow {
			z-index: 2;
			width: 30px;
			height: 40px;
		}

		&-prev, &-next {
            top: 36%;

			&:before {
				transform: scale(1);
				transition: transform $medium, border $medium;
			}
		}

		&-prev {
			$arrow-size: 20px;
			@include left(-25px);

			&:before {
				border-top: $arrow-size*(2/3) solid transparent;
				border-bottom: $arrow-size*(2/3) solid transparent;
				@include border-right($arrow-size solid #fff);
			}

			&.slick-disabled:before {
				opacity: 1;
				transform: scale(1.4);
				@include mobile {
					opacity: 0.4;
					transform: scale(1);
				}
			}
		}

		&-next {
			$arrow-size: 20px;
			@include right(-25px);

			&:before {
				border-top: $arrow-size*(2/3) solid transparent;
				border-bottom: $arrow-size*(2/3) solid transparent;
				@include border-left($arrow-size solid #fff);
			}

			&.slick-disabled:before {
				opacity: 1;
				transform: scale(1.5);
				@include mobile {
					opacity: 0.4;
					transform: scale(1);
				}
			}
		}
	}

	&--has-overlay {
		.slick-slide .content {
			&:before {
				content: "";
				position: absolute;
				top: 0;
				bottom: 0;
				@include left(0);
				@include right(0);
				z-index: 1;
				display: block;

				background-color: $f-black-overlay-light;
			}
		}
	}


	&--lg-controls {
		.slick {
			&-prev {
				@include desktop {
					$arrow-size: 30px;

					&:before {
						border-top-width: $arrow-size*(2/3);
						border-bottom-width: $arrow-size*(2/3);
						border-right-width: $arrow-size;
					}
				}
				@include lgDesktop {
					$arrow-size: 30px;
				}
			}

			&-next {
				@include desktop {
					$arrow-size: 30px;

					&:before {
						border-top-width: $arrow-size*(2/3);
						border-bottom-width: $arrow-size*(2/3);
						border-left-width: $arrow-size;
					}
				}
				@include lgDesktop {
					$arrow-size: 30px;
				}
			}
		}
	}

	&.hero-banner-slide{

		.slick {

			&-list , &-track, &-slide {
				height: 100% !important;
			}
		}
		.gallery-slide__image {
			position: relative;
			height: 100%;
		}

		 .gallery-slide__text {
			background: transparent;
			position: absolute;
			margin: 0 auto;
			top: 50%;
			left: 50%;
			transform: translate(-50%,-50%);
			text-align: center;
			width:80%;

			.carousel-tabs__ctas{
				justify-content: center;
			}
		}

		.slick-prev, .slick-next{
			top:50%;

			&.slick-disabled:before{
				transform: none;
				opacity: .5;
			}
		}

		.slick-prev{
			@include left (20px);
		}
		.slick-next{
			@include right (20px);
		}
	}

	img.gallery-slide__static-image {
		max-height: 80px;
		width: auto;
		margin: 0 auto;
	}
}
