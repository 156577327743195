.vimeo {
	.section-mobile-feature-video & {
		background-size:cover;
		background-repeat:no-repeat;
		background-position:center;		
	
		.vimeo__player {
			position:fixed;
			z-index:20000;
			top:0;
			left:0;
			bottom:0;
			right:0;
			background:#000;
			
			&.active {
				display:block;
			}
		}
	}
	
	.mobile-video__player {
		position: relative;
		z-index: 10;
		width: 100%;
		height: 100%;

		object-position: center center;

		.is-ie &, .is-edge & {
			height: auto;
			top: 50%;
			transform: translateY(-50%);
		}
	}
	
	.mobile-video__closeBtn {
		position: absolute;
		top: 50px;
		@include left(10px);
		z-index: 99;
		background: none;
		border: none;
		color: $f-gold;
		background: #fff;
		opacity: 0.5;
		border-radius: 50%;
		width: 36px;
		height: 36px;
		@include padding (8px, 5px, 5px, 5px);
		text-align: center;
		&:hover,
		&:focus {
			opacity: 1;
		}

		&:before {
			@extend %icon-font-glyph;
			content: $glyph-cross;
		}
	}

	&.brand{

		.vimeo__title {
			padding: 0 1em;
			margin-top: 10vh;
			text-align: center;
			position: relative;
			top:60%;
			max-width:none;
			max-height:none;

			@include tablet {
				font-size: (50 / $base) * 1em;
			}

			&:before {
				content: none;
			}
		}

		.vimeo__play {
			position: absolute;
			top: 0;
			bottom: 0;
			z-index: 15;
			display: block;
			width: 84px;
			height: 84px;
			margin: auto;
			text-align: center;
			background-color: rgba(0, 0, 0, .3);
			border-radius: 50%;
			border:0;
	
			&:before {
				position: absolute;
				top: 0;
				right: 0;
				bottom: 0;
				left: 5px;
				width: 0;
				height: 0;
				margin: auto;
				content: '';
				border-color: transparent transparent transparent #fff;
				border-style: solid;
				border-width: 23.5px 0 23.5px 40px;

			}
		}
	}
}