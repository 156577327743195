// gallery-simple styles
.gallery__grid {
	position: relative;
    display: flex;
    flex-wrap: wrap;
	
	@include desktop {
		&.header-banner {
            margin-top: 34px;
            
            .horizon-header & {
				// margin-top: 98px;
                margin-top: 0;
			}

            .horizon-header.with-notice & {
                margin-top: 0;
                // margin-top: 40px;
            }
		}
	}
	&__item {
        padding-bottom: 56.25%;
        position: relative;
        flex: 0 0 50%;

        &.is-large {
            .gallery__grid__image-wrapper {
                border-left: 0;
                border-bottom: 0;
            }
        }
    }
	
	&__image-wrapper {
        bottom: 0;
        height: inherit;
        left: 0;
        position: absolute;
        right: 0;
        top: 0;
        border: 1px solid #fff;

        img{
            bottom: 0;
            height: 100%;
            left: 0;
            position: absolute;
            right: 0;
            top: 0;
            width: 100%;
            display: block;
            object-fit: cover;
        }
    }	

    .col-6 {
        margin:0;

        &:nth-child(2) {
            display: flex;
            flex-wrap: wrap;

            .gallery__grid__item {
                padding-bottom: 28.125%;
                position: relative;
                flex: 0 0 50%;

                &:nth-child(3), &:nth-child(4) {
                    .gallery__grid__image-wrapper {
                        border-bottom:0;
                    }
                }
            }
        }
    }	

    .see-more-cta {
        position: absolute;
        @include right(20px);
        bottom:20px;
        background: rgba(0, 0, 0, 0.75);
        color:#fff;
        text-decoration: none;
        padding: 8px 12px;
        border-radius:6px;
        font-size:14px;
        
        &:hover {
            background: rgba(0, 0, 0, 0.88);
        }
        
        [class^="glyph-"], 
        [class*=" glyph-"] {
            font-family: $font-raleway !important;

            &::before {
                @extend %icon-font-glyph;
                display: inline-block;
                @include margin-right(10px);
            }
        }
    }

    .js-grid-gallery {
        position: absolute;
        @include left(0px);
        top:0px;
        width:100%;
        height:100%;
    }
	
	&__modal {
		.lg-outer {
			direction: ltr;
		}
		
		.lg-icon {
			font-family: "icons" !important;
			speak: none;
			font-style: normal;
			font-weight: 400;
			font-variant: normal;
			text-transform: none;
			line-height: 1;
			-webkit-font-smoothing: antialiased;
			-moz-osx-font-smoothing: grayscale;
			color:#fff !important;

			&.lg-close {
			   @extend .glyph-cross;
			   font-size:25px;
			}

			&.lg-download {
				@extend .glyph-in;
				font-size:18px;
			}

			&.lg-prev {
				@extend .glyph-chevron-left;
				font-size:2.5em;
				background:transparent;
			}

			&.lg-next {
				@extend .glyph-chevron-right;
				font-size:2.5em;
				background:transparent;
			}
		}

		.lg-counter {
			color:#fff;
		}

		.lg-outer {
			.lg-thumb-outer{
				background:transparent
			}

			.lg-thumb-item {
				border-radius:0;
				border:2px solid transparent;
		
				&:hover, &.active{
					border-color:#fff;
				}
			}
		}
	}
}