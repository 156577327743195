.has-dynamic-map-controller + .section--foreign-currency {
	@extend .section--light-grey;
	padding-top: 1em;
	color: $t-standard;
}

.homepage .section--foreign-currency {
	@extend .section--white;
	color: $t-standard;
}

#frmJoinGHA .errmsg {
	height: 0;
	margin-bottom: 0;
}
