// .t = typography

%t {
	// Testimonials
	&-xxs {
		font-size: (10 / $base) * 1em;
		font-weight: 400;	// Medium
		// font-weight: 600;	// For Times news only
		line-height: 1.5;
		text-transform: uppercase;
		letter-spacing: (300 / 1000) * 1em;
	}

	// Section title eg. Discover Anantara. Discover CTA title
	// Button CTA (DemiBold)
	// Signatures title
	&-xs {
		font-size: (12 / $base) * 1em;
		font-weight: 400;	// Medium
		// font-weight: 600;	// For Times news only
		line-height: 1.5;
		text-transform: uppercase;
		letter-spacing: (250 / 1000) * 1em;
	}

	// Image bg section title eg. Begin your journey with us
	// Carousel Tabs nav
	&-s {
		font-size: (14 / $base) * 1em;
		font-weight: 400;	// Medium
		// font-weight: 600;	// For Times news only	
		line-height: 1.5;
		text-transform: uppercase;
		letter-spacing: (300 / 1000) * 1em;
		@include mobile {
			font-size: (12 / $base) * 1em;
		}
	}

	// Suggestion
	&-sm {
		font-size: (20 / $base) * 1em;
		font-weight: 300;	// Light
		line-height: 1.3;
		letter-spacing: (50 / 1000) * 1em;
	}

	// Discover CTA title
	// Section footer text
	// Location Map title

	&-m {
		font-size: (26 / $base) * 1em;
		font-weight: 300;	// Light
		line-height: 1.25;
		letter-spacing: (50 / 1000) * 1em;
		@include mobile {
			font-size: (22 / $base) * 1em;
		}

		& p:first-of-type {
			@include desktop {
				margin-top: 0;
			}
		}
	}

	&-ml {
		font-size: (32 / $base) * 1em;
		font-weight: 300;	// Light
		line-height: 1.25;
		letter-spacing: (50 / 1000) * 1em;
		@include mobile {
			font-size: (26 / $base) * 1em;
			line-height: 1.3;
		}
		@include tablet {
			line-height: 1.4;
		}
	}

	// Signatures title text

	&-l {
		font-size: (40 / $base) * 1em;
		font-weight: 300;	// Light
		line-height: 1.25;
		text-transform: uppercase;
		letter-spacing: (20 / 1000) * 1em;
		@include mobile {
			font-size: (32 / $base) * 1em;
			line-height: 1.3;
		}
		@include tablet {
			line-height: 1.4;
		}
	}

	// Section titles

	&-xl {
		font-size: (50 / $base) * 1em;
		font-weight: 100;	// Thin
		line-height: 1.25;
		text-transform: uppercase;
		letter-spacing: (20 / 1000) * 1em;
		@include mobile {
			font-size: (32 / $base) * 1em;
		}
	}

	// Testimonials
	// Journey Explorer
	&-xxl {
		font-size: (70 / $base) * 1em;
		font-weight: 100;	// Thin
		line-height: 1.3;
		text-transform: uppercase;
		letter-spacing: (20 / 1000) * 1em;
		@include desktop {
			line-height: 1.5;
		}
		@include tablet {
			font-size: (36 / $base) * 1em;
		}
		@include mobile {
			font-size: (18 / $base) * 1em;
		}
	}

	// Footer Life is a Journey
	&-xxxl {
		font-size: (80 / $base) * 1em;
		font-weight: 100;	// Thin
		line-height: 1.3;
		text-transform: uppercase;
		letter-spacing: (20 / 1000) * 1em;
		@include desktop {
			line-height: 1.5;
		}
		@include tablet {
			font-size: (70 / $base) * 1em;
		}
		@include mobile {
			font-size: (42 / $base) * 1em;
		}
	}

	// Content Typography

	&-c {
		// Header link
		&-xxs {
			font-size: (10 / $base) * 1em;
			font-weight: 700; // Bold
			line-height: (15 / 10) * 1em;
			text-transform: uppercase;
		}

		// footer link
		&-xs {
			font-size: (12 / $base) * 1em;
			font-weight: 300; // Light
			line-height: (24 / 14) * 1em;
			letter-spacing: (50 / 1000) * 1em;
		}

		// Special offers content, footer quick links
		&-s {
			font-size: (14 / $base) * 1em;
			font-weight: 300; // Light
			line-height: (24 / 14) * 1em;
			letter-spacing: (50 / 1000) * 1em;
			
			a {
				color: $t-gold;
				text-decoration: underline;
				
				&:not(.btn--ghost) {
					border-bottom: 0;
				}
				
				&.btn {
					text-decoration: none;
				}
			}
		}

		&-m {
			font-size: (16 / $base) * 1em;
			font-weight: 300; // Light
			line-height: (24 / 16) * 1em;
			letter-spacing: (50 / 1000) * 1em;

			& p:first-of-type {
				@include desktop {
					margin-top: 0;
				}
			}
			
			> h1 {
				margin-top: 0;
			}
			
			a {
				color: $t-gold;
				text-decoration: underline;
				
				&.btn {
					text-decoration: none;
				}
				
				&:not(.btn--ghost, .text-cta) {
					border-bottom: 0;
				}
				
				&:not(.btn, .text-cta) {
					color: $t-gold;

					&:hover, &:focus {
						text-decoration: underline;
					}
				}
			}
		}

		&-l {
			font-size: (18 / $base) * 1em;
			font-weight: 300; // Light
			line-height: (24 / 16) * 1em;
			letter-spacing: (50 / 1000) * 1em;

			// @include mobile {
			// 	font-size: (16 / $base) * 1em;
			// }
		}
	}

	&-underline {
		&:after {
			display: block;
			width: 116px;
			height: 1px;
			margin-top: 16px;
			margin-bottom: 16px;
			@include margin-left(-20px);
			content: "";
			background-color: $f-gold;
		}

		&.t-xs {
			&:after {
				margin-top: 10px;
				margin-bottom: 8px;
			}
		}
	}


	&-gold {
		color: $t-gold !important;
	}

	&-white {
		color: #fff !important;
	}

	&-grey {
		color: $t-standard !important;
	}

	&-faint {
		color: $t-standard-faint !important;
	}

	&-very-faint {
		color: #ccc !important;
	}

	&-upper {
		text-transform: uppercase !important;
	}

	&-lower {
		text-transform: lowercase !important;
	}

	&-orig-case {
		text-transform: none !important;
	}

	&-center {
		text-align: center;
		
		ul{
			list-style-position: inside;
		}

		.t-underline {
			&:after{
				margin-left:auto;
				margin-right:auto;
			}
		}
	}

	&-20-ls {
		letter-spacing: (20 / 1000) * 1em !important;
	}

	&-50-ls {
		letter-spacing: (50 / 1000) * 1em !important;
	}

	&-100-ls {
		letter-spacing: (100 / 1000) * 1em !important;
	}

	&-300-ls {
		letter-spacing: (300 / 1000) * 1em !important;
	}
}

.t {
	// Testimonials
	&-xxs {
		@extend %font-small-text-size;
		@extend %t-xxs;
		
		&-o {
			text-transform: none;
		}
	}

	// Section title eg. Discover Anantara. Discover CTA title
	// Button CTA (DemiBold)
	// Signatures title
	&-xs {
		@extend %font-small-text-size;
		@extend %t-xs;
		
		&-o {
			text-transform: none;
		}
	}

	// Image bg section title eg. Begin your journey with us
	// Carousel Tabs nav
	&-s {
		@extend %font-small-text-size;
		@extend %t-s;
		
		&-o {
			text-transform: none;
		}
	}

	// Suggestion
	&-sm {
		@extend %t-sm;
	}

	// Discover CTA title
	// Section footer text
	// Location Map title

	&-m {
		@extend %t-m;
	}

	&-ml {
		@extend %t-ml;
	}

	// Signatures title text

	&-l {
		@extend %t-l;
	}

	// Section titles

	&-xl {
		@extend %t-xl;
	}

	// Testimonials
	// Journey Explorer
	&-xxl {
		@extend %t-xxl;
	}

	// Footer Life is a Journey
	&-xxxl {
		@extend %t-xxxl;
	}

	// Content Typography

	&-c {
		// Header link
		&-xxs {
			@extend %t-c-xxs;
		}

		// footer link
		&-xs {
			@extend %t-c-xs;
		}

		// Special offers content, footer quick links
		&-s {
			@extend %t-c-s;
		}

		&-m {
			@extend %t-c-m;
		}

		&-l {
			@extend %t-c-l;
		}
	}

	&-underline {
		&:after {
			display: block;
			width: 116px;
			height: 1px;
			margin-top: 16px;
			margin-bottom: 16px;
			@include margin-left(-20px);
			content: "";
			background-color: $f-gold;
		}

		&.t-xs {
			&:after {
				margin-top: 10px;
				margin-bottom: 8px;
			}
		}
		
		a {
			text-decoration: none;
		}
	}

	&-gold,
	&-green {
		color: $t-gold !important;
	}

	&-white {
		color: #fff !important;
		
		a {
			color: #fff !important;
		}
		
		&.t-underline {
			&:after {
				background-color: #FFF;
			}
		}
	}

	&-grey {
		color: $t-standard !important;
		
		a {
			color: $t-standard !important;
		}
	}

	&-faint {
		color: $t-standard-faint !important;
		
		a {
			color: $t-standard-faint !important;
		}
	}

	&-very-faint {
		color: #ccc !important;
	}

	&-upper {
		text-transform: uppercase !important;
	}

	&-lower {
		text-transform: lowercase !important;
	}

	&-orig-case {
		text-transform: none !important;
	}

	&-center {
		text-align: center;
	}

	&-left {
		text-align: left;
	}

	&-20-ls {
		letter-spacing: (20 / 1000) * 1em !important;
	}

	&-50-ls {
		letter-spacing: (50 / 1000) * 1em !important;
	}

	&-100-ls {
		letter-spacing: (100 / 1000) * 1em !important;
	}

	&-300-ls {
		letter-spacing: (300 / 1000) * 1em !important;
	}

	&-bold{
		font-weight:600;
	}	
}

%font {
	&-oswald {
		font-family: $font-oswald;
	}
	&-raleway {
		font-family: $font-raleway;
	}
	
	&-title {
		font-family: $font-title !important;
	}
	
	&-sub-title {
		font-family: $font-sub-title !important;
	}
	
	&-navigation {
		font-family: $font-navigation;
	}
	
	&-header {
		font-family: $font-header;
	}
	
	&-alternate-header {
		font-family: $font-alternate-header;
	}
	
	&-body {
		font-family: $font-body;
	}
	
	&-alternate-body {
		font-family: $font-alternate-body;
	}
	
	&-cta {
		font-family: $font-cta !important;
	}
	
	&-small-text-size {
		font-family: $font-small-text-size;
	}
	
	&-numeric {
		font-family: $font-numeric;
	}
}

.f {
	&-oswald {
		@extend %font-oswald;
	}
	&-raleway {
		@extend %font-raleway;
	}
}

//Fancy spacings

.t-s + .t-c-m > p {
	margin-top: 0;
}

.t-l + .t-s {
	margin-top: 1em;
}

//Arabic Letter Spacing Override

html[lang="ar"] *{
	letter-spacing: 0 !important;
}