// step-block styles

.steps-block {
    display: flex;
    justify-content: stretch;
    flex-wrap: wrap;
    align-items: flex-start;
    margin-top: 30px;
}

.step-block {
    &-item {
        @include margin-right(30px);
        @include text-align(left);
        margin-bottom: 40px;

        @include mobile {
            flex: 100%;
            @include margin-right(0);
            text-align: center;
        }
        
        @include tablet {
            flex: calc(50% - 30px);
        }
        
        @include desktop {
            flex: calc(25% - 30px);
            margin-bottom: 0;
        }
    }

    &-icon {
        width: 60px;
        margin-bottom: 15px;
    }

    &-title {
        letter-spacing: 0;
        color: $f-gold;
        padding-bottom: 20px;
        margin-bottom: 30px;
        position: relative;

        &::after {
            content: '';
            width: 50%;
            height: 1px;
            display: block;
            position: absolute;
            bottom: 0;
            @include left(0);
            background-color: $f-gold;

            @include mobile {
                width: 100px;
                left: 50%;
                transform: translateX(-50%);
            }
        }
    }

    &-content {

    }
}


