// listing-layout styles
.listing-item {
	@extend %clearfix;
	border-bottom: 1px solid $f-grey-light;
	margin-bottom: 30px;
	padding-bottom: 30px;

	&__thumb {

		.listing-item--video {
			display: block;
			position: relative;
			.listing-item__icon {
				@extend %play-button;
				background-color: $f-gold-overlay;
				width: 60px;
				height: 60px;

				&:before {
					left: 7px;
					border-width: 11.5px 0 11.5px 18px;
				}
			}
		}

		&:hover {
			.listing-item--video {
				.listing-item__icon {
					background-color: $f-gold;
				}
			}
		}

		img {
			margin-bottom: 20px;
			width: 100%;
			max-width: 500px;
		}

		@include tablet {
			@include float(left);
			width: 25%;

			img {
				margin-bottom: 0;
			}
		}

		@include desktop {
			@include float(left);
			width: 25%;

			img {
				margin-bottom: 0;
			}
		}
	}

	&__content {

	}

	&__text {
		position: relative;
		height: 100%;

		p {
			font-weight: 300;
			line-height: 1.25;
			margin-bottom: 15px;
		}

		@include tablet {
			@include float(left);
			padding: 0 20px;
			width: 75%;
		}

		@include desktop {
			@include float(left);
			padding: 0 20px;
			width: 75%;
		}


	}

	&__title {
		display: block;
	}

	&__date {
		@include text-align(left);
		margin-bottom: 0.5em;
	}
}

.listing-layout {
	@extend %clearfix;
	@include margin(30px, -20px, 0, -20px);

	.listing-item {
		border-bottom: 0;
		@include float(left);
		margin-bottom: 40px;
		padding: 0 20px;
		position: relative;
		width: 100%;

		&__button {
			@include tablet {
				@include float(left);
				width: 100%;
				position: relative;
				top: 5px;
				@include left(25%);
			}

			@include desktop {
				@include float(left);
				width: 100%;
				position: relative;
				top: 5px;
				@include left(25%);
				@include margin-left(20px);
			}
		}

		//For brand listing
		&.brand-listing-item{
			display:flex;
			flex-direction:column;
			background:$f-grey-light;
			text-align: center;
			padding:0;
			float:none;
			width: 100%;
			flex: 1 1 auto;

			@include desktop{
				flex: 0 0 32%;
			}

			@include tablet{
				flex: unset;
				width: 49%;
			}

			h4{
				margin:0;
				font-size:2.5em;
				color:$t-standard;
			}

			.listing-item__thumb{
				min-height:280px;

				img{
					max-width: 100%;
					width:100%;
					height:100%;
					object-fit: cover;
					object-position: center;
					min-height:280px;
				}
			}

			.listing-item__content{
				padding:14px;
				display: flex;
				flex-direction: column;
				justify-content: space-between;
				flex: 1 1 auto;

				.listing-item__text{
					flex: 1 1 auto;
					padding:0;

					.brand-logo{
						img{
							height:80px;
							margin:15px 0;
						}
					}

					p{
						font-size:15px;
						color:$t-standard;
					}
				}

				.listing-item__button{
					margin:8px auto 15px auto;
				}
			}
		}
	}

	&__two-col {
		.listing-item {
			@include tablet {
				width: 50%;
			}

			@include desktop {
				width: 50%;
			}
		}

		&__thumb {
			@include tablet {
				width: 41.666667%;
			}

			@include desktop {
				width: 41.666667%;
			}
		}

		&__text {
			@include tablet {
				width: 58.333333%;

			}

			@include desktop {
				width: 58.333333%;
			}
		}

		&__button {
			@include tablet {
				@include left(45%);
			}

			@include desktop {
				@include left(45%);
			}
		}
	}

	&__one-col {
		.listing-item {
			width:100%;

			&__thumb {
				width:100%;
			}

			&__text {
				width:100%;
			}

			&__button {
				@include left(0);
				float:none;
				top:auto;
				margin: 0 auto;
			}
		}
	}

	&.brand-listing{
		display:flex;
		flex-wrap: wrap;
		gap:2%;
		justify-content: center;
		padding:0px;
		margin: 30px 0 20px;
		&::before {
			content: unset;
		}
		@include desktop {
			justify-content: flex-start;
		}
		@include tablet {
			justify-content: flex-start;
		}
		&.alternate-content{
			.brand-listing-item{
				flex-direction:column;
				flex-wrap: wrap;

				@include desktop{
					flex: 0 0 100%;
					flex-direction:row;
				}
	
				@include tablet{
					flex: 1 1 100%;
					flex-direction:row;
				}

				.listing-item__thumb{
					flex:1 1 auto;
					width:100%;
					
					@include tablet{
						width:50%;
					}

					@include desktop{
						width:50%;
					}

					@include desktop{
						min-height:500px;
					}

					.listing-item--video{
						height:100%;
					}
				}

				.listing-item__content{
					flex:1 1 auto;
					width:100%;
					justify-content: center;
					text-align: center;

					@include tablet{
						width:50%;
					}

					@include desktop{
						width:50%;
					}

					.listing-item__text{
						width:100%;
						padding:20px;
						flex:0;
					}

					.listing-item__button{
						@include left(0);
					}
				}

				&:nth-child(even){
					@include no-mobile{
						flex-direction:row-reverse;
					}
				}


			}
		}
	}
}
