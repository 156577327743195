// header styles

.header-primary {
	@extend %font-navigation;
	
	position: fixed;
	top: 0;
	@include right(0);
	@include left(0);
	z-index: 100;
	width: 100%;
	background-color: $f-header-bg;
	transition: background-color $headerAnimation $headerEasing;
	contain: style;
	box-shadow: $f-minimal-box-shadow;


	@include desktop {
		background-color:transparent;
		box-shadow: none;

		&.is-scrolled {
			padding-bottom: 3px;
			background-color: $f-grey-dark;
			box-shadow: 0 5px 15px -5px rgba(0,0,0,.5);
		}
	}

	// On small Desktop screen
	@media (min-width: #{$bpMinDesktop}) and (max-width: 1150px) {
		&.is-scrolled {
			padding-top: 18px;
		}
	}

	.currency-converter {
		display: none;

	}

	&.is--campaign{
		@include desktop{
			padding-bottom:15px;
		}
	}

	.header-inside{
		@include desktop {
			background-color: $f-op50-logo-header;
			display:flex;
			width:100%;
			padding:10px 0;
		}
	}

	&.horizon {
        transition: all $headerAnimation $headerEasing;

        @include desktop {
            // background-color: transparent;
            background-color: $t-standard;
        }

        .header-toolbar {
            display: flex;
            justify-content: space-between;
            background-color: white;
            padding: 12px 20px;
            transition: none;

            @include desktop {
                height: 65px;
                padding: 12px 40px;
                top: 0;
                box-shadow: $f-minimal-box-shadow;
            }
        }
		
		.booking-trigger-desktop {
			transition: all $headerAnimation $headerEasing;
			position: fixed;
			top: 80px;
			@include right(30px);
			display:inline-block;
			padding:15px 20px;
			border-radius:2px;
			box-shadow: $f-minimal-box-shadow;
			transform:translateY(-200px);
			
			i {
				@include margin(0, 10px, 0, 0);
			}
		}

        &.is-scrolled {
            bottom: 100%;

            @include no-desktop {
                background-color: transparent;
                box-shadow: none;
                top: auto;
            }

            @include desktop {
                transition: none;
                background-color: transparent;
                box-shadow: none;
            }

            @media (min-width: #{$bpMinDesktop}) and (max-width: 1150px) {
                padding-top: 0;
            }
			
			.booking-trigger-desktop {
				@include desktop {
					transform:translateY(0);
				}
			}

            .header-booking-mask {
                @include desktop {
                    transform: translateY(-1000px);
                }

                .with-notice & {
                    @include desktop {
                        transform: translateY(-1000px);
                    }
                }
            }

            .header-toolbar {
                position: absolute;
                left: 0;
                right: 0;

                @include no-desktop {
                    height: 38px;
                    transform: translateY(0);
	                box-shadow: $f-minimal-box-shadow;
                    bottom: -38px;
                }

                @include desktop {
                    height: 60px;
                    top: 40px;
                }
            }

            .logo {
                &__image {
                    display: block;

                    &--alternate {
                        display: none;
                    }
                }
            }

            .account-mobile {
                height: 62px;
                transform: translateY(-200px);
            }

            .menu-mobile {
                opacity: 0;
            }

            .account {
                @include no-desktop {
                    transform: translateY(-200px);
                    opacity: 0;
                }
            }

            .myprivilege {
                .constraint {

                    .discoveryProfile {
                        .discoveryTier {
                            .red {
                                color: #ff4444;
                            }

                            .titanium {
                                color: #23282D;
                            }

                            .platinum {
                                color: #bbbcc3;
                            }

                            .gold {
                                color: #a59451;
                            }

                            .silver {
                                color: #c0c0c0;
                            }
                        }
                    }
                }
            }

            .language-selector {
                .mobile-language-toggle {
                    @include desktop {
                        margin-top: 11px;
                    }
                }

                &::after {
                    top: 11px;
                }
            }

            .panel-currency {
                @include desktop {
                    margin-top: 7px;
                }
            }

            .header-inside {
                @include no-desktop {
                    position: absolute;
                    transform: translateY(-105px);
                }
            }

            .slinky-menu {
                @include desktop {
                    ul li {
                        padding: 0 10px;
                    }
                }
            }
        }

        .header-inside {
            position: relative;

            @include no-desktop {
                transform: translateY(0);
                transition: all 0.4s
            }
			
			@include desktop {
				padding:0;
			}
        }

        .account {
            &__link {
                color: $t-gold;
                margin: 0px;
                @include margin-left(16px);
                @include padding-left(16px);
                position: relative;
                text-transform: unset !important;
                font-size: 0.75em;
                font-weight: 500;
                display: flex;
                align-items: center;

                &::after {
                    content: '';
                    position: absolute;
                    width: 1px;
                    height: 14px;
                    @include left(0);
                    background-color: $f-gold;
                }

                &:first-child {
                    @include desktop {
                        margin: 0px;
                        @include padding-left(0);
                    }

                    &::after {
                        width: 0;
                    }
                }
            }

            &__name {
                @include no-desktop {
                    display: block;
                    margin-bottom: 32px;
                    font-size: 18px;
                    font-weight: 500;
                    color: white;
                }
            }
        }
        .account__links.desktop-only {
            @include desktop {
                display: flex !important;
            }
        }

        .account-mobile {
            height: 62px;
            transform: translateY(0px);
            @include text-align(right);

            @include rtl {
                padding-left: 10px;

				.is-members & {
					padding-left: 0;
				}
            }

            &__link {
                @include padding(19px, 12px, 19px, 12px);
                position: relative;
                text-transform: capitalize !important;
                font-size: 13px;
                font-weight: 500;

                &::after {
                    content: '';
                    position: absolute;
                    width: 1px;
                    height: 21px;
                    top: 20px;
                    @include left(0);
                    background-color: white;
                }

                &:first-child {
                    &::after {
                        width: 0;
                    }
                }
				
				.is-members & {
					display:none;
				}
            }
        }

        .menu-mobile {
            bottom: 2px;
            opacity: 1;
        }

        .language-selector{
            float: none;
            position: relative;
            display: flex;
            align-items: center;
            height: 15px;

            &::after {
                content: '';
                display: none;
                position: absolute;
                width: 1px;
                height: 14px;
                top: 0px;
				@include left(16px);
                background-color: white;

                @include desktop {
                    display: block;
                }
            }

            .mobile-language-toggle {
                display: block;
                color: $t-gold;
				@include padding(0,12px,0,16px);
                font-weight: 600;
                font-size: 0.75em;

                @include desktop {
                    margin-top: 0px;
                    @include margin-left(16px);
                }

                &:after {
                    border-top-color: $f-gold;
                    top: 4px;
                }

                &.active {
                    &:after {
                        border-bottom-color: $f-gold
                    }
                }
            }

            .language-bar {
                position: fixed;
                @include right (0px);
                @include left (0px);
                top: 38px;
                width: 100vw;
                padding: 20px 10px;
                @include text-align(left);
                background: $f-gold-invert;
                box-sizing: border-box;
                flex-wrap: wrap;
                justify-content: flex-start;
                align-items: center;
                display: none;

                @include tablet{
                    @include right(40px);
					@include left(auto);
                    top: 38px;
                    max-width: 300px;
                }

                @include desktop {
                    @include right(40px);
					@include left(auto);
                    top: 50px;
                    max-width: 493px;
                }

                h4, div.t-underline {
                    display: block;
                    width: 100%;
                }

                &__item {
                    display: inline-flex;
                    flex: calc(30% - 15px);
                    max-width: 33.33%;
                    flex-grow: 0;
                    @include margin-right(15px);

                    &.is-active {
                        display: none;
                    }
                }
            }
        }

        .panel-currency {
            float: none;
            display: flex;
            @include padding-left(16px);

            &::after {
                content: '';
                display: block;
                position: absolute;
                width: 1px;
                height: 14px;
                top: 0px;
				@include left(0);
                background-color: white;

                @include no-desktop {
                    width: 0px;
                }
            }

            @include desktop {
                margin-top: 0px;
				@include margin-left(16px);
                display: flex;
            }

            .currencyMenu {
                background-color: $f-gold-invert;

                @include desktop {
                    top: 32px;
                }

                @include tablet {
					@include right(-10px);
                    top: 28px;
                }

                .currency_list li .currency-item .currency-code {
                    color: white;
                }
            }

            .t-gold {
                color: white !important;
            }

            .trigger {
                color: $t-gold;
                font-size: 0.75em;

                @include tablet {
                    margin-left: 0;
                }

                &:after {
                    border-top-color: $f-gold;
                }

                &.active {
                    &:after {
                        border-bottom-color: $f-gold;
                    }
                }
            }

            .currency-converter {
                color: $t-gold;

                select {
                    color: $t-gold;
                }

                .form {
                    .form-main {
                        top: 4px;

                        @include desktop {
                            top: 3px;
                        }
                    }
                }
            }
        }

        .currency-converter {
            .form {
                .form-main,
                .trigger {
                    &:after {
                        border-top-color:$f-gold;
                    }
                }
            }
        }

        .myprivilege {
            right: -65px;
            transform: unset;
            top: 45px;
			
			@include tablet {
				right: 0;
			}
			
			@include desktop {
				right: 0;
			}

            @include rtl {
                right: unset;
                left: 0;
				
				@include tablet {
					right: unset;
					left: 0;
				}
				
				@include desktop {
					right: unset;
					left: 0;
				}
            }
            .constraint {
                #myProfile {
                    color: $t-standard;

                    @include mobile {
                        width: calc(100% - 75px);
						
						@include rtl {
							left:0;
							right:auto;
						}
                    }

                    @include no-desktop {
                        top: 0;
                        color: white;
                    }

                    // @include tablet {
                    //     top: -25px;
                    // }

                    a {
                        color: $t-gold;

                        @include no-desktop {
                            color: white;
                        }
						
						&#lnkWelcome{
							display: none;

							@include desktop {
								display: block;
							}
						}
                    }

                    .name {
                        font-size: 1em;
                        display: flex;
                        align-items: center;
                    }

                    .menu {
                        background-color: $f-grey-dark;
                        box-shadow: $f-minimal-box-shadow;
						padding:30px 20px 20px;
						width: 100vw;
						top:45px;
						transform: translateX(calc(50% - 37px));
						
						@include rtl {
							transform: translateX(calc(50% - 383px));
						}

                        @include tablet {
                            width: 400px;
                            padding: 32px 48px 28px;
							border-radius: 4px;
							@include right(0);
							transform:none;
                        }
						
                        @include desktop {
                            width: 400px;
                        }
						
						.name {
                            display: block;
							text-align:left;
							font-size:0.8125em;
							margin-bottom:25px;
							
							&::after {
								display:none;
							}
							
							@include desktop {
								display: none;
							}
							
							@include rtl {
								text-align:right;
							}
						}

                        a {
                            color: white;
                            padding: 8px 0;
							
							&.close {
								display:block;
								width:20px;
								height:20px;
								
								@include desktop {
									display:none;
								}
							}
                        }

                        li {
                            flex: 50%;
                        }

                        .discoveryCard {
                            margin-bottom: 32px;
                        }
                    }
                }
            }

            ul {
                display: flex;
                flex-wrap: wrap;
            }
        }

        .personalization {
            display: inline-flex;
            flex-direction: row;
            align-items: center;

            @include desktop {
                height: auto;
            }
            @include tablet {
                align-items: unset;
            }
            @include mobile {
                align-items: unset;
            }

            &-item {
                @include desktop {
                    display: block;
                    position: relative;
                    color: $t-standard;
                    font-weight: 600;
                    font-size: 12px;
                    @include padding-right(16px);
                    @include margin-right(16px);
                    margin-block: auto;

                    &::after {
                        content: '';
                        display: block;
                        position: absolute;
                        background-color: white;
                        width: 1px;
                        height: 14px;
                        top: 0px;
                        @include right(0);
                    }
                }
            }
        }

        &.is-scrolled {
            .personalization {
                &-item {
                    @include desktop {
                        margin-top: 11px;
                    }
                }
            }

            .account {
                &__link {
                    margin-top: 9px;

                    &:first-child {
                        margin-top: 8px;
                    }
                }
            }
        }

        .logo {
            width: 75px;
            margin: 5px 0;
            display: flex;
            align-items: center;

            @include desktop {
                width: 180px;
                margin : 7px 0 5px;
            }

            &__link {
                height: 18px;
                @include desktop {
                    height: auto;
                }
            }

            &__image {
                max-height: 18px;
                display: block;
                @include desktop {
                    max-height: 26px;
                }

                &--alternate {
                    display: none;
                }
            }
        }

        .c-hamburger {
            span {
                background-color: white;
                &:before,
                &:after {
                    background-color: white;
                }
            }
        }

        .is-active {
            .c-hamburger {
                span {
                    background-color: transparent;
                }
            }
        }
	}
}

.header-toolbar {
	height: 38px;
	padding: 0 12px;
	margin: 0;
	white-space: nowrap;
	list-style: none;
	background-color: $f-header-bg-light-op;
	transition: transform $quick, height $quick;
	transform: translateY(0);
	will-change: transform;
	position:relative;
	z-index:203;

	@include desktop {
		padding: 10px 40px;
		height: 40px;
	}

	@include mobile {
		padding: 0;
	}

	@include no-desktop {
		display: flex;
		flex-wrap: nowrap;
		align-items: center;
		justify-content: flex-end;
		flex-direction: row;
		gap: 10px;
	}

	.is-scrolled & {
		transform: translateY(-40px);
		height: 0;
		display: none;

		@include desktop {
			height: 19px;
			padding:5px 0;
			display: block;
		}
	}

	.horizon & {
		display: flex;
		justify-content: space-between;
		background-color: white;
		padding: 12px 20px;

		@include desktop {
			height: auto;
			padding: 12px 40px;
		}
	}
}

.account-mobile {
	height: 55px;
	overflow: hidden;
	width: 100%;
	white-space: nowrap;
	text-align: center;
	background-color: $f-header-bg-dark;
	transition: transform $quick, height $quick;
	transform: translateY(0);
	will-change: transform;
	overflow: -moz-scrollbars-none;

	&::-webkit-scrollbar {
		height: 0;
		background: transparent;
	}

	&__links {

		@include mobile{
			width:100%;
			overflow: hidden;
		}
	}

	&__link {
		display: inline-block;
		padding: 17px 12px;
		font-weight: 700;
		color: #fff;
		vertical-align: middle;

		[class^="glyph-"] {
			@include margin-right(5px);
			font-size: 1.1em;
			vertical-align: middle;

			@include mobile {
				display: none;
			}
		}

		@media (max-width: 520px) {
			font-size: 11px;
			padding: 19px 8px;
		}

		@media (max-width: 450px) {
			font-size: 10px;
			padding: 19px 5px;
		}
	}

	.is-scrolled & {
		transform: translateY(-55px);
		height: 0;
	}
}

.event-banner {
	background:transparent no-repeat center;
	background-size:contain;
	position:relative;
	background-color:#000;
	display:none;

	&.display {
		display:block;
	}

	img {
		visibility:hidden;
		max-width:100%;
		max-height:75px;
	}

	.is-scrolled & {
		display: none;
	}
}
