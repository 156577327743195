// events-listing styles
.events-listing {
	background-color: #fafafa;
	@include text-align(left);
	text-shadow: none;
	padding: 32px 16px;
	margin-top: 35px;
	margin-bottom: 2em;

	@include desktop {
		@include padding(42px, 42px, 24px, 42px);
		margin: 70px 0 70px 0;
	}

	&__header {
		text-align: center;
	}

	.slick-dots {
		bottom: -30px;
	}
}
