// Rate Listing

$rate-base-bg: #f9f9f9;

.rates-listing {
	&.loading {
		svg.skeleton{
			margin: auto auto 15px;
			background:$rate-base-bg;
			padding: 0 10px;
		}

		#mask {
			animation: mask 975ms ease infinite;
		}

		@keyframes mask {
			from {transform: translateX(0)}
			to {transform: translateX(280px)}
		}
	}
		
	.section--gold &, .section--darker-grey & {
		color:$t-standard;
	}
}

.no-availability {
	display:none;
	background:#fff;
	padding:15px 0;
	text-align:center;
	color:red;
	margin-top:-15px;
	border-top:1px solid $f-grey-light-border;
}

.rate {
	@extend %clearfix;
	position: relative;
	padding: 1em;
	margin-bottom: 1em;
	background-color: $rate-base-bg;
	justify-content: space-between;
	align-items: flex-start;


	&__header {
		position: relative;
		@include padding-right(40px);

		@include mobile {
			@include padding-right(0);
		}
	}

	&__title {
		margin-top: 0;
		margin-bottom: 0.50em;

		&-link{
			@extend .t-gold;
			&:hover{
				@extend .t-gold;
				text-decoration: underline;
			}
		}
	}

	&__short-description{
		margin: 0.50em 0;
	}

	&-highlights{

		ul{
			padding: 0 15px;

			@include desktop{
				padding: 0 20px;
			}
		}

	}

	.roomInfo{
		ul{
			padding:0;
			margin:0;
			display:flex;
			flex-wrap: wrap;

			li{

				@include margin-right(15px);
				font-size:13px;
				display:flex;
				align-items: center;
				margin-bottom:15px;
				color:$f-icon-color;

				@include mobile{
					margin-bottom:8px;
				}

				.glyph-facility-1024 {
					&:before{
						@extend %icon-font-glyph;
						content: $glyph-family; 
					}
				}

				.glyph-facility-2048 {
					&:before{
						@extend %icon-font-glyph;
						content: $glyph-butler;
					}
				}

				[class^='glyph']{
					font-size: 20px;
					@include margin-right(3px);
					line-height: 1.2;
				}

				&.has-tooltip{
					position: relative;

					&:hover{
						.tooltip-content{
							display:block;
						}
					}

					.tooltip-content{
						background:#fff;
						box-shadow: 1px 2px 12px rgba(0,0,0,0.35);
						border-radius:6px;
						text-transform: none;
						z-index:3333;
						bottom:auto;
						top:10px;
						border:1px solid #ccc;
						width: 90vw;
						@include left(-20px);

						@include tablet{
							width:400px;
							@include left(auto);
							@include right(0);
							top:20px;
						}

						@include landscapeTablet{
							@include left(0);
							@include right(auto);
							top:20px;
						}

						@include desktop{
							@include left(0);
							width:600px;
							top:20px;
						}

						table,tr{
							border:0;
							margin:5px 0;
						}

						th{
							color:$f-gold;
							@extend .t-upper;
							padding:0 10px;
						}

						ul{
							padding: 0 20px;
							margin: auto;
							display: block;

							li{
								margin:0 0 2px 0;
								padding:0;
								font-size:12px;
								line-height:13px;
								font-weight: 500;
								color:#000000;
								display: list-item;
								list-style-type: disc;
								letter-spacing: 0;
							}
						}
					}
				}
			}
		}

		.glyph-facility-4{
			@extend .glyph-playstation;
		}

		.glyph-facility-2, .glyph-facility-256{
			@extend .glyph-sofa;
		}

		.glyph-facility-8, .glyph-facility-16, .glyph-facility-32, .glyph-facility-64{
			@extend .glyph-spa;
		}

		.glyph-facility-128{
			@extend .glyph-food-tray;
		}

		.glyph-facility-512{
			@extend .glyph-star;
		}
	}

	$rate-color:#78ad49;

	div.loading {
		background:$rate-base-bg url(/assets/minor/brands/images/loading.svg) no-repeat center;
		padding:2em 0;
		
		.oaks & {
			background:transparent url(/assets/minor/brands/images/loading.svg) no-repeat center;
		}
	}
	.features-special{
		border:0;
		color:$rate-color;

		.feature__item{
			font-weight:600;
			display: inline-block;
			width:auto;
			margin-bottom:6px;

			@include tablet{
				width:auto;
			}

			&__caption{
				width:auto;
			}

			[class^='glyph']{
				color:$rate-color;
				@include margin-right(5px);
				font-size:1.6em;
				width: auto;
				height: auto;
				padding: 0;

				&.glyph-checkmark{
					font-size:1.3em;
				}

				&.glyph-meal-b{
					@extend .glyph-tea-cup;
				}

				&.glyph-meal-i, &.glyph-meal-h, &.glyph-meal-l, &.glyph-meal-d, &.glyph-meal-f{
					@extend .glyph-restaurant;
				}
			}

			&.non-refundable{
				color:$f-grey-dark;
				display:block;
				margin-bottom:10px;

				&:before{
					content: '•';
					font-size: 35px;
					margin: 0 4px 0 0;
					display: inline-block;
					position: relative;
					top: 8px;
				}

			}

			@include mobile{
				@include padding-right(0);
			}
		}
	}

	.roomMarketing{
		display: flex;
		flex-wrap: wrap;
		margin: 5px 0 0;
		padding:0;

		li{
			font-size: 14px;
			list-style: none;
			margin: 0;
			@include margin(3px,11px,3px,0);

			[class^='glyph']{
				font-weight: bold;
				color: $rate-color;
				@include margin-right(3px);

			}
		}
	}

	.brand-benefits{
		margin:10px 0 0;

		div{
			margin-bottom:3px;
			font-size:14px;
		}

		.roomMarketing{
			margin: 0 ;
			padding:0;

			li{
				[class^='glyph']{
					color: $f-gold;
				}
			}
		}
	}

	.text-cta{
		padding:0;
		margin:10px 0;
		text-decoration: underline;

		[class^="glyph-"]{
			font-size:1.2em;
			position: relative;
			top:2px;
		}

	}


	&__content {
		margin-top: 1em;
		margin-bottom: 2em;
		padding-top: 1em;
		display:none;
		border-top: 1px solid rgb(219, 219, 219);

		@include mobile {
			margin-top: 0;
			margin-bottom: 0.25em;
			padding-top: 0.25em;
			padding-bottom: 1em;
		}

		.date {
			margin-bottom:auto;
			padding:initial;
		}

		&.error {
			text-align:center;
			color:red;
		}

		.policy-item,
		.price-breakdown {
			.t-xs {
				margin-bottom: 5px;
			}

			p {
				margin: 5px 0 10px;
				letter-spacing: 0;
			}
		}

		.policy-container {
			display: flex;
			flex-direction: column;
			align-items: flex-start;

			@include desktop {
				flex-direction: row;
			}
		}

		.policy-col {
			flex: 1;

			&:nth-child(2) {
				margin-top: 20px;

				@include desktop {
					margin-top: 0;
					@include margin-left(2%);
				}
			}

			.select {
				width: auto !important;
				min-width: auto !important;
				margin: 0 auto;
				display: table;

				@include desktop {
					display: inline-block;
					margin: 10px auto 0;
					@include float(right);
				}
			}

			@extend %clearfix;
		}

		.policy-item {
			padding: 0 5px;
		}

		.rate-name {
			color: white;

			p {
				padding: 5px 10px;
				font-weight: 600;
				margin: 0;
			}

			.standard {
				background-color: rgba(88,89,91,0.68);
			}

			.discovery {
				background-color: $f-gold;
			}
		}

		.price-breakdown {
			&__table {
				p {
					margin: 0 0 2px 0;
					font-size: 0.825em;
				}

				&Body {
					border-bottom: 2px solid #dedede;
					padding-bottom: 3px;
					margin-bottom: 3px;
					max-height: 260px;
					overflow-y: auto;
				}

				&Row {
					display: flex;
					flex-direction: row;
					justify-content: space-between;
				}

				&InfoTotal {
					font-weight: 600;
				}

				&Price {
					text-align: right;
    				font-weight: 600;
				}
			}
		}
	}

	&__image {
		display: inline-block;
		height: 220px;

		@include padding-right(15px);

		> img {
			max-width: 100%;
		}

		@include tablet {
			width: 50%;
		}

		@include mobile {
			width: 100%;
			margin-bottom: 1em;
			height: 180px;
			@include padding-right(0);
		}

		.gallery{
			.slick-prev{
				@include left(10px);
			}

			.slick-next{
				@include right(10px);
			}
		}
	}

	.price{

		&_wrap{
			display: flex;
			flex-direction: column;
			width: auto;
			height:auto;
			margin-bottom: 5px;
			@include text-align(left);
			flex: 1 1 0%;


			@include desktop{
				height: 56px;
				text-align: center;
				flex:none;
			}
		}

		&_label{
			padding: 4px 8px;
			border-radius: 4px 4px 0 0;
			font-size: 11px;
			color: #fff;
			font-weight: 600;
			line-height: 1;
			margin-bottom: 5px;
			position: relative;

			&.standard{
				background:rgba(88,89,91,0.68);
			}

			&.member{
				background:$f-gold-op;
			}

			.glyph{
				position: relative;
				left: 4px;
				width: 14px;
				height: 13px;
				background: rgba(0,0,0,0.16);
				border-radius: 50%;
				font-size: 7px;
				padding: 2px;
				color: #ffffff;
			}
		}
		&_cta_wrap{
			display: flex;
			flex-direction: row;
			align-items: center;
			justify-content: space-between;
			padding: 10px;
			min-height: 50px;
			flex-wrap: wrap;

			@include desktop{
				padding:5px;
				flex-direction: column;
				min-height:auto;
			}

			.btn{
				width:auto !important;
				min-width:auto;
				margin: 2px 0;
				display:block;

				@include desktop{
					margin:8px 0;
				}
			}
		}


		&_container{
			display: flex;
			flex-direction: column;
			flex: 0 0 100%;
			margin-bottom: 0;

			@include desktop{
				flex-direction: row;
				margin: 0 0 15px 0;
				height: auto;
				justify-content: flex-end;
				flex-wrap: wrap;
			}

			.option{
				text-align: center;
				display: flex;
				flex-direction: column;
				border: 1px solid darken($rate-base-bg, 7.5%);
				border-radius: 4px;
				max-width:100%;
				@include margin(0,0,15px,0);

				@include desktop{
					min-width: 12em;
					max-width: 12em;
				}

				&.member_price{
					order: 1;

					@include desktop{
						@include margin-left(10px) ;
						order: 2;
					}
				}

				&.public_price {
					order: 2;
					background: darken($rate-base-bg, 4.5%);
					border: 1px transparent;

					@include desktop {
						order: 1;
					}
				}

				&:only-child {
					@include margin-left(0);
				}
			}

			&.single_rate{

				.price_label{
					// display:none;
				}
				.price_cta_wrap{
					min-height:65px;
				}

				.price_wrap {
					margin: 5px 0;
					height: 40px;
				}
			}

		}

		/*Price styling*/

		&__currency-wrap{
			display:flex;
			align-items:center;
			flex-wrap:wrap;
			margin-top: auto;

			@include desktop{
				justify-content: center;

			}

			.currency-converter{
				.form .form-main{
					select{
						color:$t-gold;
						font-size:0.8em;
						@include padding-right(4px);
						@include padding-left(0px);
						@include margin-right(8px);
						margin-top:0;
					}

					&:after{
						border-top-color:$f-gold;
						top:5px;
					}
				}
			}

			.currency.strike{
				font-size: 0.84em;
				text-decoration: line-through;
				font-weight: 600;
				margin-top: auto;
				margin-bottom: 6px;
				opacity: .82;
				flex:1 1 100%;
			}

			.item-price{
				font-size:1.02em;
				font-weight: 700;
				margin: 0;
				margin-top: auto;
				font-feature-settings: 'lnum' 1;


				&.discounted{
					margin-top:0;
					display: block;
				}
			}
		}
	}

	// Discovery Dollar Banner
	.discovery-dollar-banner {
		order: 3;
		flex: 100%;
		display: flex;
		justify-content: flex-start;
		flex-direction: row;
		align-items: center;
		background: white;
		border: 1px solid $f-grey-light;
		padding: 5px 7px;
		box-sizing: border-box;
		border-radius: 3px;
		max-width: calc(24em + 10px);

		.earn {
			&-icon {
				border-radius: 50%;
				background-color: $f-gold;
				width: 24px;
				height: 24px;
				min-width: 24px;
				box-sizing: border-box;
				text-align: center;
				margin-right: 10px;
	
				span {
					text-align: center;
					color: white;
					font-weight: bold;
					font-size: 12px;
					line-height: 24px;
				}
			}

			&-content {
				display: flex;
				align-items: flex-start;
				flex-direction: column;

				p {
					font-weight: 600;
					margin: 0;
					line-height: 1.2em;
					font-size: (12 / $base) * 1em;
				}

				.d-dollar-terms-toggle {
					font-size: 10px;
					text-decoration: underline;
				}
			}
		}
	}

	.per_room_night{
		font-size: 0.7em;
		margin: 3px 0 0;
		font-style: italic;
	}


	&__ctas {
		@include tablet {
			width: 60%;
		}
		@include mobile {
			width: 100%;
			@include margin(0.5em, auto, 0.25em, auto);

			text-align: center;

			> .btn {
				max-width: 40%;
				@include margin-left(0.5em);
				@include margin-right(0.5em);
			}
		}
	}
}

// Earn Discovery Dollar Badge
.priceBreakdown__tableRow.priceBreakdown__tableDiscoveryRow {
	display: flex;
	justify-content: flex-end;
}

.earn-discovery-dollar {
	display: block;
	padding: 3px 0 3px 25px;
	font-weight: 600;
	font-size: 11px;
	text-align: right;
	position: relative;
	line-height: 21px;
	color: $f-gold;

	&::before {
		content: 'D$';
		position: absolute;
		background: $f-gold;
		color: white;
		border-radius: 50%;
		font-weight: 600;
		width: 21px;
		height: 21px;
		left: 0;
		top: 50%;
		transform: translateY(-50%);
		text-align: center;
		line-height: 20px;
	}
}

// Offer Listing View

.offer-display{

	.rate{

		&__title{
			i{
				font-size:16px;
				@include margin-left(5px)
			}
		}

		&__name{
			@include margin(0, 0, 5px, 0);
			font-weight: 600;
		}

		&__short-description{
			margin-bottom:10px;
		}


		&__image {
			height: 220px;

			@include tablet {
				width:33.33333%;
				height: 152px;
			}
		}

		&__content {
			margin-bottom: 0;
		}

		&__header{
			@include padding-right(40px);

			@include mobile {
				@include padding-right(0);
			}
		}

		&__item {
			padding-bottom: 10px;
			margin-bottom: 25px;
			border-bottom: 1px solid rgb(219, 219, 219);

			&::after {
				content: '';
				clear: both;
				display: table;
			}

			&:last-child {
				border: none;
				margin-bottom: 0;
				padding-bottom: 0;
			}
		}

		.feature__item{
			width:100%;
			padding:0;

			@include tablet{
				width:100%;
			}
		}

		.price_container{
			margin-bottom: 0;

			@include tablet{
				margin-top:15px;
				flex-direction: row;
				flex-wrap: wrap;
			}

			.option{
				@include desktop{
					min-width:9.5em;
					max-width:10em;
				}

				@include tablet{
					flex: calc(50% - 20px)
				}

				&:first-child{
					@include tablet{
						@include margin-right(1em);
						order: 1;
					}
				}

				&:nth-child(2) {
					@include tablet{
						order: 2;
					}
				}
			}
		}

		// Discovery Dollar Banner - Offer
		.discovery-dollar-banner {
			max-width: calc(20em);
		}
	}

	.room__image{

		@include mobile{
			padding:0;
		}
		@include padding-right(10px);

	}
}

// Mobile Policy display popup
.mobileContent {
	position: fixed;
	display: none;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
	background-color: $rate-base-bg;
    z-index: 100;
    padding: 50px 10px 30px;
	overflow: scroll;

	&.is-active {
		display: block;
	}

	&-close {
		position: fixed;
		top: 0px;
		right: 0px;
		left: 0;
		text-align: right;
		color: $t-standard;
		padding: 10px;
		background-color: $rate-base-bg;
		text-transform: uppercase;
	}

	.glyph-cross {
		color: $t-gold;
		font-size: 20px;
	}

	.policy{
		&-container {
			display: flex;
			flex-direction: column;
		}
		
		&-col {
			flex: 1;

			&:first-child {
				order: 2;
				margin-top: 40px;
			}
	
			&:nth-child(2),
			&:only-child {
				order: 1;
				margin-top: 0;
			}

			.select {
				width: auto !important;
				min-width: auto !important;
				margin: 0 auto;
				display: table;
			}
		}
		
		&-item {
			padding: 0 5px;

			.t-xs {
				margin-bottom: 5px;
			}

			.t-c-s {
				letter-spacing: 0;
				margin: 5px 0 10px;
			}

			.date {
				padding: 0;
			}
		}
	}

	.rate-name {
		color: white;

		p {
			padding: 5px 10px;
			font-weight: 600;
			margin: 0;
		}

		.standard {
			background-color: rgba(88,89,91,0.68);
		}

		.discovery {
			background-color: $f-gold;
		}
	}

	.price-breakdown {
		margin-bottom: 20px;
		
		.t-xs {
			margin-bottom: 5px;
		}

		p {
			margin: 5px 0 10px;
			letter-spacing: 0;
		}
		
		&__table {
			p {
				margin: 0 0 2px 0;
				font-size: 0.825em;
			}

			&Body {
				border-bottom: 2px solid #dedede;
				padding-bottom: 3px;
				margin-bottom: 3px;
				max-height: 260px;
				overflow-y: auto;
			}

			&Row {
				display: flex;
				flex-direction: row;
				justify-content: space-between;
				align-items: center;
			}
			
			&InfoTotal {
				font-weight: 600;
			}
			
			&Price {
				text-align: right;
				font-weight: 600;
			}
			
			&Price,
			&Info {
				margin: 0 0 2px 0 !important;
			}
		}
	}
	
}
