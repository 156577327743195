// contact-inline styles
.contact-inline {
	color: $t-gold;
	
	&__heading {
		margin: 0;
		vertical-align: top;
		line-height: 1;
		margin-bottom: 15px;
	}

	&__list {
		margin: 0 0 0;
		list-style-type: none;
		display: block;
		padding: 0;

		a, .no_link {
			display: inline-block;
			transform: translateX(bi-app-reverse-percentage(1.5em));
			word-break: break-word;
			overflow-wrap: break-word;
			hyphens: auto;
			vertical-align: middle;
			
		}


		[class*="glyph-"] {
			@include float(none);
			margin-top: 4px;
			@include margin-left(-1.5em);
			@include padding-right(0.26em);
		}
	}

	&__extra{
		margin:25px 0;

		p{
			margin:0;
		}

		strong{
			@extend .t-s;
			display:block;
			font-size:14px;
			color:$f-gold;
			margin-bottom:12px;
		}
	}

	[class*='col-'] {
		padding: 15px 20px;

		@include mobile {
			padding: 0px 10px;

			.t-c-l {
				font-size: 1em;
			}
		}
	}

	.t-c-m{
		a:not(.btn , .text-cta){
			color:#fff;
		}
	}

	.contact-inline__list{
		&.is-dark{
			a{
				color:$f-grey-dark !important;
			}
		}
	}

	

	/*GDS*/

	.gsd-codes{
		margin: 12px 0 0;
		width: 100%;
		display: block;
		overflow: hidden;

		.t-s{
			margin-bottom:8px;
		}

		&.has-divider{
			padding: 12px 0 0;
			border-top: 1px solid #eee;
		}
	}
}
