// custom-dropdown styles
.select-wrapper{
	display: inline-block;
	position: relative;

	&:after{
		display: block;
		position: absolute;
		top: 40%;
		@include right(0);
		width: 10px;
		content: "";
		border-top: 10px solid $f-gold;
		border-right: 7.33333px solid transparent;
		border-left: 7.33333px solid transparent;
		transform: translateY(-50%);
		z-index: 0;
	}

	select{
		text-align:center;
		@include padding-left(8px);
		@include padding-right(22px);
		max-width: 320px;
		font-weight:500;
		text-overflow: ellipsis;
		border-bottom: 2px solid $f-gold;
		text-align-last:center;
		position: relative;
		z-index: 1;
		&::-ms-expand {
			display: none;
		}

		&::placeholder{
			text-align: center;
		}

		option{
			color:$t-standard;
			font-size:22px;
			min-height: 2em;
		}
	}
}
// .js-custom-dropdown{
// 	text-align:center;
// 	@include padding-left(8px);
// 	@include padding-right(22px);	
// 	max-width: 320px;
// 	font-weight:500;
// 	text-overflow: ellipsis;
// 	border-bottom: 2px solid $f-gold;
// 	&::-ms-expand {
// 		display: none;
// 	}

// 	option{
// 		color:$t-standard;
// 		font-size:22px;
// 		min-height: 2em;
// 	}

// }







