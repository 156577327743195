.destination-explorer{
    z-index:auto;
    min-height: auto;
	
    .title{
        padding-top:50px;
        position: relative;
        z-index: 2; 
        margin-bottom:35px;
    }
    .destinations__menu {
        position: relative;
        z-index: 25; 
        max-width:95%;
        margin: 0 auto;
        // color:#fff;

        > ul {
            width: 100%;
            padding: 0;
            list-style: none;
            columns: 5 auto;
            column-gap: 5vw;

            html[lang="en"] &{
                columns: 4 auto;
            }
        }
    
        li {
            display: block;
            width: 100%;
            @include desktop {
                margin-bottom: 5px;
                border: 0;
            }
        }
    
        a {
            display: block;
            //color: #fff;
            font-size:0.85em;
            text-decoration: none;
            transition: color $quick, transform $medium;
        }
    }

    &.section--white {
        .t-white.title {
            color: $t-gold !important;
        }
    }	
	
	&.section--dark-grey {
		.destinations__menu {
			> ul {
				> li {
					> ul > li {
						a {
							color:$section-dark-grey-link-color;
						}
					}
				}
			}
		}
	}
	
	&.section--darker-grey {
		.destinations__menu {
			> ul {
				> li {
					> ul > li {
						a {
							color:$section-darker-grey-link-color;
						}
					}
				}
			}
		}
	}

    .full-bg--has-overlay{
        text-shadow: none;

        &:before{
            // background-color: rgba(0, 0, 0, 0.50);
            background-color: white;
        }
    }
    
    .title{
        padding-top:50px;
        position: relative;
        z-index: 2; 
        margin-bottom:35px;
    }
    
    .destinations__menu {
        position: relative;
        z-index: 25; 
        max-width:95%;
        margin: 0 auto;
        color:#fff;

        > ul {
            width: 100%;
            padding: 0;
            list-style: none;
            columns: 5 auto;
            column-gap: 5vw;

            html[lang="en"] &{
                columns: 4 auto;
            }

            > li {
                > ul > li {
                    a {
                        color: $t-standard;
                    }
                }
            }
        }
    
        li {
            display: block;
            width: 100%;
            @include desktop {
                margin-bottom: 5px;
                border: 0;
            }
        }
    
        a {
            display: block;
            // color: #fff;
            color: $t-gold;
            font-size:0.85em;
            text-decoration: none;
            transition: color $quick, transform $medium;
        }

    }
}