// Current Search
.current-search {
    &__container{
        margin:0 0 15px 0;
        background:$rate-base-bg;
        padding: 10px 15px;

		.section--gold &, .section--darker-grey & {
			color:$t-standard;
		}
    }

    &__header{
        justify-content: space-between;
        margin-bottom: 10px;
        align-items: center;
        .glyph{
            font-size:20px;
            position: relative;
            top: 2px;
        }
    }

    &__summary{

        align-items: center;
        justify-content: flex-start;
        padding:5px;
        
        .summary-item{
            flex: 1 1 auto;

            &.dates, &.guests{
                margin-bottom:15px;


                @include landscapeTablet{
                    margin-bottom:0;
                }

                @include desktop{
                    margin-bottom:0;
                }

            }

            &.cta{
                max-width:200px;

                @include mobile{
                    max-width:100%;
                }

                @include tablet{
                    max-width:100%;
                }

                .btn{
                    width:100% !important;
                }
            }


            &:hover{
                .item_subtitle{
                    color:$t-gold;
                }
            }


            .item_title{
                margin-top:0;
                margin-bottom:8px;
                font-size:0.80em;
                font-weight:600;
                color:$f-icon-color;

                @include mobile{
                    font-size:0.72em;
                }


                .glyph{
                    @include margin-right(5px);
                    font-size:1.1em;
                    color: $f-icon-color;

                    &.glyph-calendar{
                        font-size:0.9em;
                    }
                }
            }

            .item_subtitle{
                font-size:0.85em;
                font-weight:600;
                margin:0;

                @include mobile{
                    font-size:0.80em;
                    letter-spacing: 0.01em;

                }

                .seperator{
                    margin: 0 10px;
                }

                .nights{
                    font-weight:500;
                }
            }
        }
    }
}
