/**
* Converted to SCSS from jquery.mailtip css
* Modifications by Minor Hotels 2024
*/ 
ul.ui-mailtip {
	zoom: 1;
	background-color: $f-grey-faint;
	list-style: none;
	border: 1px solid $f-grey-light-border;
	overflow: hidden;
	text-align: left;
    font-weight: 600;
	/* Only show 10 emails in the list */
	max-height: 18em;
	overflow: auto;

	&:after {
		visibility: hidden;
		display: block;
		font-size: 0;
		content: " ";
		clear: both;
		height: 0;
	}

	li {
		margin: 0;
		padding: 0;
		float: none;

		p {
			height: 28px;
			text-align: inherit;
			line-height: 28px;
			cursor: pointer;
			margin: 0 9px;
			overflow: hidden;
			white-space: nowrap;
			text-overflow: ellipsis;
		}
	}

	li.active, li.hover {
		color: $f-gold;
		background: $t-standard-on-gold;
	}
}
