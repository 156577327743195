// dynamic-list styles

.dynamic-list, .static-list {
	padding: 0 1.5em;

	.is-destinations &, .flex-container &{
		padding:0;
	}

	@include mobile {
		padding: 0;
	}

	.currency-converter {
		&:after {
			border-top-color: $t-gold;
		}

		& select {
			color: $t-gold;
		}
	}

	&__list {
		padding: 0;
		margin: 0;
		list-style-type: none;
		margin-top: 20px;

		.is-destinations & , .flex-container &{
			@extend .flex-container;
		}
	}

	&__item {
		position: relative;
		margin-bottom: 1.5em;
		background-color: $f-logo-black;
		// contain: content;
		box-shadow: 0 5px 20px 0 rgba(0,0,0,.25);

		&.col-4 {
			@include border-left(0.75em solid #fff);
			@include border-right(0.75em solid #fff);
			box-shadow: none;
		}

		&:last-child {
			margin-bottom: 0;
		}

		.is-destinations &, .flex-container &{
			background:transparent;
			border:0;
			margin-bottom:1em;
			display:flex;
			
			.is-ie & {
				display:block;
			}
		}
		
		.section--gold &, .section--darker-grey & {
			color:$t-standard;
		}
	}

	&__image {
		@include aspect-ratio(1.85, 1);
		overflow: hidden;

		.static-list__img,
		.gallery,
		img {
			position: absolute;
			top: 0;
			width: 100%;
			height: 100%;
			object-fit: cover;
			object-position: center;
		}

		img.static-list__logo {
			position: absolute;
			z-index: 1;
			left: 50%;
			transform: translateX(-50%);
			top: 20px;
			width: auto;
			height: auto;
			max-height: 65px;
			object-fit: contain;
			
			.is-destinations & {
				filter: brightness(0) invert(1);
			}

			.static-list__container.OH & {
				max-height: 45px;
			}

			.static-list__container.TI & {
				max-height: 95px;
				top: 10px;
			}
		}

		&:after {
			content: " ";
			position: absolute;
			top: 0;
			@include right(0);
			bottom: -100%;
			@include left(0);
			z-index: 1;
			background: linear-gradient(to top, rgba(0,0,0,.9) 0%,rgba(0,0,0,.65) 50%,rgba(0,0,0,0) 70%,rgba(255,255,255,0) 100%);
		}
		
		&.no--filter{
			&:after{
				display:none;
			}	
		}	
	}

	

	&__container{
		.is-destinations &, .flex-container &{
			display:flex;
			@extend .flex-container--column;
			flex: 1 1 auto;
		}
	}

	&__text-container {
		position: relative;
		z-index: 10;
		height: 100%;
		padding: 20px 30px;
		background-color: #fff;

		@include mobile {
			padding: 20px;
		}

		.static-list__text {
			position: static;
			padding: 0 5px;
		}

		.static-list__location{
			@extend %font-sub-title;
			color:$t-standard-light;
		}

		.static-list__content{
			margin:5px 0;
		}

		&.is--campaign{
			padding:15px;
		}

		.is-destinations &, .flex-container &{
			display:flex;
			@extend .flex-container--column;
			flex: 1 1 0;
		}

		.trending-location & {
			box-shadow: 0px 8px 24px -4px rgba(24, 39, 75, 0.08), 0px 6px 12px -6px rgba(24, 39, 75, 0.12);
			overflow: hidden;
			
			.static-list__content{
				margin:5px 0 15px;
			}	
		}
	}

	&__text {
		position: absolute;
		@include right(0);
		bottom: 0;
		@include left(0);
		z-index: 10;
		padding: 20px 30px;

		@include mobile {
			padding: 10px 20px;
		}
	}

	.link-readmore {
		text-decoration: none;
	}

	&__title {
		margin: 0;
	}

	&__icon {
		display: inline-block;
		@include margin-right(15px);
		position: relative;

		&:last-child {
			@include margin-right(0);
		}

		i {
			color: $f-icon-color;
			@include margin-right(5px);
			font-size: 18px;
			vertical-align: middle;
		}

		p {
			display: inline-block;
			margin: 0;
		}

		/*Time icon*/

		&.time{
			@include padding-left(22px);
			margin-top:5px;

			i {
				position: absolute;
				@include left(0);
				margin-top:3px;
			}
		}

		/*Menu icon*/

		&.menu{
			@include padding-left(22px);
			margin-top:5px;
			display: block;

			i {
				position: absolute;
				@include left(0);
				margin-top:3px;
			}
		}
		
		/* Cuisine Icon */
		&.cuisine {
			display: block;
			@include padding-left(22px);
			
			.glyph-restaurant {
				position: absolute;
				@include left(0);
				margin-top:3px;
			}
			.glyph-restaurant:after {
				content:none;
			}
		}

		&Text {
			margin-right: 5px;
			white-space: nowrap;

			&::after {
				content: '';
				background-color: $f-icon-color;
				width: 5px;
				height: 5px;
				transform: translateY(-50%);
				display: inline-block;
				border-radius: 50%;
				margin-left: 5px;
				cursor: default;
			}

			&:last-child {
				margin: 0;
				padding: 0;

				&::after {
					width: 0;
					right: 0;
					display: none;
				}
			}
		}
	}

	&__summary {

		p:first-of-type {
			margin-top: 1em;
		}

		@include mobile {
			font-size: 1em;
		}
	}

	&__extra-links {
		.btn{
			min-width:100%;
			margin:5px 0;

			.col-6 &{
				@include desktop {
					min-width: 220px;
					@include margin-right(0.5em);
					
					&:last-child {
						@include margin-right(0);
					}
				}
			}
		}

		.is-destinations &, .flex-container &{
			margin-top: auto;
		}

		.trending-location & {
			.text-cta {
				@include margin-left(-41px);
			}
		}
	}	

	&__more-btn {
		&.btn {
			.col-6 &{
				@include desktop {
					min-width: calc(33.33% - 1em);
					@include margin-right(0.5em);
				
					&:last-child {
						@include margin-right(0);
					}
				}
			}
		}
	}

	&__link {
		display: inline-block;
		@include margin-right(1em);
	}

	&__pagination {
		display: inline-block;
		list-style-type: none;
		margin: 1em 0;
		padding: 5px;
		text-align: center;

		&__title {
			color: $t-gold;
			vertical-align: middle;
		}

		> li {
			display: inline-block;
			@include margin(0, 5px, 0, 0);
			border-radius: 2px;

			transition: border $quick;

			&:hover, &:focus {
				background: lighten($f-grey-dark, 10%);
			}

			&.active {
				background: $f-grey-dark;
			}

			a {
				color: $t-gold;
			}
		}

		.page {		// <a> tag
			@extend %t-s;
			display: inline-block;
			width: 32px;
			height: 32px;
			@include padding-left(3px);
			line-height: 32px;
			vertical-align: middle;
		}
			
	}

	&__content {
		position: relative;
		z-index: 12;
		margin-bottom: 15px;
		transition: transform $long;
		will-change: transform;

		&--wrap {
			@extend %clearfix;

			.country,
			.price {
				@include float(left);
			}

			.country {
				width: 75%;
			}

			.price {
				width: 25%;
			}
		}
	}
}

.static-list {
	&__image {
		&:after {
			bottom: -70%;
		}
	}

}

.pagination-container {
	display: none;
	width: 100%;
	overflow: hidden;
	
&.new{
	display:block;
}	

	.has-pagination &, .has-server-pagination & {
		display: block;
	}
}

.dynamic-modal {

	position: fixed;
	@include left(25%);
	bottom: -100vh;
	z-index: 100;
	background-color: #fff;

	max-width: 50%;
	min-width: 300px;

	transform: translateY(0);
	transition: transform $slow $easeOutExpo;
	will-change: transform;

	&__wrapper {
		max-height: 90vh;
		overflow: auto;
	}

	&--visible {
		transform: translateY(-105vh);
	}

	&__close {
		position: absolute;
		top: 0;
		@include right(-56px);
		z-index: 10;

		width: 56px;
		height: 56px;

		padding-top: 1px;

		background-color: rgba(0,0,0,0.7);
		font-size: 3em;
		font-weight: 400;
		line-height: 1;
		text-align: center;
	}

	&__image {
		@include aspect-ratio(1.85,1);

		img {
			width: 100%;
			height: 100%;
			object-fit: cover;
		}

		> .content {
			overflow: hidden;
			&:before {
				content: "";
				position: absolute;
				top: 0;
				@include right(0);
				bottom: -100%;
				@include left(0);
				background: linear-gradient(to top, rgba(0,0,0,.9) 0%,rgba(0,0,0,.65) 50%,rgba(0,0,0,0) 70%,rgba(255,255,255,0) 100%);
			}
		}

		&__content {
			position: absolute;
			bottom: 0;

			padding: 20px 40px;
		}
	}

	&__content, &__price {
		padding: 20px 40px;
	}

	&__price {
		background-color: $f-grey-light-border;
	}

	&__features {
		background-color: $f-grey-light-border;
		padding: 20px 40px;
	}

	&__links {
		@extend %clearfix;
		border-top: 1px solid #fff;
	}

	&__link {
		@include float(left);
		width: 50%;
		overflow: hidden;

		background-color: $f-header-bg;

		> .text-cta {
			padding: 15px 30px;
		}

		&:first-child {
			background-color: $f-gold;
			@include border-right(1px solid #fff);
		}
	}

	&__text {

	}

	&__list {
		@extend %clearfix;
		list-style-type: none;
		padding: 0;
		margin: 0;
	}

	&__item {
		@extend %clearfix;
		position: relative;
		@include float(left);
		width: 33.33333%;
		@include padding(1em, 3em, 1em, 3em);
		line-height: 1.2;

		> span {
			// @extend .glyph-image;
			position: absolute;
			color: $t-gold;
			font-size: 2em;
			vertical-align: middle;
			@include margin-left(-1.5em);

			&:before {
				@include padding-right(1em);
			}
		}

	}

}

.list {
	&__no-results {
		display: none !important;

		.filter-no-results & {
			display: block !important;
		}
	}
}

.section-no-results {
	display: none !important;
}

.page-no-results {
	.list__no-results {
		display: block !important;
	}
}

/*Avani plus listings*/

.is-destinations .static-list__container[data-brand="OT"],
.is-destinations .static-list__container.cross-sell { 
	.static-list__title{
		@extend .t-grey
	}

	.currency-converter{
		select{
			@extend .t-grey;
			border-bottom-color:$f-grey-dark;
		}
		.form .form-main:after{
			border-top-color:$f-grey-dark;
		}
	}

	.t-underline:after{
		background-color:$f-grey-dark;
	}

	.link-readmore{
		@extend .t-gold;
		&:hover {
			text-decoration: underline;
		}
	}

	.add-to-compare{
		display:none;
	}
}

/* Oaks Trending Destination */

.trending-location {
	.static-list,
	.dynamic-list {

	}
}