// Only display content to screen readers
//
// See: http://a11yproject.com/posts/how-to-hide-content/

.sr-only {
	position: absolute;
	width: 1px;
	height: 1px;
	padding: 0;
	margin: -1px;
	overflow: hidden;
	clip: rect(0, 0, 0, 0);
	border: 0;
}

// Use in conjunction with .sr-only to only display content when it's focused.
// Useful for "Skip to main content" links see http://www.w3.org/TR/2013/NOTE-WCAG20-TECHS-20130905/G1
// Credit: HTML5 Boilerplate

.sr-only-focusable {
	&:active,	&:focus {
		position: static;
		width: auto;
		height: auto;
		margin: 0;
		overflow: visible;
		clip: auto;
	}
}

%clearfix {
	&:before, &:after {
		display: table;
		content: " ";
	}
	&:after {
		clear: both;
	}
}

.clearfix {
	@extend %clearfix;
}

@mixin feature6-col4 {
	.section--feature-6 .col-4:nth-of-type(n+7) & {
		@content;
	}
}

@mixin smallMobile {
	@media (max-width: #{$bpSmallMobile} - 1px) {
		@content;
	}
}

@mixin mobile {
	@media (max-width: #{$bpMaxMobile} - 1px) {
		@content;
	}
}

@mixin no-mobile {
	@media (min-width: #{$bpMaxMobile}) {
		@content;
	}
}

//fix for landscape mobile gallery
@mixin landscapeMobile {
	@media (max-width: #{$bpMaxMobile} - 1px) and (orientation: landscape) {
		@content;
	}
}
@mixin landscapeShallow {
	@media (max-height: 430px) and (orientation: landscape) {
		@content;
	}
}

@mixin tablet {
	@media (min-width: #{$bpMaxMobile}) and (max-width: #{$bpMinDesktop} - 1px){
		@content;
	}
}

@mixin minTablet {
	@media (min-width: #{$bpMaxMobile}) {
		@content;
	}
}

@mixin iphoneX {
	@media (min-width: #{$bpMaxMobile}) and (max-width: #{$bpMinTablet} + 1px) and (orientation: landscape){
		@content;
	}
}


@mixin landscapeTablet {
	@media (min-width: #{$bpMinTablet} + 1px) and (max-width: #{$bpMinDesktop} - 1px) and (orientation: landscape) {
		@content;
	}
}


@mixin tabletSmall {
	@media (min-width: #{$bpMaxMobile}) and (max-width: #{$bpMinDesktop} - 2px){
		@content;
	}
}

@mixin desktop {
	@media (min-width: #{$bpMinDesktop}) {
		@content;
	}
}

@mixin desktopLong {
	@media (min-width: #{$bpMinDesktop}) and (min-height: 875px) {
		@content;
	}
}

@mixin lgDesktop {
	@media (min-width: #{$bpMinLgDesktop}) {
		@content;
	}
}

@mixin no-desktop {
	@media (max-width: #{$bpMinDesktop} - 1px) {
		@content;
	}
}

@mixin no-large-desktop {
	@media (max-width: #{$bpMinLgDesktop}) {
		@content;
	}
}

.desktop-only {
	display: none !important;
	@include desktop {
		display: block !important;
	}
}

.tablet-only {
	display: none !important;
	@include tablet {
		display: block !important;
	}
}

.mobile-only {
	display: none !important;
	@include mobile {
		display: block !important;
	}
}

.no-desktop {
	@include desktop {
		display: none !important;
	}
}

.no-tablet {
	@include tablet {
		display: none !important;
	}
}

.no-mobile {
	@include mobile {
		display: none !important;
	}
}

.column-break {
	@include desktop {
		margin-bottom: 100vh !important;
	}
}

.responsive-image {
	width:auto;
	height:auto;
	max-width:100%;
}

// https://css-tricks.com/snippets/sass/maintain-aspect-ratio-mixin/
@mixin aspect-ratio($width, $height) {
    position: relative;
    &:before{
        display: block;
        content: " ";
        width: 100%;
        padding-top: percentage($height / $width);
    }

    > .content {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        margin: 0;
        padding: 0;
        z-index: 0;
    }
}

.remodal-bg {
	&.is-visible {
		&:before {
			content: "";
			display: inline-block;
			position: fixed;
			z-index: 25;
			top: 0;
			bottom: 0;
			left: 0;
			right: 0;

			background-color: rgba(0,0,0,0.6);
		}
	}
}

.overflow-hidden {
	overflow: hidden;
}

.display {
	&-table {
		display: table;
	}
	&-table-cell {
		display: table-cell;
	}

	&-none{
		display:none;
	}
}

.align {
	&-left {
		@include text-align(left, !important);
	}

	&-right {
		@include text-align(right, !important);
	}

	&-center {
		text-align: center !important;
	}

	&-bottom {
		vertical-align: bottom;
	}

	&-top {
		vertical-align: top;
	}

	&-middle {
		vertical-align: middle;
	}
}

%ltrForce {
	direction: ltr !important;
	unicode-bidi: bidi-override;
}

.force-ltr, .ltrForce {
	@extend %ltrForce;
}

.terms-messaging {
	padding: 1.5em 0;

	@include mobile {
		padding-bottom: 0;
	}
}

.ar-185-1 {
	@include aspect-ratio(1.85, 1);
	overflow: hidden;

	img {
		object-fit: cover;
		object-position: 60% center;
		width: 100%;
		height: 100%;
	}
}

.padding-0-0 {
	padding: 0 0 !important;
}
.padding-top-0 {
	padding-top: 0 !important;
}
.padding-bottom-0 {
	padding-bottom: 0 !important;
}
.margin-0-0 {
	margin: 0 0 !important;
}
.padding-top-10 {
	padding-top: 10px !important;
}
.padding-top-20 {
	padding-top: 20px !important;
}
.margin-top-10 {
	margin-top: 10px !important;
}
.margin-bottom-10 {
	margin-bottom: 10px !important;
}
.margin-bottom-20 {
	margin-bottom: 20px !important;
}

.ScreenDimmer{
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	background-color: #000;
	z-index: 1;
	opacity: .65;
	
	.horizon-header & {
		display:none;
		z-index:50;
	}
}

%icon-font-glyph {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icons' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* Flex */
.flex-parent {
	display: flex;
}

.flex-wrap {
	&-wrap {
		flex-wrap: wrap;
	}

	&-nowrap {
		flex-wrap: nowrap;
	}
}

.flex-direction {
	&-row {
		flex-direction: row;

		&-mobile {
			@include mobile {
				flex-direction: row;
			}
		}
	}

	&-column {
		flex-direction: column;

		&-mobile {
			@include mobile {
				flex-direction: column;
			}
		}
	}
}

.flex-align-content {
    &-top {
      align-content: flex-start;
    }
    &-bottom {
      align-content: flex-end;
    }
    &-vertical-center {
      align-content: center;
    }
    &-space-between {
      align-content: space-between;
    }
    &-space-around {
      align-content: space-around;
    }
} // end column

.flex-align-items {
    &-top {
      align-items: flex-start;
    }
    &-bottom {
      align-items: flex-end;
    }
    &-vertical-center {
      align-items: center;
    }
    &-baseline {
      align-items: baseline;
    }
    &-stretch {
      align-items: stretch;
    }
} 

.flex-justify{
	&-start {
		justify-content: flex-start;
	  }
	  &-end {
		justify-content: flex-end;
	  }
	  &-center {
		justify-content: center;
	  }
	  &-space-between {
		justify-content: space-between;
	  }
	  &-space-around {
		justify-content: space-around;
	  }
  }
