// instagram styles
.instagram {
	position: relative;
	height: 100%;
	color: #fff;
	contain: strict;

	&.is-active {
		.instagram {
			&__overlay {
				transform: translateY(-50%);
			}

			&__text {
				transform: translateY(50%) !important;	// Important to overwrite inline style
			}

			&__description {
				transform: translateY(-30px);
			}
		}
	}

	&__link {
		position: absolute;
		top: 0;
		@include right(0);
		bottom: 0;
		@include left(0);
		height: 100%;
		overflow: hidden;
		color: #fff;
		text-decoration: none;
		cursor: pointer;
	}

	&__image {
		z-index: 10;
		height: 100%;
		background-repeat: no-repeat;
		background-position: center;
		background-size: cover;
	}

	&__overlay {
		position: absolute;
		top: 0;
		@include right(0);
		bottom: -100%;
		@include left(0);
		background: linear-gradient(to top, rgba(0,0,0,.9) 0%,rgba(0,0,0,.65) 60%,rgba(0,0,0,0) 70%,rgba(255,255,255,0) 100%);
		transition: transform $long;
		transform: translateY(10%);
		will-change: transform;
	}

	&__text {
		position: absolute;
		top: 0;
		@include right(0);
		bottom: 0;
		@include left(0);
		display: block;
		padding: 33px 43px;
		pointer-events: none;
		transition: transform $slow;
		will-change: transform;
		@include mobile {
			padding: 30px 20px;
		}
		@include tablet {
			padding: 30px;
		}
	}

	&__title {
		display: block;
		margin-top: 0;
		margin-bottom: 43px;
		color: inherit;
		text-decoration: none;
		@include mobile {
			margin-bottom: 30px;
		}
		@include tablet {
			margin-bottom: 38px;
		}

		&:before {
			@include padding-right(10px);
			content: $glyph-instagram;
			@extend %icon-font-glyph;
		}
	}

	&__description {
		display: block;
		transition: transform $slow;
		transform: translateY(0);
		will-change: transform;
	}
}
