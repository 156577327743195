// image-carousel styles

.simple-carousel {
	width: 100%;
	// @include tablet {
		// @include margin-left(10px);	// Removed as it was throwing out the alignment in the awards/press section on property homepage
	// }
	@include desktop {
		width: calc(100% - 40px);
		padding: 0 20px;
		@include margin-left(20px);
	}

	&.slick-dotted.slick-slider {
		margin-bottom: 40px;
		@include desktop {
			margin-bottom: 60px;
		}
	}

	.slick-prev {
		@include left(-22px);
	}
	.slick-next {
		@include right(-22px);
	}

	.slick-slide {
		> img {
			max-width: 100%;
			padding: 35px 14px;
			margin: 0 auto;
		}
	}

	&__link {
		display: block;
		padding: 35px 14px;

		> img {
			max-width: 100%;
			padding: 0;
			margin: 0 auto;
			box-shadow: 0 15px 19px 1px rgba(0, 0, 0, .15);
		}
	}

	&.no-shadow {
		.simple-carousel__link {
			> img {
				box-shadow: none;
			}
		}
	}
}
