// triangle-cta styles
.triangle-cta {
	$triangle-size: 260px;
	position: relative;
	display: table;
	height: 520px;		// Changed table's min-height to height. if the content is higher, table will be enlarged anyway
	padding: 0 1.3em;
	transition: transform $long;
	transform: translateX(bi-app-reverse-percentage(0));
	will-change: transform;
	@include desktop {
		transform: translateX(bi-app-reverse-percentage(-200%));
	}
	@include mobile {
		float: none;
		width: 100%;
		height: 300px;
		text-align: center;
	}

	&--show {
		transform: translateX(bi-app-reverse-percentage(0)) !important;
	}

	&:before {
		position: absolute;
		top: 0;
		@include right(auto);
		bottom: 0;
		@include left(0);
		z-index: -1;
		display: block;
		content: "";
		border-top: $triangle-size solid transparent;
		border-bottom: $triangle-size solid transparent;
		@include border-left($triangle-size*1.66 solid rgba(255,255,255, .8));

		@include mobile {
			top: -260px;
			@include right(auto);
			@include left(50%);
			@include margin-left(-261px);
			@include border-right($triangle-size solid transparent);
			border-bottom: $triangle-size*1.66 solid rgba(255,255,255, .8);
			@include border-left($triangle-size solid transparent);
		}
	}

	&--odd {
		@include float(right);
		transform: translateX(bi-app-reverse-percentage(0));
		@include desktop {
			transform: translateX(bi-app-reverse-percentage(200%));
		}
		@include mobile {
			float: none;
		}

		&:before {
			@include right(0);
			@include left(auto);
			@include border-right($triangle-size*1.66 solid rgba(255,255,255, .8));
			@include border-left(0);

			@include mobile {
				@include right(auto);
				@include left(50%);
				@include margin-left(-261px);
				@include border-right($triangle-size solid transparent);
				border-bottom: $triangle-size*1.66 solid rgba(255,255,255, .8);
				@include border-left($triangle-size solid transparent);
			}
		}
	}

	&__text {
		display: table-cell;
		max-width: 250px;
		vertical-align: middle;
		@include mobile {
			position: absolute;
			@include right(0);
			bottom: 10px;
			@include left(0);
			display: block;
			max-width: 200px;
			margin: 0 auto;
			word-break: break-word;
		}
	}

	&__heading {
		@extend %font-sub-title;
		margin-bottom: 0;
	}

	&__title {
		display: block;
		margin-top: 0;
		margin-bottom: 14px;
		line-height: (45 / 40) * 1em;
		font-size: 1.9em;

		@include mobile {
			font-size: 1.4em;
			margin-bottom: 5px;
		}
	}
}
