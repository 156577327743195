// weather-widget styles

.weather-widget {
	display: inline-block;
	background: transparent;

	margin-top: 10px;
	margin-bottom: 0;

	@include desktop {
		margin: 0;
	}

	&:after {
		display: block;
		clear: both;
		content: '';
	}

	&__localtime {
		@include padding-right(.5em);
		@include border-right(1px solid #666);
	}

	&__localtime, &__weather {
		@include float(left);
		@include margin-right(1em);
		margin-bottom: 6px;
		line-height: 1;

		span {
			display: inline-block;
			@include padding-right(.5em);
		}
	}

	&__power{
		@include float(left);
		margin: 10px 0;
		display:flex;
		align-items: center;
		width:100%;

		.widget_text_wrap{
			@include margin-left(8px);

			span{
				display:block;

				&.power-label{
					font-size:0.85em;
					opacity:.7;
				}
			}
		}

		[class^='glyph-power'] {
			font-size:35px;
		}
	}
}
