.two-column-image {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin: 15px 0;

    &__image {
        flex: 1 1 auto;
        width: 50%;
        height: auto;
    }
}
