// cta styles

.button__submit {
	position: relative;
	z-index: 10;
	width: 100%;
	min-height: 80px;
	margin-top: 18px;
	@include padding-right(0);
	font-size: (22 / $base) * 1em;
	font-weight: 700;
	color: #fff;
	background-color: $f-bm-book-button;
	border: 0;
	border-radius: 2px;
	transition: transform $quick, background $quick, padding-right $quick $easeInOutExpo;
	will-change: transform;

	.is-gha & {
		background-color: $f-bm-button--scrolled;
		color: #fff;

		&:hover {
			background-color: darken($f-bm-button--scrolled, 5%);
		}
	}

	&.is-spinning {
		@include padding-right(15%);

		.btn__spinner {
			opacity: 1;
		}
	}

	&:focus {
		outline: 0;
	}

	&:hover {
		background-color: $f-bm-book-button--hover;
	}

	@include mobile{
		min-height:60px;
	}

}

.best__rate{
	display:block;
	margin-top:12px;

	img{
		max-height:17px;
	}
}
