// engagement styles

.engagement {
	padding: 30px 10px;

	&:after {
		display: block;
		clear: both;
		content: '';
	}

	.questions {
		@include float(left);
		@include mobile {
			float: none;
			text-align: center;
		}
		@include tablet {
			float: none;
			text-align: center;
		}

		.engagement__link{
			display:inline-block;
			@include margin(0,10px,12px,0);
		}
	}

	.connected, .cro {
		@include float(right);
		
		@include mobile {
			float: none;
			margin-top: 50px;
			text-align: center;
		}
		@include tablet {
			float: none;
			text-align: center;
			margin-top: 30px;
		}

		.engagement__title {
			@include mobile {
				text-align: center;
			}

			@include tablet {
				text-align: center;
			}
		}
	}
	.connected {
		@include mobile {
			max-width: 350px;
			margin-inline: auto;
		}
	}

	&__title {
		display: block;
		@include margin(5px, 0, 10px, 0);
		font-weight: 600;
		@include desktop {
			@include margin(0, 0px, 15px, 0);
		}
	}

	&__link {
		transition: color $quick;
		display: inline-block;
		@include margin(0, 10px, 0, 0);
		color: #fff;

		.contact-inline &{
			margin-bottom: 10px;
		}

		@include mobile {
			margin: 0 5px 10px;
		}

		i {
			width: 28px;
			height: 28px;
			font-size: 1.55em;
			color: white;
			vertical-align: middle;
			@include margin-right(0.5em);
		}

		.glyph-mail {
			font-size: 1.8em;
		}

		&:hover, &:focus {
			.engagement__info {
				border-bottom: 1px solid #fff;
			}

			i {
				color: darken($f-gold, 10%);
			}

		}

		&.dark {
			font-size: 13px;
			color: $t-standard;
			@include margin-right(8px);
			@include padding (7px, 4px, 2px, 2px);
			width: 35px;
			height: 35px;
			border: 1px solid $t-standard;
			border-radius: 50%;
			vertical-align: middle;
			text-align: center;
			transition: background-color $quick, color $quick;
			margin-bottom:5px;

			@include tablet{
				margin: 4px 8px;
			}
			
			i {
				color:$t-standard;
			}

			&:hover, &:focus {
				background-color: $t-standard;
				color: #fff;

				i{
					color:#fff;
				}
			}
		}
	}

	&__info {
		transition: border-bottom $quick;
		border-bottom: 1px solid transparent;
		padding-bottom: 0.25em;
	}

	&.is--intro{
		margin:0;
		padding: 0;

		.connected{
			float:none;
			@include text-align(left);
			margin: 10px 0;
		}
	}

	&.is--brand{

		[class*='col-'] {
			padding: 0 30px;

			@include mobile{
				padding:0 10px;
			}
		}
	}

	&.is--dining{
		padding:0;
		margin-bottom:30px;

		.connected{
			float:none;
		}
	}
}

.has-tooltip{
	position: relative;
		
	.tooltip-content{
		display: none;
		position: absolute;
		bottom: 30px;
		@include right(0);
		z-index: 2;

		.contact-inline &{
			@include right (auto);
			@include left (0);

			@include landscapeTablet{
				@include right (0);
				@include left (auto);
			}
		}

		.contact-inline__list &{
			@include landscapeTablet{
				@include right (auto);
				@include left (0);
			}
		}

		@include desktop{
			@include right (auto);
			@include left (0);
		}

		@include mobile{
				display: none;
		}

		img{
			max-width:220px;
		}

	}

	&:hover{
		.tooltip-content{
			display: none;

			@include desktop{
					display: block;
			}

			@include landscapeTablet{
					display: block;
			} 
		}
	}
}

.section--white {
	.engagement__link i {
		color: $f-gold;
		&:hover {
			color: darken($f-gold, 10%);
		}
	}
}