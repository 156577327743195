// stepper styles
.stepper {
	@include float(right);
	&-icon {
		&[class^="glyph-"], 
		&[class*=" glyph-"] {
			display: none;
		}
	}
	
	&-btn {
		position: relative;
		display: inline-block;
		width: 20px;
		height: 20px;
		font-size: 10px;
		color: #fff;
		text-align: center;
		vertical-align: middle;
		background-color: $f-bm-book-button;
		border: 0;
		border-radius: 50%;

		> i {
			position: absolute;
			top: 5px;
			@include left(5px);

			.is-gha & {
				color: $f-grey-dark-border;
			}
		}
		
		&.disabled {
			opacity:0.3;
			cursor:auto;
		}

		.is-gha & {
			background-color: $f-grey-light;
		}
	}

	&-input {
		display: inline-block;
		width: 25px;
		padding: 0;
		margin: 0 -4px;
		font-size: (14 / 16) * 1em;
		font-weight: 700;
		text-align: center;
		vertical-align: middle;
		color: $t-bm-on-secondary;
		background-color: transparent;
		border: 0;

		-moz-appearance: textfield;

		.is-gha & {
			color: #fff;
			background-color: $f-grey-dark-border;
		}


		&::-webkit-inner-spin-button,
		&::-webkit-outer-spin-button {
			-webkit-appearance: none;
			margin: 0;
		}
	}
}
