// footer styles

.footer-container {
	@extend %font-navigation;
	
	position: relative;
	z-index: 30;
}

.footer-banner-section{
	// background: #141313;
	background: #4a4a4a;
}

footer {
	// background: #141313;
	background: #4a4a4a;

	.footer-middle {
		width: 100%;
		padding: 30px 0 60px;
		border-top: 1px solid $f-grey-border;

		&:after {
			display: block;
			clear: both;
			content: '';
		}
		
		.container--mw1320 {
			padding:0 10px;
		}
	}

	.footer-bottom {
		// background: #0e0e0d;
		background: #4a4a4a;
		border-top: 1px solid $f-grey-border;
		
		@include desktop {
			padding-bottom: 0;
		}
	}
}

.footer-links {
	padding: 0;
	margin: 0;
	list-style: none;
	
	@include mobile {
		width: 100%;
	}

	li {
		display: inline-block;
		width: 30%;
		@include padding-right(20px);
		@include margin(5px, 0, 4px, 0);
		vertical-align: top;
		
		@include mobile {
			width: 48%;
		}

		.footer-links-fullWidth & {
			width:auto;
			display:block;
		}
		
		.footer-links-col & {
			@include desktop {
				width:auto;
				display:block;
			}
		}
	}

	a {
		display: inline-block;
		color: #fff;
		text-decoration: none;
		border-bottom: 1px solid transparent;
		transition: border $quick;

		&:hover, &:focus {
			border-bottom: 1px solid #fff;
		}
	}

	&.is-fallback{

		.t-underline{
			margin:0.8em 0;

			&:after{
				margin-top:5px;
				@include desktop{
					margin:2px 0 5px 0;
				}
			}

		}
	}
}

.brand-footer {
	.footer-links {
		.flex-parent {
			flex-direction: column;
			
			@include desktop {
				flex-direction: row;
			}
		}
		
		&-list {
			&.single-column {
				@include desktop {
					width:33.33%;
					
					li {
						width:100%;
					}
				}
			}
			
			&.two-columns {
				@include desktop {
					width:66.33%;
					
					li {
						width:45%;
					}
				}
			}
		}
		
		&-col {
			h3 {
				@extend %font-navigation;
				
				&:after {
					@include margin-left(0);
				}
			}
			
			@include desktop {
				width:33.33%;
				@include padding(0, 15px, 0, 0);
				
				&.col-3 {
					width:25%;
				}
			
				h3 {
					margin-top:0;
				}
			}
		}
	}
}
