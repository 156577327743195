// carousel-tabs styles

.carousel-tabs {
	&.hidearrows{
		.slick-arrow{
			display:none !important;
		}
	}
	@include margin(0, -20px, 20px, -20px);

	@include tablet {
		margin: 0 auto;
		max-width: 97%;
	}

	@include desktop {
		margin: 0 auto;
		max-width: 95%;
	}

	@include lgDesktop {
		max-width: none;
	}

	.nano-content {
		@include margin-right(-17px);
		@include padding-right(17px);

		@include no-desktop {
			position: static;
		}

		@at-root .carousel-tabs--stacked#{&} {
			position: static;
		}
	}

	.gallery, .gallery-slide {

		.slick {

			&-slide {
				@include aspect-ratio(1.85, 1);
			}

			&-arrow {
				width: 20px;
				height: 40px;
				mix-blend-mode: difference;

				&:before {
					opacity: 1;
				}
			}

			&-prev {
				&:before {
					opacity: 1;
					@include border-right(20px solid $f-gold);
				}

				&.slick-disabled:before {
					transform: scale(1);
				}
			}

			&-next {
				&:before {
					opacity: 1;
					@include border-left(20px solid $f-gold);
				}

				&.slick-disabled:before {
					transform: scale(1);
				}
			}

			&-dots {
				li {
					button {
						&:before {
							border-color: $f-grey-dark;
						}
					}
					&.slick-active button:before {
						background-color: $f-grey-dark;
					}
				}
			}
		}

		@include mobile {
			.slick {
				&-slide {
					@include aspect-ratio(1.85, 1);
				}
			}
		}

		@include desktop {
			width: 104%;
			@include margin-left(-2%);
		}
		@include lgDesktop {
			width: 110%;
			@include margin-left(-5%);
		}
	}
	.gallery-slide {
		.slick {
			&-dots {
				text-align: center;

				li {
					button {
						&:before {
							@include no-desktop {
								border-color: $t-standard-faint;
							}
						}
					}
					&.slick-active button:before {
						@include no-desktop {
							background-color: $t-standard-faint;
						}
					}
				}

				@at-root .carousel-tabs--stacked#{&} {
					@include padding-right(0);
					bottom: 12px;
				}

				@include no-desktop {
					bottom: -22px;
				}

				@include mobile {
					bottom: 8px;
				}

				@include desktop {
					@include padding-right(40%);
				}
			}
		}
	}

	.gallery-slide {

		&--half {
			display: inline-block;
			vertical-align: top;
			width: 100%;

			@include desktop {
				width: 62%;
				@include margin-left(-2%);
			}

			@include lgDesktop {
				width: 65%;
				@include margin-left(-5%);
			}

			.gallery-slide__image {
				width: 100%;
			}

			.slick {
				&-dots {
					@include desktop {
						@include padding-right(0);
					}
				}
			}
		}

		&__image {
			width: 100%;
			display: inline-block;
			vertical-align: top;

			@at-root .carousel-tabs--stacked#{&} {
				width: 100%;
			}

			@include desktop {
				width: 60%;
			}
		}

		& ~ .carousel-tabs__description,
		&__text {
			position: relative;
			width: 40%;
			display: inline-block;
			@include float(right);
			margin: 0;
			@include right(0);
			top: 0;
			background: #eee;
			padding: 0 40px;
			overflow: auto;

			box-shadow: $f-inset-box-shadow-long;

			// @include lgDesktop {
			// 	@include padding-right(70px);
			// }

			@include tablet {
				position: relative;
				width: 100%;
				padding: 0px 40px;
				float: none;
				box-shadow: none;
			}

			@include mobile {
				width: 100%;
				padding: 0;
				background: none;
				float: none;
			}

			.section--light-grey & {
				background: #fff;

				@include mobile {
					background: transparent;
			}
		}
		}

		&__text {
			@at-root .carousel-tabs--stacked#{&} {
				width: 100%;
				float: none;
				height: auto;
				box-shadow: none;
				padding: 0 30px;
				background: transparent;

				@include mobile {
					background: none;
					padding: 0;
				}
			}
		}

		.slick {
			&-list {
				@include tablet {
					height: 100% !important;
				}

				@include desktop {
					height: 100% !important;
				}

				@include lgDesktop {
					height: 100% !important;
				}
			}

			&-slide {
				&:before {
					content: none;
				}
			}
		}
	}

	&__btn.btn {
		@include desktop {
			margin-right: 0;
		}
	}

	&__nav {
		position: relative;
		z-index: 6;
		@include mobile {
			display: none;
		}

		.slick-track {
			@include no-mobile {
				@include rtl {
					margin-right: 60.5px;
				}
			}
		}

		.slick-arrow {
			height: 41px;
			position: absolute;
			width: auto;
			top: 0!important;
			transform: translateY(0);
			z-index: 3;
			background: $f-grey-dark;
			padding: 5px;
			text-align: center;
			opacity: 1;
			transition: opacity .25s ease-in-out;
			pointer-events: auto;

			&:before {
				width: auto;
				transform: scale(0.7);
			}

			&.is-hidden {
				opacity: 0;
				pointer-events: none;
			}
		}

		.slick-prev {
			@include left(0);

			&:before {
				border-right-width: 15px;

				@include desktop {
					border-right-width: 15px;
				}
			}
		}

		.slick-next {
			@include right(0);

			&:before {
				border-left-width: 15px;

				@include desktop {
					border-left-width: 15px;
				}
			}
		}

		.slick-list {
			max-width: 100%;
			overflow: hidden;
			height: 62px;
			margin-bottom: -21px;
		}

		.carousel-tabs__cat {
			position: relative;
			display: inline-block;
			padding: 10px 30px;

			&:before {
				position: absolute;
				top: 0;
				@include left(0);
				z-index: -1;
				display: block;
				width: 100%;
				height: 100%;
				content: '';
				background: $f-grey-light;
				transition: transform .25s ease-in-out, background .25s;
				transform: scale(1, 1);
				@-moz-document url-prefix() {
					transform: scale(1.001, 1);
				}
				transform-origin: top;

				will-change: transform;
			}

			&:after {
				position: absolute;
				top: 40px;
				@include left(50%);
				z-index: 2;
				display: block;
				width: 8px;
				@include margin-left(-8px);
				content: '';
				// border-top: 8px solid $t-standard-faint;
				border-top: 8px solid $f-pale-blue;
				@include border-right(8px solid transparent);
				@include border-left(8px solid transparent);
				opacity: 0;
				transition: opacity .25s ease-in-out;
			}
			&.is-active {
				color: #fff;

				&:after {
					opacity: 1;
				}

				&:hover:before,
				&:focus:before,
				&:before {
					// background: $f-grey-dark;
					background: $f-gold;
					transform: scale(1, 1.5);
					@-moz-document url-prefix() {
						transform: scale(1.001, 1.5);
					}
				}
			}

			&:hover,
			&:focus {
				color: #fff;

				&:before {
					background: $t-standard-faint;
				}
			}
		}
	}

	&__list {
		@include desktop {
			min-height: 392px;
		}

		@include mobile {
			min-height: 0;
		}
	}

	&__item {
		position: relative;
		@include mobile {
			margin-bottom: 2px;
		}

		.carousel-tabs__cat {
			display: block;
			padding: 10px 20px;
			background: $f-grey-light;

			&:before {
				display: inline-block;
				font-family: 'icons' !important;
				content: $glyph-chevron-down;
			}
		}

		&.is-active {
			.carousel-tabs__cat {
				color: #fff;
				background: $f-grey-dark;

				&:before {
					display: inline-block;
					font-family: 'icons' !important;
					content: $glyph-chevron-up;
				}
			}
		}
	}

	&__content {
		padding: 0;
		color: $t-standard;
		background: transparent;

		@include mobile {
			@include padding(20px, 30px, 0, 30px);
			color: #fff;
			background: $t-standard;
		}
	}

	&__title {
		margin: 30px 0 10px;
		@include mobile {
			margin-bottom: 0;
		}
	}

	&__text {
		font-size: .9em;
		line-height: 1.8;

		.carousel-tabs__description,
		.features,
		.carousel-tabs__ctas {
			display: inline-block;
			vertical-align: top;
		}

		.col-3 {
			width: 100%;
		}

		.features {
			// @include padding-left(30px);
			// @include border-left(1px solid $f-grey-light-border);

			@include mobile {
				border: 0;
				margin-bottom: 20px;
			}
		}
	}

	&__text {
		@include tablet {
			padding: 0 20px;
		}
		@include mobile {
			p,
			ul {
				width: 100%;
				margin: 0;
			}

			ul {
				display: none;
			}
		}
	}

	&__ctas {
		width: 100%;
		margin-bottom: 30px;
		// @include padding-right(1em);
		display: flex;
		flex-direction: column;

		@include tablet {
			flex-direction: row;
			flex-wrap: nowrap;
		}
		@include desktop {
			flex-direction: row;
			flex-wrap:wrap;
		}

		.btn {
			display: block;
			text-decoration: none;

			&:first-child {
				@include no-mobile {
					@include margin-right(15px);
				}
			}

			@include tabletSmall {
				display: inline-block;
				width: 49% !important;
				margin-bottom: 10px;
			}
			
		}

	}

	.features-special {
		margin-bottom: 25px;

		[class^='glyph'] {
			@include mobile {
				color: #fff;
			}
		}
	}
}

.features-special {
	width: 100%;
	font-size: .85em;
	// @include border-right(1px solid $f-grey-light-border);
	// @include border-left(1px solid $f-grey-light-border);

	@include mobile {
		border: 0;

		&.no-filter {
			margin-bottom: 18px;
		}
	}

	.listing-with-gallery__wrapper & {
		border: 0;
		padding: 0;
	}

	.section--light-grey & {
		border-color: #ddd;
	}

	&.features-special--wide {
		width: 100%;
	}

	.t-s {
		display: block;
		margin-bottom: 0.5em;
	}

	.carousel-tabs__text & {
		@include tablet {
			width: 32%;
			@include border-right(0);
			margin: 0;
		}
		@include mobile {
			width: 100%;
			margin: 20px 0;
			border-color: $f-grey-dark;
		}
	}

	[class^='glyph'] {
		display: inline-block;
		width: 32px;
		height: 36px;
		@include margin-right(10px);
		font-size: 1.5em;
		text-align: center;
		padding-top: 8px;
		color: $f-icon-color;

		vertical-align: middle;

		.section--dark-grey & {
			color: #fff;
		}
	}

	.glyph-wifi-mid {
		font-size: 2em;
		padding-top: 5px;
	}

	.glyph-airport-transfer {
		font-size: 2em;
		padding-top: 5px;
	}

	/*Icons styling*/

	.glyph-stargazing{

		&-fill{
			&:before{
				content: $glyph-stargazing;
				background: $f-icon-color;
				padding: 3px;
				border-radius: 80px;
				color: #fff;
				border: 1px solid transparent;
			}
		}

		&-outline{
			&:before{
				content: $glyph-stargazing;
				padding: 3px;
				border-radius: 80px;
				border: 1px solid $f-icon-color;
			}
		}
	}
}

.feature__item {
	display: inline-block;
	width: 49.5%;
	vertical-align: top;
	@include padding-right(2em);

	@include tablet {
		width: 40%;
	}

	@include mobile {
		width: 100%;
	}

	&__caption {
		display: inline-block;
		width: calc(100% - 48px);
		line-height: 1.25;
		vertical-align: middle;

		.content-intro-complex & {
			font-weight: 300;
		}
	}
}
