// language styles
.language-selector{
	max-width: 95%;
	position:relative;
	display:inline-block;
	@include float(left);
	height: 100%;

	@include no-desktop {
		height: auto;
	}

	.mobile-language-toggle {
		display: none;
		color:#fff;
		position: relative;
		font-size: 0.68em;
		font-weight: 600;
		@include padding-right (12px);
		padding-top: 3px;
		cursor: pointer;

		@include no-desktop {
			display: block;
		}

		i {
			@include margin-right(5px);
		}

		&:after {
			content: '';
			@include right (0);
			position: absolute;
			top: 7px;
			display: inline-block;
			width: 6px;
			border-top: 6px solid #fff;
			border-right: 4px solid transparent;
			border-left: 4px solid transparent;
			transition: border 0.25s;
		}

		&.active{
			&:after {
				border-top: 0;
				border-bottom: 6px solid #fff;
				transition: all 0.25s;
				-webkit-transition: all 0.25s;
			}
		}
	}

	.language-bar {
		padding: 0;
		margin: 0;
		white-space: nowrap;
		list-style: none;
		overflow:auto;
		background: transparent;
		display:none;

		@include no-desktop {
			position: fixed;
			@include right (0px);
			@include left (0px);
			top: 30px;
			width: 100vw;
			padding: 20px 10px;
			@include text-align(left);
			background: $f-grey-dark;
			box-sizing: border-box;
			flex-wrap: wrap;
			justify-content: flex-start;
			align-items: center;

			@include tablet{
				@include right(40px);
				left: auto;
				top: 38px;
				max-width: 300px;
			}
		}
		
		@include desktop {
			display:block;
		}

		@include lgDesktop {
			font-size: 1em;
		}

		&::-webkit-scrollbar {
			height: 0;
			background: transparent;
		}

		&.is-show {
			display: flex;
		}

		h4, div.t-underline {
			display: none;
			margin: 0;
			font-size:1.1em;

			@include no-desktop {
				display: block;
				width: 100%;
			}
		}

		&__item {
			display: inline-flex;
			flex: calc(30% - 15px);
			max-width: 33.33%;
			flex-grow: 0;
			@include margin-right(15px);

			@include desktop {
				margin: 0 5px;
				vertical-align: middle;
				vertical-align: top;
				max-width:none;
			}

			&.is-active {
				display: none;
			}
		}

		&__link {
			display: block;
			@include padding(13px, 2px, 5px, 2px);
			font-size: 0.68em;
			color: #fff;
			transition: color $quick;
			@include desktop {
				padding: 3px;
				font-size: 0.70em;
			}

			@include no-desktop {
				// color: $t-standard;
			}

			.is-scrolled & {
				font-weight: 500;
			}

			&:hover, &:focus {
				color: lighten($t-standard, 40%);

				.is-scrolled & {
					color: $t-standard-on-gold;
				}
			}
		}

	}
}
