.member-visible {
    display: none;

    body.is-members & {
        display: block;
    }
}

.is-members {
    .public-visible {
        display: none;
    }
}

.multi-filter {
	@include desktop {
        max-width: 1320px;
        margin: 20px auto;
        overflow: hidden;
        padding: 15px;
        white-space: normal;
    }
}

// accordion styles
.tabs {
    margin-top: 20px;
    margin-bottom: 10px;
    display: block;
    text-align: center;
    overflow: auto;
    white-space: nowrap;
    padding: 15px 5px;
    -webkit-transition: all 1s cubic-bezier(0.19, 1, 0.22, 1);
    transition: all 1s cubic-bezier(0.19, 1, 0.22, 1);

    &.is-hidden {
        @extend .hidden;
    }
	
	.section-header--left-aligned & {
		text-align: left;
		padding: 0;
		border-bottom: 1px solid #f1eff0;
	}

    @include desktop {
        max-width: 1320px;
        margin: 20px auto;
        overflow: hidden;
        padding: 15px;
        white-space: normal;
    }

    @include tablet {
        padding: 15px;
    }

    .tab__item {
		@extend %font-navigation;
		
        display: inline-block;
        border-bottom: 1px solid transparent;
        margin: 0 1.2em 0.2em;
		
		.section-header--left-aligned & {
			@include margin(0, 30px, 0, 0);
		}
		
        @include mobile {
            min-width: 130px;
            margin: 0 8px;
        }

        &:hover {
            border-bottom: 1px solid $t-gold;
            transition: all 0.8s ease;
        }

        &.is-active:hover {
            border-color: transparent;
        }
		
		&.label {
			&:hover {
				border-bottom:0;
			}
			
			.tab__title {
				text-align: left;
			
				> div {
					color: $t-standard;
				}
			}
		}
		
		.multi-filter & {
			margin: 0 0.5em 0.2em;
		}
    }

    .tab__title {
        cursor: pointer;
        text-align: center;
        display: block;

        > div {
            color: $f-gold;
            font-size: 0.9em;

            @include desktop {
                font-size: 1.05em;
                line-height: 2.5em;
            }
        }

        &.active > div {
            font-weight: 600;
            position: relative;

            &:after {
                content: " ";
                background: $f-gold;
                height: 2px;
                width: 100%;
                bottom: -6px;
                position: absolute;
                left: 50%;
                transform: translate(-50%, -50%);

                @include desktop {
                    content: " ";
                    width: 0;
                    height: 0;
                    border-left: 8px solid transparent;
                    border-right: 8px solid transparent;
                    border-top: 10px solid $f-gold;
                    background: transparent;
                }
				
				.section-header--left-aligned & {
					height: 2px;
					width: 100%;
					background: #13213c;
					border: 0;
					bottom: -2px;
				}
            }
        }
    }

    &.is--sticky {
        position: fixed;
        width: 100%;
        top: 36px;
        @include left(0);
        background: $f-grey-faint;
        z-index: 99;
        -webkit-transition: all 1s cubic-bezier(0.19, 1, 0.22, 1);
        transition: all 1s cubic-bezier(0.19, 1, 0.22, 1);

        .horizon-header & {
            top: 75px;
            z-index: 10;
        }
        
        .with-notice.horizon-header & {
            top: 125px;
        }

        .is-scrolled & {
            .horizon-header & {
                top: 15px;
            }
        }

        @include desktop {
            top: 140px;
            margin: 0;
            font-size: (12 / $base) * 1em;
            font-weight: 600; // Semi-bold
            line-height: (24 / 14) * 1em;
            padding: 5px;
            min-width: 100%;

            .horizon-header & {
                top: 180px;
                background: $t-standard-light;
            }
            
            .with-notice.horizon-header & {
                top: 240px;
            }

            .is-scrolled & {
                .horizon-header & {
                    top: 60px;
                }
            }
        }

        // On small Desktop screen
        @media (min-width: #{$bpMinDesktop}) and (max-width: 1150px) {
            top: 125px;
        }

        .tab__title > div {
            @include desktop {
                line-height: 1.5em;
            }
        }

        .tab__title.active > div {
            color: $f-gold;

            &:after {
                bottom: -12px;
                height: 2px;

                @include desktop {
                    bottom: -5px;
                    height: 4px;
                    border-left-width: 6px;
                    border-right-width: 6px;
                    border-top-width: 4px;
                }
            }
        }

        .tab__item {
            margin: 0.3em 1.2em 0.2em;
        }
    }
	
	&__fallback {
	    text-align: center;
		
		&__select {
			@extend %t-ml;
			color: $t-gold;
			text-transform: initial;
			@include padding(4px, 53px, 0, 18px);
			background: transparent;
			border: 0;
			border-bottom: 3px solid $t-gold;
			border-radius: 0;
			margin: 0 0 15px 0;
			font-weight: 100;

			-webkit-appearance: none;
			-moz-appearance: none;

			&::-ms-expand {
				display: none;
			}
		}
		&.is--sticky {
			position: fixed;
			@include padding(10px, 0, 0, 0);
			width: 100%;
			top: 60px;
			@include left(0);
			background: $f-grey-faint;
			z-index: 99;
			-webkit-transition: all 1s cubic-bezier(0.19, 1, 0.22, 1);
			transition: all 1s cubic-bezier(0.19, 1, 0.22, 1);

			.horizon-header & {
				top: 100px;
			}
			
			.with-notice.horizon-header & {
				top: 152px;
			}

			.is-scrolled & {
				.horizon-header & {
					top: 38px;
				}
			}
		}
	}
	
	.multi-filter & {
		text-align:left;
		margin:0;
		padding:0;
		
		&:first-child {
			border-bottom:1px solid $f-gold;
			padding-bottom:10px;
			margin-bottom:10px;
		}
		
		.tab__item {
			&:hover {
				border-bottom:0;
			}
			
			&::after {
				content: '';
				background-color: lighten($f-icon-color, 30);
				width: 5px;
				height: 5px;
				transform: translateY(-50%);
				display: inline-block;
				border-radius: 50%;
				margin-left: 0.7em;
				cursor: default;
			}

			&:last-child {
				@include margin-right(0px);
				padding: 0;

				&::after {
					width: 0;
					right: 0;
					display: none;
				}
			}
			
			&.label {
				float:left;

				&::after {
					width: 0;
					right: 0;
					display: none;
				}
			}
		}

        .tab__title {
			text-align:left;
			display:inline-block;
			
			&:hover {
				border-bottom: 1px solid $t-gold;
				transition: all 0.8s ease;
			}
			
			> div {
				@include desktop {
					line-height: 1.2em;
				}
			}
			
			&.active {
				> div {
					&:after {
						border:0;
					}
				}
			}
        }
		
		.tab__item_options {
			display:flex;
			flex-wrap: wrap;
		}
	}

    .category-tabs-wrapper & {
        @at-root .t-center#{&} {
            &.brands-filter {
                text-align: center;
                border-bottom: unset;
            }
        }
    }
}

//End tabs

.container {
    &.offers {
        &.member_preview {
            .btn--ghost {
                color: #fff;
                background-color: $f-gold;
                border: 0px;

                body.is-members & {
                    background-color: transparent;
                    color: $t-standard;
                    border: 1px solid $f-op50-grey-dark;
                }
            }

            .js-book-offer {
                display: none;

                body.is-members & {
                    display: inline-block;
                }
            }

            &.previewed {
                .js-book-offer {
                    display: inline-block;
                }

                .btn--ghost {
                    background-color: transparent;
                    color: $t-standard;
                    border: 1px solid $f-op50-grey-dark;
                }
            }
        }

        .dynamic-list, 
        .static-list {
            &__extra-links {
                display: flex;
                flex-direction: column;
        
                @include desktop {
                    flex-direction: row;
                }

                .btn {
                    margin-bottom: 0.5em;
                    width: auto;
                    flex: 1;
                    
                    @include desktop {
                        min-width: calc(50% - 4px);
    
                        + .btn {
                            @include margin-left(8px);
                        }
                    }
                }
            }
        }
    }

    &.justify-content {
        display: flex;
        justify-content: center;
		flex-wrap:wrap;
    }
}
