// ordered-block styles
.ordered-block {
	@extend %clearfix;
	background-color: $f-grey-faint;
	@include text-align(left);
	text-shadow: none;
	@include padding(42px, 42px, 68px, 42px);
	margin-top: 35px;
	margin-bottom: 2em;

	@include desktop {
		margin-top: 70px;
	}

	&__column {
		width: 100%;

		@include desktop {
			width: (100 / 3) * 1%;		// More exact way to get equal columns
			padding: 0 2em;
			&:last-child {
				.ordered-block__number {
					&:after {
						content: none;
					}
				}
			}
		}
	}

	&__header {
		margin-bottom: 2.5em;
		@include text-align(center);
	}

	&__content {
		@extend %clearfix;
		margin-bottom: 1em;
	}

	&__cta {
		margin-bottom: 2em;

		@include desktop {
			margin-bottom: 0;
		}
	}

	&__number {
		display: inline-block;
		color: #fff;
		background-color: $f-gold;

		width: 46px;
		height: 46px;

		padding-top: 12px;
		margin-bottom: 1em;

		font-size: 18px;
		line-height: 1;

		text-align: center;
		border-radius: 50%;

		&:after {
			content: "";
			@include margin-left(0.5em);
			display: block;
			width: 0;
			height: 2px;
			margin-top: -8px;
			background-color: $f-gold;

			transition: width $slow;

			@include mobile {
				max-width: 60vw;
			}
		}

		.is-visible & {
			&:after {
				width: calc((100vw - (100vw - 1140px)) / 3.1);	// 100% of the screen, minus the piece over the max width of the container, divided into the number of columns present
			}
		}

	}

}
