// checkin-widget styles

.checkin-widgets {
	display: inline-block;
	background: transparent;

	margin-top: 5px;
	margin-bottom: 5px;


	&:after {
		display: block;
		clear: both;
		content: '';
	}

	.checkin {
		@include padding-right(.5em);
		@include border-right(1px solid $t-standard-light);
	}

	span{
		display: inline-block;
		@include margin-right(2px);
		line-height: 1;
		color: $t-standard-light;

		&:before{
			top:2px;
			position: relative;
		}
	}

	.checkin, .checkout {
		display: inline-block;
		@include margin-right(1em);
		margin-bottom: 6px;
		line-height: 1;
		font-size:0.76em;
		letter-spacing: 0.05em;
		color:$t-standard-light;

		span {
			display: inline-block;
			@include padding-right(.5em);
		}
	}
}
