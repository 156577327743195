// gallery-simple styles
.gallery {
	padding: 0;
	margin: 0;
	list-style: none;

	&__item {
        @include aspect-ratio(1.85, 1);	// Styling for pre-initialisation of the JS
        display: none;

        &:first-child {
            display: block;
        }

		.gallery--inline & {
			display: none;

			&:first-child {
				display: block;
			}

			.content {
				&:before {
					content: "";
					position: absolute;
					top: 0;
					bottom: 0;
					@include left(0);
					@include right(0);
					z-index: 115;
					display: block;
				}
			}
		}

		.gallery--inline & {
			min-height: 350px;
			max-height: 55vh;		// The 0.1vh extra is a fix for white gap appearing in the next component. VH calculations are off and margin-bottom: -1px causes a double scrollbar in some instances
		}

		.gallery--header & {
			min-height: $hero-min-height;
		}
	}

	img {
		width: 100%;
		height: 100%;
		max-width: 100%;

		object-fit: cover;
		
		&.anchor {
			&__top {
				object-position: top center;
			}
			&__bottom {
				object-position: bottom center;
			}
			&__center {
				object-position: center;
			}
		}
	}

	&__caption {
		position: absolute;
		bottom: 8%;
		z-index: 200;

		max-width: 100%;
		@include padding(1em, 40px, 1em, 1.5em);

		background-color: $f-black-overlay;

		transform: translateX(bi-app-reverse-percentage(0));
		transition: transform $medium $easeInOutExpo, background-color $medium;

		.gallery--hero & {
			top: auto;
			bottom: 65px;
			left: 50%;
			transform: translateX(-50%);
			text-align: center;
			padding: 1em;
			background-color: transparent;
		}

		&.hide {
			@include mobile {
				background-color: $f-black-overlay-light;
				transform: translateX(calc(-100% + 35px));

				@include rtl {
					transform: translateX(calc(100% - 35px));
				}

				.gallery__toggle-caption {
					&:before {
						content: $glyph-chevron-right;
					}
				}
			}
		}

		@include desktop {
			max-width: 70%;
		}

	}

	&__toggle-caption {
		position: absolute;
		top: 50%;
		@include right(5px);
		width: 30px;
		line-height: 1;

		transform: translateY(-50%);
		&:before {
			content: $glyph-chevron-left;
			@extend %icon-font-glyph;

			font-size: 2em;
		}
	}

	&__overlay {
		width: 100vw;
		height: 50vh;
		position: absolute;
		left: 0;
		bottom: 0;
		display: block;
		opacity: .85;
		background: rgba(0,0,0,0);
		background: linear-gradient(to bottom,rgba(0,0,0,0) 50%,#000 100%);
	}

	&.slick-slider {
		height: 100%;
		.alternate-content & {
			min-height: inherit;
		}

		&.gallery--header, &.gallery--hero, &.gallery--inline.gallery--lg-controls {
			margin-bottom: 0;	// Fix potential for gap to appear between this and the next component
		}
	}

	.slick {

		&-list , &-track, &-slide {
			height: 100%;
			.alternate-content & {
				min-height: inherit;
			}
		}

		&-slide {
			@include aspect-ratio(1.85, 1);

			@include mobile {
				overflow: hidden;
			}
			
			> .content {
				transform: none;
			}
		}

		&-arrow {
			z-index: 2;
			width: 30px;
			height: 40px;
		}

		&-prev {
			$arrow-size: 20px;
			@include left($arrow-size/2);

			@include desktop {
				@include left($arrow-size);
			}

			&:before {
				border-top: $arrow-size*(2/3) solid transparent;
				border-bottom: $arrow-size*(2/3) solid transparent;
				@include border-right($arrow-size solid #fff);
			}
		}

		&-next {
			$arrow-size: 20px;
			@include right($arrow-size/2);

			@include desktop {
				@include right($arrow-size);
			}

			&:before {
				border-top: $arrow-size*(2/3) solid transparent;
				border-bottom: $arrow-size*(2/3) solid transparent;
				@include border-left($arrow-size solid #fff);
			}
		}
	}

	&--hero {
		.slick {
			&-dots {
				bottom: 62px;
			}
		}
	}

	&--inline {

		.slick {
			&-slide {
				display: block;
				opacity: 1;
			}
		}
	}

	&--has-overlay {
		.content {
			&:before {
				content: "";
				position: absolute;
				top: 0;
				bottom: 0;
				@include left(0);
				@include right(0);
				z-index: 115;
				display: block;

				background-color: $f-black-overlay-light;
			}
		}
	}

	&--lg-controls {
		.slick {
			&-prev {
				$arrow-size: 30px;
				@include left($arrow-size/3);

				&:before {
					border-top-width: $arrow-size*(2/3) / 1.5;
					border-bottom-width: $arrow-size*(2/3) / 1.5;
					border-right-width: $arrow-size / 1.5;
				}

				@include desktop {
					@include left($arrow-size);

					&:before {
						border-top-width: $arrow-size*(2/3);
						border-bottom-width: $arrow-size*(2/3);
						border-right-width: $arrow-size;
					}
				}

			}

			&-next {
				$arrow-size: 30px;
				@include right(0);

				&:before {
					border-top-width: $arrow-size*(2/3) / 1.5;
					border-bottom-width: $arrow-size*(2/3) / 1.5;
					border-left-width: $arrow-size / 1.5;
				}

				@include desktop {
					@include right($arrow-size);

					&:before {
						border-top-width: $arrow-size*(2/3);
						border-bottom-width: $arrow-size*(2/3);
						border-left-width: $arrow-size;
					}
				}

			}
		}
	}

	&--header, &.gallery--inline.gallery--lg-controls {

		&__link {
			display: block;
			height: 100%;
			position: absolute;
			left: 0;
			right: 0;
			z-index: 1;
		}

		.slick-dots {
			bottom: 10px;

			li button:before {
				width: 10px;
				height: 10px;
			}
		}
	}

}

.mobile-carousel .slick-list{
	overflow: visible;
}

.gallery--header.gallery--inline + .container {
	pointer-events: none;
	padding-left: 40px;
	padding-right: 40px;
}
