// video-banner styles
.banner-logo {
	position: absolute;
	top: 0;
	@include right(0);
	bottom: 0;
	@include left(0);
	z-index: 4;
	width: 80%;
	max-width: 360px;
	margin: auto;
}

.video-banner, .journey-video-banner {
	position: absolute;
	top: 0;
	display: block;
	width: 100%;
	height: 100%;
	overflow: hidden;

	&__playBtn {
		z-index: 60;
	}

	&__muteBtn {
		position: absolute;
		top: 28px;
		@include left(10px);
		z-index: 99;
		background: none;
		border: none;
		color: $f-gold;
		background: #fff;
		opacity: 0.5;
		border-radius: 50%;
		width: 36px;
		height: 36px;
		padding: 5px;
		text-align: center;
		&:hover,
		&:focus {
			opacity: 1;
		}

		&:before {
			@extend %icon-font-glyph;
			content: $glyph-volume-high;
		}

		&.is-muted {
			&:before {
				content: $glyph-volume-mute;
			}
		}

		.campaign--banner &{
		
			@include mobile{
				top: 20px;
			}	
		}

		@include desktop{
			top:220px;
		}

		@include landscapeTablet{
			top:220px;
		}

		.horizon-header & {
			@include desktop{
				top:50px;
			}
	
			@include landscapeTablet{
				top:50px;
			}
		}
	}

	&:after {
		position: absolute;
		top: 0;
		@include left(0);
		z-index: 2;
		display: block;
		width: 100%;
		height: 100%;
		content: '';
		background: rgba(0,0,0,.3);
	}

	&__player {
		position: relative;
		z-index: 10;
		width: 100%;
		height: 100%;
		opacity:0;

		object-fit: cover;
		object-position: center center;

		.is-ie &, .is-edge & {
			height: auto;
			top: 50%;
			transform: translateY(-50%);
		}
	}

	.video-link {
		display: none;
	}
}
