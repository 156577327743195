.center-popup{
    position:fixed;
    bottom:0;
    @include left(0);
    width:100%;
    height:auto;
    z-index:9999;
    box-shadow: 0px 1px 15px rgba(0,0,0,0.5);
    transition: all .5s ease-in-out;
    transform: translateY(100%);
    

    &.is-active{
        transition: all .5s ease-in-out;
        transform: translateY(0);
    }

    .inner-content{
        max-height:35vh;
        overflow-y:scroll;
    }

    .title-center{
        text-align: center;
    }

    table{
        border:0;

        tr{
            border:0;

            &:first-child{
                td{
                    border: 0 !important;
                }
            }
    
            th {
				@extend %font-alternate-body;
                padding:8px;
                color:$f-grey-dark;
                font-weight: 400;
                letter-spacing: 1px;
                font-size: 0.82em;
                text-transform: uppercase;
                @include text-align(left);
                min-width:200px;
            }
    
            td{
                padding:8px;
                font-size:0.8em;
                color:$t-standard;
                letter-spacing: 0;
                text-align: justify;
                a{
                    color:$t-gold;
                    text-decoration: underline;
                }
            }
        }

        &.responsive-table{
            border:0;

            tr:last-child{
                border:0;
            }
        }
    }

    .js-close{
        position: absolute;
        color:$f-grey-dark;
        @include right(10px);
        top:15px;
    }
}