
$circleTop: 95px; // Previously: 95px

.circle-container {
	position: fixed;
	top: $circleTop;
	@include right(0);
	bottom: 0;
	@include left(0);
	z-index: 30;
	width: 400px;
	height: 400px;
	margin: auto;
	pointer-events: auto;
	opacity: 1;
	transition: opacity 2s ease-in-out, transform .5s linear;
	transition-delay: .75s, 0.25s;
	transform: translateY(0);
	will-change: transform;
	contain: layout;

	&.circle-container--pointer {
		pointer-events: none;
	}

	&.is-hidden {
		position: static;
		opacity: 0;
		transition: opacity 0s ease-in-out;
	}

	&.circle-small {
		width: 180px;
		height: 180px;

		@include desktopLong {
			top: 0;
		}

		.section-header__text {
			margin-top: 80px;
			font-size: (22 / $base) * 1em;
		}

		&.is-fixed {
			transition: opacity .5s ease-in-out;
			transform: translateY(0);
		}
	}

	&.is-fixed {
		pointer-events: none !important;
		transition: opacity .5s ease-in-out, transform 1s ease-in-out;
		transform: translateY(-32px);

		.is-ie & {
			.circle-bubble {
				opacity: 0;
				transition-delay: 0s;
				animation: none;
			}
			.circle-location {
				opacity: 0;
			}
		}

		.circle-text,
		.loading-circle,
		.locations {
			opacity: 0;
		}
	}

	//Hide Arrow for psuedo js-destination

	.js-destination{
		&:after{
			display:none;
		}
	}
}

.circle-text {
	position: relative;
	z-index: 2;
	text-align: center;
	opacity: 1;
	transition: opacity .3s ease-in;

	.section-header__text {
		margin-top: 100px;
		margin-bottom: 20px;
		line-height: 1.2;
	}
}

.big-circle {
	position: absolute;
	top: 0;
	opacity: 1;
	transition: transform 1s ease-in;
	transform: scale(1);
	will-change: transform;

	circle {
		transition: fill 0s .5s ease-in;

		fill: transparent;
	}

	&.is-filled {
		circle {
			fill: $f-gold;
		}
	}

	.circle-container.is-fixed & {
		transform: scale(.008);

		circle {
			stroke: $f-gold;
		}
	}
}

.locations {
	opacity: 1;
	transition: opacity .3s ease-in;
}

.locations-listing, .text-list {
	position: relative;
	height: 100%;
	padding: 0;
	margin: 0;
	list-style: none;
}

.text-list {
	.text-item {
		position: absolute;
		@include right(0);
		@include left(0);
		@include padding-left(5px);
		margin: auto;
	}
}

.text-item {
	opacity: 0;
	transform: opacity .4s ease-in;

	&.is-show {
		opacity: 1;
	}
}

.title-list {
	position: absolute;
	bottom: 25%;
	z-index: 3;
	width: 100%;
	height: 60px;
	padding: 0;
	margin: 0;
	overflow: visible;
	text-align: center;
	list-style: none;
	opacity: 1;
	transition: opacity 1s .25s ease-in;

	&.is-hidden {
		opacity: 0;
		transition: opacity 2s ease-in;
	}

	.text-item {
		position: absolute;
		top: 0;
		@include right(0);
		@include left(0);
		margin: 0 auto;

		p {
			margin: 0;
			line-height: 1.2;
		}
	}

	.btn {
		top: 70px;
	}

	body.naladhu &{
		display:none;
	}
}

.circle-location {
	position: absolute;
	z-index: 2;
	cursor: pointer;
	border-radius: 50%;
	box-shadow: 0 3px 5px rgba(0, 0, 0, .5);
	transition: fill .25s, border .25s, transform .25s;
	transform: scale(1);

	fill: #fff;

	&.is-fill, &.is-active {
		border: 1px solid rgba(164,142,130, .6);

		fill: $f-gold;
	}

	&:hover {
		border: 1px solid rgba(164,142,130, .4);
		transform: scale(1.6);

		fill: $f-gold;
	}
}

.loading-circle {
	position: absolute;
	top: 4px;
	left: 4px;
	z-index: 0;
	opacity: 1;
	transition: opacity .3s ease-in;
}

.locations-7 {
	.location-1 {
		top: 0;
		left: 48%;
	}

	.location-2 {
		top: 18%;
		right: 10.5%;
	}

	.location-3 {
		top: 59.5%;
		right: 1%;
	}

	.location-4 {
		right: 26.5%;
		bottom: 5%;
	}

	.location-5 {
		bottom: 5%;
		left: 28%;
	}

	.location-6 {
		top: 59.5%;
		left: 1%;
	}

	.location-7 {
		top: 18%;
		left: 11%;
	}

	+ .locations-listing {
		.location-2,
		.location-3,
		.location-4 {
			.locations-listing__text {
				float: right;
				text-align: right;
			}

			&:before {
				transform-origin: left;
			}

			&.is-show, &.is-active {
				&:before {
					transform: scaleX(1);
				}
			}
		}

		.location-5,
		.location-6,
		.location-7 {
			.locations-listing__text {
				float: left;
				text-align: left;
			}

			&:before {
				transform-origin: right;
			}

			&.is-show, &.is-active {
				&:before {
					transform: scaleX(1);
				}
			}
		}

		.location-2 {
			top: 77px;
			right: -280px;
		}

		.location-3 {
			top: 243px;
			right: -320px;
		}

		.location-4 {
			top: 372px;
			right: -215px;
		}

		.location-5 {
			top: 372px;
			left: -205px;
		}

		.location-6 {
			top: 243px;
			left: -315px;
		}

		.location-7 {
			top: 77px;
			left: -275px;
		}
	}
}

.locations-6 {
	.location-1 {
		top: 0;
		left: 48%;
	}

	.location-2 {
		top: 24.5%;
		right: 6%;
	}

	.location-3 {
		right: 7%;
		bottom: 23.5%;
	}

	.location-4 {
		bottom: 0;
		left: 48%;
	}

	.location-5 {
		bottom: 23.5%;
		left: 7%;
	}

	.location-6 {
		top: 24.5%;
		left: 7%;
	}

	+ .locations-listing {
		.locations-listing__text {
			width: 190px;
		}

		.location-1,
		.location-2,
		.location-3,
		.location-4 {
			.locations-listing__text {
				float: right;
				text-align: right;
			}

			&:before {
				transform-origin: left;
			}

			&.is-show, &.is-active {
				&:before {
					transform: scaleX(1);
				}
			}
		}

		.location-5,
		.location-6 {
			.locations-listing__text {
				float: left;
				text-align: left;
			}

			&:before {
				transform-origin: right;
			}

			&.is-show, &.is-active {
				&:before {
					transform: scaleX(1);
				}
			}
		}

		.location-1 {
			top: 3px;
			right: -100px;
		}

		.location-2 {
			top: 50px;
			right: -170px;
		}

		.location-3 {
			top: 130px;
			right: -170px;
		}

		.location-4 {
			top: 172px;
			right: -100px;
		}

		.location-5 {
			top: 130px;
			left: -170px;
		}

		.location-6 {
			top: 50px;
			left: -170px;
		}
	}
}

.locations-listing {
	position: absolute;
	top: 0;
	right: 0;
	left: -10px;
	width: 250px;
	margin: auto;

	.locations-6 + & {
		width: 200px;
	}
}

.locations-listing__item {
	position: absolute;
	width: 100%;

	&.is-show, &.is-active {
		.locations-listing__text {
			opacity: 1;
			transform: translateY(0);
		}
	}

	&:after {
		display: block;
		height: 0;
		clear: both;
		content: '';
	}

	&:before {
		display: block;
		width: 100%;
		height: 3px;
		content: '';
		background: $f-gold;
		transition: transform .25s ease-in;
		transform: scaleX(0);
	}

	.locations-listing__text {
		width: 220px;
		margin: 5px 0;
		line-height: 1.2;
		opacity: 0;
		transition: opacity .25s .25s ease-in, transform .25s .25s ease-in;
		transform: translateY(-10px);
	}

	a.locations-listing__text {
		&:hover, &:focus {
			text-decoration: underline;
		}
	}
}

/*Switch text position*/
.locations-6 {
	 + .locations-listing{

		.locations-listing__item {

			&.location-1, &.location-2, &.location-6{

				.locations-listing__text{
					position:absolute;
					@include right(0);
					bottom: 0;
				}
			}
		}
	}
}



.circle-lines {
	position: fixed;
	top: $circleTop;
	@include right(0);
	bottom: 0;
	@include left(0);
	z-index: 5;
	width: 950px;
	height: 950px;
	margin: auto;
	opacity: .6;
	transition: transform 1.5s;
	transform: scale(.48);
	will-change: transform;

	&.is-scrolled {
		z-index:20;
		.line {
			stroke-dashoffset: 1;
		}
	}

	&.is-big {
		opacity: .3;
		transform: scale(1.65);

		@media (max-width: 1440px) {
			transform: scale(1.4);
		}
		@media (max-width: 1340px) {
			transform: scale(1.3);
		}
		@media (max-width: 1240px) {
			transform: scale(1.2);
		}
		@media (max-width: 1140px) {
			transform: scale(1.1);
		}

		svg {
			path,
			circle,
			eclipse {
				stroke-width: 1;
			}
		}
	}

	&.is-absolute {
		position: absolute;
		top: 200vh;

		@-moz-document url-prefix() {
			top: 150vh;
		}
	}

	&.is-fixed {
		.line {
			animation: none;
		}
	}

	.line {
		position: absolute;
	}

	.line-1, .line-2, .line-3 {
		transform: rotate(90deg);

		stroke-dasharray: 3000;
		stroke-dashoffset: 3000;

		.is-ie & {
			stroke-dasharray: 1;
		}
	}

	.line-1 {
		top: 0;
		left: 54px;
		animation: dash 2s ease-out forwards, movingLeft 5s 2.6s ease-in-out infinite;
	}

	.line-2 {
		top: 36px;
		left: 12px;
		animation: dash 2s .8s ease-out forwards, movingRight 7s 2.6s ease-in-out infinite;
	}

	.line-3 {
		animation: dash 2s 1.2s ease-out forwards, movingLeft 6s 2.6s ease-in-out infinite;
	}

	.line-4 {
		top: 55px;
		left: 55px;
		opacity: 0;
		animation: fade 2s 3s ease-in-out forwards;
	}
}

.circle-bubble {
	position: absolute;
	display: block;
	width: 12px;
	height: 12px;
	margin: auto;
	border: 1px solid #fff;
	border-radius: 50%;
	animation: bubbling 1.5s infinite;

	&.is-show,
	&.is-fill,
	&.is-active {
		border: 1px solid $f-gold;
	}
}


@keyframes fade {
	to {
		opacity: 1;
	}
}
@keyframes dash {
	to {
		stroke-dashoffset: 0;
	}
}
@keyframes movingLeft {
	0% {
		transform: rotate(90deg) translate(0,0);
	}
	25% {
		transform: rotate(90deg) translate(-20px,0px);
	}
	50% {
		transform: rotate(90deg) translate(-20px,20px);
	}
	75% {
		transform: rotate(90deg) translate(0px,20px);
	}
	100% {
		transform: rotate(90deg) translate(0,0);
	}
}
@keyframes movingRight {
	0% {
		transform: rotate(90deg) translate(0,0);
	}
	25% {
		transform: rotate(90deg) translate(20px,0px);
	}
	50% {
		transform: rotate(90deg) translate(20px,0px);
	}
	75% {
		transform: rotate(90deg) translate(0px,20px);
	}
	100% {
		transform: rotate(90deg) translate(0,0);
	}
}

@keyframes bubbling {
	from {
		opacity: 1;
		transform: scale(1);
	}
	to {
		opacity: .1;
		transform: scale(2);
	}
}
