// testimonial styles
.testimonial {
	padding-top: 60px;
	padding-bottom: 60px;
	display: flex;
	justify-content: stretch;
	flex-direction: row;

	@include no-desktop {
		flex-direction: column
	}

	.col-7, .col-5 , .col-12{
		@include padding-left(20px);
		@include padding-right(20px);

		@include desktop {
			@include padding-left(40px);
			@include padding-right(40px);
		}
	}
	
	.col-5 {
		@include mobile {
			padding: 0 5px;
		}
		
		@include no-desktop {
			width: 100%;
		}

		@include desktop {
			display: inline-flex;
		}
	}
	
	.col-7 {
		@include mobile {
			padding: 0 5px;
		}
		
		@include no-desktop {
			margin-top: 20px;
		}
	}

	&.fullwidth {
		padding-top: 30px;
		padding-bottom: 30px;

		&__box {
			max-width: 960px;
		}
	} 

	&__box {
		max-width: $text-width;
		
		@include desktop {
			@include margin-left(-30px);
		}

		.section-header {
			@include padding-left(30px);
		}
	}

	&__image {
		@extend %t-underline;
		&:focus {
			outline: 0;
		}
		> img {
			display: inline-block;
			overflow: hidden;
			border-radius: 50%;
			transform: scale(.7);
		}

		&:after {
			background-color: transparent;
		}

		.slick-current & {
			> img {
				transform: scale(1);
			}

			&:after {
				background-color: $f-gold;
			}
		}
	}

	&__name, &__location, &__property {
		@extend %font-sub-title;
		display: block;
		@include mobile {
			display: none;

			.slick-current & {
				display: block;
			}
		}
	}

	&__property {
		margin-bottom: 0.5em;
	}

	&__tripadvisor {
		> a {
			display: block;
			padding: 10px 0;

			> img {
				padding: 0;
			}
		}
		img {
			max-width: 103px;
			padding: 10px 0;
		}
	}

	.section-header {
		@include no-desktop {
			display: none;
		}

		&__title {
			position: relative;
			text-align: initial;
	
			// &:after {
			// 	@extend %icon-font-glyph;
			// 	position: absolute;
			// 	@include left(-120px);
			// 	z-index: 1;
	
			// 	font-size: 180px;
			// 	color: rgba(255, 255, 255, .7);
	
			// 	content: $glyph-open-quotes;
	
			// 	@include mobile {
			// 		top: -60px;
			// 		@include left(-30px);
			// 	};
			// }
	
			span {
				position: relative;
				z-index: 10;
			}
		}
	}

	&.is--swapped{
		flex-direction: row-reverse;
		flex-wrap: wrap;
	}
}

.slider {

	&.quotes {
		margin-bottom: 60px;
		@include margin-left(-30px);

		&.fullwidth{
			margin-bottom:30px;
			max-width: 960px;
		}

		@include desktop {
			max-width: $text-width;
			
			.slick-prev {
				@include left(-40px);
			}
			.slick-next {
				@include right(-40px);
			}
			.slick-dots {
				width: 90%;
			}
		}
		@include tablet {
			.t-sm {
				font-size: (18 / $base) * 1em;
			}
			.slick-prev {
				@include left(-10px);
			}
		}
		@include mobile {
			@include margin-left(-16px);

			.t-sm {
				font-size: (16 / $base) * 1em;
			}
			.slick-prev {
				@include left(-9px);
				top: -20px;
			}
			.slick-next {
				@include right(-25px);
				top: -20px;
			}
		}

		.slick-dots {
			width: 100%;
			@include padding-left(30px);

			@include no-desktop {
				bottom: -15px;
			}
		}

		.slick-slide {
			@include margin-left(32px);
			@include mobile {
				@include margin-left(16px);
			}
		}

	}
}

.facility-rating {
	padding: 30px;
	// margin-top: 40px;
	// margin-bottom: 30px;

	@include tablet {
		display: flex;
		flex-direction: row;
		justify-content: space-evenly;
		align-items: center;
		flex-wrap: wrap;
		padding: 20px 0;
	}

	@include desktop {
		// margin-top: -10px;
		// margin-bottom: 60px;
		padding: 32px 48px;
	}

	&__heading {
		display: block;
		max-width: 220px;
		color: $t-standard-light;

		> span {
			display: block;
			font-size: 4.375em;		// Maintain size across all break points
		}

		@include tablet {
			position: relative;
			display: inline-block;

			&:after {
				position: absolute;
				top: 50%;
				transform: translateY(-50%);
				@include right(-32px);
				width: 1px;
				height: 116px;
				margin-top: 0;
			}
		}
	}

	&__scores {
		@extend %font-alternate-body;
		padding: 0;
		margin: 0;
		list-style-type: none;

		@include tablet {
			display: inline-flex;
			flex-direction: column;
			justify-content: center;
			@include margin-left(64px);
		}
	}

	&__score {
		margin-bottom: 5px;
		color: $t-standard-light;
	}

	&__dots {
		> span {
			display: inline-block;
			width: 10px;
			height: 10px;
			@include margin-right(3px);
			background-color: $f-gold;
			border: 1px solid $f-gold;
			border-radius: 50%;

			&:last-child {
				@include margin-right(8px);
			}
		}

		&.dot--1 {
			> span ~ span {
				background-color: transparent;
			}
		}
		&.dot--1-5 {
			> span ~ span {
				background-color: transparent;
			}
			> span:nth-child(2) {
				@include border-left-width(5px);
			}
		}
		&.dot--2 {
			> span + span ~ span {
				background-color: transparent;
			}
		}
		&.dot--2-5 {
			> span + span ~ span {
				background-color: transparent;
			}
			> span:nth-child(3) {
				@include border-left-width(5px);
			}
		}
		&.dot--3 {
			> span + span + span ~ span {
				background-color: transparent;
			}
		}
		&.dot--3-5 {
			> span + span + span ~ span {
				background-color: transparent;
			}
			> span:nth-child(4) {
				@include border-left-width(5px);
			}
		}
		&.dot--4 {
			> span + span + span + span ~ span {
				background-color: transparent;
			}
		}
		&.dot--4-5 {
			> span + span + span + span ~ span {
				background-color: transparent;
			}
			> span:nth-child(5) {
				@include border-left-width(5px);
			}
		}
	}
}

