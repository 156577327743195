@import "variables";

@font-face {
  font-family: '#{$icomoon-font-family}';
  src:  url('#{$icomoon-font-path}/#{$icomoon-font-family}.eot?g3gat2');
  src:  url('#{$icomoon-font-path}/#{$icomoon-font-family}.eot?g3gat2#iefix') format('embedded-opentype'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.ttf?g3gat2') format('truetype'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.woff?g3gat2') format('woff'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.svg?g3gat2##{$icomoon-font-family}') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="glyph-"], [class*=" glyph-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: '#{$icomoon-font-family}' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.glyph-warning {
  &:before {
    content: $glyph-warning; 
  }
}
.glyph-search {
  &:before {
    content: $glyph-search; 
  }
}
.glyph-mail {
  &:before {
    content: $glyph-mail; 
  }
}
.glyph-pets {
  &:before {
    content: $glyph-pets; 
  }
}
.glyph-wifi-mid {
  &:before {
    content: $glyph-wifi-mid; 
  }
}
.glyph-share {
  &:before {
    content: $glyph-share; 
  }
}
.glyph-check-circle-outline {
  &:before {
    content: $glyph-check-circle-outline; 
  }
}
.glyph-pinterest {
  &:before {
    content: $glyph-pinterest; 
  }
}
.glyph-location-pin {
  &:before {
    content: $glyph-location-pin; 
  }
}
.glyph-chevron-right {
  &:before {
    content: $glyph-chevron-right; 
  }
}
.glyph-chevron-left {
  &:before {
    content: $glyph-chevron-left; 
  }
}
.glyph-chevron-down {
  &:before {
    content: $glyph-chevron-down; 
  }
}
.glyph-chevron-up {
  &:before {
    content: $glyph-chevron-up; 
  }
}
.glyph-xiaohongshu {
  &:before {
    content: $glyph-xiaohongshu; 
  }
}
.glyph-hamper {
  &:before {
    content: $glyph-hamper; 
  }
}
.glyph-atm {
  &:before {
    content: $glyph-atm; 
  }
}
.glyph-beauty-salon {
  &:before {
    content: $glyph-beauty-salon; 
  }
}
.glyph-childrens-facilities {
  &:before {
    content: $glyph-childrens-facilities; 
  }
}
.glyph-laundry-service {
  &:before {
    content: $glyph-laundry-service; 
  }
}
.glyph-limousine-service {
  &:before {
    content: $glyph-limousine-service; 
  }
}
.glyph-no-smoking {
  &:before {
    content: $glyph-no-smoking; 
  }
}
.glyph-shuttle {
  &:before {
    content: $glyph-shuttle; 
  }
}
.glyph-tour-desk {
  &:before {
    content: $glyph-tour-desk; 
  }
}
.glyph-undercover-parking {
  &:before {
    content: $glyph-undercover-parking; 
  }
}
.glyph-valet-service {
  &:before {
    content: $glyph-valet-service; 
  }
}
.glyph-kids-pool {
  &:before {
    content: $glyph-kids-pool; 
  }
}
.glyph-laundry-services {
  &:before {
    content: $glyph-laundry-services; 
  }
}
.glyph-person {
  &:before {
    content: $glyph-person; 
  }
}
.glyph-door {
  &:before {
    content: $glyph-door; 
  }
}
.glyph-lock {
  &:before {
    content: $glyph-lock; 
  }
}
.glyph-unlock {
  &:before {
    content: $glyph-unlock; 
  }
}
.glyph-twitter {
  &:before {
    content: $glyph-twitter; 
  }
}
.glyph-ironing {
  &:before {
    content: $glyph-ironing; 
  }
}
.glyph-family {
  &:before {
    content: $glyph-family; 
  }
}
.glyph-globe {
  &:before {
    content: $glyph-globe; 
  }
}
.glyph-ev-station {
  &:before {
    content: $glyph-ev-station; 
  }
}
.glyph-niyama {
  &:before {
    content: $glyph-niyama; 
  }
}
.glyph-private-cabana {
  &:before {
    content: $glyph-private-cabana; 
  }
}
.glyph-recycle {
  &:before {
    content: $glyph-recycle; 
  }
}
.glyph-kayak {
  &:before {
    content: $glyph-kayak; 
  }
}
.glyph-drinking-water {
  &:before {
    content: $glyph-drinking-water; 
  }
}
.glyph-bathrobe {
  &:before {
    content: $glyph-bathrobe; 
  }
}
.glyph-breakfast {
  &:before {
    content: $glyph-breakfast; 
  }
}
.glyph-champagne {
  &:before {
    content: $glyph-champagne; 
  }
}
.glyph-coffee {
  &:before {
    content: $glyph-coffee; 
  }
}
.glyph-direct-access--lagoon {
  &:before {
    content: $glyph-direct-access--lagoon; 
  }
}
.glyph-fresh-coconut {
  &:before {
    content: $glyph-fresh-coconut; 
  }
}
.glyph-ice-cream {
  &:before {
    content: $glyph-ice-cream; 
  }
}
.glyph-inroom-safe {
  &:before {
    content: $glyph-inroom-safe; 
  }
}
.glyph-moviestar {
  &:before {
    content: $glyph-moviestar; 
  }
}
.glyph-popcorn {
  &:before {
    content: $glyph-popcorn; 
  }
}
.glyph-private-terrace {
  &:before {
    content: $glyph-private-terrace; 
  }
}
.glyph-refrigerator {
  &:before {
    content: $glyph-refrigerator; 
  }
}
.glyph-selection-of-table {
  &:before {
    content: $glyph-selection-of-table; 
  }
}
.glyph-slippers {
  &:before {
    content: $glyph-slippers; 
  }
}
.glyph-sunken {
  &:before {
    content: $glyph-sunken; 
  }
}
.glyph-discovery-circle {
  &:before {
    content: $glyph-discovery-circle; 
  }
}
.glyph-discovery {
  &:before {
    content: $glyph-discovery; 
  }
}
.glyph-anantara-spa-product {
  &:before {
    content: $glyph-anantara-spa-product; 
  }
}
.glyph-volume-mute {
  &:before {
    content: $glyph-volume-mute; 
  }
}
.glyph-volume-high {
  &:before {
    content: $glyph-volume-high; 
  }
}
.glyph-google-plus {
  &:before {
    content: $glyph-google-plus; 
  }
}
.glyph-in {
  &:before {
    content: $glyph-in; 
  }
}
.glyph-info2 {
  &:before {
    content: $glyph-info2; 
  }
}
.glyph-arrow-left2 {
  &:before {
    content: $glyph-arrow-left2; 
  }
}
.glyph-sina-weibo {
  &:before {
    content: $glyph-sina-weibo; 
  }
}
.glyph-comment {
  &:before {
    content: $glyph-comment; 
  }
}
.glyph-thin-arrow-down {
  &:before {
    content: $glyph-thin-arrow-down; 
  }
}
.glyph-open-quotes {
  &:before {
    content: $glyph-open-quotes; 
  }
}
.glyph-mobile {
  &:before {
    content: $glyph-mobile; 
  }
}
.glyph-facebook2 {
  &:before {
    content: $glyph-facebook2; 
  }
}
.glyph-phone {
  &:before {
    content: $glyph-phone; 
  }
}
.glyph-link {
  &:before {
    content: $glyph-link; 
  }
}
.glyph-plus {
  &:before {
    content: $glyph-plus; 
  }
}
.glyph-minus {
  &:before {
    content: $glyph-minus; 
  }
}
.glyph-cross {
  &:before {
    content: $glyph-cross; 
  }
}
.glyph-checkmark {
  &:before {
    content: $glyph-checkmark; 
  }
}
.glyph-facebook {
  &:before {
    content: $glyph-facebook; 
  }
}
.glyph-instagram {
  &:before {
    content: $glyph-instagram; 
  }
}
.glyph-youtube {
  &:before {
    content: $glyph-youtube; 
  }
}
.glyph-whatsapp {
  &:before {
    content: $glyph-whatsapp; 
  }
}
.glyph-bubble {
  &:before {
    content: $glyph-bubble; 
  }
}
.glyph-sun {
  &:before {
    content: $glyph-sun; 
  }
}
.glyph-rating-empty-bubble {
  &:before {
    content: $glyph-rating-empty-bubble;     
    color: #00aa6c;
  }
}
.glyph-rating-half-bubble {
  &:before {
    content: $glyph-rating-half-bubble;     
    color: #00aa6c;
  }
}
.glyph-rating-bubble {
  &:before {
    content: $glyph-rating-bubble;     
    color: #00aa6c;
  }
}
.glyph-tripadvisor {
  &:before {
    content: $glyph-tripadvisor; 
  }
}
.glyph-elysia {
  &:before {
    content: $glyph-elysia; 
  }
}
.glyph-naladhu {
  &:before {
    content: $glyph-naladhu; 
  }
}
.glyph-mn-arrow-down {
  &:before {
    content: $glyph-mn-arrow-down; 
  }
}
.glyph-half-star {
  &:before {
    content: $glyph-half-star; 
  }
}
.glyph-underfloor-heating {
  &:before {
    content: $glyph-underfloor-heating; 
  }
}
.glyph-gas-fireplace {
  &:before {
    content: $glyph-gas-fireplace; 
  }
}
.glyph-electric-heating {
  &:before {
    content: $glyph-electric-heating; 
  }
}
.glyph-meeting-banquet {
  &:before {
    content: $glyph-meeting-banquet; 
  }
}
.glyph-meeting-cabaret {
  &:before {
    content: $glyph-meeting-cabaret; 
  }
}
.glyph-power-l {
  &:before {
    content: $glyph-power-l; 
  }
}
.glyph-power-k {
  &:before {
    content: $glyph-power-k; 
  }
}
.glyph-power-j {
  &:before {
    content: $glyph-power-j; 
  }
}
.glyph-power-i {
  &:before {
    content: $glyph-power-i; 
  }
}
.glyph-power-h {
  &:before {
    content: $glyph-power-h; 
  }
}
.glyph-power-g {
  &:before {
    content: $glyph-power-g; 
  }
}
.glyph-power-f {
  &:before {
    content: $glyph-power-f; 
  }
}
.glyph-power-e {
  &:before {
    content: $glyph-power-e; 
  }
}
.glyph-power-d {
  &:before {
    content: $glyph-power-d; 
  }
}
.glyph-power-c {
  &:before {
    content: $glyph-power-c; 
  }
}
.glyph-power-b {
  &:before {
    content: $glyph-power-b; 
  }
}
.glyph-power-a {
  &:before {
    content: $glyph-power-a; 
  }
}
.glyph-power-o {
  &:before {
    content: $glyph-power-o; 
  }
}
.glyph-power-n {
  &:before {
    content: $glyph-power-n; 
  }
}
.glyph-power-m {
  &:before {
    content: $glyph-power-m; 
  }
}
.glyph-airport-transfer {
  &:before {
    content: $glyph-airport-transfer; 
  }
}
.glyph-menu {
  &:before {
    content: $glyph-menu; 
  }
}
.glyph-kaokao {
  &:before {
    content: $glyph-kaokao; 
  }
}
.glyph-telegram {
  &:before {
    content: $glyph-telegram; 
  }
}
.glyph-tiktok {
  &:before {
    content: $glyph-tiktok; 
  }
}
.glyph-food-tray {
  &:before {
    content: $glyph-food-tray; 
  }
}
.glyph-elewana {
  &:before {
    content: $glyph-elewana; 
  }
}
.glyph-anantara {
  &:before {
    content: $glyph-anantara; 
  }
}
.glyph-avani {
  &:before {
    content: $glyph-avani; 
  }
}
.glyph-tivoli {
  &:before {
    content: $glyph-tivoli; 
  }
}
.glyph-oaks {
  &:before {
    content: $glyph-oaks; 
  }
}
.glyph-nhow {
  &:before {
    content: $glyph-nhow; 
  }
}
.glyph-nh {
  &:before {
    content: $glyph-nh; 
  }
}
.glyph-ncollection {
  &:before {
    content: $glyph-ncollection; 
  }
}
.glyph-gha {
  &:before {
    content: $glyph-gha; 
  }
}
.glyph-messenger {
  &:before {
    content: $glyph-messenger; 
  }
}
.glyph-chrome-cast {
  &:before {
    content: $glyph-chrome-cast; 
  }
}
.glyph-stargazing {
  &:before {
    content: $glyph-stargazing; 
  }
}
.glyph-wechat {
  &:before {
    content: $glyph-wechat; 
  }
}
.glyph-line {
  &:before {
    content: $glyph-line; 
  }
}
.glyph-Canyon-view {
  &:before {
    content: $glyph-Canyon-view; 
  }
}
.glyph-Gabled-ceiling {
  &:before {
    content: $glyph-Gabled-ceiling; 
  }
}
.glyph-Gourmet-resident {
  &:before {
    content: $glyph-Gourmet-resident; 
  }
}
.glyph-air-conditioning {
  &:before {
    content: $glyph-air-conditioning; 
  }
}
.glyph-bar-counter {
  &:before {
    content: $glyph-bar-counter; 
  }
}
.glyph-engine {
  &:before {
    content: $glyph-engine; 
  }
}
.glyph-longtail-boat {
  &:before {
    content: $glyph-longtail-boat; 
  }
}
.glyph-temples-tour {
  &:before {
    content: $glyph-temples-tour; 
  }
}
.glyph-Local-handicrafts {
  &:before {
    content: $glyph-Local-handicrafts; 
  }
}
.glyph-USB-charging-station {
  &:before {
    content: $glyph-USB-charging-station; 
  }
}
.glyph-thai-handcraft {
  &:before {
    content: $glyph-thai-handcraft; 
  }
}
.glyph-thai-silk {
  &:before {
    content: $glyph-thai-silk; 
  }
}
.glyph-time-together {
  &:before {
    content: $glyph-time-together; 
  }
}
.glyph-patio {
  &:before {
    content: $glyph-patio; 
  }
}
.glyph-living-area {
  &:before {
    content: $glyph-living-area; 
  }
}
.glyph-apartment {
  &:before {
    content: $glyph-apartment; 
  }
}
.glyph-higher-floor {
  &:before {
    content: $glyph-higher-floor; 
  }
}
.glyph-captain {
  &:before {
    content: $glyph-captain; 
  }
}
.glyph-cocktail {
  &:before {
    content: $glyph-cocktail; 
  }
}
.glyph-chef {
  &:before {
    content: $glyph-chef; 
  }
}
.glyph-outdoor-deck {
  &:before {
    content: $glyph-outdoor-deck; 
  }
}
.glyph-dugong {
  &:before {
    content: $glyph-dugong; 
  }
}
.glyph-parking {
  &:before {
    content: $glyph-parking; 
  }
}
.glyph-bathroom-and-separate-toilet {
  &:before {
    content: $glyph-bathroom-and-separate-toilet; 
  }
}
.glyph-hairdryer {
  &:before {
    content: $glyph-hairdryer; 
  }
}
.glyph-temperature-controlled-grape-fridge {
  &:before {
    content: $glyph-temperature-controlled-grape-fridge; 
  }
}
.glyph-washer-and-dryer {
  &:before {
    content: $glyph-washer-and-dryer; 
  }
}
.glyph-dvd-player {
  &:before {
    content: $glyph-dvd-player; 
  }
}
.glyph-departure-gift {
  &:before {
    content: $glyph-departure-gift; 
  }
}
.glyph-telescope {
  &:before {
    content: $glyph-telescope; 
  }
}
.glyph-binoculars {
  &:before {
    content: $glyph-binoculars; 
  }
}
.glyph-daily-fresh-fruits {
  &:before {
    content: $glyph-daily-fresh-fruits; 
  }
}
.glyph-balcony2 {
  &:before {
    content: $glyph-balcony2; 
  }
}
.glyph-barbecue {
  &:before {
    content: $glyph-barbecue; 
  }
}
.glyph-basins {
  &:before {
    content: $glyph-basins; 
  }
}
.glyph-bathroom-product {
  &:before {
    content: $glyph-bathroom-product; 
  }
}
.glyph-bathtub {
  &:before {
    content: $glyph-bathtub; 
  }
}
.glyph-beach-bag {
  &:before {
    content: $glyph-beach-bag; 
  }
}
.glyph-beach-shoes {
  &:before {
    content: $glyph-beach-shoes; 
  }
}
.glyph-beach-toys {
  &:before {
    content: $glyph-beach-toys; 
  }
}
.glyph-bed-crib {
  &:before {
    content: $glyph-bed-crib; 
  }
}
.glyph-boardgames {
  &:before {
    content: $glyph-boardgames; 
  }
}
.glyph-bunk-bed {
  &:before {
    content: $glyph-bunk-bed; 
  }
}
.glyph-cliff-beach-view {
  &:before {
    content: $glyph-cliff-beach-view; 
  }
}
.glyph-cliff-top-ocean-view {
  &:before {
    content: $glyph-cliff-top-ocean-view; 
  }
}
.glyph-cocktail-making-facilities {
  &:before {
    content: $glyph-cocktail-making-facilities; 
  }
}
.glyph-cribe {
  &:before {
    content: $glyph-cribe; 
  }
}
.glyph-dining-table {
  &:before {
    content: $glyph-dining-table; 
  }
}
.glyph-double-basins {
  &:before {
    content: $glyph-double-basins; 
  }
}
.glyph-double-vanity {
  &:before {
    content: $glyph-double-vanity; 
  }
}
.glyph-garden-view {
  &:before {
    content: $glyph-garden-view; 
  }
}
.glyph-goods-for-kids {
  &:before {
    content: $glyph-goods-for-kids; 
  }
}
.glyph-guest-wc {
  &:before {
    content: $glyph-guest-wc; 
  }
}
.glyph-hammock {
  &:before {
    content: $glyph-hammock; 
  }
}
.glyph-home-theatre {
  &:before {
    content: $glyph-home-theatre; 
  }
}
.glyph-housekeeping {
  &:before {
    content: $glyph-housekeeping; 
  }
}
.glyph-interconnecting-rooms {
  &:before {
    content: $glyph-interconnecting-rooms; 
  }
}
.glyph-ipad {
  &:before {
    content: $glyph-ipad; 
  }
}
.glyph-jacuzzi {
  &:before {
    content: $glyph-jacuzzi; 
  }
}
.glyph-kasara {
  &:before {
    content: $glyph-kasara; 
  }
}
.glyph-kids-club {
  &:before {
    content: $glyph-kids-club; 
  }
}
.glyph-king-bed {
  &:before {
    content: $glyph-king-bed; 
  }
}
.glyph-life-jacket {
  &:before {
    content: $glyph-life-jacket; 
  }
}
.glyph-majlis-seating {
  &:before {
    content: $glyph-majlis-seating; 
  }
}
.glyph-mangroves-view {
  &:before {
    content: $glyph-mangroves-view; 
  }
}
.glyph-mini-bar-with-glassware {
  &:before {
    content: $glyph-mini-bar-with-glassware; 
  }
}
.glyph-multimedia-connectivity {
  &:before {
    content: $glyph-multimedia-connectivity; 
  }
}
.glyph-oryx {
  &:before {
    content: $glyph-oryx; 
  }
}
.glyph-outdoor-cabana {
  &:before {
    content: $glyph-outdoor-cabana; 
  }
}
.glyph-outdoor-dining-area {
  &:before {
    content: $glyph-outdoor-dining-area; 
  }
}
.glyph-outdoor-lounge {
  &:before {
    content: $glyph-outdoor-lounge; 
  }
}
.glyph-outdoor-sala {
  &:before {
    content: $glyph-outdoor-sala; 
  }
}
.glyph-oval-bath-tub {
  &:before {
    content: $glyph-oval-bath-tub; 
  }
}
.glyph-over-water-hammock {
  &:before {
    content: $glyph-over-water-hammock; 
  }
}
.glyph-panoramic-view {
  &:before {
    content: $glyph-panoramic-view; 
  }
}
.glyph-patio-with-tow-daybeds {
  &:before {
    content: $glyph-patio-with-tow-daybeds; 
  }
}
.glyph-pillow-menu {
  &:before {
    content: $glyph-pillow-menu; 
  }
}
.glyph-playstation {
  &:before {
    content: $glyph-playstation; 
  }
}
.glyph-pool2 {
  &:before {
    content: $glyph-pool2; 
  }
}
.glyph-privacy-fence {
  &:before {
    content: $glyph-privacy-fence; 
  }
}
.glyph-private-entrance {
  &:before {
    content: $glyph-private-entrance; 
  }
}
.glyph-private-fence {
  &:before {
    content: $glyph-private-fence; 
  }
}
.glyph-river-view {
  &:before {
    content: $glyph-river-view; 
  }
}
.glyph-room-for-disabled-available {
  &:before {
    content: $glyph-room-for-disabled-available; 
  }
}
.glyph-separate-entrance-lift {
  &:before {
    content: $glyph-separate-entrance-lift; 
  }
}
.glyph-single-bed {
  &:before {
    content: $glyph-single-bed; 
  }
}
.glyph-sound-system2 {
  &:before {
    content: $glyph-sound-system2; 
  }
}
.glyph-spa2 {
  &:before {
    content: $glyph-spa2; 
  }
}
.glyph-standalone-two-storey-villa {
  &:before {
    content: $glyph-standalone-two-storey-villa; 
  }
}
.glyph-storage-space-for-luggage {
  &:before {
    content: $glyph-storage-space-for-luggage; 
  }
}
.glyph-sun-loungers {
  &:before {
    content: $glyph-sun-loungers; 
  }
}
.glyph-swing-bed {
  &:before {
    content: $glyph-swing-bed; 
  }
}
.glyph-teens-club {
  &:before {
    content: $glyph-teens-club; 
  }
}
.glyph-three-country-view {
  &:before {
    content: $glyph-three-country-view; 
  }
}
.glyph-traditional-chinese-tea-set {
  &:before {
    content: $glyph-traditional-chinese-tea-set; 
  }
}
.glyph-turndown-service {
  &:before {
    content: $glyph-turndown-service; 
  }
}
.glyph-twin-bed {
  &:before {
    content: $glyph-twin-bed; 
  }
}
.glyph-villa {
  &:before {
    content: $glyph-villa; 
  }
}
.glyph-walk-in-wardrobe {
  &:before {
    content: $glyph-walk-in-wardrobe; 
  }
}
.glyph-wardrobe {
  &:before {
    content: $glyph-wardrobe; 
  }
}
.glyph-wet-bar {
  &:before {
    content: $glyph-wet-bar; 
  }
}
.glyph-writing-desk {
  &:before {
    content: $glyph-writing-desk; 
  }
}
.glyph-yoga-mat {
  &:before {
    content: $glyph-yoga-mat; 
  }
}
.glyph-profile {
  &:before {
    content: $glyph-profile; 
  }
}
.glyph-booking {
  &:before {
    content: $glyph-booking; 
  }
}
.glyph-login {
  &:before {
    content: $glyph-login; 
  }
}
.glyph-fax {
  &:before {
    content: $glyph-fax; 
  }
}
.glyph-external {
  &:before {
    content: $glyph-external; 
  }
}
.glyph-residence {
  &:before {
    content: $glyph-residence; 
  }
}
.glyph-time-clean-invert {
  &:before {
    content: $glyph-time-clean-invert; 
  }
}
.glyph-bed {
  &:before {
    content: $glyph-bed; 
  }
}
.glyph-sunset {
  &:before {
    content: $glyph-sunset; 
  }
}
.glyph-sunrise {
  &:before {
    content: $glyph-sunrise; 
  }
}
.glyph-capacity {
  &:before {
    content: $glyph-capacity; 
  }
}
.glyph-roomsize {
  &:before {
    content: $glyph-roomsize; 
  }
}
.glyph-thumbs-up {
  &:before {
    content: $glyph-thumbs-up; 
  }
}
.glyph-tea-cup {
  &:before {
    content: $glyph-tea-cup; 
  }
}
.glyph-vk {
  &:before {
    content: $glyph-vk; 
  }
}
.glyph-meeting-board-room {
  &:before {
    content: $glyph-meeting-board-room; 
  }
}
.glyph-meeting-class-room {
  &:before {
    content: $glyph-meeting-class-room; 
  }
}
.glyph-meeting-cocktail {
  &:before {
    content: $glyph-meeting-cocktail; 
  }
}
.glyph-meeting-h-square {
  &:before {
    content: $glyph-meeting-h-square; 
  }
}
.glyph-meeting-theatre {
  &:before {
    content: $glyph-meeting-theatre; 
  }
}
.glyph-meeting-u-shape {
  &:before {
    content: $glyph-meeting-u-shape; 
  }
}
.glyph-private-dining {
  &:before {
    content: $glyph-private-dining; 
  }
}
.glyph-call {
  &:before {
    content: $glyph-call; 
  }
}
.glyph-email {
  &:before {
    content: $glyph-email; 
  }
}
.glyph-time-clean {
  &:before {
    content: $glyph-time-clean; 
  }
}
.glyph-butler {
  &:before {
    content: $glyph-butler; 
  }
}
.glyph-club-lounge-access {
  &:before {
    content: $glyph-club-lounge-access; 
  }
}
.glyph-coffee-machine {
  &:before {
    content: $glyph-coffee-machine; 
  }
}
.glyph-Direct-beach-access {
  &:before {
    content: $glyph-Direct-beach-access; 
  }
}
.glyph-inroom-spa-treatments {
  &:before {
    content: $glyph-inroom-spa-treatments; 
  }
}
.glyph-kitchenette {
  &:before {
    content: $glyph-kitchenette; 
  }
}
.glyph-outdoor-shower {
  &:before {
    content: $glyph-outdoor-shower; 
  }
}
.glyph-pool {
  &:before {
    content: $glyph-pool; 
  }
}
.glyph-shampoo-bottle {
  &:before {
    content: $glyph-shampoo-bottle; 
  }
}
.glyph-shower {
  &:before {
    content: $glyph-shower; 
  }
}
.glyph-sofa {
  &:before {
    content: $glyph-sofa; 
  }
}
.glyph-sound-system {
  &:before {
    content: $glyph-sound-system; 
  }
}
.glyph-sundeck {
  &:before {
    content: $glyph-sundeck; 
  }
}
.glyph-tv {
  &:before {
    content: $glyph-tv; 
  }
}
.glyph-window {
  &:before {
    content: $glyph-window; 
  }
}
.glyph-wine-fridge {
  &:before {
    content: $glyph-wine-fridge; 
  }
}
.glyph-balcony {
  &:before {
    content: $glyph-balcony; 
  }
}
.glyph-hour-service {
  &:before {
    content: $glyph-hour-service; 
  }
}
.glyph-bicycles {
  &:before {
    content: $glyph-bicycles; 
  }
}
.glyph-diving {
  &:before {
    content: $glyph-diving; 
  }
}
.glyph-elephant {
  &:before {
    content: $glyph-elephant; 
  }
}
.glyph-fitness-centre {
  &:before {
    content: $glyph-fitness-centre; 
  }
}
.glyph-golf {
  &:before {
    content: $glyph-golf; 
  }
}
.glyph-handy-smartphone {
  &:before {
    content: $glyph-handy-smartphone; 
  }
}
.glyph-meeting-and-banqueting {
  &:before {
    content: $glyph-meeting-and-banqueting; 
  }
}
.glyph-over-water {
  &:before {
    content: $glyph-over-water; 
  }
}
.glyph-residing-doctor {
  &:before {
    content: $glyph-residing-doctor; 
  }
}
.glyph-restaurant {
  &:before {
    content: $glyph-restaurant; 
  }
}
.glyph-snorkelling {
  &:before {
    content: $glyph-snorkelling; 
  }
}
.glyph-spa {
  &:before {
    content: $glyph-spa; 
  }
}
.glyph-swimming-pool {
  &:before {
    content: $glyph-swimming-pool; 
  }
}
.glyph-tennis-court {
  &:before {
    content: $glyph-tennis-court; 
  }
}
.glyph-water-sport {
  &:before {
    content: $glyph-water-sport; 
  }
}
.glyph-wedding-chapel {
  &:before {
    content: $glyph-wedding-chapel; 
  }
}
.glyph-yoga {
  &:before {
    content: $glyph-yoga; 
  }
}
.glyph-zebra {
  &:before {
    content: $glyph-zebra; 
  }
}
.glyph-beach {
  &:before {
    content: $glyph-beach; 
  }
}
.glyph-city {
  &:before {
    content: $glyph-city; 
  }
}
.glyph-desert {
  &:before {
    content: $glyph-desert; 
  }
}
.glyph-heritage {
  &:before {
    content: $glyph-heritage; 
  }
}
.glyph-mountain {
  &:before {
    content: $glyph-mountain; 
  }
}
.glyph-river {
  &:before {
    content: $glyph-river; 
  }
}
.glyph-image {
  &:before {
    content: $glyph-image; 
  }
}
.glyph-calendar {
  &:before {
    content: $glyph-calendar; 
  }
}
.glyph-angle-double-down {
  &:before {
    content: $glyph-angle-double-down; 
  }
}
.glyph-heart {
  &:before {
    content: $glyph-heart; 
  }
}
.glyph-star {
  &:before {
    content: $glyph-star; 
  }
}
.glyph-check-circle {
  &:before {
    content: $glyph-check-circle; 
  }
}
.glyph-angle-right {
  &:before {
    content: $glyph-angle-right; 
  }
}
.glyph-angle-down {
  &:before {
    content: $glyph-angle-down; 
  }
}
.glyph-snapchat {
  &:before {
    content: $glyph-snapchat; 
  }
}

