// video-cta styles

.is-ie .video-cta--full-height .video-cta__video {
	width: auto;
	height: auto;
	min-width: 100%;
	max-width: 150%;
	min-height: 100%;
}

.is-ie .video-cta .video-cta__video {
	width: auto;
	height: auto;
	min-width: 100%;
	max-width: 150%;
	min-height: 100%;
}

.video-cta {
	@include aspect-ratio(1.85, 1);

	@include mobile {
		margin-bottom: 22px;

		.discover & {
			margin-bottom: 0;
		}
	}

	&.not-active {
		.video-cta {
			&__image {
				&:before {
					transform: translateY(-50%);
				}
			}
		}
	}

	&--full-height {
		@include desktop {
			height: 75vh;
		}
	}

	&__link {
		height: 100%;
		overflow: hidden;
		color: inherit;


		&:before {
			$triangle-size: 60vw;
			position: absolute;
			z-index: 19;
			top: 0;
			@include left(50%);
			display: block;
			margin: 0 auto;
			content: "";

			width: $triangle-size;
			border-top: $triangle-size solid rgba(255,255,255, .3);
			@include border-right($triangle-size*0.7 solid transparent);
			@include border-left($triangle-size*0.7 solid transparent);

			opacity: 0;
			transition: transform $long, opacity $long;
			transform: translateY(-75%) translateX(bi-app-reverse-percentage(-50%));
			will-change: transform, opacity;

			// .video-cta--full-height & {
			// 	$triangle-size: 70vw;

			// 	width: $triangle-size;
			// 	border-top-width: $triangle-size;
			// 	border-right-width: $triangle-size*0.7;
			// 	border-left-width: $triangle-size*0.7;
			// }

			@include tablet {
				$triangle-size: 30vw;

				width: $triangle-size;
				border-top-width: $triangle-size;
				border-right-width: $triangle-size*0.7;
				border-left-width: $triangle-size*0.7;

				// .video-cta--full-height & {
				// 	$triangle-size: 60vw;

				// 	width: $triangle-size;
				// 	border-top-width: $triangle-size;
				// 	border-right-width: $triangle-size*0.7;
				// 	border-left-width: $triangle-size*0.7;
				// }
			}

			@include desktop {
				$triangle-size: 28vw;

				width: $triangle-size;
				border-top-width: $triangle-size;
				border-right-width: $triangle-size*0.7;
				border-left-width: $triangle-size*0.7;

				.video-cta--full-height & {
					$triangle-size: 42vw;

					width: $triangle-size;
					border-top-width: $triangle-size;
					border-right-width: $triangle-size*0.7;
					border-left-width: $triangle-size*0.7;
				}
			}
		}

		// &:hover, &:focus {
		// 	&:before {
		// 		opacity: 1;
		// 		transform: translateY(0) translateX(bi-app-reverse-percentage(-50%));
		// 	}

		// 	.video-cta {
		// 		&__text {
		// 			transform: translateY(200%);
		// 		}

		// 		&__offscreen {
		// 			transform: translateY(13%);

		// 			@include desktop {
		// 				transform: translateY(24%);
		// 			}
		// 		}

		// 		&__image {
		// 			&:before {
		// 				transform: translateY(0);
		// 			}
		// 		}
		// 	}
		// }
	}

	&__image {
		z-index: 10;
		height: 100%;
		transition: transform $medium cubic-bezier(.785, .135, .15, .86);

		> img {
			width: 100%;
			height: 100%;
			object-fit: cover;
			object-position: center center;

			position: absolute;
			top: 0;
			z-index: -1;
		}

		&:before {
			content: "";
			position: absolute;
			top: 0;
			@include right(0);
			bottom: -100%;
			@include left(0);
			background: linear-gradient(to top, rgba(0,0,0,.9) 0%,rgba(0,0,0,.65) 25%,rgba(0,0,0,0) 55%,rgba(255,255,255,0) 100%);
			transition: transform $long;
			transform: translateY(-25%);
			will-change: transform;

			.video-cta--full-height & {
				@include desktop {
					transform: translateY(-35%);
				}
			}
			@include no-desktop {
				background: linear-gradient(to top, rgba(0,0,0,.9) 0%,rgba(0,0,0,.5) 45%,rgba(0,0,0,0) 65%,rgba(255,255,255,0) 100%);
			}
		}
	}

	&__video {
		width: 100%;
		min-width: 100%;
		height: 100%;
		min-height: 100%;
		z-index: 18;
		object-fit: cover;
	}

	&__text, &__offscreen {
		position: absolute;
		@include right(0);
		bottom: 40px;
		@include left(0);
		display: block;
		text-align: center;
		pointer-events: none;
		transition: transform $slow;
		will-change: transform;
	}

	&__text {
		transform: translateY(0);
	}

	&__offscreen {
		top: 0;
		text-shadow: $t-text-shadow;
		transform: translateY(-200%);

		.video-cta__title {
			max-width:220px;
			word-break: break-word;
			margin: 0 auto;
			padding: 0;
			font-size:1.3em;

			@include desktop{
				max-width:450px;
				word-break: normal;
				font-size:1.625em;
			}

			.video-cta--full-height & {
				@include desktop {
					max-width: 300px;
				}
			}

			&.mw-250{
				max-width: 250px;
			}
		}
	}

	&__heading {
		@extend %font-sub-title;		
		color: $t-gold;

		&.t-white {
			@include no-desktop {
				color: $t-gold !important;
			}
		}

		&.t-l {
			@include no-desktop {
				font-size: (12 / $base) * 1em;
				font-weight: 400;	// Medium
				line-height: 1.5;
				text-transform: uppercase;
				letter-spacing: (250 / 1000) * 1em;
			}
		}
	}

	&__title {
		@extend %font-header;
		
		display: block;
		color: #fff;

		.video-cta--full-height & {
			@include desktop {
				font-weight: 100;
			}

			&.t-upper {
				@include no-desktop {
					text-transform: none !important;
				}
			}
			
			&.t-ml {
				@include no-desktop {
					font-size: (26 / $base) * 1em;
					font-weight: 300;	// Light
					line-height: 1.25;
					letter-spacing: (50 / 1000) * 1em;
				}

				@include mobile {
					font-size: (22 / $base) * 1em;
				}
			}
		}
	}
}

.discover {
	@include tablet {
		.col-6 {
			width: 50%;
		}
	}
	@include mobile {
		overflow: hidden;
		transition: height $slow;
	}
}
