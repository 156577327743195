// calendar styles

.english-format {
	display: block;
}

.ja-zh-format {
	display: none;
}

.ko-format {
	display: none;
}

html[lang="ja"], html[lang="zh"]{
	.ja-zh-format {
		display: block;
	}
	
	.ko-format {
		display: none;
	}

	.english-format {
		display: none;
	}

	.date {
		&__group {
			&:after {
				@include mobile {
					top: 40px;
				}
			}
		}
	}
}

html[lang="ko"]{
	.ko-format {
		display: block;
	}
	
	.ja-zh-format {
		display: none;
	}

	.english-format {
		display: none;
	}

	.date {
		&__group {
			&:after {
				@include mobile {
					top: 40px;
				}
			}
		}
	}
}

.calendar-dimmer {
	display:none;
	position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: #000;
    z-index: 11;
    opacity: 0;
	
	.horizon & {
		opacity: .65;
	}
}
.calendar-container {
	line-height: calc(20 / 16);
	position: absolute;
	pointer-events: auto;
	z-index: 400;
	top: 250px;
    @include right ($mask-max - 2);
	max-width:607px;
	
	.horizon & {
		max-width:none;
	}
		
	@include tablet {
		.horizon & {
			left: 50%;
			transform: translateX(-50%);
			right:auto;
		}
	}
	
	@include desktop {
		max-width:677px;
		
		.horizon & {
			top:100%;
			left: 50%;
			transform: translateX(-50%);
			right:auto;
			max-width:none;
		}
	}
	
	@include mobile {
		transform: none;
		height: 100%;
		width: 100vw;
		left: 0;
		right:0;
		top: 0px;
		height:100vh;
		overflow-y: scroll;
		z-index: 999999;
	}

	@include iphoneX{
		transform: none;
		height: 100%;
		width: 100vw;
		left: 0;
		right:0;
		top: 0px;
		height:100vh;
		overflow-y: scroll;
		z-index: 999999;
	}

	@include tabletSmall{
		@include right(0);
		
		.horizon & {
			@include right(auto);
			left: 50%;
			transform:  translateX(-50%);
		}
	}
	
	@include landscapeShallow {
		top:10px;
	}

	@include lgDesktop {
		top: 200px;
	}
	
	.litepicker{
		z-index:9;
		.container__months{

			@include mobile{
				max-width:100%;
				width:auto;
				margin-top:40px;
				border:0;
			}

			@include iphoneX{
				max-width:100%;
				width:auto;
				padding-bottom: 20px;
				transform: translateY(-20px);
				border:0;
			}
			.month-item{
				@include mobile{
					border:0;
					width:100%;
					padding:0;
				}
	
				@include smallMobile{
					width:auto;
					padding:0;
				}
	
				@include tablet{
					width:calc(40px * 7);
				}

				@include iphoneX{
					padding:0;
					width: calc(50% - 1px);
					@include border-right(1px solid $f-gold);
				}

				.month-item-header{

					@include mobile{
						background:$f-grey-faint;
						margin:0 0 15px;
						padding:5px 0;
					}
				}

				&:nth-child(even){
					@include iphoneX{
						@include border-right(0);
					}
				}
			}

			&.columns-2 {
				@extend %calendarWidth;
			}
			
	
		}

		.container__days {

			div, a{

				@include tablet{
					width:40px;
					height:40px;
				}
				
				@include landscapeTablet {
					height:30px;
					padding: 5px 10px;
				}

				@include mobile{
					width: calc(100% / 7);
				}

				@include iphoneX{
					width: calc(100% / 7);
				}
			}
		}
	} 
	
	.calendar-header{
		@extend %calendarWidth;
		width: 100vw;
		position: fixed;
		top: 0;
		z-index: 9999999;
		margin: 0;
		background:#fff;
		border-bottom:1px solid $f-grey-light;
		padding: 10px;
		border-radius: 0;
		@include text-align(center);

		@include iphoneX{
			display:block !important;
			top:0!important;
		}

		@include tablet{
			position: relative;
			width:100%;
			padding: 10px;
			background:#fff;
			border-bottom:1px solid $f-grey-light;
			top:20px;
		}

		@include desktop{
			position: relative;
			width:100%;
			padding: 10px;
			background:#fff;
			border-bottom:1px solid $f-grey-light;
			top:20px;
		}

		.js-stay-selection{
    		font-weight: 600;
			font-size: 1em;
			display:inline-block;

			@include mobile{
				font-size:0.9em;
			}
		}

		.js-calendar-close {
			position:absolute;
			width:16px;
			height:16px;
			@include right(12px);
			opacity: 0.7;
			display: inline-block;

			&:hover {
				opacity: 1;
			}

			&::before, &::after{
				position: absolute;
				@include right (8px);
				content: ' ';
				height: 15px;
				width: 2px;
				top:2px;
				background-color: $t-standard;

			}

			&:before{
				transform: rotate(45deg);
			}

			&:after{
				transform: rotate(-45deg);
			}
		}
	}
	
	.calendar-footer {
		@extend %calendarWidth;
		width: 100vw;
		position: fixed;
		bottom: 0;
		z-index: 9999999;
		margin: 0;
		background:$f-grey-faint;
		border-top:1px solid $f-grey-light;
		padding: 10px 5px;
		font-size: 0.9em;
		display:none;
		
		&.is-active {
			display:block;
		}
		
		.selected {
			@include margin(0, 0, 5px, 35px);
			font-weight:bold;
		}
		.restricted {
			&__icon {
				@include margin(0, 10px, 0, 0);
				
				position:relative;
				display:block;
				@include float(left);
				width:25px;
				height:20px;
				opacity: 0.4;
				border: 1px solid #333;
				text-indent:-1000px;
				
				&:before, &:after {
					position: absolute;
					top: 1px;
					left: 50%;
					content: ' ';
					height: 15px;
					width: 1px;
					background-color: #333;
					opacity:0.4;
				}

				&:before {
					transform: rotate(45deg);
				}
				&:after {
					transform: rotate(-45deg);
				}
			}
		
			&__message {
				display:block;
				overflow:hidden;
				@include margin(0, 10px, 0, 0);
				
				a {
					text-decoration:underline;
				}
			}
		}
		
		@include tablet {
			position:relative;
			width:100%;
			margin-top: -5px;
		}
		
		@include desktop {
			position:relative;
			width:100%;
			margin-top: -5px;
		}
		
		@include lgDesktop {
		}
	}

	%calendarWidth {
		@include desktop {
			width: calc(calc(45px * 14) + 45px);
		}

		@include tablet{
			width: calc(calc(40px * 14) + 45px);
		}

		.horizon & {
			@include mobile{
				max-width:100%;
				width:auto;
			}

			@include iphoneX{
				max-width:100%;
				width:auto;
			}
		}
	}
}



.date {
	margin-bottom: -18px;
	@include padding(0, 20px, 22px, 20px);

	&:first-child {
		@include border-left(0);
	}

	@media (max-height: $bpShortWindowBookingMask) {
		padding-bottom: 12px;
	}

	&__group {
		position: relative;
		background-color: $f-bm-inputs;
		padding-bottom: 14px;
		border-radius: 2px;

		$triangle-size: 10px;
		&:after {
			display: block;
			position: absolute;
			top: 50px;
			@include right(8px);
			width: $triangle-size;
			@include margin-left(1em);
			content: '';
			// border-top: $triangle-size solid $f-bm-button;
			border-top: $triangle-size solid $f-bm-arrow;
			@include border-right($triangle-size/3*2.2 solid transparent);
			@include border-left($triangle-size/3*2.2 solid transparent);

			transform: translateY(-($triangle-size/2));

			@include mobile {
				top: 30px;
				@include right(16px);
			}

			.is-gha & {
				// border-top-color: $f-bm-button--scrolled;
				border-top-color: $f-bm-arrow;
			}

			.date--no-picker & {
				content: none;
			}
		}
	}

	&__picker {
		display: block;
		@include float(left);
		width: 50%;
		color: $t-bm-on-primary--alt;
		text-align: center;
		@extend %clearfix;

		.is-gha & {
			color: $t-standard;
		}

		&:last-child {
			@include border-left(1px solid $f-bm-border--primary);
		}

		input{
			width:100%;
			visibility: hidden;
			height:0;
		}
	}

	&__number {
		font-weight: 200; // Light
		line-height: 1.25;
		color: $t-bm-on-primary;

		.is-gha & {
			color: $t-standard;
		}
	}

	&__month {
		label + & {
			margin-top: 5px;
			margin-bottom: -5px;
		}

		&.ja-zh-format {
			padding-top: 10px;
			margin-bottom: -10px;
			
			.horizon & {
				padding-top: 0;
				margin-bottom: 0;
			}
		}
		
		&.ko-format {
			padding-top: 10px;
			margin-bottom: -10px;
			
			.horizon & {
				padding-top: 0;
				margin-bottom: 0;
			}
		}
	}

	&__day {
		font-weight: 500;
		color: $t-bm-on-primary--subtle;
	}
}


.container__months {
	position: relative;
	z-index: 100;
	display: block;
	color: #333;
	background: #fff;
	border-top: 1px solid #ccc;
	border-bottom: 1px solid #ccc;
	border-bottom-color: #bbb;
	// font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;

	@include mobile {
		max-width: 100%;
	}
}


.container__months {
	*zoom: 1;

	@media (max-width: 382px) {
		@include right(0 !important);
		@include left(0 !important);
		max-width: 382px;
	}


	&:before,
	&:after {
		display: table;
		content: " ";
	}

	&:after {
		clear: both;
	}
}

.month-item {
	@include float(left);
	width: $mask-max;
	max-width: 100%;
	@include padding(12px, 21px, 21px, 21px);
	@include border-right(1px solid $f-gold);
	@include smallMobile {
		width: 100%;
	}

	&:last-child {
		border: 0;
	}
}

.month-item-header {
	position: relative;
	z-index: 9999;
	display: inline-block;
	padding: 5px 3px;
	margin: 0;
	overflow: hidden;
	color: $t-gold;
	background-color: #fff;
	@extend %t-s;

	strong{
		font-weight:normal;
	}
}

.button-next-month,
.button-previous-month {
	position: relative;
	display: block;
	width: 20px;
	height: 30px;
	padding: 0;
	overflow: hidden;
	text-indent: 20px; // hide text using text-indent trick, using width value (it's enough)
	white-space: nowrap;
	cursor: pointer;
	background-color: transparent;
	background-repeat: no-repeat;
	background-position: center center;
	background-size: 75% 75%;
	border: 0;
	outline: none;
	opacity: .5;

	*position: absolute;
	*top: 0;

	&:hover {
		opacity: 1;
	}

	&.is-disabled {
		cursor: default;
		opacity: .2;
	}
}

.button-previous-month {
	@include float(left);

	@include ltr {
		background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAeCAYAAAAsEj5rAAAAUklEQVR42u3VMQoAIBADQf8Pgj+OD9hG2CtONJB2ymQkKe0HbwAP0xucDiQWARITIDEBEnMgMQ8S8+AqBIl6kKgHiXqQqAeJepBo/z38J/U0uAHlaBkBl9I4GwAAAABJRU5ErkJggg==');
	}
	@include rtl {
		background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAeCAYAAAAsEj5rAAAAU0lEQVR42u3VOwoAMAgE0dwfAnNjU26bYkBCFGwfiL9VVWoO+BJ4Gf3gtsEKKoFBNTCoCAYVwaAiGNQGMUHMkjGbgjk2mIONuXo0nC8XnCf1JXgArVIZAQh5TKYAAAAASUVORK5CYII=');
	}
}

.button-next-month {
	@include float(right);

	@include ltr {
		background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAeCAYAAAAsEj5rAAAAU0lEQVR42u3VOwoAMAgE0dwfAnNjU26bYkBCFGwfiL9VVWoO+BJ4Gf3gtsEKKoFBNTCoCAYVwaAiGNQGMUHMkjGbgjk2mIONuXo0nC8XnCf1JXgArVIZAQh5TKYAAAAASUVORK5CYII=');
	}
	@include rtl {
		background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAeCAYAAAAsEj5rAAAAUklEQVR42u3VMQoAIBADQf8Pgj+OD9hG2CtONJB2ymQkKe0HbwAP0xucDiQWARITIDEBEnMgMQ8S8+AqBIl6kKgHiXqQqAeJepBo/z38J/U0uAHlaBkBl9I4GwAAAABJRU5ErkJggg==');
	}
}
