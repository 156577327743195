.timeline-section {
	&.with-margin {
		margin-top:40px;
	}
	
    .timeline-list-wrapprer {
        max-width: 100px;
        width: 100%;
        position: absolute;
        top: 0;
        @include desktop {
            position: relative;
        }
        
    }
	
    .timeline-list {
        display: block;
        width: 100%;
        position: relative;
        display: flex;
        margin-left: 0;
		
        .open-button {
            cursor: pointer;
			
            &.activeLine {
                text-decoration: underline;
            }
			
            .timeline-year {
				@extend %font-numeric;
				
                height: fit-content;
                display: flex;
                align-items: center;
                gap: 10px;
                margin: 10px auto;
                color: $t-gold;
                position: relative;
                font-size: 30px;
                line-height: 30px;
            }
            .title {
                @include padding-left(20px);
            }
        }
        
    }
	
    .timeline-wrapper {
        display: none;
		
        &.active {
            display: block;
        }
		
        .detail-wrapper {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            gap: 20px;
            top: 0;
            @include right(0px);
            height: 100%;
            width: 100%;
            @include padding-left(90px);
            @include padding-right(20px);
			
            @include desktop {
                position: absolute;
                flex-direction: row;
                max-width: unset;
                padding: 0 20px;
            }
            .head-detail {
                @include desktop {
                    @include padding-left(100px);
					
					height:100%;
					overflow:auto;
                }
            }
			
            h3 {
                margin: 0;
                font-size: 5em;
                line-height: 1;
                padding-bottom: 20px;
                @include desktop {
                    padding-bottom: 30px;
                }  
            }
            .detail {
                ul {
                    @include padding-left(16px);
                }
            }
        }
		
        img {
            width: 100%;
            @include desktop {
                object-fit: cover;
                width: 50%;
                height: 100%;
            }
        }
    }

    .slick-prev, .slick-next {
        position: relative !important;    
        transform: rotate(90deg);
        @include left(8px !important);
        @include right(0px !important);
        @include rtl {
            transform: rotate(-90deg);
        }
    }
	
    .slick-disabled {
        display: none !important;
    }
}



