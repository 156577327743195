// content-carousel styles

.content-carousel {
	.carousel-list {
        padding: 0;
		margin-top: unset;

        .slick-list {
            &:before,
            &:after {
                display: none;
            }
        }
    }
	
	.slick-track {
		background-color: $f-grey-faint;
	}

    .carousel-slide {
		&-accordian-title {
			background: $f-gold;
			color: white;
			margin-bottom: 0px;
			padding: 10px 20px;

			&:before {
				display: inline-block;
				font-family: 'icons' !important;
				content: $glyph-chevron-down;
				@include margin-right(5px);
			}
			&.is-active {
				&:hover:before,
				&:focus:before,
				&:before {
					display: inline-block;
					font-family: 'icons' !important;
					content: $glyph-chevron-up;
				}
			}
			@include desktop {
				display:none;
			}
		}
		
		&-accordian-content {
			display: flex;
			padding: 0;
			flex-direction:column;
			
			@include desktop {
				flex-direction:row;
				align-items:center;
				align-content:center;
			}
		}

        &-thumb {
            width: 100%;
            height: 220px;
            border-radius: 0;
            background-size: cover;
			background-position: center center;
            min-height: 400px;
			
			@include tablet {
				height: 400px;
			}
			
			@include desktop {
				width: 50%;
				height: auto;
			}
			
			@include lgDesktop {
				width: 60%;
				min-height: 400px;
			}
        }

        &-content {
            width: 100%;
            background-color: $f-grey-faint;
            padding: 5%;
            text-align: center;
            align-items: center;
            display: flex;
			flex-direction:column;
			
			@include desktop {
				width: 50%;
				padding: 50px 50px;
			}
			
			@include lgDesktop {
				width: 40%;
			}

			&.invert {
				.carousel-slide-text, .carousel-slide-title, .carousel-slide-subtitle{
					color:white;
				}
				.carousel-slide-cta .btn{
					color:$f-gold !important;
					@extend .btn--white;

				}
			}
        }

        &-content-wrapper {
            width: 100%;
        }

        &-title {
            font-family: $font-oswald;
            color: $f-grey-dark;
            text-transform: uppercase;
            font-size: 1em;
            font-weight: 500;
            letter-spacing: 2px;
            color: $t-standard;
            font-weight: 500;

            [lang="en"] & {
                letter-spacing: 2px;
            }

			&-image {
				display: flex;
				justify-content: center;
				margin:0 0 30px;

				img {
					max-height: calc(max(2em, 20vh));
					max-width: calc(min(75%, calc(75vw / 2)));
				}
			}
        }
		
		&-subtitle {
			@extend %font-sub-title;
		}

		&-text {
			margin-bottom: 20px;
		}

        &-cta {
            .btn {
                padding: 10px 20px;
                text-transform: uppercase;

                [lang="en"] & {
                    letter-spacing: 2px;
                }
            }

            .select {
                height: 44px;
                width: 100%;
                background-color: $f-gold;
                color: white;
                border: 0;
                padding: 10px;

                option {
                    background-color: white;
                    color: #222;
                }
				
				@include tablet {
					width: 49%;
				}
            }
        }
    }

	.slick-anantara-pagination {
		.slick-dots {
			@extend %font-navigation;
			
			display: block;
			width: 100%;
			padding-bottom: 15px;
			margin: 0;
			list-style: none;
			text-align: center;
			position: unset;

			li {
				padding: 15px 5px 0;
				display: inline-block;
				width: unset;
				height:auto;
				
				div {
					cursor: pointer;
					display: flex;
					flex-direction: column;
					align-items: center;
				}

				span {
					text-align: center;
					display: block;
					padding-top: 5px;
					font-size: 1.1em;
					font-weight: 500;
					color: $t-standard;

					[lang="en"] & {
						letter-spacing: 2px;
					}
		
					.section--gold &, .section--darker-grey & {
						color:#FFF;
					}
				}
				button {
					font-size: 0;
					line-height: 0;
					display: block;
					min-width: 80px;
					height: 4px;
					padding: 5px;
					cursor: pointer;
					color: transparent;
					border: 0;
					outline: none;
					border-radius: 2px;
					padding: 0;
					margin: 0 5px;
					background-color: $f-bm-border--primary;
					
					&:before {
						content: '';
						text-align: center;
						display: none;
					}
				}
				
				&.slick-active button {
					background-color: $f-gold;
				}
			}
			
			@include tablet {
				padding-bottom: 0;
				
				li {
					div {
						margin: 0;
					}
				}
			}
		}
	}

	&.is--swapped {
		.carousel-slide-accordian-content {
			@include desktop{
				flex-direction:row-reverse;
			}
			@include lgDesktop{
				flex-direction:row-reverse;
			}
		}
	}

	@include desktop {
		&.reverse {
			.slick-anantara-pagination {
				display: flex;
				flex-direction: column-reverse;

				.pager__item {
					flex-direction: column-reverse;
				}
			}
		}
	}
	
}