

.alert {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    background: white;
    padding: 5px 20px;
    margin: 0 0 15px 0;
    border: 1px solid;
    @include border-left(5px solid);

    &:before {
        @include padding-right(10px);
        content: $glyph-warning;
        @extend %icon-font-glyph;
        font-size: 1.5em;
    }

    &--warning {
        border-color: $t-warning;

        &:before {
            color: $t-warning;
        }
    }

    &--error {
        border-color: $t-error;

        &:before {
            color: $t-error;
        }
    }
}
