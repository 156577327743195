.banner-search {
	position: absolute;
	left: 50%;
	top:50%;
	z-index: 5;
	width:80%;
	transform: translate(-50%, -50%);
	
	section:first-child.section--center-content & {
		@include desktop {
			padding: 150px 0 0 0;
		}

		.horizon-header & {
			@include desktop {
				padding: 0;
			}
		}
	}
	
	@include desktop {
		max-width: 800px;
	}
	
	@include tablet {
		width:90%;
		max-width: 800px;
	}
	
	&__title {
		h3 {
			margin-bottom:20px;
			
			@include mobile {
				font-size: 1.5em;
			}
			
			&.as-image {
				margin-bottom:0;
			}
		}
		
		img {
			max-width:100%;
		}
	}
	
	&__content {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: stretch;
		margin-bottom:10px;
		
		@include tablet {
			flex-direction: row;
		}
		
		@include desktop {
			flex-direction: row;
		}
		
		.select-wrapper {
			width: 100%;
			background-color: rgba(255,255,255,.8);
			
			&:after {
				top:50%;
				right:15px;
			}
		}
		
		select {
			width: 100%;
			text-align:left;
			color: #a59451;
			text-transform: none;
			padding: 15px 40px 15px 10px;
			background: transparent;
			border: 0;
			border-radius: 0;
			font-weight: 100;
			-webkit-appearance: none;
			max-width:none;
		}
		
		button {
			width:auto;
			margin-bottom: 0;
		}
	}
	
	&__footer {
	}

	&:not(.meeting-footer *, .with-notice *) {
		.is-scrolled & {
			@include desktop {
				// top: calc(50% + 152px);
			}
		}
	}
}