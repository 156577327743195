// content-block styles

.content-block {

    padding: 35px 10px;

    .container--full & {
        padding: 35px 30px;
    }

    &--swapped {
        @include desktop {
            .content-block__text {
                width: (6.5/12) * 100%;
                @include float(right);
            }
            .content-block__media {
                width: (5/12) * 100%;
                @include float(right);
            }
        }
    }

    &__text {
        .nano-content {
            position: relative;
        }

        h2:first-child {
            @extend %t-l;
            margin-top: 0;
            //word-break: break-all;
        }

        .t-l{
            word-break: break-word;
        }

        h3 {
            margin-bottom: 0;
            @extend %t-s;
        }

        p {
            margin-top: 0;
            @extend %t-c-m;
        }
    }

    &__text .nano-content, &__covered {
        @include desktop {
            @include padding(60px, 35px, 60px, 0);
        }
    }

    &__covered {
        display: none;
        opacity: 0;

        transform: translateY(-25px);
        transition: transform $slow $easeOutExpo, opacity ($slow * 2) $easeOutExpo;
        will-change: transform;

        @include desktop {
            display: block;
            padding-top: 110px;
            @include padding-left(0);
        }

        .show-more & {
            opacity: 1;
            transform: translateY(0);
        }
    }

    &__price {
        margin-bottom: 1em;
    }
    &__ctas {
        margin: 1em 0;
    }

    &__media {
        transform: translateX(bi-app-reverse-percentage(0));
        transition: transform $slow $easeOutExpo;
        will-change: transform;

        .show-more & {
            transform: translateX(bi-app-reverse-percentage(40vw));
        }

        @include tablet {
            padding-top: 1em;
        }
    }

    &__list {
        @extend %clearfix;
        list-style-type: none;
        padding: 0;
        margin: 0;
    }

    &__item {
        @extend %clearfix;
        position: relative;
        @include float(left);
        width: 45%;
        @include margin-right(5%);
        @include padding(1em, 1em, 1em, 3em);
        line-height: 1.2;

        @include desktop {
            @include padding-left(3em);
        }

        > span {
            position: absolute;
            color: $t-gold;
            font-size: 2em;
            vertical-align: middle;
            @include margin-left(-1.5em);

            &:before {
                @include padding-right(1em);
            }
        }
    }

    &--fullbleed {
        $text-columns: 5;

        position: static;
        @include left(0);

        @include desktop {
            position: absolute;
            @include left(($text-columns/12) * 100%);
        }


        .content-block--swapped & {
            @include left(auto);
            @include right((7/12) * 100%);
        }

        .map-overlay {

            height: 450px;
            background: none;

            @include no-desktop {
                position: static;
            }

            @include desktop {
                width: 100%;
                height: 100vh;
                background: rgba(0,0,0,.8);
                min-height: 700px;
            }

            & iframe {
                width: 100%;
                height: 100%;

                &.active {
                    pointer-events: none;
                }
            }
        }

    }

    &__image {
        @include aspect-ratio(2.35, 1);

        height: 420px;

        img {
            width: 100%;
            height: 100%;
            max-width: 100%;
            object-fit: cover;
            object-position: center 60%;
        }
    }

    @include desktop {
        padding: 0;
        height: 608px;  // Request from client #72634
        overflow: hidden;

        .container--full & {
            padding: 0 20px;
        }

        &__text,
        &__media {
            height: 608px  // Request from client #72634
        }

        &__text {
            width: (5/12) * 100%;
            @include float(left);

            .nano-content {
                position: absolute;
            }
        }
        &__media {
            width: (7/12) * 100%;
            @include float(left);
        }
    }

    @include tablet {
        padding: 35px;

        .container--full & {
            padding: 35px 40px;
        }

        &__text,
        &__media {
            float: none;
            width: 100%;
            margin: 0;
        }

        .rte-block {
            width: 49%;
            display: inline-block;
        }
    }
}

.has-more {
    &__trigger {
        position: absolute;
        bottom: 2em;
        @include left(-56px);
        z-index: 10;
        width: 56px;
        height: 56px;
        padding-top: 15px;
        background-color: $f-gold-header;
        font-size: 1.8em;
        font-weight: 400;
        line-height: 1;
        text-align: center;
        color: #fff;
    }
}

.icon-plus-toggle {
    position: absolute;
    top: 0;
    bottom: 0;
    @include left(0);
    @include right(0);
    display: block;
    height: 30px;
    width: 30px;
    margin: auto;

    &:before,
    &:after {

        content: '';
        position: absolute;
        top: 0;
        bottom: 0;
        @include left(0);
        @include right(0);
        width: 30px;
        height: 8px;
        margin: auto;
        background-color: #fff;
        transition: transform .25s ease-in-out, all .25s;
    }

    &:after {
        transform: rotate(90deg);
    }

    .show-more & {
        &:before {
            transform: translateY(8px) rotate(225deg);
        }

        &:after {
            transform: translateY(-8px) rotate(315deg);
        }
    }
}


