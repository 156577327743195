/*Video Gallery Item Style*/

.video-gallery-item {
    width:100%;
    // margin-bottom:15px;
    flex: 1 1 50%;
    position: relative;
    border: 10px solid transparent;

    @include mobile {
        flex: 1;
    }

    .video__container {
        overflow: hidden;
        position: relative;

        &::after {
            padding-top: 56.25%;
            display: block;
            content: '';
        }
    }

    .video__image, video {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }

    video{
        display:none;
        z-index:4;
        background-color: #000;
    }

    .video__image{
        z-index:2;
        background-size:cover;
        background-position: center;
        background-repeat:no-repeat;
        // position: relative;

        &:after{
            content:'';
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            background-color: #000;
            z-index: 0;
            -moz-opacity: .75;
            opacity: .75;
            background: rgba(0,0,0,0);
            background: -webkit-linear-gradient(top,rgba(0,0,0,0) 0,#000 100%);
            background: linear-gradient(to bottom,rgba(0,0,0,0) 0,#000 100%);
            transform-origin: center bottom;
            width:100%;
            height:120px;
        }

        img {
            width: 100%;
        }
    }

    .video__content{
        z-index:3;
        position: absolute;
        text-align: center;
        width:100%;
        height:100%;

        h5{
            bottom:10px;
            font-size: 1.2em;
            color:#fff;
            width: 100%;
            padding: 0 20px;
            position: absolute;

            @include tablet {
                margin: 0;
            }

            @include mobile {
                display: none;
            }
        }
    }

    .video__playBtn{
        font-size: 2.2em;
        text-align: center;
        position: absolute;
        width: 70px;
        height: 70px;
        background: white;
        border-radius: 50%;
        @include padding(15px, 0, 15px, 8px);
        opacity: .8;
        border:0;
        top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);

        &:hover {opacity: 1;}
        &:focus {opacity: 1;}

        &:before {
            content: '';
			display: block;
			position: absolute;
			left: calc(50% + 5px);
			top: 50%;
			transform: translate(-50%, -50%);
			border-top: 20px solid transparent;
			border-bottom: 20px solid transparent;

			border-left: 30px solid $f-gold;
        }
    }

    .video__mobileContent {
        padding-bottom: 10px;

        h5 {
            margin: 0;
            text-align: center;
            font-size: 1em;
            padding: 0 20px;
        }
    }

    &.active{
        .video__content, 
        .video__image{
            opacity:0;
            transition: all .2s ease-in-out;
        }

        video{
            display:block;
        }
    }
}
