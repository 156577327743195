// destinations styles

.property-list {

	h3 {
		margin-top: 0;
	}

	ul {
		width: 100%;
		padding: 0;
		list-style: none;
	}
}

.destinations {
	position: absolute;
	top:0;
	z-index: 20;
	display: none;
	width: 100%;
	height: $mask-height-desktop;
	padding: 30px 50px;
	@include text-align(left);
	// background: $f-grey-dark;
	background: $destination-overlay-bg;
	@include lgDesktop {
		font-size: 1em;
	}

	.with-notice & {
		height: $mask-height-desktop-with-notice;
	}

	.horizon-header & {
		height: calc(100vh - #{$horizon-header-toolbar-height} - #{$horizon-booking-mask-height});
	}
	
	.horizon-header.hide-booking-mask & {
		height: calc(100vh - #{$horizon-header-toolbar-height});
	}

	.horizon-header.with-notice & {
		height: calc(100vh - #{$notice-bar-height} - #{$horizon-header-toolbar-height} - #{$horizon-booking-mask-height});
	}

	.horizon-header.hide-booking-mask.with-notice & {
		height: calc(100vh - #{$notice-bar-height} - #{$horizon-header-toolbar-height});
	}


	.header-primary.is-scrolled & {
		position: relative;
		height: $mask-height-desktop--scrolled;
		background: $destination-overlay-bg;
	}

	> .nano-content {
		padding: 40px;
/* 		@include margin-right(-17px); */
		-ms-overflow-style: scrollbar;

		html[lang="en"] &{
			padding: 20px 40px;
		}
	}

	&__search {
		position: relative;
		display: inline-block;
		margin-bottom: 10px;
		clear: both;
	}

	&__link {
		&:after {
			position: absolute;
			@include right(0);
			bottom: 10px;
			display: inline-block;
			@include margin-left(-40px);
			font-family: 'icons';
			font-size: 2.5em;
			line-height: 1;
			color: $t-gold;
			content: $glyph-search;
		}
	}

	.tt-input {
		text-overflow: ellipsis;
		word-wrap: normal;
	}

	.tt-menu {
		margin: 0;
	}

	ul {
		width: 100%;
		padding: 0;
		list-style: none;
	}

	li {
		display: block;
		width: 100%;
		border-top: 1px solid $f-grey-dark-border;
		@include desktop {
			margin-bottom: 5px;
			border: 0;
		}
	}

	a {
		display: block;
		// color: #fff;
		color: $t-menu-default-color;
		text-decoration: none;
		transition: color $quick, transform $medium;
	}

	.js-destinations__close {
		position: absolute;
		top: 0;
		@include right(17px);
		width: 50px;
		height: 50px;
		background: $f-header-bg-dark;

		@include desktop {
			background-color: $f-grey-dark;
		}

		&:before,
		&:after {
			position: absolute;
			top: 15px;
			@include left(25px);
			width: 2px;
			height: 20px;
			content: ' ';
			background-color: $f-grey-light;
		}

		&:before {
			transform: rotate(45deg);
		}

		&:after {
			transform: rotate(-45deg);
		}
	}
}


.destinations__menu > ul {
	display: block;
	columns: 1 auto;
	column-gap: 5vw;

	@include tablet{
		columns: 2 auto;
    }

	@include desktop{
		columns: 5 auto;
    }

	html[lang="en"] &{
		@include desktop{
			columns: 4 auto;
		}
	}

	a {
		display: block;
	}

	// Country level
	> li {
		padding-bottom: 1em;
		margin-bottom: 0;
		-webkit-column-break-inside: avoid;
		page-break-inside: avoid;
		break-inside: avoid;

		//fallback columns and sub items
		&.is-fallback{
			break-before: column;
			break-inside: auto;
		}

		> a {
			@extend %t-c-m;
			@extend %t-upper;
			font-weight: 700;
			color: $t-menu-country-color;
			
			.destination-explorer .full-bg--has-overlay & {
				color: $t-gold;
			} 

			&.view-all {
				@extend %t-s;
				@extend %btn;
				color: #fff!important;
				background-color: $t-gold;

				&:hover {
					background-color: $f-gold-dark;
				}
			}

			&:hover, &:focus {
				color: darken($t-menu-country-color, 7.5%);
			}
		}

		//Fallback title
		.fallback-title{
			@extend %t-c-m;
			font-weight: 700;
			color: $t-gold;
		}



		&.has-cities > ul {
			margin: 5px 0;
			// City level
			> li {
				margin-bottom: 5px;
				> a {
					@extend %t-c-s;
					@extend %t-orig-case;
					font-weight: 700;
					line-height: 1.5;
					&:hover, &:focus {
						color: $t-standard-faint;
					}
				}
				// Resort level
				> ul {
					margin-top: 5px;
					// @include margin-left(20px);
					padding:0;

					> li {
						> a {
							@extend %t-c-s;
							@extend %t-orig-case;
							&:hover, &:focus {
								color: $t-standard-faint;
							}
						}

						// Mini-Resort level
						> ul {
							margin-top: 5px;
							@include margin-left(20px);
							padding:0;

							> li {
								> a {
									@extend %t-c-s;
									@extend %t-orig-case;
									&:hover, &:focus {
										color: $t-standard-faint;
									}
								}
							}
						}
					}
				}
			}
		}

		&.has-cities.is-fallback > ul {
			// Country level
			> li.has-cities {				
				// City Level
				> ul {
					> li {
						> a {
							font-weight: bold;
						}
					}
				}
			}
		}


		// Resort level
		> ul {
			margin-top: 5px;
			// @include margin-left(20px);
			padding:0;


			> li {
				> a {
					@extend %t-c-s;
					@extend %t-orig-case;
					&:hover, &:focus {
						color: $t-standard-faint;
					}
				}

				// Mini-Resort level
				> ul {
					margin-top: 5px;
					@include margin-left(20px);
					padding:0;

					> li {
						> a {
							@extend %t-c-s;
							@extend %t-orig-case;
							&:hover, &:focus {
								color: $t-standard-faint;
							}
						}
					}
				}
			}
		}
	}
}


.destinations__menu--full-names > ul {
	// Country level
	> li {

		> ul {

			// City level
			> li {

				> a {
					font-weight: 400;
				}
			}
		}
	}
}


