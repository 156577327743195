// cta-banner styles
.cta-banner {
    display: flex;
    flex-direction: column;
    justify-content: middle;
    align-items: center;
    
    @include desktop {
        flex-direction: row;
        align-items: center;
        justify-content: space-around;
    }

    &__logos {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
    }

    &__logo {
        display: inline-block;
        vertical-align: middle;
        max-width: 125px;
        max-height: 30px;
        
        @include mobile {
            max-width: 40%;
            max-height: 100%;
        }

        img {
            width: 100%;
        }
    }
    
    &__discoveryLogo {
        @include margin-left(20px);
        @include padding(10px, 0, 10px, 20px);
        @include border-left(1px solid $t-standard);
        display: inline-block;
        vertical-align: middle;
        max-width: 125px;

        @include mobile {
            max-width: 40%;
        }

        svg {
            fill: $t-standard;
            width: 100%;
        }
    }

    &__image {
        max-width: 30%;

        @include no-desktop {
            display: none;
        }

        img {
            width: 100%;
            display: block;
        }
    }

    &__content {
        padding-bottom: 40px;
    }

    &__title {
        font-weight: 700;
        text-align: center;
		margin: 40px 0;
		
		h2, h3 {
			margin:0;
		}
    }

    &__cta {
        text-align: center;
    }
	
	.full-bleed-cta & {
		.cta-banner {
			&__image {
				display:block;
				width:100%;
				position: relative;
				height: 100%;
				overflow: hidden;
				min-width: 100%;
				min-height: 320px;
				
				@include desktop {
					width:50%;
					min-width: 50%;
					max-width:50%;
					min-height: 500px;
				}
				
				img {
					position: absolute;
					z-index: 0;
					width: 100%;
					height: 100%;
					font-family: "object-fit:cover";
					object-fit: cover;
				}
			}
			&__content {
				width:100%;
				
				@include desktop {
					width:50%;
				}
				
				.cta-banner__text {
					margin:0 40px;
					text-align:center;
					
					@include desktop {
						margin:0 120px;
					}
				}
			}
		}
	}
}
