.mini_gallery_grid {
    flex-direction: column;
    display: flex;
    gap: 1px;
    margin-bottom: 1.5em;
    @include tablet {
        gap: 1.5em;
    }
    @include desktop {
        flex-direction: unset;
        gap: 1.5em;
        &.flipped {
            flex-direction: row-reverse;
        }
    }   
    &__box {
        order: 2;
        width: 100%;
        display: flex;
        flex-direction: row;
        gap: 1px;
        @include tablet {
            gap: 1.5em;
        }
        @include desktop {
            flex-direction: column;
            order: unset;
            width: 33%;
            gap: 1.5em;
        }
        a, img {
            display: block;
            height: 130px !important;
            @include tablet {
                height: 250px !important;
            }
            @include desktop {
                height: 243px !important;
            }
        }
        &.box-large {
            order: 1;
            width: 100%;
            flex-direction: column;
            @include tablet {
                display: block;
            }
            @include desktop {
                order: unset;
                width: calc(100% - (33% + 1.5em));
            }
            .mini_gallery_grid__box_item {
                @include tablet {
                    width: unset;
                }
            }
            a, img {
                height: 208px !important;
                @include tablet {
                    height: 300px !important;
                }
                @include desktop {
                    height: calc(486px + 1.5em) !important;
                }      
            }
        }
        &_item {
            position: relative;
            overflow: hidden;
            @include tablet {
                width: 50%;
            }

            .video_playBtn{
                display: block;
                width: 75px;
                height: 75px;
                margin:auto;
                text-align: center;
                background-color: rgba(0, 0, 0, .3);
                border-radius: 50%;
                position: absolute;
                top:50%;
                left:50%;
                transform: translate(-50%,-50%);
        
                &:before {
                    position: absolute;
                    top: 0;
                    bottom: 0;
                    left:50%;
                    transform: translateX(-35%);
                    width: 0;
                    height: 0;
                    margin: auto;
                    content: '';
                    border-color: transparent transparent transparent #fff;
                    border-style: solid;
                    border-width: 20px 0 20px 32px;
        
                }
            }
        
            .mini_gallery_grid__image_wrapper {
                img {
                    width: 100% !important;
                    height: 100%;
                    object-fit: cover;
                    transform: scale(1);
                    transition: transform 1s;
                    will-change: transform;
                }
                .mini_gallery_grid__label {
                    position: absolute;
                    bottom: 16px;
                    @include left(28px);
                    @include right(20px);
                    z-index: 5;
                    display: none;
                    pointer-events: none;
                    transition: transform 1s;
                    will-change: transform;
                    @include tablet {
                        display: block;
                    }
                    @include desktop {
                        display: block;
                        transform: translateY(200%);
                    }
                }
                &::before {
                    content: "";
                    position: absolute;
                    top: 0;
                    @include right(0);
                    bottom: -100%;
                    @include left(0);
                    z-index: 1;
                    background: linear-gradient(to top, rgba(0,0,0,.9) 0%,rgba(0,0,0,.65) 25%,rgba(0,0,0,0) 55%,rgba(255,255,255,0) 100%);
                    transition: transform $long;
                    will-change: transform;
                    @include tablet {
                        transform: translateY(-25%);
                    }
                    @include desktop {
                        transform: translateY(-5%);
                    }
                }
                &:hover:not(.disabled *) {
                    img {
                        transform: scale(1.05);
                    }
                    .mini_gallery_grid__label {
                        transform: translateY(0);
                    }
                    &::before {
                        transform: translateY(-25%);
                    }
                }
            }
        }
    }

    .category-listing__wrapper & {
        &__box {
            gap: 5px;
            @include desktop {
                gap: 1.5em;
            }
        }
        gap: 5px;
        @include desktop {
            gap: 1.5em;
        }
    }
}