// fullbleed-image styles
.full-bg {
	position: relative;
	height: 100%;
	overflow: hidden;		// removed as it is stilling the filter list being visible on accommodation listing page
	@extend %clearfix;

	&--has-overlay {
		.btn {
			text-shadow: none;
		}

		&:before {
			content: "";
			position: absolute;
			top: 0;
			bottom: 0;
			@include left(0);
			@include right(0);
			z-index: 1;
			display: block;
			pointer-events: none;

			background-color: $f-black-overlay-light;
		}
	}

	&__image {
		position: absolute;
		z-index: 0;
		width: 100%;
		height: 100%;

		object-fit: cover;
	}

	&__link {
		display: block;
		top:0;
		left:0;
		right:0;
		bottom:0;
		position: absolute;
		z-index: 1;
		
		&.with-gallery {
			top:10%;
			left:10%;
			right:10%;
			bottom:10%;
			
			@include desktop {
				top: 10%;
				left: 5%;
				right: 5%;
				bottom: 5%;
			}
		}
	}

	&__caption {
		@extend %font-header;
		
		position: absolute;
		text-align: center;
		color: white;
		bottom: 20px;
		left: 50%;
		transform: translateX(-50%);
		z-index: 15;
		padding: 1em;
		@extend %t-c-s;
		width:100%;

		@include desktop {
			max-width: 60%;
		}
		
		a {
			text-decoration:none;
		}
		
		.btn {
			margin-top:5px;
		}
		
		&.with-countdown {
			bottom: 65px;
		}
	}

	&__overlay {
		width: 100vw;
		height: 50vh;
		position: absolute;
		left: 0;
		bottom: 0;
		z-index: 10;
		display: block;
		opacity: .85;
		background: rgba(0,0,0,0);
		background: linear-gradient(to bottom,rgba(0,0,0,0) 50%,#000 100%);
	}

	&__play {
		position: absolute !important;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
	}
	.banner-title {
		@extend %font-header;
	}
	
	.banner-title,
	.section-header {
		z-index: 15!important;
		
		img {
			max-width:100%;
		}
	}

	.section-header {
		&__text {
			color: #fff;
		}
	}
}

.awards {
	.col-6 {
		@include tablet {
			width: 70%;
		}
		@include mobile {
			width: 90%;
		}
	}

	.section-header {
		&__title {
			@include text-align(left);
		}
		&__text {
			@include text-align(left);
			@include margin-left(0);
			@include margin-right(0);
			@include tablet {
				max-width: 90%;
			}
		}
	}
}

.awards-list {
	padding: 0;
	@include margin(0, 0, 125px, 0);
	list-style-type: none;
	@include mobile {
		margin-bottom: 50px;
	}

	&--small {
		margin: 15px 0;
	}

	&__item {
		display: inline-block;
		@include margin-right(25px);
		vertical-align: middle;

		img {
			.awards-list--small & {
				max-width: 100px;
			}
		}
		@include tablet {
			@include margin-right(0);
		}
		@include mobile {
			max-width: 30%;

			img {
				max-width: 100%;
			}
		}
	}
}

.awards-rich-text {
	> ul {
		padding: 0;
		margin: 15px 0;
		list-style-type: none;

		> li {
			display: inline-block;
			@include margin-right(25px);
			vertical-align: middle;

			img {
				max-width: 100px;
				min-height: 70px;
			}
			@include mobile {
				max-width: 30%;
				img {
					max-width: 100%;
				}
			}
		}
	}
}

.section--fullheight{

	// Journey explorer overlay
	.overlay {
		position: absolute;
		top: 0;
		z-index: 3;
		display: none;
		width: 100%;
		height: 100%;
		background: #000;
		opacity: 1;
	}
}

.section--halfheight{
	.full-bg__overlay {
		height: 80vh;
		top: 0;
	}
}

// Campaign VDO Banner

.campaign--banner {
	.overlay {
		position: absolute;
		top: 0;
		z-index: 10;
		display: none;
		width: 100%;
		height: 100%;
		background: rgba(0,0,0,0.4);
		opacity: 1;
	}

	.content {
		position: absolute;
		top: 50%;
		right: 0;
		left: 0;
		z-index: 55;
		text-align: center;
		transform: translateY(-50%);
		margin:0 30px;
		padding: 0;

		> .t-white{
			margin-bottom:10px;
		}

		.main-title{
			@include mobile{
				font-size: 1.8em;
			}
		}
		
		@include desktop {
			right: 100px;
			left: 100px;
			margin:0 auto;
			padding: 0 20px;
		}
	}
}

.news-container {
	> div {
		min-height: 510px;
		@include mobile {
			min-height: 300px;
		}
	}

	.section-header {
		max-width: 1140px;
		@include margin-right(auto);
		@include margin-left(auto);
	}

	.section-header__text {
		margin-bottom: 0;
		@include mobile {
			text-transform: none;
		}
	}

	.section-footer {
		padding-top: 0;
	}

	&--feature {
		> div {
			min-height: 100vh;
			@include mobile {
				min-height: 100vh;
			}
		}

		.section-header {
			margin-top: 30vh;
		}
	}
}

.centered-content {
	.full-bg {
		width: 100%;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
	}

	&__container {
		position: relative;
		z-index: 1;
		max-width: 80vw;

		@include desktop {
			max-width: 60vw;
		}
	}

	&--boxed {
		background-color: rgba(0, 0, 0, 0.8);
		padding: 0 10px;
		
		@include desktop {
			padding: 0 80px;
		}

		.section-footer {
			padding-bottom: 40px;
		}
	}

	h3 {
		margin-bottom: 0;

		&.as-image {
			img {
				max-width: 100%;
			}
		} 
	} 
} 