// compare-list styles

.add-to-compare {
	@include mobile {
		display: none;

		~ .btn {
			width: 100% !important;
			@include margin-left(0, !important);
			@include margin-right(0, !important);
			max-width: none;
		}
	}

}

.compare-list {
	position: fixed;
	top: 55%;
	@include right(-250px);
	z-index: 31;

	width: 200px;

	background-color: rgba(54,54,54, .8);

	transform: translateX(bi-app-reverse-percentage(0)) translateY(-45%);
	transition: transform $long $easeOutExpo;
	will-change: transform;

	@include mobile {
		display: none;
	}

	&.is-visible {
		transform: translateX(bi-app-reverse-percentage(-250px)) translateY(-45%);
	}

	.btn {
		margin-bottom: 0;
		padding: 15px 25px;
	}

	&__list {
		list-style-type: none;
		padding: 0;
		margin: 0;
	}

	&__item {
		@include padding(0, 20px, 10px, 20px);
		margin-bottom: 1em;
		border-bottom: 1px solid #444;

		@include desktop {
			margin-bottom: 0;
			border-bottom: 0;
		}
		@include desktopLong {
			margin-bottom: 1em;
			border-bottom: 1px solid #444;
		}

		transform: scale(0);
		transition: transform $long $easeOutExpo;
		will-change: transform;

		&.is-visible {
			transform: scale(1);
		}

		&:last-child {
			margin-bottom: 0;
			border-bottom: 0;
		}
	}

	&__container {
		position: relative;
	}

	&__close {
		position: absolute;
		top: 0;
		@include right(0);

		width: 28px;
		height: 28px;

		padding-top: 1px;

		background-color: rgba(0,0,0,0.35);
		font-size: 1.5em;
		font-weight: 100;
		line-height: 1;
		text-align: center;
	}

	&__heading {
		padding: 0 20px;
	}

	&__text {

	}

	&__image {
		max-width: 100%;
		width: 100%;
		height: 80px;

		object-fit: cover;
	}

	&__title {
		line-height: 1;
	}

	&__msg {
		display: inline-block;
		@include padding(0, 20px, 8px, 20px);

		&.is-hidden {
			display: none;
		}
	}

}

.disable-compare .add-to-compare {
	@extend .text-cta--disabled
}

.compare-modal {
	position: fixed;
	bottom: -100vh;
	@include left(0);
	z-index: 150;

	width: 100%;

	background-color: #fff;
	text-align: center;

	transform: translateY(0);
	transition: transform $slow $easeOutExpo;
	will-change: transform;

	&.is-visible {
		transform: translateY(-100vh);
	}

	&__wrapper {
		position: relative;
		height: 100vh;
		overflow: auto;

		&:before, &:after {
			content: "";
			position: absolute;
			z-index: 10;
			width: 100%;
			height: 20px;
			display: block;
		}

		// &:before {
		// 	top: 115px;
		// 	box-shadow: inset 0 20px 15px -10px rgba(0, 0, 0, 0.15);
		// }
	}

	&__close {
		position: absolute;
		top: 0;
		@include right(0);
		z-index: 20;

		width: 56px;
		height: 56px;

		padding-top: 1px;

		background-color: rgba(0,0,0,0.5);
		font-size: 3em;
		font-weight: 400;
		line-height: 1;
		text-align: center;
	}

	&__heading {
		position: relative;
		z-index: 10;
		padding: 20px 0;

		box-shadow: $f-minimal-box-shadow;
	}

	&__column-wrapper {
		overflow: hidden;
	}

	&__column {
		@include border-left(1px solid #fff);
		@include border-right(1px solid #fff);
		@include text-align(left);

		/* two items */
		&:first-child:nth-last-child(2),
		&:first-child:nth-last-child(2) ~ & {
		   @extend .col-6;
		   @extend [class*=" col-"];
		}

		/* three items */
		&:first-child:nth-last-child(3),
		&:first-child:nth-last-child(3) ~ & {
			@include tablet {
				width: 33.33% !important;
			}

		    @extend .col-4;
		    @extend [class*=" col-"];

		    .btn {
		    	width: 100%;
		    	margin-bottom: 0.5em;
		    	@include margin-left(0);
		    }
		}

	}

	&__image {
		@include aspect-ratio(1.85,1);
		overflow: hidden;
		box-shadow: 0 5px 15px -5px rgba(0,0,0,.5);

		img {
			width: 100%;
			height: 100%;
			object-fit: cover;
			object-position: center 60%;
		}

		> .content {
			overflow: hidden;

			&:before {
				content: "";
				position: absolute;
				top: 0;
				@include right(0);
				bottom: -40%;
				@include left(0);
				background: linear-gradient(to top, rgba(0,0,0,.9) 0%,rgba(0,0,0,.65) 50%,rgba(0,0,0,0) 70%,rgba(255,255,255,0) 100%);
			}
		}

		&__content {
			position: absolute;
			bottom: 0;

			padding: 20px 40px;
		}
	}

	&__category {

	}

	&__title {

	}

	&__cta {
		background-color: $f-grey-light-border;
		padding: 40px;

		@include mobile {
			@include padding(20px, 20px, 10px, 20px);
		}
	}

	&__price {
		margin-bottom: 1em;
	}

	&__links {

	}

	&__link {

	}

	&__content {
		@include mobile {
			display: none;
		}
		@include padding(40px, 40px, 10px, 40px);
	}

	&__text {

	}

	&__features {
		@include mobile {
			@include padding(0, 20px, 10px, 20px);
		}

		background-color: $f-grey-light-border;
		padding: 40px;
	}

	&__list, .compare-modal__features ul {
		list-style-type: none;
		padding: 0;
		margin: 0;

		@include desktop {
			display: flex;
			flex-direction: row;
			flex-wrap: wrap;
		}

		@include mobile {
			display: block;
			margin-top: 0.5em;
		}
	}

	&__item, .compare-modal__features li {
		position: relative;
		width: 100%;
		padding: .5em 0;
		line-height: 1.2;
		word-wrap: break-word;
		word-break: break-word;
		box-sizing: border-box;
		display: flex;
		justify-content: stretch;

		@include desktop {
			width: 50%;
			padding: 10px 5px;
		}

		> span {
			height: auto;
			color: $t-gold;
			font-size: 2em;

			@media (min-width: 1200px) {
				width: auto;
				height: 150%;
			}

			@include mobile {
				width: auto;
				margin-top: -5px;
			}

			&::before {
				@if $isRTL {
					@include padding-left(1em);
				} @else {
					@include padding-right(1em);
				}
			}
		}

		> p {
			margin: 0;
		}
	}

}
