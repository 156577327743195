// related-stories styles
.related-stories {
	display: flex;
	flex-direction: row;
	align-items: flex-start;
	flex-wrap: wrap;

	a {
		flex: 100%;
		box-sizing: border-box;

		@include desktop {
			flex: 33.33%;
			max-width: 33.33%;
			padding: 0 10px;

			&:first-child,
			&:nth-child(3n+1) {
				@include padding-left(0);
			}
		}
	}

	.item {
		overflow:auto;
		margin-bottom:10px;
		display: flex;
		flex-direction: column;
	}

	.image {
		position: relative;
		overflow: hidden;
		min-height: 150px;
		width: 100%;
		margin-bottom: 5px;

		img {
			position: absolute;
			z-index: 0;
			width: 100%;
			height: 100%;
			font-family: "object-fit:cover";
			object-fit: cover;
		}
	}

	.text {
		overflow:hidden;
		@extend .t-c-s;

		p {
			@extend .t-sm;
			margin:0 0 10px;
		}
	}
}
