/* Required styles; changing these may break the menu */

.slinky-menu {
	overflow: hidden;
	transform: translateZ(0);
	transition: all 300ms ease;
}

.slinky-menu > ul {
	@include left(0);
	position: relative;
	transform: translateZ(0);
	transition: all 300ms ease;
}

.slinky-menu ul,
.slinky-menu li {
	list-style: none;
	margin: 0;
}

.slinky-menu ul {
	width: 100%;
}

.slinky-menu a {
	display: block;
}

.slinky-menu li ul {
	display: none;
	@include left(100%);
	position: absolute;
	top: 0;
}

.slinky-menu .header {
	position: relative;
}

.slinky-menu h2 ~ a.back {
	@include left(0);
	position: absolute;
	top: 0;
}

.slinky-menu h2 {
	font-size: 1em;
	margin: 0;
	text-align: center;
}

/* Default theme */

.slinky-menu .header {
	background: #AAA;
}

.slinky-menu h2 {
	color: #FFF;
	padding: 1em;
}

.slinky-menu a {
	background: #AAA;
	border: none;
	color: #FFF;
	padding: 1em;
}

.slinky-menu a:hover {
	background: #000;
}

.slinky-menu .next:after {
	content: '\276f';
	@include margin-left(1em);
	@include float(right);
}

.slinky-menu .back:before {
	content: '\276e';
	@include margin-right(1em);
	@include float(left);
}
