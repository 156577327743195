// menu styles

.menu {
	&-container {
		position:relative;
		width: 100%;
		max-height: $menu-height;
		overflow-y: auto;
		color: #fff;
		text-align: center;
		transition: padding-top $headerAnimation $headerEasing;

		@include desktop {
			padding-top: 10px;
			overflow: visible;

			&:before {
				content: none;
			}
		}

		.horizon & {
			@include desktop {
				background-color: $f-gold;
				padding-bottom: 10px;
				// transition: padding-top $headerAnimation $headerEasing, transform $headerAnimation $headerEasing;
				top: 0;
				transition: padding-top $headerAnimation $headerEasing, top $headerAnimation $headerEasing;
			}
			
			.is-scrolled & {
				@include no-desktop {
					position: absolute;
					top: calc(100% + 38px);
				}
			}
		}

		.horizon.is-scrolled & {
			&.is-active {
				.with-notice & {
					@include no-desktop {
						// top: -52px;
					}
				}
			}
			@include desktop {
				top: -1000px;
				// transform: translateY(-1000px);
			}
		}

		.account-mobile {
			display: none;
			padding: 11px 30px;
			@include text-align(left);
			background-color: $f-header-bg-dark;

			&__link {
				display: block;
				padding: 7px 0;
				font-weight: 700;
			}
		}

		.is-scrolled & {
			max-height: $menu-height--scrolled;

			@include desktop{
				padding-top: 7px;
			}
		}

		&.is-active {
			.account-mobile {
				display: block;
			}

			&:before {
				position: absolute;
				@include right(0);
				@include left(0);
				top: 0px;
				z-index: 5;
				width: 100%;
				height: 25px;
				content: "";
				box-shadow: inset 0 25px 20px -10px rgba(0,0,0,.3);
			}
		}

		.menu-close {
			width: 100%;
			//Unsure Merge
			height: 25px;
			content: "";
			box-shadow: inset 0 15px 10px -10px rgba(0, 0, 0, 0.3);
			//Unsure Merge			// transition: height $medium;

			&::before,
			&::after {
				position: absolute;
				top: 20px;
				left: 50%;
				transform: translateX(-50%);
				width: 2px;
				height: 20px;
				content: ' ';
				background-color: $f-grey-light;
			}

			&::before {
				transform: rotate(45deg);
			}

			&::after {
				transform: rotate(-45deg);
			}
		}

		&.is-active {
			.menu-close {
				.horizon.is-scrolled & {
					display: block;
					height: 62px;
					background-color: $f-gold;
				}
			}
		}
	}
	
	&-dimmer {
		position:fixed;
		width:100vw;
		height:100vh;
		background:#000;
		opacity: .65;
		display:none;
	}
}

.slinky-menu {
	display: none;
	width: 100%;
	background-color: $f-header-bg-light;

	@include desktop {
		display: block;
		background-color: transparent;
	}

	.brand-home > a {
		color: white;
		&:before {
			$triangle-size: 8px;
			display: block;
			@include float(left);
			width: $triangle-size;
			margin-top: 2px;
			@include margin-right(1em);
			content: '';
			border-top: $triangle-size/3*2 solid transparent;
			@include border-right($triangle-size solid $f-menu-triangle-color);
			border-bottom: $triangle-size/3*2 solid transparent;
		}
	}

	a:hover {
		background: $f-header-bg-light;
		@include desktop {
			background: inherit;
		}
	}

	.is-active & {
		display: block;
	}

	.next.has-link {
		top: 0;
		@include right(0);
		@include float(right);
		height: 51px;
		@include padding-right(25px);
		@include padding-left(10px);
		margin-top: -51px;
		clear: both;
		background: $f-header-bg-light;
		border-bottom: 1px solid $f-header-bg-dark;
		@include border-left(1px solid $f-header-bg-dark);
	}

	.next:after {
		$triangle-size: 10px;
		display: block;
		@include float(right);
		width: $triangle-size;
		@include margin-left(1em);
		content: '';
		border-top: $triangle-size/3*2 solid transparent;
		border-bottom: $triangle-size/3*2 solid transparent;
		@include border-left($triangle-size solid $f-menu-triangle-color);
	}

	.next:not(.has-link):after {
		@include right(15px);
		@include margin-left(-45px);
	}

	.back:before {
		$triangle-size: 6px;
		display: block;
		@include float(left);
		width: $triangle-size;
		margin-top: 2px;
		@include margin-right(1em);
		content: '';
		border-top: $triangle-size/3*2 solid transparent;
		@include border-right($triangle-size solid $f-menu-triangle-color);
		border-bottom: $triangle-size/3*2 solid transparent;
	}

	ul {
		padding: 0;
		margin: 0;

		li {
			transition: padding $headerAnimation $headerEasing;
			@include desktop {
				display: inline-block;
				padding: 0 10px;
				text-shadow: 0px 1px 13px rgba(0, 0, 0, 0.65);

				.is-scrolled & {
					padding: 0 5px;
					text-shadow: none;
				}

				.no-banner &{
					text-shadow: none;
				}
				
				&.active {
					a.menu__item__link {
						border-bottom: 1px solid $f-gold;
					}
				}
			}

			a {
				padding: 18px 30px;
				line-height: (16 / $base) * 1em;
				@include text-align(left);
				background-color: $f-header-bg-light;
				border-bottom: 1px solid $f-header-bg-dark;
				transition: color $quick;
				@include desktop {
					padding: 0;
					font-size: (12 / $base) * 1em;
					font-weight: 600; // Semi-bold
					line-height: (24 / 14) * 1em;
					text-align: center;
					background-color: transparent;
					border-bottom: 0;
				}

				&.has-link {
					@include padding-right(67px);
				}

				&.loyalty{

					&:before {
						@include margin-right(3px);
						content: $glyph-gha;
						@extend %icon-font-glyph;
					}

					.horizon & {
						&:before {
							@include margin-right(0);
							content: '';
						}
					}
				}
			}
		}

		li:first-child {
			> a {
				@include mobile {
					padding-top: 25px;
				}
			}

			.back {
				padding-top: 30px;
				padding-bottom: 17px;
			}
		}

		> li {
			> a {
				&.active, &:hover, &:focus {
					color: $f-grey-light;

					@include desktop {
						.header-primary--light-bg &,.site-container.no-banner & {
							color: lighten($t-standard, 20%);
						}

						.header-primary--light-bg.is-scrolled &,.site-container.no-banner .is-scrolled & {
							color: $t-standard-on-gold;
						}
					}

					.is-scrolled & {
						color: $t-standard-on-gold;
					}
				}
			}

			&.t-gold {
				> a {
					&.active, &:hover, &:focus {
						color: $f-grey-light;
					}
				}
			}
		}
	}

	.menu {
		position: relative;

		&:after {
			content: "";
			display: block;
			width: 100%;
			background: transparent;
			opacity: 0;
		}

		@include desktop {
			&:after {
				content: none;
			}
		}

		&__item {

			@include desktop {
				.header-primary--light-bg &,.site-container.no-banner & {
					color: $t-standard;
					
					&.active {
						a.menu__item__link {
							border-bottom: 1px solid $t-gold;
						}
					}
				}

				.header-primary--light-bg.is-scrolled &,.site-container.no-banner .is-scrolled & {
					color: #fff;
					
					&.active {
						a.menu__item__link {
							border-bottom: 1px solid white;
						}
					}
				}

				.horizon-header .header-primary--light-bg &,
				.horizon-header .site-container.no-banner &,
				.horizon-header .header-primary--light-bg.is-scrolled &,
				.horizon-header .site-container.no-banner .is-scrolled & {
					color: white;
				}

				a.menu__item__link {
					padding-bottom: 1px;
				}
			}

			&:last-child {
				&:after {
					display: block;
					width: 100%;
					height: 0;
					content: '';
					background-color: $f-header-bg-light;
					@include desktop {
						content: none;
					}
				}
			}
		}
	}

	.sub-menu {
		&:after {
			content: "";
			display: block;
			width: 100%;
			background: transparent;
			opacity: 0;
		}
		@include desktop {
			position: absolute;
			display: none !important;
		}

		&__item {

			&.no-children {
				.next.has-link {
					display: none;
				}
			}

			&.t-gold {
				> a {
					color: white;
				}
			}
		}
	}
}

.js-destination {
	$triangle-size: 6px;
	position: relative;
	@include margin-right(1em);
	@include no-desktop {
		@include margin-right(0);
	}
	@include desktop {
		&:hover:after {
			border-top-color: $f-grey-light;

			.header-primary--light-bg &,.site-container.no-banner & {
				border-top-color: lighten($t-standard, 20%);
			}
		}

		.is-scrolled &:hover:after {
			border-top-color: $t-standard-on-gold;

		}
		.is-scrolled.header-primary--light-bg &:hover:after,.site-container.no-banner & {
			border-top-color: $t-standard-on-gold;

		}
	}

	&:after {
		position: absolute;
		top: 20px;
		@include right(-15px);
		display: inline-block;
		width: $triangle-size;
		@include margin-left(1em);
		content: '';
		border-top: $triangle-size solid $f-menu-triangle-color;
		@include border-right($triangle-size/3*2 solid transparent);
		@include border-left($triangle-size/3*2 solid transparent);
		transition: border-color $quick;

		@include desktop {
			top: 3px;

			.header-primary--light-bg &,.site-container.no-banner & {
				border-top-color: $t-standard;
			}

			.header-primary--light-bg.is-scrolled &,.site-container.no-banner .is-scrolled & {
				border-top-color: #fff;
			}
		}

		.account & {
			content: none;
		}
	}
}

.menu-mobile {
	position: absolute;
	@include left(0);
	width: 75px;
	background-color: $f-header-bg;
	@include border-right(1px solid $f-header-bg-faint);
	transition: background-color $medium;
	@include tablet {
		width: 156px;
	}
	&.is-active {
		background-color: $f-header-bg-dark;

		.c-hamburger {
			width: 25px;
			@include tablet {
				width: 35px;
			}
		}
	}
}

.c-hamburger {
	position: relative;
	display: block;
	width: 34px;
	height: 59px;
	padding: 0;
	margin: 0 auto;
	overflow: hidden;
	font-size: 0;
	text-indent: -9999px;
	cursor: pointer;
	border: none;
	border-radius: 0;
	appearance: none;
	box-shadow: none;
	transition: background .3s, width $medium;
	@include tablet {
		width: 56px;
	}

	.account & {
		width: 19px;
		height: 103px;
	}

	.booking-room & {
		width: 15px;
		height: 19px;

		position: absolute;
		top: 50%;
		@include right(10px);
		transform: translateY(-50%);
	}
	
	.horizon .booking-room & {
		span {
			@include desktop {
				top: 9px;
			}
		}
	}

	.account .booking-trigger--finish-my-booking & {
		@include tablet {
			height: 85px;
		}

		@include mobile {
			height: 85px;
		}
	}

	&:focus {
		outline: none;
	}

	span {
		position: absolute;
		top: 27px;
		@include right(0);
		@include left(0);
		display: block;
		height: 2px;
		// background-color: $f-gold;
		background-color: white;

		.account & {
			top: 48%;
			background-color: #fff;
		}

		&:before, &:after {
			position: absolute;
			@include left(0);
			display: block;
			width: 100%;
			height: 2px;
			content: "";
			// background-color: $f-gold;
			background-color: white;

			.account & {
				background-color: #fff;
			}

			.booking-room & {
				background-color: $t-standard;
			}
		}

		&:before {
			top: -6px;
			@include tablet {
				top: -10px;
			}
		}

		&:after {
			bottom: -6px;
			@include tablet {
				bottom: -10px;
			}
		}
	}
}

.c-hamburger--htx {
	background-color: transparent;
}

.c-hamburger--htx span {
	transition: background 0s .3s;
}

.c-hamburger--htx span:before,
.c-hamburger--htx span:after {
	transition-delay: .3s, 0s;
	transition-duration: .3s, .3s;
}

.c-hamburger--htx span:before {
	/* autoprefixer: off */
	transition-property: top, transform;
}

.c-hamburger--htx span:after {
	/* autoprefixer: off */
	transition-property: bottom, transform;
}

/* active state, i.e. menu open */
.is-active .c-hamburger--htx span {
	background: none;
}

.horizon .c-hamburger--htx span {
	@include desktop {
		background: none;
	}
}

.is-active .c-hamburger--htx span:before {
	top: 0;
	transform: rotate(45deg);
}

.horizon .c-hamburger--htx span:before {
	@include desktop {
		top: 0;
		transform: rotate(45deg);
	}
}

.is-active .c-hamburger--htx span:after {
	bottom: 0;
	transform: rotate(-45deg);
}

.horizon .c-hamburger--htx span:after {
	@include desktop {
		bottom: 0;
		transform: rotate(-45deg);
	}
}

.is-active .c-hamburger--htx span:before,
.is-active .c-hamburger--htx span:after  {
	transition-delay: 0s, .3s;
}

@include no-desktop {
	.is-iphone-10 {
		.menu-container {
			max-height: calc(100vh - 223px);

			.is-scrolled & {
				max-height: calc(100vh - 21px);
			}
		}
	}
}
