// standard-content styles
.standard-block {

	padding: 35px 10px;

	&--less-padding {
		.standard-block__text {
			padding-top: 35px;
		}
	}

	&__text {
		.t-m {
			margin-bottom: 1em;
		}

		.t-c-m > p {
			margin-top: 1em;
		}
	}

	@include tablet {
		padding: 35px;
	}

	@include desktop {
		padding: 0;

		&__text {
			padding: 45px 95px;
		}

		&__next_text {
			padding-top: 0px;
			padding-left: 95px;
			padding-right: 95px;
			padding-bottom: 45px;
		}
	}

	.features-special {
		border: 0;
	}

	.rte-block ul {
		padding: 0;
		padding-inline-start: 40px;
	}
}
