// social-share styles
.social-share {
	margin-bottom: 1em;

	&__title {
		margin-bottom: 0.5em;
	}

	&__item {
		display: inline-block;
		font-size: 18px;
		color: $t-standard;
		@include margin-right(5px);
		padding-top: 5px;
		width: 30px;
		height: 30px;

		border: 1px solid $t-standard;
		border-radius: 50%;

		vertical-align: middle;
		text-align: center;

		transition: background-color $quick, color $quick;

		&:hover, &:focus {
			background-color: $t-standard;
			color: #fff;
		}
	}
}
