// content-intro styles
.content-intro {

	&.has-title {
		> .col-7 > .t-c-m {
			@include desktop {
				padding-top: 25px;
			}
		}
	}

	@include tablet {
		.col-5 {
			width: 100%;
		}
	}

	@include mobile {
		margin: 0 -15px;

		.feature__item {
			@include padding-right(0);
		}
	}

	.section-header {
		padding: 0 20px;
		@include text-align(left);

		&__title {
			@include text-align(left);
			@include padding-left(3px);
		}

		&__text {
			@extend %t-underline;
			font-size: 2.8em;
			@include text-align(left);
			margin-bottom: 0;
			word-wrap: normal;

			@include mobile {
				font-size: 2.0em;
				word-wrap: break-word;
			}

			@include desktop {
				margin-bottom: 30px;
			}
		}
	}

	.t-c-m {
		max-width: $text-width;
		padding: 0 20px;

		@include desktop {
			padding-top: 4px;
		}

		> img {
			@include float(left);
			margin-top: -2.5em;
		}
	}

	img {
		padding: 0 20px;
	}

	.features-special {
		width: 100%;
		border: 0;
		border-top: 1px solid $f-grey-light;
		@include padding(1.2em, 15px, 0, 0);

		@include tablet {
			margin-bottom: 0;
		}

		@include mobile {
			@include padding(1em, 0, 0, 0);
			font-size: 0.875em;
		}
	}

	.feature__item {
		padding-top: 0.5em;
		margin-bottom: 0;

		@include tablet {
			width: auto;
			min-width: 300px;
		}
	}

	.privilege-discount {
		border-top: 0;
		@include desktop {
			border: 0;
		}
		@include no-desktop {
			width: calc(100% - 40px);

			margin: 0 20px;
			padding: 0;
			&__offer {
				width: auto;
			}

			&__highlight {
				font-size: 2.4em;
			}

			&__primary {
				font-size: 1em;
			}
		}
	}
}

.readmore {
	@include mobile {
		p:first-child {
			min-height: 28px;

			+ p {
				margin-top: 1.5em;
			}
		}
	}
}

.link-readmore {
	margin: 15px 0;
	padding: 0;
	color: $t-gold;
	font-weight: 400;
	font-size: 14px;
	text-decoration: none;

		
	&:hover{
		text-decoration: underline !important;
	}
	
	@include mobile {
		text-align: center;
	}

	&--readless {
		margin-top: 0;
	}
	
	&--left {
		text-align: left;
	}
	
	&--center {		
		margin: 20px 0;
		text-align: center;
	}	
}




.privilege-discount {
	$smallMobile: 540px;

	padding: 0 20px;
	max-width: 880px;
	margin:15px auto;

	z-index:15;

	border-top: 1px solid $f-grey-light;
	border-bottom: 1px solid $f-grey-light;

	&.no-border{
		border: 0;
	}

	.static-list__item & {
		position: absolute;
		top: 0.5em;
		@include right(0);
		width: 230px;
		font-size:0.9em;

		padding: 0;

		border: 0;

		@media (max-width: 390px) {
			top: 0.5em;
			bottom: auto;
		}

		@media (max-width: $smallMobile) {
			width: 180px;
		}

		@include tablet {
			width: 210px;
		}

		@include desktop {
			width: 220px;
		}

	}

	&.badge{
		top:5px;
		@include right(5px);
		background: $f-gold;
		padding:4px 8px;
		max-width:300px;
		font-size:12px;
		width:auto;
		border-radius:3px;
		transition: all .2s ease-in-out;
		margin:5px 0 12px 0;
		z-index: 5;

		&:hover{
			background:$t-gold;
			transition: all .2s ease-in-out;
		}
	}

	&__highlight {
		display: inline-block;
		font-weight: 400;
		@include padding-right(0.2em);

		@include mobile {
			font-size: 3em;
		}

		.static-list__item & {
			position: absolute;


			@include tablet {
				font-size: 2em;
			}
			@include mobile {
				font-size: 2.4em;
			}
			@media (max-width: $smallMobile) {
				font-size: 1.6em;
			}
		}
	}

	&__offer {
		display: inline-block;

		&:hover, &:focus {
			.privilege-discount__cta {
				transform: translateX(bi-app-reverse-percentage(1em));
			}
		}

		@include no-desktop {
			width: 100%;
		}

		.static-list__item & {
			width: 100%;
			@include padding-left(70px);

			@media (max-width: $smallMobile) {
				@include padding-left(50px);
			}

			@include tablet {
				@include padding-left(60px);
				font-size: 0.85em;
			}
			@include desktop {
				@include padding-left(74px);
			}

		}

		[class^="glyph-"] {
			display: inline-block;
			background: $f-gold;
			width: 15px;
			height: 15px;
			@include margin-left(0.5em);

			color: #fff;
			text-align: center;
			border-radius: 50%;
			vertical-align: middle;

			&:before {
				font-size: 15px;
				vertical-align: top;
			}
		}
	}

	&__primary {
		display: inline-block;

		@include mobile {
			font-size: 1.2em;
		}

		@media (max-width: $smallMobile) {
			line-height: 1.15;
		}

		.static-list__item & {
			@include mobile {
				font-size: 0.9em;
			}
		}
	}

	&__cta {
		display: block;
		vertical-align: middle;
		transition: transform $medium;
		transform: translateX(bi-app-reverse-percentage(0));
		will-change: transform;

		@include no-desktop {
			padding-bottom: 1em;
		}
	}

}
