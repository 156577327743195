// quick-look styles
.quick-look {
    width: 100%;
    border: 0;
    @include padding(1.2em, 15px, 0, 0);

    .content-intro & {
        padding: 1.2em 20px;
    }

    @include tablet {
        margin-bottom: 0;
    }

    @include mobile {
        @include padding(1em, 0, 0, 0);
        font-size: 0.875em;
    }

    &__title {

    }

    &__lists {
        padding: 0;
    }

    &__listItem {
        padding-top: 1em;
        margin-bottom: 0;
        display: inline-block;
        width: 49.5%;
        vertical-align: top;
        padding-right: 2em;
        list-style: none;

        @include no-desktop {
            padding-top: 1em;
            width: 100%;

            &:first-child {
                padding-top: 0;
            }
        }

         a {
            color: $t-gold;
         }
    }

    &__caption {
		display: inline-block;
		width: calc(100% - 48px);
		line-height: 1.25;
		vertical-align: middle;

		.content-intro-complex & {
			font-weight: 300;
		}
	}

    &--separator {
        border-top: 1px solid $f-grey-light;
    }
}
