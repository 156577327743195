// Anchor Links styles


.anchor-links-container{
	flex-wrap: nowrap;
	align-items:center;
	overflow-x: scroll;
	overflow-y: scroll;
	justify-content: start;

	&.is--sticky{
		position:fixed;
		width:100%;
		top:56px;
		@include left(0);
		background:$t-standard;
		z-index:99;
		-webkit-transition: all 1s cubic-bezier(0.19, 1, 0.22, 1);
 		transition: all 1s cubic-bezier(0.19, 1, 0.22, 1);
		padding: 12px 20px;
	}

	@include tablet{
		justify-content: center;
	}

	@include desktop{
		overflow: hidden;
		flex-wrap:wrap;
		justify-content: center;
	}

	.filled & {
		@include desktop {
			justify-content: center;
		}
		@include margin(2em, 0, 2em, 0);
		border-bottom: unset;
	}
}

.anchor-item{
	color:#fff;
	background:transparent;
	color: #fff;
    background: transparent;
    @include margin-right(30px);
    flex: 0 0 auto;
	text-align:center;
	font-size: 1em;
	white-space: nowrap;

	@include tablet{
		color:$t-gold;
		border:1px solid $f-gold;
		padding:12px;
		border-radius: 5px;
		margin: 0 1em 15px 0;
	}

	@include desktop{
		color:$t-gold;
		border:1px solid $f-gold;
		padding:12px;
		border-radius: 5px;
		margin: 0 1em 15px 0;
	}

	&:hover{
		text-decoration: none !important;
		@include desktop{
			background:#f4f4f4;
		}
	}

	&:focus, &:active, &:focus-within{
		font-weight:600;
	}
	&.active{
		font-weight:bold;
		position: relative;

		&:after{
			content: ' ';
		    	background: $f-gold;
		    	height:2px;
		    	width:100%;
			    bottom: -6px;
			    position: absolute;
			    right: 50%;
			    left: 50%;
			    transform: translate(-50%,-50%);
		}
	}
}