.is-booking-mask {
	display: block;

	.has-finish-booking & {
		.booking-section {
			transform: translateY(-100%);
		}

		&.translate-in-top {
			.booking-section {
				transform: translateY(0);
			}
		}
	}

	> .nano-content {
		// top: 0 !important;
		right: 0 !important;
		// bottom: 0 !important;
		// left: 0 !important;
	}
}

.is-finish-my-booking {
	display: none;

	.has-finish-booking & {
		display: block;
	}
}

.booking-section {
	display: none;
	width: 100%;
	max-width: ($mask-max - 2);
	@include margin-left(auto);
	background-color: $f-bm-bg--primary;
	box-shadow: $f-minimal-box-shadow;
	transition: transform $medium;
	will-change: transform;

	transform: translateY(0);

	.is-active & {
		display: block;
	}

	.nano-pane {
		z-index: 20;
	}

	&.translate-out-top {
		transform: translateY(-100%);
	}

	fieldset {
		border: 0;
		padding: 0;
		margin: 0;
	}

	@include mobile{
		max-width: 100%;
	}

	&__wrapper{
		position: relative;
	}
}

.booking-form {

	&-group {
		position: relative;
		z-index: 10;
		padding: 0 15px;

		&:last-child {
			padding-bottom: 19px;

			.is-finish-my-booking & {
				padding-bottom: 0;
			}

			&.date {
				padding-bottom: 19px;
			}
		}

		background-color: $f-bm-bg--primary;
		transition: background-color $quick;

		.is-gha & {
			background-color: transparent;
		}

		&:last-child {
			.booking-form-subgroup {
				border-bottom: 0;
			}
		}


		.button__submit.is-spinning {
			padding: 0;
			pointer-events: none;
			&:after{
				content: '';
				display: inline-block;
				width: 25px;
				height: 25px;
				vertical-align: middle;
				border: 3px solid rgba(255,255,255,.3);
				border-radius: 50%;
				border-top-color: #fff;
				animation: spin 1s  infinite linear;
				-webkit-animation: spin 1s infinite linear;
			}

			.button__text {
				display: none;
			}
		}
	}

	&-subgroup {
		position: relative;
		z-index: 10;
		padding: 11px 2px;
		background-color: $f-bm-bg--secondary;
		border-bottom: 1px solid $f-bm-border--secondary;

		transition: background-color $quick;

		&:hover {
			background-color: $f-bm-bg--secondary--hover;
		}

		@media (max-height: $bpShortWindowBookingMask) {
			padding-top: 6px;
			padding-bottom: 6px;
		}

		.is-gha & {
			background-color: $f-grey-dark-border;
			border-bottom-color: $f-grey-border;
		}

		.booking-checkbox {
			display: inline-block;
			@include float(left);
			width: auto;
			height: 22px;
			margin-top: 0;
			@include margin-right(.75em);
			vertical-align: middle;
			border: 1px solid $f-gold-dark;
		}
	}

	&-label {
		display: block;
		color: $t-bm-on-primary--subtle;
		margin-bottom: 0;
		text-align: center;
		@extend %t-c-s;
		font-weight: 700;

		.is-gha & {
			color: $t-standard;
		}

		.booking-date & {
			margin-top: -10px;
			margin-bottom: 10px;
		}
	}

	&-subgroup-label {
		display: inline-block;
		font-size: (13 / $base) * 1em;
		font-weight: 700;
		color: $t-bm-on-secondary;
		margin-bottom: 0;
		text-transform: uppercase;
		vertical-align: middle;

		.is-gha & {
			color: #fff;
		}

		> span {
			display: block;
		}

		.label--small {
			font-size: (10 / $base) * 1em;
		}
	}

	&__new-booking {
		display: block;
		text-align: center;
		color: $t-bm-on-secondary;
		text-decoration: underline;

		margin-bottom: -5px;
		padding: 9px 0;

		&:hover, &:focus {
		}
	}
}

.booking {

	&-form {
		.is-gha & {
			height: 537px;
			max-height: 537px;
		}
	}

	&-destination {
		position: relative;		// Relative for the little drop down triangle
		z-index: 13;
		@include padding(16px, 20px, 22px, 20px);
		box-shadow: none;

		@media (max-height: $bpShortWindowBookingMask) {
			padding-top: 6px;
			padding-bottom: 12px;
		}
	}

	&-calendar {
		z-index: 11;
		.is-gha & {
			z-index: 40;
		}
	}

	&-date {
		position: relative;		// Positioning of the booking mask calendars
		z-index: 12;
		border-top: 1px solid $f-bm-border--primary;
		border-bottom: 1px solid $f-bm-border--primary;
		padding: 18px 0;
		@extend %clearfix;

		@media (max-height: $bpShortWindowBookingMask) {
			padding-top: 12px;
		}
	}

	&-guest {
		&__flexible {
			.is-gha & {
				display: none;
			}
		}

		.is-gha & {
			display: none;
		}
	}

	&-promo {
		@include margin-left(0);
		@include padding-left(0);
		.booking-form-subgroup {
			padding: 0;
			border-bottom: 0;
		}

		.is-gha & {
			display: none;
		}
	}

	&-guarantee {
		display: block;
		padding: 12px 0;
		text-align: center;
		font-weight: 700;

		> span {
			display: inline-block;
			font-size: 16px;
			padding-top: 1px;
			@include padding-right(6px);
			vertical-align: top;
		}
	}

	&-room {
		display: none;
		padding: 10px 28px;
		text-align: center;
		background-color: $f-bm-inputs;

		.is-gha & {
			display: none;
		}

        .horizon & {
            @include no-desktop {
                background-color: rgba(255, 255, 255, 0.2) !important;
                color: white;
                margin-top: 15px;
            }

            @include desktop {
                position: relative;
                padding: 0 15px;
                @include text-align(left);
                border-radius: 2px;
                @include padding-left(30px);
            }

            .c-hamburger span {
                @include no-desktop {
                    top: 8px;
                }
            }

            .close-toggle {
                @include desktop {
                    display: none;
                    @include right(auto);
                    @include left(8px);
                }
            }
        }
	}
}

.guarantee__container {
	.is-gha & {
		display: none;
	}
}

.horizon {
	.header-booking-mask {
        @include desktop {
            transform: translateY(0);
            transition: transform $headerAnimation $headerEasing;
            position: relative;
            z-index: 202;
            background-color: $f-grey-dark;
            box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.24);
        }

        .container {
            @media screen and (min-width: 1025px) and (max-width: 1280px) {
                padding: 0 10px;
            }

            @include tablet {
                width: 100vw;
            }
        }

		.booking-trigger {
			position: fixed;
			bottom: 10px;
			top: auto;
			left: 30px;
			right: 30px;
			padding:10px 20px;
			border-radius:5px;
			box-shadow: $f-minimal-box-shadow;
			
			&:before {
                content: $glyph-calendar;
                @extend %icon-font-glyph;
				@include margin(0, 10px, 0, 0);
			}
		}
		
        @include no-desktop {
            > .btn .close-toggle {
                position: absolute;
                right: 0;
                left: 0;
                width: 19px;
                height: 103px;
            }

            .btn-internal {
                transition: transform .4s,width .4s;
                transform: translateY(0);
                word-break: break-word;
            }

            #booking-mask-form,
            .booking-section__wrapper {
                height: 100%;
            }

            .c-hamburger {
                display: none;

                @include tablet {
                    width: 34px;
                }
            }

            &.is-active {
                position: fixed;
                z-index: 202;
				top:0;

                @include no-desktop {
                    z-index: 203;
                }

                .booking-trigger {
                    bottom: auto;
                    top: 0;
                    left: 0;
                    right: 0;
					padding:6px 12px 5px 12px;
					border-radius:0;
					box-shadow: none;
					
					&:before {
						display:none;
					}
                }

                .btn--gold {
                    background-color: $f-gold;
                    margin: 0;
                }

                .btn-internal {
                    transform: translateY(-43%);
                }
                .c-hamburger {
                    display: block;
                    margin: 0 auto;
                }
            }
        }
    }

    .nano.is-booking-mask {
        z-index: 10;
        overflow: visible;

        .nano-content {
			background-color:  transparent;
			
            @include desktop {
                position: static;
                overflow-x: visible;
            }
        }
    }

    .is-active {
        .nano.is-booking-mask {
            .nano-content {
                @include no-desktop {
                    position: fixed;
                    top: 35px;
                }
            }
        }
    }

    .booking-form-group {
        @include no-desktop {
            background-color: transparent;

            &:last-child {
                padding-bottom: 19px;
                flex: 1;
                display: flex;
                flex-direction: column;
                justify-content: flex-end;
            }
        }

        @include desktop {
            background-color: transparent;
            padding: 0;
            @include padding-right(15px);

            &.booking-destination {
                min-width: 20%;
                flex: 1;
            }

            &:last-child {
                padding: 0px;
            }
        }
    }

    .booking-section {
        .select2-container--anantara {
            .select2-selection--single {
                background-color: rgba(255, 255, 255, 0.20);
                @include desktop {
                    border-radius: 2px;
                }

                @include no-desktop {
                    border-radius: 4px;
                }

                .select2-selection__rendered,
                .select2-selection__placeholder {
                    color: $t-bm-select2-placeholder;
                }

                .select2-selection__arrow {
                    b {
                        border-color: white transparent transparent transparent;
                    }
                }
            }
        }

        .booking-date {
            &__wrapper {
                border-radius: 2px;
                background-color: rgba(255, 255, 255, 0.20);
            }
        }

        // Mobile and Tablet
        @include no-desktop {
            min-height: 100%;
            max-width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: stretch;
            background-color: $f-gold;

            .booking-form {
                height: 100%;
                display: flex;
                flex-direction: column;
            }

            .booking-form-label {
                color: white;
            }

            .booking-date {
                border-color: transparent;
            }

            .date {
                &__group {
                    border-radius: 4px;

                    &::after {
                        border-top-color: white;
                    }
                }

                &__month {
                    font-size: 16px;
                }

                &__number,
                &__month,
                &__day {
                    color: white;
                }

                &__picker:last-child {
                    @include border-left(0);
                }
            }

            .booking-guest {
                .booking-form-subgroup {
                    border-bottom: 0;
                    margin-bottom: 14px;
                    border-radius: 4px;
                    padding: 12px;
                }

                .booking-form-subgroup-label {
                    color: white;
                    text-transform: capitalize;
                }
            }

            .stepper-input {
                color: white;
            }

            .booking-promo {
                @include padding-left(15px);
                margin-bottom: 70px;

                .booking-form-subgroup {
                    background-color: $f-gold;
                }

                .promo {
                    &__container {
                        &-wrapper {
                            width: 100%;
                        }

                        .booking-code {
                            @include float(left);
                            width: auto;
                            padding: 15px 0;
                        }

                        .booking-select {
                            width: 35%;
                            margin: 15px 0;
                        }
                    }

                    &-btn {
                        color: white;
                        text-decoration: underline;

                        @include padding(18px, 2px, 18px, 12px);
                    }
                }

                &.is-active {
                    .promo {
                        &__container {
                            transform: translateX(0) translateY(100%);
                        }
                    }
                }
            }
        }

        @include tablet {
            .booking-promo {
                .promo {
                    &__container {
                        width: calc(100% - 32px);

                        .booking-select {
                            @include float(left);
                            width: 20%;
                        }

                        .booking-code {
                            @include float(left);
                            padding: 7px 0;
                        }
                    }
                }
            }
        }

        // All Desktop
        @include desktop {
            background-color: transparent;
            box-shadow: none;
            display:  block;
            max-width: 100%;
            padding: 10px 40px;

            .booking-form {
                display: flex;
                flex-direction: row;

                .button {
                    &__submit {
                        margin-top: 0;
                        font-size: 14px;
                        padding: 12px 16px;
                        min-height: 45px;
                    }
                }
            }

            // Destination
            .booking-destination {
                @include padding(0px, 15px, 0px, 0);
            }

            .booking-form-label[for='booking-hotel'] {
                display: none;
            }
            .booking-form-label[for='booking-dest'] {
                display: none;
            }
            .select2-container--anantara {
                .select2-selection--single {
                    @include text-align(left);
                    min-height: 45px;

                    .select2-selection__rendered {
                        @include padding(11px, 36px, 10px, 16px);
                    }

                    .select2-selection__rendered,
                    .select2-selection__placeholder {
                        font-size: 18px;
                        text-overflow: ellipsis;
                        overflow: hidden;
                        white-space: nowrap;
                    }

                    .select2-selection__arrow {
                        top: 9px;
                        @include right(8px);
                    }
                }
            }

            // Calendar
            .booking-calendar {
                min-width: 285px;
            }

            .booking-date {
                border: 0;
                padding-block: 0px;
                min-height: 45px;
                display: flex;

                &::before {
                    display: none;
                }

                &__wrapper {
                    display: inline-flex;
                    padding: 6px 0;
                    min-height: 45px;
                    width: 100%;
                }

                .booking-form-label {
                    @include text-align(left);
                    margin-top: 0;
                    margin-bottom: 0;
                    font-size: 12px;
                    line-height: 12px;
                    font-weight: 600;
                    color: white;
                    padding-bottom: 5px;
                }
            }

            .date {
                &__group {
                    background-color: transparent;
                    padding-bottom: 0;
                    display: inline-flex;
                    flex-direction: row;
                    @include padding-right(20px);

                    &::after {
                        top: 6px;
                        @include right(0);
                        border-top-color: white;
                    }
                }

                &__number,
                &__month {
                    font-weight: 700;
                    color: white;
                    font-size: 13px;
                    line-height: 13px;
                    max-width: 80px;
                    text-overflow: ellipsis;
                    overflow: hidden;
                    white-space: nowrap;
                }

                &__day {
                    position: absolute;
                    width: 1px;
                    height: 1px;
                    padding: 0;
                    margin: -1px;
                    overflow: hidden;
                    clip: rect(0 0 0 0);
                    border: 0;
                }

                &__picker {
                    color: white;
                    @include text-align(left);
                    float: none;
                    display: flex;
                    align-items: center;

                    .booking-form-group {
                        display: inline-flex;
                        flex-direction: column;
                        padding: 0px 10px;
                        margin-bottom: 0px;
                    }
                }
            }

            .english-format {
                padding-right: 5px;
            }

            // Booking Mask - Occupancy
            .booking-guest {
                display: inline-flex;
                flex-direction: row;
                justify-content: center;
                // width: 42%
            }

            .booking-form-guest-trigger {
                order: 1;
                @include margin-right(15px);
                position: relative;
                width: calc(65% - 15px);
                min-width: 400px;

                .booking-form-guest-wrapper {
                    display: inline-flex;
                    align-items: center;
                    position: relative;
                    background-color: rgba(255, 255, 255, 0.20);
                    border-radius: 2px;
                    min-height: 45px;
                    padding: 12px;
                    @include padding-right(22px);
                    @include padding-left(35px);
                    color: white;
                    font-weight: 700;
                    font-size: 14px;
                    line-height: 21px;
                    width: 100%;

                    &::before {
                        content: $glyph-person;
                        font-size: 20px;
                        position: absolute;
                        top: 50%;
                        transform: translateY(-50%);
                        @include left(10px);
                    }

                    &::after {
                        content: '';
                        display: block;
                        position: absolute;
                        top: 50%;
                        @include right(8px);
                        width: 10px;
                        @include margin-left(1em);
                        content: '';
                        border-top: 10px solid white;
                        border-right: 7.3333333333px solid transparent;
                        border-left: 7.3333333333px solid transparent;
                        transform: translateY(-5px);
                    }
                }

                .guest-adult,
                .guest-child,
                .guest-infant,
                .guest-room {
                    position: relative;
                    display: inline-block;
                    padding: 0 4%;
                    font-weight: 600;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;

                    @include desktop {
                        max-width: 100px;
                    }
                }

                .guest-adult,
				.guest-infant,
                .guest-child {
                    &::after {
                        content: '';
                        background: white;
                        display: block;
                        width: 4px;
                        height: 4px;
                        border-radius: 50%;
                        position: absolute;
                        @include left(0);
                        top: 50%;
                        transform: translateY(-50%);
                    }
                }
            }

            .booking-guest-rooms,
            .booking-form-guest-trigger  {
                padding: 0px
            }

            .booking-guest-rooms {
                order: 2;
                background-color:  transparent;
                //width: 35%;
                min-width: 170px;

                @include desktop {
                    align-items: flex-start !important;
                }
            }

            .booking-form-room-wrapper {
                background-color: rgba(255, 255, 255, 0.20);
                color: white;
                display: inline-flex;
                padding: 12px;
                min-height: 45px;
                border-radius: 2px;
                width: 100%;

                fieldset {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    width: 100%;
                }

                .stepper {
                    &-icon {
                        &[class^="glyph-"],
                        &[class*=" glyph-"] {
                            display: inline-block;
                            font-size: 20px;
                        }
                    }

                    &-btn {
                        background-color: transparent;
                    }

                    &-input {
                        font-size: 18px;
                        color: white;
                        font-weight: 700;
                    }
                }

                .booking-form-subgroup-label {
                    color: white;
                    padding: 0 6px;
                    font-weight: 600;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    text-transform: capitalize;
                    max-width: 54px;

                    &::after {
                        content: '';
                        display: inline-block;
                        height: 0;
                        width: 0;
                        font-size: 0;
                        clear: both;
                    }
                }
            }

            .booking-form-guest-group {
                display: none;
                position: absolute;
                left: 0;
                right: 0;
                z-index: 5;
                background-color: $f-gold;
                padding: 16px;

                fieldset {
                    width: 100%;
                }

                &.is-active {
                    display: block;
                }

                .stepper {
                    &-input {
                        font-size: 18px;
                        font-weight: 700;
                    }
                }

                .booking-form-subgroup {
                    background-color: white;
                    padding: 12px;
                    margin-bottom: 14px;

                    .booking-form-subgroup-label {
                        text-transform: capitalize;
                    }

                    &:last-child {
                        margin-bottom: 0;
                    }
                }
            }

            .booking-form-subgroup {
                border: 0;
                background-color: transparent;
                height: 100%;
                display: flex;
                align-items: center;
            }

            // Have a code?
            .promo {
                &-btn {
                    padding: 0;
                    color: white;
                    text-decoration: underline;
                    text-align: center;
                }

                &__container {
                    display: none;
                    background-color: $f-gold;
                    border-radius: 2px;
                    padding: 16px;
                    top: 100%;
                    min-width: 300px;

                    &::after{
                        border: 0;
                    }

                    &-wrapper {
                        background-color: white;
                        display: flex;
                        justify-content: center;
                    }
                }

                &__select {
                    width: 150px;
                }
            }
        }

        .booking-form-guest-wrapper {
            &::before {
                @extend %icon-font-glyph;
            }
        }

        // Small Screen Desktop
        @media screen and (min-width: 1025px) and (max-width: 1280px) {
            padding: 10px 0;

            .booking-calendar {
                min-width: 260px;
            }

            .date {
                &__picker {
                    .booking-form-group {
                        padding: 0 10px;
                    }
                }

                &__group {
                    @include padding-right(14px);
                }
            }

            .booking-form-guest-trigger {
                width: auto;
                min-width: unset;
                @include margin-right(0px);

                .booking-form-guest-wrapper {
                    padding: 12px 10px;
                    @include padding-right(25px);
                    display: flex;
                    justify-content: space-between;

                    &::before {
                        content: '';
                        display: none;
                    }
                }

                .guest-room {
                    @include padding-left(0 !important);
                }

                .guest-room,
                .guest-child,
                .guest-adult,
                .guest-infant {
                    max-width: fit-content;
                    padding: 0 10px;
                }
            }

            .booking-form-room-wrapper {
                padding: 12px 10px;

                .booking-form-subgroup-label {
                    padding: 0;
                    @include padding-right(6px);
                }

                .stepper {
                    &-icon {
                        &[class^="glyph-"],
                        &[class*=" glyph-"] {
                            display: none;
                        }
                    }
                }
            }

            .booking-guest-rooms {
                min-width: 140px;
            }

            .promo {
                &__container {
                    right: 0;
                    left: auto;
                    transform: translateX(0);
                }

                &-btn {
                    font-size: 10px;
                    line-height: 1.3em;
                }
            }

            .booking-form {
                .button__submit {
                    padding: 12px 5px;
                }
            }
        }
    }
}

