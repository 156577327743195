// account styles

.account {
	position: absolute;
	@include right(0);
	@include left(0);
	transition: transform $medium;

	@include desktop {
		z-index:30;
		@include right(40px);
		@include left(auto);
		transform: translateY(0);

		&--left {
			@include right(auto);
			@include left(40px);

			.is-scrolled & .account__links {
				transform: translateY(0);
			}
		}

		.horizon-header & {
			position: static;
		}

		.is-scrolled & {
			transform: translateY(-20px);
			
			.horizon-header & {
				transform: none;
			}
		}
	}

	@include no-desktop {
		.horizon-header & {
			opacity: 1;
			transition: all 0.4s;
			transform: translateY(0);
		}
	}

	// On small Desktop screen
	@media (min-width: #{$bpMinDesktop}) and (max-width: 1150px) {
		&--left {
			.is-scrolled & .account__links {
				transform: translateY(-18px);
			}
		}
	}

	&.is-active {
		@include right(0);
		z-index:90;
		@include desktop {
			transform: translateY(-28px);

			.is-scrolled & {
				transform: translateY(-60px);
			}
		}

	}

	&__name {
		display: none;
	}

	&__link {
		position: relative;
		display: block;
		@include margin(4px, 0, 8px, 0);
		color: #fff;
		transition: color $quick;
		vertical-align: middle;

		[class^="glyph-"] {
			@include margin-right(5px);
			font-size: 1.2em;
			vertical-align: middle;
		}

		@include desktop {
			display: inline-block;
			@include margin-left(10px);

			.is-scrolled & {
				margin-bottom: 5px;
			}

			&:first-child {
				@include margin-left(0);
			}
		}

		&:hover, &:focus {
			color: $t-standard-on-gold;
		}

		&--home {
			@include margin-right(10px);

			&:after {
				position: absolute;
				top: 0;
				@include right(-12px);
				content: '|';

				.is--campaign &{
					display: none;
				}
			}
		}
	}

	&__links {
		.is-active & {
			@include margin-right(40px);
		}

		.account.member & {
			.is-members:not(.horizon-header) & {
				visibility:hidden;
			}
		}

	}

	&__booking {
		position: absolute;
		@include right(0);
		@include left(calc(100% - #{$mask-max}));
		/* z-index: -1; */

		.horizon-header & {
			@include desktop {
				display: none;
			}
		}

		@include smallMobile {
			@include left(0);
		}

		// .nano {
		// 	> .nano-content {
		// 		-ms-overflow-style: none;
		// 	}
		// }

		.is-active & {
			z-index: 30;	// Causes the logo link to 'home' to be covered when the booking mask is open
			left: 0;

			> .nano {
				position: absolute;
				top: 59px;
				@include right(0);
				width: 100vw;		// Full width screen view so items outside the mask can be visible
				height: calc(100vh - 59px);
				@include desktop {
					top: $header-height-desktop--active; // Original: $header-height-desktop;
					height: $mask-height-desktop;

					.is-scrolled & {
						top: $header-height-desktop--scrolled;
						height: $mask-height-desktop--scrolled;
					}
				}
				@include mobile {
					width: 100%; // No sections outside the mask on mobile, so width can return to normal
					top:35px;
					height: calc(100vh - 35px);
				}

				> .nano-pane {
					z-index: 10;
				}
			}
		}

		// Reservation / Book button
		> .btn {
			font-family: $font-navigation!important;
			
			/* z-index: 35; */
			display: table;
			@include float(right);
			max-width: 35%;
			height: 59px;
			padding-top: 5px;
			overflow: hidden;
			margin: 0;
			background-color: $f-bm-button;
			color: $t-bm-button !important;

			&:hover {
				background-color: $f-bm-button--scrolled--hover;

				@include no-desktop {
					background-color: $f-bm-button;
				}
			}

			.has-finish-booking & {
				display: none;
			}
			
			&.booking-trigger {
				max-width:none;
			}

			@include desktop {
				display: block;
				max-width: $mask-max / 2;
				height: 33px;
				@include margin-left(auto);
				transition: height $medium, background-color $medium;
				margin-top:8px;
			}

			@include tablet {
				line-height: 1.4;
				max-width: 50%;
			}

			@include mobile {
				line-height: 1.4;
			
				&.booking-trigger {
					max-width: 35%;
				}
			}

			&.btn--finish {
				display: none;

				.has-finish-booking & {
					display: table;
				}

				@include desktop {
					max-width: 300px;
				}
			}

			&:active {
				top: 0;
				z-index: 9;
			}

			.is-active & {
				@include desktop {
					width: $mask-max;
					max-width: $mask-max;
					height: $header-height-desktop--active;
					-webkit-transform: translateX(bi-app-reverse-percentage(2px));
					-moz-transform: translateX(bi-app-reverse-percentage(0px));
					margin-top:0px;
					background-color: darken($f-gold, 5%);

					&:hover,
					&:focus {
						background-color: darken($f-gold, 8%);
					}

					.is-scrolled & {
						height: $header-height-desktop--scrolled;
						background-color: darken($f-gold, 5%);

						&:hover,
						&:focus {
							background-color: darken($f-gold, 8%);
						}
					}
				}

				@include tablet {
					background-color: darken($f-gold, 8%);
				}

				@include mobile{
					height:35px;
					width:100%;
					max-width:100%;
					transition: width $medium;
					background-color: $f-gold;
				}
			}

			> span {
				display: table-cell;
				vertical-align: middle;
			}

			.close-toggle {
				position: absolute;
				@include right(0);
				@include left(0);
			}

			.is-scrolled & {
				@include desktop {
					// background-color: $f-bm-button--scrolled;
					background-color: $f-gold;

					&:hover, &:focus {
						background-color: $f-bm-button--scrolled--hover;
					}
				}
			}
		}

		// Reservation button
		.btn {
			&-internal {
				display: table-cell;
				vertical-align: middle;
				transition: transform $medium, width $medium;
				transform: translateY(0);
				word-break:break-word;
				@include desktop {
					display: block;
				}
			}

			.is-active & {
				&-internal {
					transform: translateY(-125%);

					@include mobile{
						transform: translateY(-238%);
					}
				}

			}
		}
	}
	&.member {
		.horizon-header & {
			display: flex;
		}
	}
}



.best-rate-menu {

	position: absolute;
	top: 59px;
	@include right(0);

	@include desktop {
		position: relative;
		@include float(right);
		width: 100%;
		top: auto;
		@include right(auto);
		margin-top:8px;
	}

	&__link {
		display: block;
		@include float(right);

		@include text-align(right);

		background: #fff;
		margin-top: 15px;
		@include margin-right(10px);
		@include padding(8px, 8px, 6px, 8px);
		border-radius: 10px;
		box-shadow: $f-minimal-box-shadow;

		.is--campaign &{
			display:none;
		}

		@include desktop {
			background: transparent;
			margin: 0;
			@include padding(5px, 0, 0, 0);
			border-radius: 0;
			box-shadow: none;

			@include float(left);

			.is-scrolled & {

				padding-top: 2px;
			}

			> img {
				height:16px;
			}
		}
	}
}

.best-rate {
	$bestRateOffset: 10;

	display: none;
	position: fixed;
	z-index: 50;
	top: $header-height + $bestRateOffset;
	@include right(10px);
	max-width: calc(100% - 20px);

	transform: translateY(-20px);
	opacity: 0;
	transition: transform $quick, opacity $quick, top $quick;

	will-change: transform, opacity;

	.is-scrolled & {
		top: $header-height--scrolled + $bestRateOffset;
	}

	@include tablet {
		@include right(9px);
	}

	@include desktop {
		top: $header-height-desktop + $bestRateOffset;
		@include left(42px);
		@include right(auto);

		.is-scrolled & {
			top: $header-height-desktop--scrolled + $bestRateOffset;
		}
	}

	&__content {
		max-width: 320px;

		@include margin-left(auto);

		margin: 0 auto;
		background: #fff;
		box-shadow: $f-minimal-box-shadow;

		@include desktop {
			max-width: 400px;
		}
	}

	&__wrapper {
		max-width: 340px;
		width: 100%;
		padding: 20px;

		[class*='glyph-'] {
			@include margin-left(8px);
			vertical-align: top;
		}
	}

	&__close {
		@include float(right);
		padding-top: 12px;
		@include padding-right(15px);
		color: $t-standard;
		font-size: 35px;

		cursor: pointer;
	}

	&.show {
		transform: translateY(0);
		opacity: 1;
	}

	.btn {
		margin-bottom: 0;
	}

}


.myprivilege {
	position: absolute;
	@include right(50%);
	z-index: 50;
	width: 100%;
	max-width: 410px;
	text-align: center;
	color: #fff;
	top: 0;
	transform: translate(50%, 59px);
	transition: transform $quick;
	background-color: transparent;

	input:-webkit-autofill,
	textarea:-webkit-autofill,
	select:-webkit-autofill {
		-webkit-box-shadow: 0 0 0px 1000px $f-header-bg-light inset;
		-webkit-text-fill-color: #fff !important;
	}

	@include mobile {
		@include rtl {
			@include left(50%);
		}
	}
	@include tablet {
		@include rtl {
			@include left(50%);
		}
		width: 410px;
		transform: translate(50%, 67px);

		.is-scrolled & {
			transform: translate(50%, 69px);
		}
	}

	@include desktop {
		width: 410px;
		@include right(0);
		transform: translateY(94px);
		
		.horizon-header & {
			position: static;
			transform: none;
			width: auto;
    		max-width: 100%;
		}

		.is-scrolled & {
			transform: translateY(114px);

			.horizon-header & {
				transform: none;
			}
		}
	}

	h3 {
		@extend %t-m;
		color: $t-gold;
		margin-top: 10px;
		margin-bottom: 10px;
		text-transform: uppercase;
	}

	b {
		font-weight: 600;
	}

	p, b {
		@extend %t-c-m;
	}

	a {
		color: $t-gold;
		transition: color $quick;

		&:hover, &:focus {
			color: $f-gold;
			text-decoration: none;
		}
	}

	.ps-scrollbar-y-rail {
		@include right(20px);
	}

	.ps-container > .ps-scrollbar-y-rail {
		width: 4px;
		top: 0!important;
	}

	.ps-container.ps-in-scrolling.ps-y > .ps-scrollbar-y-rail {
		opacity: 0.5;
	}

	.ps-container:hover>.ps-scrollbar-y-rail:hover>.ps-scrollbar-y,
	.ps-container>.ps-scrollbar-y-rail>.ps-scrollbar-y {
		background: rgba(255,255,255,0.5);
		width: 4px;
	}

	.content {
		margin-top: 0;
	}

	.constraint {
		> div {
			display: none;
			margin: 0 auto;
			@include margin-right(0);
			@include padding(16px, 34px, 34px, 34px);
			background: $f-header-bg-light;
		}

		> ul {
			display: none;
		}

		#myProfile {
			display: flex;
			flex-direction: column;
			position: absolute;
			color: #fff;
			padding: 0;
			background: transparent;
			top: -108px;
			width: 100%;

			@include tablet {
				top: -115px;
			}

			@include desktop {
				width: max-content;
				flex-direction: row;
				top: -101px;
				@include right(0);

				.horizon-header & {
					position: static;
				}
			}

			.is-scrolled & {
				top: -121px;

				// On small Desktop screen
				@media (min-width: #{$bpMinDesktop}) and (max-width: 1150px) {
					top: -140px;
				}
			}

			z-index: 100;

			.menu {
				display: none;
				background-color: $f-header-bg-light;
				padding: 8px;
				position: absolute;
				z-index: 4;
				width: 50%;
				min-width: 220px;
				top: 48px;
				@include right(50%);
				transform: translateX(50%);
				
				@include rtl {
					transform: translateX(-50%);
				}

				@include desktop {
					top: 35px;
					@include right(0);
					transform: translateX(0);
					width: 220px;
					min-width: 0px;
				}

				> h3 {
					display: none;
				}

				li {
					@include text-align(left);
					margin: 0;
				}

				a {
					font-size: 0.82em;
					font-weight: 400;
					line-height: 1.7142857143em;
					letter-spacing: 0.05em;
					display: block;
					padding: 8px 10px;
					margin: unset;
					max-width:none;

					&:hover {
						background-color: rgba(255,255,255,0.3);
					}
					
					&.close {
						display:none;
						
						&:hover {
							background-color: transparent;
						}
					}
				}
				
				.name {
					display:none;
				}

				.discovery {
					&Card {
						width: 100%;
						padding: 8px;
						margin-bottom: 10px;
						box-sizing: border-box;
						position: relative;
						min-height: 60px;
						border-radius: 2px;
						background-image: url(/assets/minor/brands/images/discovery/gha-mark.png);
						background-repeat: no-repeat;
						background-position: right 30px center;

						&.red {
							background-color: #b60101;
							color: white
						}

						&.titanium {
							background-color:#333333;
							color: white;
						}

						&.platinum {
							background-color:#e5e4e2;
							color: #191919;
						}

						&.gold {
							background-color:#b3a258;
							color: white;
						}

						&.silver {
							background-color:#bcc3cc;
							color: #191919
						}
					}

					&Tier, &Number, &ExpireDate {
						position: absolute;
						margin: 0;
						font-weight: 600;
					}

					&Number, &ExpireDate {
						line-height: 0.57em;
						bottom: 8px;
					}

					&Tier {
						text-transform: capitalize;
						font-size: 0.875em;
						line-height: 0.875em;
					}

					&Number {
						font-size: 0.67em;
						@include left(8px);
					}

					&ExpireDate {
						font-size: 0.57em;
						@include right(8px);

						@include mobile {
							@include text-align(right);

							span {
								display: block;
								padding-top: 7px;
							}
						}
					}
				}
			}

			li {
				margin-bottom: 10px;
			}

			a {
				color: #fff;
				display: flex;
				max-width: fit-content;
				margin-inline: auto;
				align-items: center;
			}

			.image {
				display: none;
				background: transparent url(/assets/minor/brands/images/icons/user.png) no-repeat 0 0;
				background-size: cover;
				width: 22px;
				height: 22px;
				vertical-align: middle;
				@include margin(2px, 10px, 0, 0);
				padding-top: 1px;
				border-radius: 20px;
			}

			.name {
				font-size: 0.875em; 
				&:after {
					@extend %icon-font-glyph;
					content: $glyph-chevron-down;
					@include margin-left(5px);
					vertical-align: middle;
					
					@include rtl {
						float:left;
					}
				}
			}
		}

		#profileFB {
			width: 36px;
			height: 36px;
			border-radius: 50%;
		}

		#lnkWelcome {
			color: #fff;
			font-size: 0.875em;
			display: block;
			margin-block: auto;
			.horizon-header & {
				font-size: 13px;
			}

			@include desktop {
				order: 2;
				display: inline-block;
				vertical-align: middle;
				padding: 0px;
				@include padding-left(10px);

				.horizon-header .is-scrolled & {
					padding-inline: 10px;;
				}
			}
		}

		#joinPrivilege,
		#joinPrivilegeAfterLogin {
			display: block;
			position: absolute;
			@include right(0);
			top: 20px;
			z-index: 1;
			width: 300px;
			padding: 20px;
		}

		.discovery {
			&Profile {
				display: flex;
				justify-content: center;
				flex-direction: row;
				font-size: 0.75em;

				.horizon-header & {
					@include no-desktop {
						justify-content: flex-end;
					}
				}

				p {
					margin: 0;
				}

				@include desktop {
					order: 1;
					justify-content: space-between;
				}

				.discovery {
					&Tier,
					&Dollar {
						padding:5px 10px 1px;
						position: relative;
						direction: ltr;

						&:after {
							content: '';
							background-color: white;
							display: none;
							width: 1px;
							height: 16px;
							top: calc(50% + 2px);
							position: absolute;
							transform: translateY(-50%);

							@include right(0);

							.horizon-header & {
								@include desktop {
									background-color: white;
                                    top: unset;
                                    height: 14px;
									transform: unset;
                                }

                                @include no-desktop {
                                    background-color: white;
                                    top: calc(50% + 2px);
                                }
							}
						}

						@include desktop {
							padding:0 10px 0px;
							display: flex;
							align-items: center;

							&::after {
								display: block;
								top: calc(50%);
							}
						}
					}
					
					&Dollar {
						.horizon-header & {
							@include desktop {
								padding: 0px;
								padding-inline: 10px;
                            }
						}

						.horizon-header .is-scrolled & {
							@include desktop {
                                padding: 10px 10px 1px;
                            }
						}
					}

					&Tier {
						.horizon-header & {
							
							&::after {
								background:transparent!important;
								display: inline-block;
								width: 6px;
								border-top: 6px solid #fff;
								border-right: 4px solid transparent;
								border-left: 4px solid transparent;
								top: -webkit-calc(50% + 4px);
								height: fit-content;
							}

						}

						.horizon-header .is-scrolled & {
							@include desktop {
								@include padding(10px,10px,1px,0);
                            }
						}

						&::after {
							display: block;
						}

						img, p {
							display: inline-block;
							vertical-align: middle;
						}

						img {
							height: 12px;
							padding-right: 4px;
						}

						@include desktop {
							img {
								height: 19px;
							}
						}

						// On small Desktop screen
						@media (min-width: #{$bpMinDesktop}) and (max-width: 1150px) {
							img {
								height: 12px;
							}
						}

						.red {
							color: #ff4444;
						}

						.titanium {
							color: #23282D;
						}

						.platinum {
							color: #e5e4e2;
						}

						.gold {
							color: #a59451;
						}

						.silver {
							color: #bcc3cc;
						}

						.is-scrolled & {
							.platinum, .titanium, .gold, .silver {
								color: white;
							}
						}
					}
				}
				
				div:first-child() {
					.horizon-header & {
						@include margin-left(16px);
						@include padding-left(16px !important);
						
						@include desktop {
							&::before {
								content: '';
								position: absolute;
								width: 1px;
								height: 14px;
								@include left(0);
								background-color: white;
								top: unset;
							}
						}
					}
				}
			}


		}
	}

	ul {
		list-style: none;
		margin: 0;
		padding: 0;
	}

	.form {
		padding: 0 !important;
	}

	fieldset {
		border: none;
		padding: 0;
		@include margin(30px, 0, 10px, 0, !important);
		max-width: 400px;
		position: relative;

		&.btn {
			margin: 0 auto !important;
			background: transparent;
			color: #fff;

			button {
				margin-top: 1em;
			}
		}
	}

	#signupPopup {
		max-height: calc(100vh - #{$header-height-desktop--scrolled});
		overflow: auto;
	}

	#frmSignUp {

		> fieldset:first-child {
			@include desktop {
				width: (4/12) * 100%;
				@include float(left);
			}
		}

		> fieldset:first-child + fieldset {
			@include desktop {
				width: (8/12) * 100%;
				@include float(left);
				margin-bottom: 26px;
			}
		}
	}

	.input {
		display: block;

		.input__label {
			@extend %font-alternate-body;
			@extend %t-s;
			@include float(left);
		}
	}

	label.error, #loginUnsuccessful {
		@extend %t-c-s;
		display: block;
		margin-top: 7px;
		margin-bottom: -20px !important;
		color: #ff4444;

	}

	input {
		@extend %t-c-m;
		padding-top: 0;
		padding-bottom: 5px;
		border-radius: 0;
	}

	input[type="number"]::-webkit-inner-spin-button, input[type="number"]::-webkit-outer-spin-button {
		-webkit-appearance: none;
		margin: 0;
	}

	input,
	textarea,
	select {
		&:-webkit-autofill {
			// color: $brand_dark_green!important;
		}
	}

	#lnkForgetPassword {
		@extend %t-c-xs;

		display: block;
		@include float(left);
		@include margin(0, auto, 20px, auto);
		@include text-align(left);
		color: $t-standard-faint;


		border-bottom: 1px solid transparent;
		transition: border-bottom $quick;

		&:hover, &:focus {
			border-bottom: 1px solid $t-standard-light;
		}
	}

	p {
		display: block;
		margin: 10px auto;
		max-width: 400px;
	}

	#confirmLoggedIn p {
		margin-bottom: 30px;
	}

	label {
		@include float(left);
		display: inline-block;
		margin-bottom: 0;
	}

	label[for="signuptitle"] {
		@extend %t-s;
	}

	input[type="checkbox"] {
		@include float(left);
		width: auto;
	}

	.note {
		width: calc(100% - 20px);
		@include float(left);
		@include padding-left(10px);
		@include text-align(left);
		margin-top: 0;
	}

	.note, .terms {
		font-size: 0.75em;
	}

	.terms {
		clear: both;
		margin-bottom: 0;
	}

	.or {
		@extend %t-c-s;
		display: block;
		width: 100%;
		margin: 6px 0;
	}

	#lnkLogin,
	.loginSignup {
		@extend %t-c-m;
		display: block;
		width: auto;
		margin: 15px auto;

		&:hover, &:focus {
			color: $t-standard-on-gold;
		}
	}

	.loginSignup {
		margin-bottom: 0;
	}

	.gha {
		overflow: hidden;
		width: 100%;
		margin-bottom: 1.5em;

		&:before {
			content: '';
			width: 25px;
			height: 18px;
			display: inline-block;
			vertical-align: top;
			background: url(/assets/minor/brands/images/icons/gha.png) no-repeat left top;
		}
	}

	select {
		@extend %t-c-s;

		@include padding(8px, 8px, 4px, 0);
		@include float(left);
		@include text-align(left);

		height: 22px;

		@include mobile {
			padding: 0;
		}
		@include tablet {
			padding: 0;
		}
	}

	button {
		// @extend .button-navy;
		margin-top: 0;
	}

	.myBookingPopupClose, .loginPopupClose, .signupPopupClose, .close, .dismiss {
		// @extend .icon-close;
		width: 36px;
		height: 36px;
		display: block;
		position: absolute;
		top: 5px;
		// font-size: 0;
		cursor: pointer;
		color: #fff;

		&:before {
			font-family: 'icomoon';
			position: absolute;
			@include left(0);
			top: 0;
			@include right(0);
			display: block;
			font-size: 28px;
		}
	}
	.myBookingPopupClose, .loginPopupClose, .signupPopupClose, .close {
		@include right(20px);
	}

	.dismiss {
		@include right(0);
		&:before {

			position: absolute;
			@include left(0);
			top: 0;
			@include right(0);
			display: block;
			font-size: 20px;
		}
	}

	.btn {
		width: 100%;

		button {
			@extend %btn;
			@extend .btn--gold;
		}
	}

	#btnCancleFBConfirmPassword,
	.cancel  {
		@include margin-left(15px);
		font-size: 0.875em;
	}

	#frmFaceBookLogin .btnFBLogin,
	#frmSignUp .btnFBLogin {
		margin-top: 0;
	}

	.btnFBLogin {
		@extend %btn;
		margin-top: 0;
		background-color: $f-blue;
		color: #fff;

		&:before {
			@extend %icon-font-glyph;
			content: $glyph-facebook;
			@include margin-right(10px);
		}

		&:hover, &:focus {
			background-color: $f-blue-tone;
		}

	}
}

.js-my-booking,
.js-my-login,
.js-my-signup {

	&.is-active {
		color: $f-grey-light-border;
	}
}
