$icomoon-font-family: "icons" !default;
$icomoon-font-path: "fonts" !default;

$glyph-warning: unquote('"\\e9eb"');
$glyph-search: unquote('"\\e90a"');
$glyph-mail: unquote('"\\e900"');
$glyph-pets: unquote('"\\e9cf"');
$glyph-wifi-mid: unquote('"\\e90d"');
$glyph-share: unquote('"\\e907"');
$glyph-check-circle-outline: unquote('"\\e9ce"');
$glyph-pinterest: unquote('"\\e923"');
$glyph-location-pin: unquote('"\\e90b"');
$glyph-chevron-right: unquote('"\\e905"');
$glyph-chevron-left: unquote('"\\e906"');
$glyph-chevron-down: unquote('"\\e903"');
$glyph-chevron-up: unquote('"\\e904"');
$glyph-xiaohongshu: unquote('"\\ea1a"');
$glyph-hamper: unquote('"\\ea19"');
$glyph-atm: unquote('"\\ea0d"');
$glyph-beauty-salon: unquote('"\\ea0e"');
$glyph-childrens-facilities: unquote('"\\ea11"');
$glyph-laundry-service: unquote('"\\ea12"');
$glyph-limousine-service: unquote('"\\ea13"');
$glyph-no-smoking: unquote('"\\ea14"');
$glyph-shuttle: unquote('"\\ea15"');
$glyph-tour-desk: unquote('"\\ea16"');
$glyph-undercover-parking: unquote('"\\ea17"');
$glyph-valet-service: unquote('"\\ea18"');
$glyph-kids-pool: unquote('"\\ea09"');
$glyph-laundry-services: unquote('"\\ea0c"');
$glyph-person: unquote('"\\ea07"');
$glyph-door: unquote('"\\ea08"');
$glyph-lock: unquote('"\\ea05"');
$glyph-unlock: unquote('"\\ea06"');
$glyph-twitter: unquote('"\\ea04"');
$glyph-ironing: unquote('"\\ea03"');
$glyph-family: unquote('"\\ea02"');
$glyph-globe: unquote('"\\ea01"');
$glyph-ev-station: unquote('"\\ea00"');
$glyph-niyama: unquote('"\\e9ff"');
$glyph-private-cabana: unquote('"\\e9fe"');
$glyph-recycle: unquote('"\\e9fd"');
$glyph-kayak: unquote('"\\e9fc"');
$glyph-drinking-water: unquote('"\\e9fb"');
$glyph-bathrobe: unquote('"\\e9ec"');
$glyph-breakfast: unquote('"\\e9ed"');
$glyph-champagne: unquote('"\\e9ee"');
$glyph-coffee: unquote('"\\e9ef"');
$glyph-direct-access--lagoon: unquote('"\\e9f0"');
$glyph-fresh-coconut: unquote('"\\e9f1"');
$glyph-ice-cream: unquote('"\\e9f2"');
$glyph-inroom-safe: unquote('"\\e9f3"');
$glyph-moviestar: unquote('"\\e9f4"');
$glyph-popcorn: unquote('"\\e9f5"');
$glyph-private-terrace: unquote('"\\e9f6"');
$glyph-refrigerator: unquote('"\\e9f7"');
$glyph-selection-of-table: unquote('"\\e9f8"');
$glyph-slippers: unquote('"\\e9f9"');
$glyph-sunken: unquote('"\\e9fa"');
$glyph-discovery-circle: unquote('"\\e9e9"');
$glyph-discovery: unquote('"\\e9ea"');
$glyph-anantara-spa-product: unquote('"\\e911"');
$glyph-volume-mute: unquote('"\\e95a"');
$glyph-volume-high: unquote('"\\e95b"');
$glyph-google-plus: unquote('"\\ea8b"');
$glyph-in: unquote('"\\e94d"');
$glyph-info2: unquote('"\\e94f"');
$glyph-arrow-left2: unquote('"\\ea40"');
$glyph-sina-weibo: unquote('"\\ea9a"');
$glyph-comment: unquote('"\\f02b"');
$glyph-thin-arrow-down: unquote('"\\e909"');
$glyph-open-quotes: unquote('"\\e908"');
$glyph-mobile: unquote('"\\e902"');
$glyph-facebook2: unquote('"\\e901"');
$glyph-phone: unquote('"\\e942"');
$glyph-link: unquote('"\\e9cb"');
$glyph-plus: unquote('"\\ea0a"');
$glyph-minus: unquote('"\\ea0b"');
$glyph-cross: unquote('"\\ea0f"');
$glyph-checkmark: unquote('"\\ea10"');
$glyph-facebook: unquote('"\\ea91"');
$glyph-instagram: unquote('"\\ea92"');
$glyph-youtube: unquote('"\\ea9d"');
$glyph-whatsapp: unquote('"\\ea93"');
$glyph-bubble: unquote('"\\e9e7"');
$glyph-sun: unquote('"\\ea1e"');
$glyph-rating-empty-bubble: unquote('"\\ea23"');
$glyph-rating-half-bubble: unquote('"\\ea24"');
$glyph-rating-bubble: unquote('"\\ea25"');
$glyph-tripadvisor: unquote('"\\ea26"');
$glyph-elysia: unquote('"\\ea22"');
$glyph-naladhu: unquote('"\\ea21"');
$glyph-mn-arrow-down: unquote('"\\ea1f"');
$glyph-half-star: unquote('"\\ea20"');
$glyph-underfloor-heating: unquote('"\\ea1b"');
$glyph-gas-fireplace: unquote('"\\ea1c"');
$glyph-electric-heating: unquote('"\\ea1d"');
$glyph-meeting-banquet: unquote('"\\e977"');
$glyph-meeting-cabaret: unquote('"\\e9e8"');
$glyph-power-l: unquote('"\\e9e6"');
$glyph-power-k: unquote('"\\e9d8"');
$glyph-power-j: unquote('"\\e9d9"');
$glyph-power-i: unquote('"\\e9da"');
$glyph-power-h: unquote('"\\e9db"');
$glyph-power-g: unquote('"\\e9dc"');
$glyph-power-f: unquote('"\\e9dd"');
$glyph-power-e: unquote('"\\e9de"');
$glyph-power-d: unquote('"\\e9df"');
$glyph-power-c: unquote('"\\e9e0"');
$glyph-power-b: unquote('"\\e9e1"');
$glyph-power-a: unquote('"\\e9e2"');
$glyph-power-o: unquote('"\\e9e3"');
$glyph-power-n: unquote('"\\e9e4"');
$glyph-power-m: unquote('"\\e9e5"');
$glyph-airport-transfer: unquote('"\\e9d6"');
$glyph-menu: unquote('"\\e9d5"');
$glyph-kaokao: unquote('"\\e9d3"');
$glyph-telegram: unquote('"\\e9d4"');
$glyph-tiktok: unquote('"\\e9d2"');
$glyph-food-tray: unquote('"\\e9d1"');
$glyph-elewana: unquote('"\\e9cd"');
$glyph-anantara: unquote('"\\e9ac"');
$glyph-avani: unquote('"\\e9c5"');
$glyph-tivoli: unquote('"\\e9c6"');
$glyph-oaks: unquote('"\\e9c7"');
$glyph-nhow: unquote('"\\e9c8"');
$glyph-nh: unquote('"\\e9c9"');
$glyph-ncollection: unquote('"\\e9ca"');
$glyph-gha: unquote('"\\e9cc"');
$glyph-messenger: unquote('"\\e9c4"');
$glyph-chrome-cast: unquote('"\\e9b9"');
$glyph-stargazing: unquote('"\\e9b7"');
$glyph-wechat: unquote('"\\e9b6"');
$glyph-line: unquote('"\\e9b5"');
$glyph-Canyon-view: unquote('"\\e9ba"');
$glyph-Gabled-ceiling: unquote('"\\e9bb"');
$glyph-Gourmet-resident: unquote('"\\e9bc"');
$glyph-air-conditioning: unquote('"\\e9bd"');
$glyph-bar-counter: unquote('"\\e9be"');
$glyph-engine: unquote('"\\e9bf"');
$glyph-longtail-boat: unquote('"\\e9c0"');
$glyph-temples-tour: unquote('"\\e9c1"');
$glyph-Local-handicrafts: unquote('"\\e9c2"');
$glyph-USB-charging-station: unquote('"\\e9c3"');
$glyph-thai-handcraft: unquote('"\\e99e"');
$glyph-thai-silk: unquote('"\\e99f"');
$glyph-time-together: unquote('"\\e9a0"');
$glyph-patio: unquote('"\\e9a1"');
$glyph-living-area: unquote('"\\e9a2"');
$glyph-apartment: unquote('"\\e9a3"');
$glyph-higher-floor: unquote('"\\e9a4"');
$glyph-captain: unquote('"\\e9a5"');
$glyph-cocktail: unquote('"\\e9a6"');
$glyph-chef: unquote('"\\e9a7"');
$glyph-outdoor-deck: unquote('"\\e9a8"');
$glyph-dugong: unquote('"\\e9a9"');
$glyph-parking: unquote('"\\e9aa"');
$glyph-bathroom-and-separate-toilet: unquote('"\\e9ab"');
$glyph-hairdryer: unquote('"\\e9ad"');
$glyph-temperature-controlled-grape-fridge: unquote('"\\e9ae"');
$glyph-washer-and-dryer: unquote('"\\e9af"');
$glyph-dvd-player: unquote('"\\e9b0"');
$glyph-departure-gift: unquote('"\\e9b1"');
$glyph-telescope: unquote('"\\e9b2"');
$glyph-binoculars: unquote('"\\e9b3"');
$glyph-daily-fresh-fruits: unquote('"\\e9b4"');
$glyph-balcony2: unquote('"\\e913"');
$glyph-barbecue: unquote('"\\e916"');
$glyph-basins: unquote('"\\e91f"');
$glyph-bathroom-product: unquote('"\\e92a"');
$glyph-bathtub: unquote('"\\e92c"');
$glyph-beach-bag: unquote('"\\e92e"');
$glyph-beach-shoes: unquote('"\\e936"');
$glyph-beach-toys: unquote('"\\e946"');
$glyph-bed-crib: unquote('"\\e959"');
$glyph-boardgames: unquote('"\\e95f"');
$glyph-bunk-bed: unquote('"\\e960"');
$glyph-cliff-beach-view: unquote('"\\e961"');
$glyph-cliff-top-ocean-view: unquote('"\\e962"');
$glyph-cocktail-making-facilities: unquote('"\\e963"');
$glyph-cribe: unquote('"\\e964"');
$glyph-dining-table: unquote('"\\e965"');
$glyph-double-basins: unquote('"\\e966"');
$glyph-double-vanity: unquote('"\\e967"');
$glyph-garden-view: unquote('"\\e968"');
$glyph-goods-for-kids: unquote('"\\e969"');
$glyph-guest-wc: unquote('"\\e96a"');
$glyph-hammock: unquote('"\\e96b"');
$glyph-home-theatre: unquote('"\\e96c"');
$glyph-housekeeping: unquote('"\\e96d"');
$glyph-interconnecting-rooms: unquote('"\\e96e"');
$glyph-ipad: unquote('"\\e96f"');
$glyph-jacuzzi: unquote('"\\e970"');
$glyph-kasara: unquote('"\\e971"');
$glyph-kids-club: unquote('"\\e972"');
$glyph-king-bed: unquote('"\\e973"');
$glyph-life-jacket: unquote('"\\e974"');
$glyph-majlis-seating: unquote('"\\e975"');
$glyph-mangroves-view: unquote('"\\e976"');
$glyph-mini-bar-with-glassware: unquote('"\\e978"');
$glyph-multimedia-connectivity: unquote('"\\e979"');
$glyph-oryx: unquote('"\\e97a"');
$glyph-outdoor-cabana: unquote('"\\e97b"');
$glyph-outdoor-dining-area: unquote('"\\e97c"');
$glyph-outdoor-lounge: unquote('"\\e97d"');
$glyph-outdoor-sala: unquote('"\\e97e"');
$glyph-oval-bath-tub: unquote('"\\e97f"');
$glyph-over-water-hammock: unquote('"\\e980"');
$glyph-panoramic-view: unquote('"\\e981"');
$glyph-patio-with-tow-daybeds: unquote('"\\e982"');
$glyph-pillow-menu: unquote('"\\e983"');
$glyph-playstation: unquote('"\\e984"');
$glyph-pool2: unquote('"\\e985"');
$glyph-privacy-fence: unquote('"\\e986"');
$glyph-private-entrance: unquote('"\\e987"');
$glyph-private-fence: unquote('"\\e988"');
$glyph-river-view: unquote('"\\e989"');
$glyph-room-for-disabled-available: unquote('"\\e98a"');
$glyph-separate-entrance-lift: unquote('"\\e98b"');
$glyph-single-bed: unquote('"\\e98c"');
$glyph-sound-system2: unquote('"\\e98d"');
$glyph-spa2: unquote('"\\e98e"');
$glyph-standalone-two-storey-villa: unquote('"\\e98f"');
$glyph-storage-space-for-luggage: unquote('"\\e990"');
$glyph-sun-loungers: unquote('"\\e991"');
$glyph-swing-bed: unquote('"\\e992"');
$glyph-teens-club: unquote('"\\e993"');
$glyph-three-country-view: unquote('"\\e994"');
$glyph-traditional-chinese-tea-set: unquote('"\\e995"');
$glyph-turndown-service: unquote('"\\e996"');
$glyph-twin-bed: unquote('"\\e997"');
$glyph-villa: unquote('"\\e998"');
$glyph-walk-in-wardrobe: unquote('"\\e999"');
$glyph-wardrobe: unquote('"\\e99a"');
$glyph-wet-bar: unquote('"\\e99b"');
$glyph-writing-desk: unquote('"\\e99c"');
$glyph-yoga-mat: unquote('"\\e99d"');
$glyph-profile: unquote('"\\e956"');
$glyph-booking: unquote('"\\e95c"');
$glyph-login: unquote('"\\e95d"');
$glyph-fax: unquote('"\\e95e"');
$glyph-external: unquote('"\\e957"');
$glyph-residence: unquote('"\\e955"');
$glyph-time-clean-invert: unquote('"\\e954"');
$glyph-bed: unquote('"\\e953"');
$glyph-sunset: unquote('"\\e94e"');
$glyph-sunrise: unquote('"\\e950"');
$glyph-capacity: unquote('"\\e951"');
$glyph-roomsize: unquote('"\\e952"');
$glyph-thumbs-up: unquote('"\\e9d0"');
$glyph-tea-cup: unquote('"\\e9b8"');
$glyph-vk: unquote('"\\e958"');
$glyph-meeting-board-room: unquote('"\\e947"');
$glyph-meeting-class-room: unquote('"\\e948"');
$glyph-meeting-cocktail: unquote('"\\e949"');
$glyph-meeting-h-square: unquote('"\\e94a"');
$glyph-meeting-theatre: unquote('"\\e94b"');
$glyph-meeting-u-shape: unquote('"\\e94c"');
$glyph-private-dining: unquote('"\\e941"');
$glyph-call: unquote('"\\e943"');
$glyph-email: unquote('"\\e944"');
$glyph-time-clean: unquote('"\\e945"');
$glyph-butler: unquote('"\\e90e"');
$glyph-club-lounge-access: unquote('"\\e90f"');
$glyph-coffee-machine: unquote('"\\e910"');
$glyph-Direct-beach-access: unquote('"\\e912"');
$glyph-inroom-spa-treatments: unquote('"\\e914"');
$glyph-kitchenette: unquote('"\\e915"');
$glyph-outdoor-shower: unquote('"\\e917"');
$glyph-pool: unquote('"\\e918"');
$glyph-shampoo-bottle: unquote('"\\e919"');
$glyph-shower: unquote('"\\e91a"');
$glyph-sofa: unquote('"\\e91b"');
$glyph-sound-system: unquote('"\\e91c"');
$glyph-sundeck: unquote('"\\e91d"');
$glyph-tv: unquote('"\\e91e"');
$glyph-window: unquote('"\\e920"');
$glyph-wine-fridge: unquote('"\\e921"');
$glyph-balcony: unquote('"\\e922"');
$glyph-hour-service: unquote('"\\e924"');
$glyph-bicycles: unquote('"\\e925"');
$glyph-diving: unquote('"\\e926"');
$glyph-elephant: unquote('"\\e927"');
$glyph-fitness-centre: unquote('"\\e928"');
$glyph-golf: unquote('"\\e929"');
$glyph-handy-smartphone: unquote('"\\e92b"');
$glyph-meeting-and-banqueting: unquote('"\\e92d"');
$glyph-over-water: unquote('"\\e92f"');
$glyph-residing-doctor: unquote('"\\e930"');
$glyph-restaurant: unquote('"\\e931"');
$glyph-snorkelling: unquote('"\\e932"');
$glyph-spa: unquote('"\\e933"');
$glyph-swimming-pool: unquote('"\\e934"');
$glyph-tennis-court: unquote('"\\e935"');
$glyph-water-sport: unquote('"\\e937"');
$glyph-wedding-chapel: unquote('"\\e938"');
$glyph-yoga: unquote('"\\e939"');
$glyph-zebra: unquote('"\\e93a"');
$glyph-beach: unquote('"\\e93b"');
$glyph-city: unquote('"\\e93c"');
$glyph-desert: unquote('"\\e93d"');
$glyph-heritage: unquote('"\\e93e"');
$glyph-mountain: unquote('"\\e93f"');
$glyph-river: unquote('"\\e940"');
$glyph-image: unquote('"\\f03e"');
$glyph-calendar: unquote('"\\f073"');
$glyph-angle-double-down: unquote('"\\e90c"');
$glyph-heart: unquote('"\\f004"');
$glyph-star: unquote('"\\f005"');
$glyph-check-circle: unquote('"\\f058"');
$glyph-angle-right: unquote('"\\f105"');
$glyph-angle-down: unquote('"\\f107"');
$glyph-snapchat: unquote('"\\e9d7"');

