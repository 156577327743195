// content-intro-complex styles
.content-intro-complex {
	@extend %clearfix;

	@include desktop {
		padding-bottom: 40px;
	}

	@include tablet {
		@include padding(10px, 20px, 20px, 20px);

		.col-4,
		.col-7,
		.col-9 {
			float: none;
			width: 100%;
			margin: 0;
		}
	}
	@include mobile {
		.col-4,
		.col-7,
		.col-9 {
			float: none;
			width: 100%;
			margin: 0;
		}
	}

	.features-special {
		width: 100%;
		border: 0;
		border-top: 0;
		@include padding(8px, 15px, 0, 0);

		[class^='glyph'] {
			width:25px;
		}
	}

	.privilege-discount + .features-special {
		border: 0;
		padding-top: 0;

		@include desktop {
			@include padding(0, 15px, 0, 0);
		}
	}

	.privilege-discount {
		@include padding(20px, 0, 10px, 0);
		margin-bottom: 20px;

		@include desktop {
			padding-top: 10px;
		}

		@include no-desktop {
			width: 100%;

			&__offer {
				width: auto;
			}

			&__highlight {
				font-size: 2.4em;
			}

			&__primary {
				font-size: 1em;
			}
		}

	}

	.feature__item {
		margin-bottom: 0;

		@include tablet {
			width: auto;
			min-width: 280px;
		}
	}

	&__text {
		max-width: $text-width;

		> h1 {
			margin-top: 0;
		}

		@include tablet {
			.btn {
				@include margin(1em, 1em, 1em, 0);
			}

			> div, > ul {
				vertical-align: top;
				display: inline-block;
				width: (100 / 3) * 1%;

				&.weather-widget, &.t-c-m, &.t-m {
					width: inherit;
				}
			}

			> div {
				@include float(left);

				&.app-section{
					float:none;
					width:100%;
					display:flex;
				}
			}

			.trip-advisor__row {
				width: 49%;
				float: none;

				#resort & {
					width: 100%;
					margin-bottom: 0;
				}
			}

			.awards-rich-text {
				width: 100%;
			}

			.rte-block {
				width: 49%;
				display: inline-block;
				margin-top: 0;

				&__heading {
					margin-top: 15px;
				}
				ul.rte-block__list {
					width: 100%;
				}
			}

			.content-intro-complex__cta {
				width: 100%;
			}
		}
	}

	&__buy-cta {
		min-height: 32px;
		width:100%;
		overflow: hidden;

		@include tablet {
			width: 100% !important;
			margin-bottom: 0;
		}

	}

	&__buy {
		@include float(right);
		@media screen and (max-width: $content-complex-buttons) {
			display: block;
			width: 100%;
			float: none;
			margin-top: 1em;
		}

		@include tablet {
			display: inline;
			@include margin-left(2em);
		}
	}

	&__media {
		margin-bottom: 1em;

		iframe {
			width: 100%;
			height: 250px;

			@include desktop {
				width: 90%;
				height: 400px;
			}
			@include tablet {
				width: 100%;
				height: 400px;
			}
		}
		
		img {
			height: 100%;
			width:100%;
		}

		img, button {
			@include desktop {
				max-width: 90%;
			}
			max-width: 100%;
		}

		.gallery{
			@include desktop {
				max-width: 90%;
			}

			img{
				max-width:100%;
			}
		}
	}

	&--swapped {
		@include desktop {
			.content-intro-complex__media {
				@include float(right);
				@include margin-left(0);
			}

			.content-intro-complex__text {
				@include margin-left((100 / 12) * 1%);
				@include padding-left(25px);
			}
		}
	}

	.intro-logo {
		margin-bottom: 22px;
		
		img {
			max-width:100%;
		}

		@include mobile {
			text-align: center;        
			width: 100%;
		}
		 
        @include tablet {
			text-align: center;      
			width: 100%;
        }
	}

	.read-more-content {
		position: relative;

		.gradient {
			background: #eee;
			background: linear-gradient(0deg, #eee 0%, rgba(253,187,45,0) 100%);
			width: 100%;
			height: 50px;
			position: absolute;
			bottom: 40px;
			left: 0;
		}

		.more-content{
			display: none;
		}

		.more-link {
			display: block;
			margin: 20px 0;

			@include no-desktop {
				text-align: center;
			}
		}
	}
}

.intro-virtual-tour {
	position: relative;
	width: 100%;

	@include desktop {
		width: 90%;
	}

	iframe {
		width: 100%;
	}

	a {
		display: block;
		position: relative;

		span{
			display: block;
			position: absolute;
			@include left(0);
			bottom: 4%;
			background: $f-black-overlay;
			width: 100%;
			padding: 10px;
			@include text-align(right);
			color:#fff;
		}

		&.iframe {
			display:block;
			position: absolute;
			@include left(0);
			width:100%;
			height:100%;
			top:0;
			background: transparent url(/assets/minor/brands/images/icons/ico-360.png) no-repeat center;
			z-index: 1;
		}
	}

	img{
		height:auto;
		max-width: 100%;
	}

	.gallery__item & {
		position:absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		
		@include desktop {
			width: 100%;
		}
	
		iframe {
			height:100%;
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
		}
		
		a {
			span {
				bottom: 8%;
			}
		}
	}
}
