// detail-bar styles

.copyright {
	// @include float(left);
	width: 100%;
	color: $t-standard-faint;
	text-align: center;

	@include desktop {
		@include text-align(left);
	}

	> span.t-c-xs{
		display: block;
	}

	> a {
		display: block;

		&.psb{
			@include margin-right(10px);
			filter: grayscale(100%);
			-webkit-filter: grayscale(100%);

			&:hover{
				filter: none;
				-webkit-filter: none;
			}

			@include desktop {
				@include float(left);
			}
		}

		&.bien{
			img{
				@include margin-right(5px);
			}
		}
	}
}

.footer-detail {
	position: relative;

	.container {
		display: flex;
		flex-direction: column;
		align-items: center;

		@include desktop {
			flex-direction: row;
			align-items: flex-start;
		}
	}

	.gha-logo {
		display: inline-block;
		margin: 10px auto;
		order: 3;
		@include desktop {
			order: 2;
			text-align: center;
		}
		@include mobile {
			margin: 20px auto;
		}
	}

	&-links {
		// @include float(right);
		order: 2;
		width: 100%;
		padding: 0;
		text-align: center;
		list-style: none;

		@include desktop {
			order: 3;
			@include text-align(right);
			flex:1 1 auto;
		}

		li {
			display: inline-block;
			@include margin-left(10px);
		}

		a {
			color: $t-standard-faint;
			border-bottom: 1px solid transparent;
			transition: border $quick;

			&:hover, &:focus {
				border-bottom: 1px solid $t-standard-faint;
			}
		}
	}

	p, ul {
		@include mobile {
			float: none;
			width: 100%;
			margin: 5px 0;
			text-align: center;
		}
	}
}
