/*! fancyBox v2.1.4 fancyapps.com | fancyapps.com/fancybox/#license */

.fancybox-wrap, .fancybox-skin, .fancybox-outer, .fancybox-inner, .fancybox-image {
    padding: 0;
    margin: 0;
    border: 0;
    outline: none;
    vertical-align: top;
}

.fancybox-wrap {
    iframe, object {
        padding: 0;
        margin: 0;
        border: 0;
        outline: none;
        vertical-align: top;
    }
}

.fancybox-nav {
    padding: 0;
    margin: 0;
    border: 0;
    outline: none;
    vertical-align: top;

    span {
        padding: 0;
        margin: 0;
        border: 0;
        outline: none;
        vertical-align: top;
    }
}

.fancybox-tmp {
    padding: 0;
    margin: 0;
    border: 0;
    outline: none;
    vertical-align: top;
}

.fancybox-wrap {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 8020;
}

.fancybox-skin {
    position: relative;
    text-shadow: none;
}

.fancybox-opened {
    z-index: 8030;
    background-color: #444;

    .fancybox-skin {
        box-shadow: 0 10px 25px rgba(0, 0, 0, 0.5);
    }
}

.fancybox-outer {
    position: relative;
}

.fancybox-inner {
    position: relative;
    overflow: hidden;
}

.fancybox-type-iframe .fancybox-inner {
    -webkit-overflow-scrolling: touch;
}

.fancybox-error {
    margin: 0;
    padding: 15px;
    white-space: nowrap;
}

.fancybox-image, .fancybox-iframe {
    display: block;
    width: 100%;
    height: 100%;
}

.fancybox-image {
    max-width: 100%;
    max-height: 100%;
}

#fancybox-loading, .fancybox-close, .fancybox-prev span, .fancybox-next span {

}

#fancybox-loading {
    position: fixed;
    top: 50%;
    @include left(50%);
    margin-top: -22px;
    @include margin-left(-22px);
    background-position: 0 -108px;
    opacity: 0.8;
    cursor: pointer;
    z-index: 8060;

    div {
        width: 44px;
        height: 44px;
    }
}

.fancybox-close {
    position: absolute;
    top: 0;
    @include right(15px);
    width: 36px;
    height: 36px;
    cursor: pointer;
    z-index: 8040;
    color: #fff;

    &--no-scroll {
        @include right(0);
    }

    .close-btn {
		position: absolute;
		top: 0;
		@include right(0);
		width: 58px;
		height: 58px;
		background: $f-header-bg-light-op;

        @include mobile {
            width: 38px;
            height: 38px;
        }

		&:before,
		&:after {
			position: absolute;
			top: 15px;
			@include left(28px);
			width: 2px;
			height: 26px;
			content: ' ';
			background-color: $f-grey-light;

            @include mobile {
                top: 9px;
                @include left(18px);
                width: 2px;
                height: 20px;
            }
		}

		&:before {
			transform: rotate(45deg);
		}

		&:after {
			transform: rotate(-45deg);
		}
	}
}

.fancybox-outside {
    @include right(-58px);
    @include mobile {
        @include right(0);
        top: -38px;
    }
}

.fancybox-nav {
    position: absolute;
    top: 0;
    width: 40%;
    height: 100%;
    cursor: pointer;
    text-decoration: none;

    /* helps IE */
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    z-index: 8040;
}

.fancybox-prev {
    @include left(0);
}

.fancybox-next {
    @include right(0);
}

.fancybox-nav span {
    position: absolute;
    top: 50%;
    width: 46px;
    height: 56px;
    margin-top: -23px;
    cursor: pointer;
    z-index: 8040;

    background-color: $f-header-bg-light-op;

    @include mobile {
        width: 30px;
        height: 46px;
        margin-top: -15px;
    }

    &:before {
        display: block;
        width: $triangle-size;
        color: $slick-arrow-color;
        content: "";
        opacity: $slick-opacity-default;
        transition: opacity $quick;

        margin-top: 15px;
        @include margin-left(10px);

        @include mobile {
            margin-top: 12px;
            @include margin-left(0);
        }

    }

}

$triangle-size-small: 15px;

.fancybox-prev span {
    @include left(0);

    &:before {
        border-top: $triangle-size-small/3*2 solid transparent;
        border-bottom: $triangle-size-small/3*2 solid transparent;
        @include border-right($triangle-size-small solid #fff);

        @include desktop {
            border-top: $triangle-size/3*2 solid transparent;
            border-bottom: $triangle-size/3*2 solid transparent;
            @include border-right($triangle-size solid #fff);
        }
    }
}

.fancybox-next span {
    @include right(0);

    &:before {
        @include margin-left(16px);

        border-top: $triangle-size-small/3*2 solid transparent;
        @include border-left($triangle-size-small solid #fff);
        border-bottom: $triangle-size-small/3*2 solid transparent;

        @include desktop {
            border-top: $triangle-size/3*2 solid transparent;
            @include border-left($triangle-size solid #fff);
            border-bottom: $triangle-size/3*2 solid transparent;
        }

        @include mobile {
            @include margin-left(10px);
        }
    }

}

.fancybox-nav:hover span {
    visibility: visible;
}

.fancybox-tmp {
    position: absolute;
    top: -99999px;
    @include left(-99999px);
    visibility: hidden;
    max-width: 99999px;
    max-height: 99999px;
    overflow: visible !important;
}

/* Overlay helper */

.fancybox-lock {
    overflow: hidden;
}

.fancybox-overlay {
    position: absolute;
    top: 0;
    @include left(0);
    overflow: hidden;
    display: none;
    z-index: 8010;
    background: url('/assets/minor/brands/images/plugins/fancybox_overlay.png');
}

.fancybox-overlay-fixed {
    position: fixed;
    bottom: 0;
    @include right(0);
}

.fancybox-lock .fancybox-overlay {
    overflow: auto;
    // overflow-y: scroll;  // Background is not locked, so do not need to compensate for the width difference
}

/* Title helper */

.fancybox-title {
    visibility: hidden;
    position: relative;
    text-shadow: none;
    z-index: 8050;
}

.fancybox-opened .fancybox-title {
    visibility: visible;
}

.fancybox-title-float-wrap {
    position: absolute;
    bottom: 0;
    @include right(50%);
    margin-bottom: -35px;
    z-index: 8050;
    text-align: center;

    .child {
        display: inline-block;
        @include margin-right(-100%);
        padding: 2px 20px;
        background: transparent;

        /* Fallback for web browsers that doesn't support RGBa */
        background: rgba(0, 0, 0, 0.8);
        border-radius: 15px;
        text-shadow: 0 1px 2px #222;
        color: #FFF;
        font-weight: bold;
        line-height: 24px;
        white-space: nowrap;
    }
}

.fancybox-title-outside-wrap {
    position: relative;
    margin-top: 10px;
    color: #fff;
}

.fancybox-title-inside-wrap {
    padding-top: 10px;
}

.fancybox-title-over-wrap {
    position: absolute;
    bottom: 0;
    @include left(0);
    color: #fff;
    padding: 10px;
    background: #000;
    background: rgba(0, 0, 0, 0.8);
}
