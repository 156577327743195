// image styles

.half-bg__image{
    padding:0px;

    @include tablet{
        padding:20px;
    }


    img{
        position: relative;
        z-index: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
    }

    @include desktop{
        width:50%;
        position: absolute;
        top:0;
        height: 100%;
        padding:0;
    }

    &-is-left{
        @include left(0);
    }

    &-is-right{
        @include right(0);
    }

}

.image {
    &--full {
        width: 100%;
    }
}
