.square {
	&-profile {
		&-wrapper {
			transition: 0.5s;
			width:100%;
			margin-bottom: 1.5em;
			font-family: $font-raleway;

			@include tablet {
				width: 48.5%;
			}
			
			@include desktop {
				width: 33.333333%;
				padding: 0 10px;
			}
			
			.title {
				letter-spacing: unset;
				margin: 0.5em 0 0;
			}
			
			.position {
				font-size: 1.125em;
				font-weight: 300;
				line-height: 1.5em;
				letter-spacing: 0.05em;
			}

			img.image-square {
				width: 100%;
			}
			
			a {
				img.image-square {
					object-fit: cover;
					
					@include desktop {
						transition: all .3s ease-out;
						filter: saturate(60%);
					}
				}
				
				&:hover {
					img.image-square {
						@include desktop {
							filter: none;
							transform: scale(1.05);
						}
					}
				}
			}

			&.feature-profile {
				width:100%;
				margin-top: 1.5em;

				@include desktop {
					margin: 5em 0;
				}

				> a {
					display: flex;
					flex-direction: column-reverse;
					align-items: center;

					@include minTablet {
						justify-content: space-between;
						flex-direction: row;
					}

					.feature-profile-col {
						width:100%;
						display: flex;
						flex-direction: column;
						justify-content: center;

						@include tablet {
							width:48.5%;
							height: 100%;
						}
					}
				}
			}
			
			.contact-info-wrapper {
				margin-top: auto;

				.contact-info {
					span {
						margin-right: 0.25em
					}
				}
			}
			
			.four-column & {
				@include desktop {
					width: 25%;
				}
				
				.position {
					font-size: 1em;
				}
			}
		}
	}
}

.profile-list {
	margin-top: 20px;
	justify-content:space-between;
	
	@include desktop {
		justify-content:flex-start;
	}
}