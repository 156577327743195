$litepickerBgColor: #fff;
$litepickerDayWidth: 45px;
$litepickerDayHeight: 45px;

$litepickerMonthHeaderTextColor: #333;
$litepickerMonthButton: $f-bm-button;
$litepickerMonthButtonHover:  $f-gold-overlay;
$litepickerMonthWidth: 315px; // 7 days
$litepickerMonthWeekdayColor: #9e9e9e;

$litepickerDayColor: #333;
$litepickerDayColorHover: $f-gold-overlay;
$litepickerDayIsTodayColor: darken($f-grey-light, 10%);
$litepickerDayIsInRange: lighten($f-grey-light, 10%);
$litepickerDayIsLockedColor: #9e9e9e;
$litepickerDayIsBookedColor: #9e9e9e;
$litepickerDayIsStartColor: #fff;
$litepickerDayIsStartBg: $f-gold;
$litepickerDayIsEndColor: #fff;
$litepickerDayIsEndBg: $f-gold;

$litepickerButtonCancelColor: #fff;
$litepickerButtonCancelBg: #9e9e9e;
$litepickerButtonApplyColor: #fff;
$litepickerButtonApplyBg: $f-gold-op;

$litepickerButtonResetBtn: #909090;
$litepickerButtonResetBtnHover: $f-gold-op;

$litepickerHighlightedDayColor: #333;
$litepickerHighlightedDayBg: #ffeb3b;

.show-week-numbers {
	$litepickerMonthWidth: calc($litepickerDayWidth * 8);
}

.litepicker {
	font-size: 1em;
	display: none;

	.container {
		&__main {
			display: flex;
		}
		
		&__months {
			display: flex;
			flex-wrap: wrap;
			background-color: $litepickerBgColor;
			width: calc($litepickerMonthWidth + 20px); // 10px is padding (left 5px, right: 5px)
			box-sizing: content-box;

      .horizon & {
        border: unset;
      }

			&.columns-2 {
				@extend %calendarWidthLitepicker
			}
			

			&.split-view {
				.month-item {
					&-header {
						.button-previous-month,
						.button-next-month {
							visibility: visible;
						}
					}
				}
			}

			.month-item {
				@include padding(12px, 10px, 10px,10px);
				width: $litepickerMonthWidth;
				box-sizing: content-box;

				&-header {
					display: flex;
					justify-content: space-between;
					font-weight: 500;
					padding: 10px 5px;
					text-align: center;
					align-items: center;
					color: $litepickerMonthHeaderTextColor;

					div {
						flex: 1;

						> .month-item-name {
							@include margin-right (5px);
						}

						> .month-item-year {
							padding: 0;
						}
					}

					.reset-button {
						color: $litepickerButtonResetBtn;

						> svg,
						> img {
							fill: $litepickerButtonResetBtn;
							pointer-events: none;
						}

						&:hover {
							color: $litepickerButtonResetBtnHover;

								> svg {
									fill: $litepickerButtonResetBtnHover;
								}
						}
					}

					.button-previous-month,
					.button-next-month {
						visibility: hidden;
						text-decoration: none;
						color: $litepickerMonthButton;
						padding: 3px 5px;
						border-radius: 3px;
						transition: color 0.3s, border 0.3s;
						cursor: default;

						> svg,
						> img {
							fill: $litepickerMonthButton;
							pointer-events: none;
						}

						&:hover {
							color: $litepickerMonthButtonHover;

							> svg {
								fill: $litepickerMonthButtonHover;
							}
						}
					}
				}

				&-weekdays-row {
					display: flex;
					justify-self: center;
					justify-content: flex-start;
					color: $litepickerMonthWeekdayColor;

					> div {
						padding: 5px 0;
						font-size: 85%;
						flex: 1;
						width: $litepickerDayWidth;
						text-align: center;
					}
				}

				&:first-child {
					.button-previous-month {
						visibility: visible;
					}
				}

				&:last-child {
					.button-next-month {
						visibility: visible;
					}
				}

				&.no-previous-month {
					.button-previous-month {
						visibility: hidden;
					}
				}

				&.no-next-month {
					.button-next-month {
						visibility: hidden;
					}
				}
			}
		}

		&__days {
			display: flex;
			flex-wrap: wrap;
			justify-self: center;
			justify-content: flex-start;
			text-align: center;
			box-sizing: content-box;

			> div,
			> a {
				padding: 10px;
				width: $litepickerDayWidth;
				height: $litepickerDayHeight;
			}

			.day-item {
				color: $litepickerDayColor;
				text-align: center;
				text-decoration: none;
				border-radius: 50px;
				transition: color 0.3s, border 0.3s;
				cursor: default;

				&:hover {
					color: $litepickerDayColorHover;
					box-shadow: inset 0 0 0 1px $litepickerDayColorHover;
				}

				&.is-today {
					color: #fff;
					background:#b3b3b3;
				}

				&.is-locked {
					position:relative;
					color: $litepickerDayIsLockedColor;
					opacity:0.8;

					&:hover {
						color: $litepickerDayIsLockedColor;
						box-shadow: none;
						cursor: default;
					}

					&:before, &:after {
						position: absolute;
						top: 5px;
						left: 50%;
						content: ' ';
						height: 30px;
						width: 1px;
						background-color: $litepickerHighlightedDayColor;
						opacity:0.4;
					}

					&:before {
						transform: rotate(45deg);
					}
					&:after {
						transform: rotate(-45deg);
					}

					&.is-before, &.is-after {
						opacity:0.6;

						&:before, &:after {
							display:none;
						}
					}

					&.is-start-date {
						color:$litepickerDayIsStartColor;
						
						&:before, &:after {
							display:none;
						}
					}
				}

				&.is-booked {
					color: $litepickerDayIsBookedColor;

					&:hover {
						color: $litepickerDayIsBookedColor;
						box-shadow: none;
						cursor: default;
					}
				}

				&.is-in-range {
					background-color: $litepickerDayIsInRange;
					border-radius: 0;
				}

				&.is-start-date {
					color: $litepickerDayIsStartColor;
					background-color: $litepickerDayIsStartBg;
					@include border-radius(25px,0,0,25px);
				}

				&.is-end-date {
					color: $litepickerDayIsEndColor;
					background-color: $litepickerDayIsEndBg;
					@include border-radius(0,25px,25px,0);
				}

				&.is-start-date.is-end-date {
					@include border-radius(25px,25px,25px,25px);
				}

				&.is-highlighted {
					color: $litepickerHighlightedDayColor;
					background-color: $litepickerHighlightedDayBg;
				}
			}

			.week-number {
				display: flex;
				align-items: center;
				justify-content: center;
				color: #9e9e9e;
				font-size: 85%;
			}
		}

	// &__footer {
	//   text-align: right;
	//   padding: 10px 5px;
	//   margin: 0 5px;
	//   background-color: #fafafa;
	//   box-shadow: inset 0px 3px 3px 0px #ddd;
	//   border-bottom-left-radius: 5px;
	//   border-bottom-right-radius: 5px;

	//   .preview-date-range {
	//     margin-right: 10px;
	//     font-size: 90%;
	//   }

	//   .button-cancel {
	//     background-color: $litepickerButtonCancelBg;
	//     color: $litepickerButtonCancelColor;
	//     border: 0;
	//     padding: 3px 7px 4px;
	//     border-radius: 3px;

	//     > svg,
	//     > img {
	//       pointer-events: none;
	//     }
	//   }

	//   .button-apply {
	//     background-color: $litepickerButtonApplyBg;
	//     color: $litepickerButtonApplyColor;
	//     border: 0;
	//     padding: 3px 7px 4px;
	//     border-radius: 3px;
	//     margin-left: 10px;
	//     margin-right: 10px;

	//     &:disabled {
	//       opacity: 0.7;
	//     }

	//     > svg,
	//     > img {
	//       pointer-events: none;
	//     }
	//   }
	// }

	// &__tooltip {
	//   position: absolute;
	//   margin-top: -4px;
	//   padding: 4px 8px;
	//   border-radius: 4px;
	//   background-color: #fff;
	//   box-shadow: 0 1px 3px rgba(0, 0, 0, 0.25);
	//   white-space: nowrap;
	//   font-size: 11px;
	//   pointer-events: none;
	//   visibility: hidden;

	//   &:before {
	//     position: absolute;
	//     bottom: -5px;
	//     left: calc(50% - 5px);
	//     border-top: 5px solid rgba(0, 0, 0, 0.12);
	//     border-right: 5px solid transparent;
	//     border-left: 5px solid transparent;
	//     content: "";
	//   }

	//   &:after {
	//     position: absolute;
	//     bottom: -4px;
	//     left: calc(50% - 4px);
	//     border-top: 4px solid #fff;
	//     border-right: 4px solid transparent;
	//     border-left: 4px solid transparent;
	//     content: "";
	//   }
	// }
	}

	&-open {
		overflow: hidden;
	}

	&-backdrop {
		display: none;
		background-color: #000;
		opacity: 0.3;
		position: fixed;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
	}
}

.calendar-header,
.calendar-footer,
%calendarWidthLitepicker {
  @include desktop {
    width: calc(630px + 45px);    
  }

  .horizon & {
    @include mobile {
      max-width: 100%;
      width: auto;
    }

    @include iphoneX {
      max-width: 100%;
      width: auto;
    }
  }
}