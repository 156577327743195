// blog-cta styles
.blog-cta {
	// @include aspect-ratio(1.85, 1);
	// background-color: $f-grey-dark;

	// overflow: hidden;
	flex: 50%;

	padding: 0 10px;

	@include mobile {
		margin-bottom: 22px;
		// background-color: rgba(0,0,0,0.8);
		flex: 100%;
		padding: 0;

		&:before {
			padding-top: 0;
		}

		> .content {
			position: static;
		}
	}

	&__link {
		&:hover, &:focus {
			.blog-cta {
				&__image {
					> img {
						transform: scale(1.2);
						transition: transform 20s linear;

						@include mobile {
							transform: scale(1);
						}
					}
				}
				// &__text {
				// 	background-color: rgba(0,0,0,0.5);
				// 	@include mobile {
				// 		background-color: transparent;
				// 	}
				// }

				// &__onscreen {
				// 	transform: translateY(0);
				// }

				// &__offscreen {
				// 	transform: translateY(0);
				// 	opacity: 1;
				// }
			}
		}
	}

	&__text {
		// position: absolute;
		top: 0;
		@include left(0);
		height: 100%;
		width: 100%;
		// background-color: rgba(0,0,0,0.3);
		padding: 22px 28px;

		transition: background-color 2s $easeOutExpo;

		@include tablet {
			top: auto;
			bottom: 0;
			height: auto;
			background-color: transparent;
		}

		@include mobile {
			position: static;
			padding: 12px 14px;
			background-color: transparent;
		}

		.text-cta {
			padding: 0.85em 0;
		}
	}

	&__heading,
	&__date {
		display: inline-block;
		position: relative;
	}

	&--dot {
		@include margin-left(10px);
		@include padding-left(15px);	

		&::after {
			content: '';
			@include left(-3px);
			top: 50%;
			position: absolute;
			background-color: $f-gold;
			width: 5px;
			height: 5px;
			transform: translateY(-50%);
			display: block;
			border-radius: 50%;
		}
	}

	&__title {
		@extend %font-navigation;
		
		margin: 0.25em 0;
	}

	&__author {
		display: block;
		margin: 0.25em 0 0.8em;
	}

	&__description {

	}

	&__image {
		// position: relative;
		z-index: -1;
		height: 300px;
		overflow: hidden;
		width: 100%;
		// height: 100%;

		@include desktop {
			max-width: 640px;
		}


		@include mobile {
			z-index: 1;
		}

		> img {
			transform: scale(1);
			transition: transform $long linear;
			object-fit: cover;
			width: 100%;
			height: 100%;
			object-position: center;
		}
	}

	&__onscreen {
		// transition: transform 2s $easeOutExpo;
		// transform: translateY(35px);

		// @include mobile {
		// 	transform: translateY(0);
		// }
	}

	&__offscreen {
		// transition: transform 2s $easeOutExpo, opacity 2s $easeOutExpo;
		// transform: translateY(25px);
		// opacity: 0;

		// @include mobile {
		// 	transform: translateY(0);
		// 	opacity: 1;
		// }
	}

}

.blog {
	&__heading {
		margin-top: 0;
		position: relative;
    	margin-bottom: 40px;

		&:after {
			content:' ';
			width:100%;
			position: absolute;
			height:1px;
			background-color: $f-gold;
			bottom: -20px;
			left:0;
		}	
	}

	&__heading-noline {
		margin-top: 0;
		position: relative;
    	margin-bottom: 40px;		
	}

	&__category {
		padding: 0 3px 0px;
		display: inline-block;
	}

	&__date {
		@include text-align(left);
		display: inline-block;
		margin-bottom: 32px;
		@include padding-left(3px);
	}

	&__intro {
		> p {
			margin-top: 0;
		}
	}
}
