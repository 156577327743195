/* #### Generated By: http://www.cufonfonts.com #### */

@font-face {
  font-family: 'Oswald';
  font-style: normal;
  font-weight: 400;
  src: local('Oswald Regular'), local('Oswald-Regular'), url(/assets/minor/brands/fonts/-g5pDUSRgvxvOl5u-a_WHw.woff) format('woff');
  font-display: swap;
}

// @font-face {
//   font-family: 'Raleway';
//   font-style: normal;
//   font-weight: 100;
//   src: local('Raleway Thin'), local('Raleway-Thin'), url(/assets/minor/brands/fonts/RJMlAoFXXQEzZoMSUteGWD8E0i7KZn-EPnyo3HZu7kw.woff) format('woff');
//   font-display: swap;
// }

// @font-face {
//   font-family: 'Raleway';
//   font-style: normal;
//   font-weight: 300;
//   src: local('Raleway Light'), local('Raleway-Light'), url(/assets/minor/brands/fonts/-_Ctzj9b56b8RgXW8FAriRsxEYwM7FgeyaSgU71cLG0.woff) format('woff');
//   font-display: swap;
// }
@font-face {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 600;
  src: local('Raleway SemiBold'), local('Raleway-SemiBold'), url(/assets/minor/brands/fonts/xkvoNo9fC8O2RDydKj12bxsxEYwM7FgeyaSgU71cLG0.woff) format('woff');
  font-display: swap;
}
@font-face {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 700;
  src: local('Raleway Bold'), local('Raleway-Bold'), url(/assets/minor/brands/fonts/JbtMzqLaYbbbCL9X6EvaIxsxEYwM7FgeyaSgU71cLG0.woff) format('woff');
}
@font-face {
  font-family: 'Chamberi Display';
  font-style: normal;
  font-weight: 700;
  src: local('Chamberi Display SemiBold'), local('ChamberiDisplay-SemiBold'), url(/assets/minor/brands/fonts/ChamberiDisplay-SemiBold.woff) format('woff');
}
@font-face {
  font-family: 'Freight Neo Pro';
  font-style: normal;
  font-weight: 700;
  src: local('Freight Neo Pro Bold'), local('FreightNeoPro-Bold'), local('FreightNeo-Bold'), url(/assets/minor/brands/fonts/FreightNeo-Bold.woff) format('woff');
  font-display: swap;
}
@font-face {
  font-family: 'Freight Neo Pro';
  font-style: normal;
  font-weight: 600;
  src: local('Freight Neo Pro Semi'), local('FreightNeoPro-Semi'), local('FreightNeo-Semi'), url(/assets/minor/brands/fonts/FreightNeo-Semi.woff) format('woff');
  font-display: swap;
}
@font-face {
  font-family: 'Freight Neo Pro';
  font-style: normal;
  font-weight: 400;
  src: local('Freight Neo Pro Medium'), local('FreightNeoPro-Medium'), local('FreightNeo-Medium'), url(/assets/minor/brands/fonts/FreightNeo-Medium.woff) format('woff');
  font-display: swap;
}
@font-face {
  font-family: 'Freight Neo Pro';
  font-style: italic;
  font-weight: 400;
  src: local('Freight Neo Pro Medium Italic'), local('FreightNeoPro-MediumItalic'), local('FreightNeo-MediumItalic'), url(/assets/minor/brands/fonts/FreightNeo-MediumItalic.woff) format('woff');
  font-display: swap;
}