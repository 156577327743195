.portfolio {
	&__brand {
		display: flex;
		align-items: stretch;
		flex-direction: column;
		background-color: #0d3451;
		
		@include desktop {
			flex-direction: row;
		}
		
		&__gallery {
			width:100%;
			
			@include desktop {
				width: 50%;
				height: 400px;
			}
		}
		
		&__content {
			display: flex;
			flex-direction: column;
			justify-content: center;
			width: 100%;
			padding: 70px 50px;
	
			@include desktop {
				width: 50%;
				height: 400px;
			}
			
			img {
				margin: 0 auto 20px;
				display: block;
				max-width:100%;
				
				@include tablet {
					max-width: 60%;
					margin: 0 auto 50px;
				}
				
				@include desktop {
					max-width: 55%;
				}
			}
			
			&__detail {
				display: flex;
				flex-direction: row;
				flex-wrap: wrap;
				justify-content: space-between;
				width: 100%;
				margin: 0 auto;
				
				.figure {
					position: relative;
					text-align: center;
					width: 50%;
					
					@include lgDesktop {
						width: 25%;
					}
					
					.number {
						font-size: 4em;
						line-height: 1.2em;
						color: rgba(255,255,255,.15);
						margin: 0;
					}
					
					.unit {
						font-size: .9em;
						text-transform: uppercase;
						color: #fff;
						margin-top:10px;
					}
					
					@include tablet {
						width: 25%;
						height: auto;
					}
				}
			}
		}
		
		&__logo {
			padding:20px 0;
			
			img {
				display:none;
				
				&.colored {
					opacity:1;
					display:block;
				}
			}
			
			.footer-brand__item {
				padding:12px 0;
			}
		}
	}
}