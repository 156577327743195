.cross-sell-container {
	.brand-logo {
        &:before{
            content:'';
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            background-color: #58595B;
            z-index: 0;
            -moz-opacity: .65;
            opacity: .75;
            background: rgba(88,89,91,0);
            background: -webkit-linear-gradient(top,rgba(88,89,91,0) 0,#000 100%);
            background: linear-gradient(to top,rgba(88,89,91,0) 0,#000 100%);
            transform-origin: center top;
            width:100%;
            height:120px;
        }
	}
	
	.avani & {
		.text-separator--light {
			&::before {
				background-color:#95979b;
			}
		}
		
		.static-list__container {
			&.cross-sell {
				.t-underline {
					&:after {
						background-color: #95979b;
					}
				}
				
				.currency-converter {
					select {
						border-bottom: 2px solid #95979b;
					}
				}
			}
		}
	}
}