// Normalize Styles
@import 'node_modules/normalize.css/normalize';
@import 'node_modules/select2/src/scss/core';
@import 'node_modules/intl-tel-input/src/css/intlTelInput';
@import 'node_modules/lightgallery/scss/lightgallery-bundle';
@import 'node_modules/@nobleclem/jquery-multiselect/jquery.multiselect';

// Need to import after variables
@import 'src/assets/minor/anantara/icons/style.scss';

@import './_helpers';
@import './_grid';
@import './_typography';
@import './_slick';
@import './_nanoscroller';
@import './_typeahead';
@import './_fluidbox';
@import './_fancybox';
@import './_tablesaw';

@import './_litepicker';
@import './_mailtip';

@include no-desktop {
	@import './_jquery.slinky';
}

// Import Modules
@import 'src/_modules/sections/booking/booking';
@import 'src/_modules/sections/booking/calendar/calendar';
@import 'src/_modules/sections/booking/cta/cta';
@import 'src/_modules/sections/booking/destination/destination';
@import 'src/_modules/sections/booking/details/details';
@import 'src/_modules/sections/booking/promo/promo';
@import 'src/_modules/sections/booking/gha/gha';

@import 'src/_modules/elements/stepper/stepper';
@import 'src/_modules/elements/alert/alert';
@import 'src/_modules/elements/button/button';
@import 'src/_modules/elements/currency/currency';
@import 'src/_modules/elements/tooltip/tooltip';
@import 'src/_modules/elements/next-page/next-page';

@import 'src/_modules/sections/footer/footer';
@import 'src/_modules/sections/footer/detail-bar/detail-bar';
@import 'src/_modules/sections/footer/engagement/engagement';
@import 'src/_modules/sections/footer/hotels/hotels';
@import 'src/_modules/sections/footer/newsletter/newsletter';
@import 'src/_modules/sections/footer/parallax/parallax';
@import 'src/_modules/sections/footer/mobile-social-message/mobile-social-message';

@import 'src/_modules/_partials/fullbleed-image/fullbleed-image';
@import 'src/_modules/elements/image/image';


@import 'src/_modules/sections/header/header';
@import 'src/_modules/sections/header/currency/currency';
@import 'src/_modules/sections/header/account/account';
@import 'src/_modules/sections/header/language/language';
@import 'src/_modules/sections/header/logo/logo';
@import 'src/_modules/components/journey-circle/journey-circle';

@import 'src/_modules/sections/menu/menu';
@import 'src/_modules/sections/menu/destinations/destinations';
@import 'src/_modules/sections/menu/fullscreen-menu/fullscreen-menu';
@import 'src/_modules/components/tripadvisor/tripadvisor';
@import 'src/_modules/components/weather-widget/weather-widget';
@import 'src/_modules/components/checkin-widget/checkin-widget';
@import 'src/_modules/components/category-changer/category-changer';
@import 'src/_modules/components/spec-block/spec-block';
@import 'src/_modules/components/tab-control/tab-control';

@import 'src/_modules/_partials/section-footer/section-footer';
@import 'src/_modules/_partials/section-header/section-header';

@import 'src/_modules/elements/image-carousel/image-carousel';
@import 'src/_modules/elements/content-carousel/content-carousel';
@import 'src/_modules/elements/text-carousel/text-carousel';
@import 'src/_modules/components/mobile-video/mobile-video';
@import 'src/_modules/components/presentation-item/presentation-item';
@import 'src/_modules/components/page-announcements/page-announcements';

@import 'src/_modules/components/social-share/social-share';
@import 'src/_modules/components/social-media/social-media';
@import 'src/_modules/components/social-media/facebook/facebook';
@import 'src/_modules/components/social-media/twitter/twitter';
@import 'src/_modules/components/social-media/instagram/instagram';
@import 'src/_modules/components/social-media/vimeo/vimeo';
@import 'src/_modules/components/social-media/youtube/youtube';
@import 'src/_modules/components/social-media/pinterest/pinterest';
@import 'src/_modules/components/social-media/weibo/weibo';

@import 'src/_modules/components/testimonial/testimonial';
@import 'src/_modules/elements/text-cta/text-cta';
@import 'src/_modules/elements/text-listing/text-listing';
@import 'src/_modules/elements/text-reveal-cta/text-reveal-cta';
@import 'src/_modules/components/quick-look/quick-look';
@import 'src/_modules/elements/text-separator/text-separator';
@import 'src/_modules/components/triangle-cta/triangle-cta';
@import 'src/_modules/components/video-cta/video-cta';
@import 'src/_modules/components/video-banner/video-banner';
@import 'src/_modules/components/blog-cta/blog-cta';
@import 'src/_modules/components/hotel-select/hotel-select';
@import 'src/_modules/components/carousel-tabs/carousel-tabs';
@import 'src/_modules/components/category-tabs/category-tabs';
@import 'src/_modules/components/map-explore/map-explore';
@import 'src/_modules/components/gallery-column/gallery-column';
@import 'src/_modules/components/events-listing/events-listing';
@import 'src/_modules/components/benefit-carousel/benefit-carousel';
@import 'src/_modules/elements/dynamic-list/dynamic-list';
@import 'src/_modules/elements/dynamic-map/dynamic-map';
@import 'src/_modules/elements/coordinates/coordinates';
@import 'src/_modules/elements/gallery-simple/gallery-simple';
@import 'src/_modules/elements/gallery-slide/gallery-slide';
@import 'src/_modules/elements/gallery-grid/gallery-grid';
@import 'src/_modules/elements/floorplan/floorplan';
@import 'src/_modules/elements/table/table';
@import 'src/_modules/elements/two-column-image/two-column-image';
@import 'src/_modules/components/table-hero/table-hero';
@import 'src/_modules/components/marketing-message/marketing-message';
@import 'src/_modules/components/form/form';
@import 'src/_modules/components/accordion/accordion';
@import 'src/_modules/components/alternative-dates/alternative-dates';
@import 'src/_modules/components/intro-video/intro-video';
@import 'src/_modules/components/video-gallery-item/video-gallery-item';

@import 'src/_modules/elements/compare-list/compare-list';
@import 'src/_modules/elements/gallery-item/gallery-item';
@import 'src/_modules/components/listing-with-gallery/listing-with-gallery';

@import 'src/_modules/content/content-intro/content-intro';
@import 'src/_modules/content/content-intro-complex/content-intro-complex';
@import 'src/_modules/content/standard-block/standard-block';
@import 'src/_modules/content/content-block/content-block';
@import 'src/_modules/content/highlight-block/highlight-block';
@import 'src/_modules/content/ordered-block/ordered-block';
@import 'src/_modules/content/detail-block/detail-block';
@import 'src/_modules/content/listing-layout/listing-layout';
@import 'src/_modules/content/contact-us/contact-us';
@import 'src/_modules/content/contact-inline/contact-inline';
@import 'src/_modules/content/step-block/step-block';
@import 'src/_modules/content/category-detail-layout/category-detail-layout';
@import 'src/_modules/content/my-booking/my-booking-form';
@import 'src/_modules/content/hotel-directory/hotel-directory';
@import 'src/_modules/components/accordion/accordion';
@import 'src/_modules/components/countdown/countdown';
@import 'src/_modules/components/rates-listing/rates-listing';
@import 'src/_modules/components/current-search/current-search';
@import 'src/_modules/components/mini-gallery/mini-gallery';
@import 'src/_modules/components/timeline/timeline';


@import 'src/_modules/components/destination-explorer/destination-explorer';
@import 'src/_modules/components/cta-banner/cta-banner';
@import 'src/_modules/components/feature-banner-carousel/feature-banner-carousel';

@import 'src/_modules/content/buyout/buyout';
@import 'src/_modules/components/inline-popup/inline-popup';
@import 'src/_modules/elements/announcement-banner/announcement-banner';
@import 'src/_modules/components/center-popup/center-popup';

@import 'src/_modules/elements/custom-dropdown/custom-dropdown';
@import 'src/_modules/elements/banner-search/banner-search';

@import 'src/_modules/elements/app-banner/app-banner';
@import 'src/_modules/elements/anchor-links/anchor-links';
@import 'src/_modules/elements/badge/badge';
@import 'src/_modules/elements/notification-box/notification-box';

@import 'src/_modules/content/image-highlight-block/image-highlight-block';

@import 'src/_modules/components/discovery-enroll/discovery-enroll';
@import 'src/_modules/components/related-stories/related-stories';
@import 'src/_modules/components/cross-sell-hotels/cross-sell-hotels';
@import 'src/_modules/components/feature-destination/feature-destination';
@import 'src/_modules/components/child-page-listing/child-page-listing';
@import 'src/_modules/components/brand-portfolio/brand-portfolio';
@import 'src/_modules/components/feature-banner/feature-banner';
@import 'src/_modules/components/alternate-content/alternate-content';
@import 'src/_modules/components/number-counter/number-counter';

@import 'src/_modules/elements/breadcrumb/breadcrumb';
@import 'src/_modules/elements/circle-profile/circle-profile';
@import 'src/_modules/elements/square-profile/square-profile';

@import './_shame';

// Box sizing partial styles:
// Apply a natural box layout model to all elements
*,
*:before,
*:after {
	-webkit-box-sizing: border-box;
	   -moz-box-sizing: border-box;
	        box-sizing: border-box;
}

// Styling on warning for users on IE7 or below
.browsehappy {
	padding: .5em 0;
	margin: 0;
	color: #000;
	text-align: center;
	background: #fff8e2;
}

.skip-links {
	position: absolute;
	z-index: 99;
	background-color: #fff;
}

.hidden {
	display: none;
}

html, body {
	@extend %font-body;
	
	padding-top: 0;
	margin-top: 0;
	// overflow-x: hidden;	// Removed to make fancybox work properly, prevent double scroll bar
	font-weight: 400;
	color: $t-standard;
}

h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
	@extend %font-header;
	line-height: 1;
	font-weight: 500;
}

h1, .h1 {
	font-size: $font-size-h1;
}
h2, .h2 {
	font-size: $font-size-h2;
}
h3, .h3 {
	font-size: $font-size-h3;
}
h4, .h4 {
	font-size: $font-size-h4;
}
h5, .h5 {
	font-size: $font-size-h5;
}
h6, .h6 {
	font-size: $font-size-h6;
}

a {
	color: inherit;
	text-decoration: none;
}

label a, .t-c-xs a {
	text-decoration: underline;
}

main {
	overflow-x: hidden;
	background:$f-main-background;
}

.visuallyhidden {
	position: absolute;
	width: 1px;
	height: 1px;
	padding: 0;
	margin: -1px;
	overflow: hidden;
	clip: rect(0 0 0 0);
	border: 0;
}

#scroll-to-top {
	position: fixed;
	@include right(5%);
	bottom: 5%;
	display: none;
	font-size: 3em;
	color: $t-standard;
	text-decoration: none;
	opacity: .6;

	&:hover, &:focus {
		opacity: 1;
	}

	&:before {
	}
}

.responsive-table {
	@extend %t-c-m;

	h1, h2, h3, h4, h5, h6 {
		@extend %t-c-l;
	}

	&--partner {
		background-color: #fff;
		border:0;

		tr{
			border:0;
		}

		img {
			margin: 10px 20px 20px 20px;
		}

		a {
			color: $t-gold;
		}

		tr {
			td:first-child {
				text-align: center;
				@include mobile {
					padding-top: 15px;
					img {
						margin-bottom: 0;
					}
				}
			}
			td {
				@include mobile {
					padding-top: 0;
					padding-bottom: 0;
				}
			}
		}

		tr:first-child {
			td {
				@include mobile {
					border-top: 0 !important;
				}
			}
			td:first-child {
				border-bottom: 0;
			}
		}
	}
}

/* Destination Landing Page */
.destination__intro {
	@include tablet {
		width: 50%;
	}
}

.destination__list-wrapper {
	@include tablet {
		width: 50%;
	}
}

/**
	Sub navigation
**/
.sub-nav {
	width: 100%;
	max-width: 330px;
	padding: 35px 40px 25px;
	background: transparentize($f-grey-light,0.05);

	margin-bottom: 1.5em;

	@include desktop {
		margin-bottom: 55px;
		margin-top:30px;
	}
	@include tablet {
		margin-bottom: 35px;
	}

	&--header {
		position: absolute;
		@include right(0);
		bottom: 100%;
		z-index: 10;
		width: auto;
	}

	ul {
		list-style: none;
		margin: 0;
		padding: 0;
	}

	li { margin-bottom: 6px; }

	a {
		position: relative;
		display: inline-block;
		padding-bottom: 8px;
		transition: all $quick ease-in-out;

		&:after {
			display: block;
			content: '';
			position: absolute;
			background: $f-gold;
			width: 0;
			height: 1px;
			bottom: 0;
			@include left(25px);
			transition: all $medium ease-in-out;
		}

		&.active, &:hover {
			color: $t-gold;

			&:after {
				@include left(-10px);
				width: 45px;
			}
		}
	}
}

.pull-left {
	@include float(left);
}

.pull-right {
	@include float(right);
}

img {
	&.pull-left {
		@include margin-right(15px);
		margin-bottom: 10px;
	}

	&.pull-right {
		@include margin-left(15px);
		margin-bottom: 10px;
	}
}

// For travel agents
#callTAWrap {
	position: relative;
}

#callTA {
	position: fixed;
	bottom: 40px;
	@include right(20px);
	z-index: 20;
	padding: 8px 10px;
	background: #FFF;
	border: 2px solid $f-gold;
	border-radius: 25px;
	transition: all $quick ease-out;

	@media(min-width: 1850px) {
		bottom: 10px;
	}

	a.toggle {
		display: block;
		font-weight: bold;
		font-size: 14px;
		line-height: 1em;
		@include padding(0, 5px, 0, 30px);
		cursor: text;

		.glyph-phone {
			position: absolute;
			@include left(13px);
			padding-top: 1px;
			font-size: 24px;
		}
	}

	a.toggle:hover {
		color: $f-gold;
	}

	a.toggle span {
		display: block;
	}

	a.toggle span.for {
		display: none;
	}

	p {
		border-top: 1px solid $f-gold;
		margin: 10px 0 0;
		display: none;
		max-width: 230px;
		@include padding(10px, 0, 0, 10px);
	}

	p a {
		color: #58595b;
		cursor: text;
	}

	&.show a.toggle {
		line-height: 1.5em;
	}

	&.show a.toggle span {
		display: inline;
	}

	&.show a.toggle span.for {
		display: inline;
	}

	&.show p {
		display: block;
	}
}

#launcher {
	margin-bottom: 20px !important;
	z-index: 20 !important;
}

/* fade image in after load */
.lazyload,
.lazyloading {
	opacity: 0.7;

	.is-ie & {
		opacity: 1;
	}
}
.lazyloaded {
	opacity: 1;
	transition: opacity $quick;
	will-change: opacity;
}

.clear-padding {
	padding: 0 !important;

	.container {
		padding: 0 !important;
	}

	.half-blue--fallbackleft, .half-blue--fallbackright, .property-blue--fallbackleft, .property-blue--fallbackright {
		padding: 0 !important;
	}
}

.add-padding-left {
	@include padding-left(70px);
}

.add-padding-right {
	@include padding-right(70px);
}

[class^="glyph-"], [class*=" glyph-"] {
	display: inline-block;
}

._hj-f5b2a1eb-9b07_widget {
	z-index: 20 !important;
}

.zopim {
	@include mobile{
		z-index: 20 !important;
	}
}

html[lang="vi"] *, html[lang="ru"] *{
    font-family: BlinkMacSystemFont,-apple-system,Segoe UI,Roboto,Helvetica,Arial,sans-serif;
}

html[lang="ru"] {
	.t-xs, .t-s{
		letter-spacing: 1px;
	}
}

.flex-strect-btn{
	@media (min-width: 376px) {
		display: flex;
	}
    justify-content: stretch;
    vertical-align: middle;
}

/* Blog Page*/

.blog-list {
	display: inline-flex;
	flex-direction: row;
	flex-wrap: wrap;
}

.responsive-video {
	@include aspect-ratio(1.85, 1);

	iframe {
		width: 100%;
    	height: 100%;
		position: absolute;
		top: 0;
		left: 0;
	}
}

.tablesaw.tablesaw-stack.fixed{
	display: none;
}

.text-white{
	color: #fff;
}