// accordion styles

.accordion {
	margin-top: 20px;
	margin-bottom: 20px;
}

.accordion__item {
	border-bottom: 1px solid $t-gold;

	.faq &{
		border:0;
		border-bottom:1px solid darken($f-grey-light-border, 10%);
	}

	&:last-of-type {
		border: none;
		.faq &{
			border-bottom:1px solid darken($f-grey-light-border, 10%);
		}
	}
}

.accordion__title {
	display: flex;
    align-items: center;
    justify-content: space-between;
	position: relative;
	background: darken($f-grey-light-border, 5%);
	@include padding(10px, 40px, 10px, 20px);
	cursor: pointer;

	&:hover, &:focus {
		background: darken($f-grey-light-border, 5%);
	}

	&:after {
		content: '\25BC';
		display: inline-block;
		position: absolute;
		@include right(15px);
		top: 12px;
		bottom: 0;
		margin: auto;
		font-size: 1em;
		color: $t-gold;
		transition: transform .35s ease-in-out;

		.faq &{
			top:15px;
			@include mobile{
				top:25px;
			}
		}
	}

	&.active:after {
		content: "\25B2";
	}

	.faq & {
		background:transparent;
		@include padding(16px, 40px, 16px, 10px);
		transition: all .1s ease-in-out;

		&:hover {
			background: darken($f-grey-light-border, 10%);
			transition: all .1s ease-in-out;
		}
		
		h2 {
			@extend %font-body;
			margin:0;
		}
	}

}

.accordion__content {
	padding: 20px;
	background: $f-grey-light-border;
	display: none;

	& a {
		color: $t-gold;
	}

	p, ul {
		font-weight: 400;

		&:first-child {
			margin-top: 0;
		}
	}
}
