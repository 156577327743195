/* =======================================================================
01 Global
========================================================================== */

$numberCols: 12;
$column: 100/$numberCols;
.col {

	@for $i from 1 through $numberCols/4 {
		&-#{$i} {
			width: 50%;
		}
	}

	@for $i from $numberCols/4 + 1 through $numberCols {
		&-#{$i} {
			width: 100%;
		}
	}

	// @for $i from 1 through $numberCols {
	// 	&-offset-#{$i} {
	// 		margin-left: 0;
	// 	}
	// }

	@include tablet {
		@for $i from 1 through $numberCols {
			&-#{$i} {
				width: min(($column * $i * 2) * 1%, 100%);
			}
		}
	}

	@include desktop {
		@for $i from 1 through $numberCols {
			&-#{$i} {
				width: ($column * $i) * 1%;
			}

			&-offset-#{$i} {
				@include margin-left(($column * $i) * 1%);
			}
		}
	}

}

[class^="col-"],
[class*=" col-"] {
	@include float(left);
	padding: 0;
}

// Used to act as an alternative to haveing multiple classes to determine how a column will act on desktop/tablet/mobile

// First 3 columns will be .col-4, the 4th onwards will act as a .col-3 on desktop
.section--feature-3 .col-4:nth-of-type(n+4) {
	@include desktop {
		width: ($column * 3) * 1%;
	}
}

// First 6 columns will be .col-4, the 4th onwards will act as a .col-3 on desktop
.section--feature-6 .col-4:nth-of-type(n+7) {
	@include desktop {
		width: ($column * 3) * 1%;
	}
}

@include tablet {

	.col-tab-12 {
		width: (100% / 12) * 12;

		[id^="list-with-filter"] & {
			padding: 0;
		}
	}

	.col-tab-9 {
		width: (100% / 12) * 9;

		[id^="list-with-filter"] & {
			padding: 0;
		}
	}

	.col-tab-8 {
		width: (100% / 12) * 8;

		[id^="list-with-filter"] & {
			padding: 0;
		}
	}

	.col-tab-7 {
		width: (100% / 12) * 7;

		[id^="list-with-filter"] & {
			padding: 0;
		}
	}

	.col-tab-6 {
		width: 50%;

		[id^="list-with-filter"] & {
			padding: 0;
		}
	}

	.col-tab-5 {
		width: (100% / 12) * 5;

		[id^="list-with-filter"] & {
			padding: 0;
		}
	}

	.col-tab-4 {
		width: 33.33333%;

		[id^="list-with-filter"] & {
			padding: 0;
		}
	}
	.col-tab-3 {
		width: 25%;

		[id^="list-with-filter"] & {
			padding: 0 0.4em;
		}
	}

	.col-tab-12 {
		width: 100%;
	}
}

@include mobile {
	.col-mob-12 {
		width: 100%;
	}
}

.row {
	margin-bottom: 25px;
}

/* =======================================================================
02 Layout
========================================================================== */
%container {
	max-width: 1600px;
	padding: 0 $site-gutter;
	margin: 0 auto;

	@extend %clearfix;
}
.container {
	@extend %container;
	
	position: relative!important; 	// Fix for full screen BG images using an img tag and object-fit

	&--full {
		padding: 0;
		max-width: none;
	}

	&--mw600 {
		max-width: 600px !important;
	}

	&--mw720 {
		max-width: 720px !important;
	}
	&--mw960 {
		max-width: 960px !important;
	}
	&--mw1140 {
		max-width: 1140px !important;
	}
	&--mw1320 {
		max-width: 1320px !important;
	}
	&--mw1440 {
		max-width: 1440px !important;
	}
	&--mh55vh {
		height: 55vh !important;
	}
	&--mh65vh {
		height: 65vh !important;
	}
	&--mh75vh {
		height: 75vh !important;
	}
	
	&--static {
		position: unset!important;
	}
	
	&--aligned-left {
		text-align:initial;
	}

	// Padding for the blue/blue tone 2 column background
	&--col-padding {
		&.container--mw1320 {
			max-width: 1450px !important;
		}

		.col-6 {
			padding: 0 65px;
			@include tablet {
				padding: 0 35px;
				width: 50%;

				&:first-child {
					@include padding-left(10px);
				}
				&:last-child {
					@include padding-right(10px);
				}
			}

			&:first-child {
				@include padding-left(50px);
			}
			&:last-child {
				@include padding-right(50px);
			}
		}
			
		&.blog-footer {
			.col-6 {
				@include mobile {
					padding:0;
				}
			}
		}
	}

	&.has-overlay {
		.slick-list {
			&:after {
				content: '';
				position: absolute;
				top: 0;
				bottom: 0;
				left: 0;
				right: 0;
				z-index: 2;
				display: block;
				background-color: rgba(0, 0, 0, 0.3);
			}
		}
	}

	&.has-overlay {
		&:after {
			content: '';
			position: absolute;
			top: 0;
			bottom: 0;
			left: 0;
			right: 0;
			z-index: 2;
			display: block;
			background-color: rgba(0, 0, 0, 0.3);
		}
	}
}

section {
	position: relative;
}

main > .content {
	margin-top:$header-mobile-height;
	
	@include desktop {
		margin-top: 0;
	}

	.with-notice & {
		margin-top: $header-mobile-with-notice-height;

		@include desktop {
			margin-top: 0;
		}
	}

	.horizon-header & {
		@include desktop {
			margin-top: 180px;
		}

		&.is-scrolled {
			@include desktop {
				margin-top: 195px;
			}
		}
	}

	.horizon-header.hide-booking-mask & {
		@include desktop {
			margin-top: 105px;
			padding-top: 125px;
		}

		&.is-scrolled {
			@include desktop {
				margin-top: 195px;
			}
		}
	}

	.with-notice.horizon-header & {
		@include no-desktop {
			margin-top: 149px;
		}

		@include desktop {
			margin-top: 240px;
		}

		&.is-scrolled {
			margin-top: 150px;

			@include desktop {
				margin-top: 195px;
			}
		}
	}

	.with-notice.horizon-header.hide-booking-mask & {
		@include desktop {
			margin-top: 155px;
			padding-top: 175px;
		}

		&.is-scrolled {
			@include desktop {
				margin-top: 195px;
			}
		}
	}

	.with-notice.has-event-banner.horizon-header & {
		margin-top: $horizon-header-mobile-with-notice-with-event-height;

		@include desktop {
			margin-top: 313px;
		}

		&.is-scrolled {
			@include desktop {
				margin-top: 36px;
			}
		}
	}

	.has-event-banner.horizon-header {
		@include desktop {
			margin-top: 260px;

			&.is-scrolled {
				margin-top: 36px;
			}
		}
	}
	
	&.is-scrolled {
		margin-top:$header-mobile-height;

		@include desktop {
			margin-top: 36px;
		}
	}

	&.no-lang-bar {
		margin-top: $header-height--scrolled;

		@include desktop {
			margin-top: 85px;
		}

		&.small-margin{
			margin-top: 50px;
		}
	}

	> section {
		@extend %clearfix;
	}

	body.has-event-banner &{
		margin-top:180px;

		@include desktop {
			margin-top: 0;
		}	
		
		@include landscapeTablet {
			margin-top: 0;
		}
	}
}

.section {
	&--gold {
		background-color: $section-gold-bg-color;
		color:$section-gold-text-color;

		+ .section--same-as-previous {
			background-color: $section-gold-bg-color;
			color:$section-gold-text-color;
		}
	}

	&--dark-grey {
		background-color: $section-dark-grey-bg-color;
		color: $section-dark-grey-text-color;

		+ .section--same-as-previous {
			background-color: $section-dark-grey-bg-color;
			color: $section-dark-grey-text-color;
		}
	}

	&--darker-grey {
		background-color: $section-darker-grey-bg-color;
		color: $section-darker-grey-text-color;
		
		+ .section--same-as-previous {
			background-color: $section-darker-grey-bg-color;
			color: $section-darker-grey-text-color;
		}
	}

	&--blog-intro {
		background-color:$section-blog-intro-bg-color;
		color:$section-blog-intro-text-color;

		+ .section--same-as-previous {
			background-color:$section-blog-intro-bg-color;
			color: $section-blog-intro-text-color;
		}
	}

	&--light-grey {
		background-color: $section-light-grey-bg-color;
		color:$section-light-grey-text-color;
		
		+ .section--same-as-previous {
			background-color: $section-light-grey-bg-color;
			color:$section-light-grey-text-color;
		}
	}

	&--lighter-grey {
		background-color: $section-lighter-grey-bg-color;
		color:$section-lighter-grey-text-color;
		
		+ .section--same-as-previous {
			background-color: $section-lighter-grey-bg-color;
			color:$section-lighter-grey-text-color;
		}
	}

	&--blue-blue-tone {
		background: $section-blue-tone-bg-color;
		color:$section-blue-tone-text-color;

		@include mobile {
			background: $section-blue-bg-color;
			color:$section-blue-tone-text-color;
		}
	}

	&--white {
		background-color: $section-white-bg-color;
		color:$section-white-text-color;
		
		+ .section--same-as-previous {
			background-color: $section-white-bg-color;
			color:$section-white-text-color;
		}
	}
	
	&--transparent {
		background-color: transparent;
	}

	&--fullheight {
		position: relative;
		height: auto;
		min-height: 100vh;

		&:first-child {
			min-height: calc(100vh - 78px);
			padding-top: 0;
			@include desktop {
				min-height: 100vh;
			}
		}
		
		.full-bg {
			height: auto !important;
			position:absolute!important;
			top:0;
			left:0;
			right:0;
			bottom:0;
		}
	}

	&--halfheight {
		height: 55vh;
		position: relative;
		
		@include desktop {
			height: 80vh;
		}
		
		.full-bg {
			position:absolute!important;
			height: auto !important;
			top:0;
			left:0;
			right:0;
			bottom:0;
		}
		
		.horizon-header & {
			@include desktop {
				height: 60vh;
			}
		}
	}

	&--fullheight,
	&--halfheight{
		/*Mobile Journey*/

		&#begin-journey{
			@include mobile{
				min-height:320px;
			}
		}
	}

	&--forceheight {
		height: auto !important;
	}

	&--overflow-hidden {
		overflow: hidden;
	}

	&--will-expand {
		overflow: hidden;
		height: 300px;

		transition: height $slow ease-out;

		&.is-expanded {
			overflow: visible;
			height: auto!important;

			.btn--arrows {
				display: none;
			}

			&:after {
				content: none;
			}
		}

		&--taller {
			height: 100vh;
		}

		&:after {
			content: "";
			position: absolute;
			z-index: 5;
			left: 0;
			right: 0;
			bottom: -1px;
			width: 100%;
			height: 200px;
			// pointer-events: none;

			background: linear-gradient(to bottom, rgba(255,255,255,0) 0%,rgba(255,255,255,0.5) 30%,rgba(255,255,255,1) 90%);
		}

		&.section--dark-grey:after {
			background: linear-gradient(to bottom, rgba(30,30,30,0) 0%,rgba(30,30,30,0.5) 30%,rgba(30,30,30,1) 90%);
		}
	}

	// Padding Bottom Extra Short
	&--pbxs {
		padding-bottom: 1em !important;

		@include desktop {
			padding-bottom: 30px !important;
		}
		@include tablet {
			padding-bottom: 20px !important;
		}
	}

	// Padding Bottom Short
	&--pbs {
		padding-bottom: 1.5em !important;

		@include desktop {
			padding-bottom: 55px !important;
		}
		@include tablet {
			padding-bottom: 35px !important;
		}
	}

	// Padding Bottom Medium
	&--pbm {
		padding-bottom: 1.5em !important;

		@include desktop {
			padding-bottom: 75px !important;
		}
		@include tablet {
			padding-bottom: 55px !important;
		}
	}

	// Padding Bottom Tall
	&--pbt {
		padding-bottom: 1.5em !important;
		@include desktop {
			padding-bottom: $header-height-desktop !important;
		}
	}

	// Padding Top Short
	&--ptxs {
		padding-top: 1em !important;

		@include desktop {
			padding-top: 30px !important;
		}
		@include tablet {
			padding-top: 20px !important;
		}
	}

	// Padding Top Short
	&--pts {
		padding-top: 1.5em !important;

		@include desktop {
			padding-top: 55px !important;
		}
		@include tablet {
			padding-top: 35px !important;
		}
	}

	// Padding Top Medium
	&--ptm {
		padding-top: 1.5em !important;

		@include desktop {
			padding-top: 80px !important;
		}
		@include tablet {
			padding-top: 45px !important;
		}
	}

	// Padding Top Tall
	&--ptt {
		padding-top: 1.5em !important;
		@include desktop {
			padding-top: 170px !important;
		}
	}

	&--header-grey {
		&:before {
			content: "";
			display: block;
			background-color: $f-grey-light-border;
			width: 100%;
			height: 4em;
		}
	}

	&--content-top {
		> .full-bg.parallax-window {
			@include desktop {
				height: auto;
				min-height: 75vh;
			}
			.container {
				top: 0;
				transform: translateY(0);
			}
		}
	}

	&--center-content {
		position: relative;
		color: #fff;

		.full-bg, .full-bg.parallax-window {
			min-height: 350px;
			height: 55vh;

			> div {
				position: absolute;
				top: 50%;
				right: 0;
				left: 0;
				z-index: 1;
				text-align: center;
				transform: translateY(-50%);

				&.centered-content__container {
					position: relative;
					top: auto;
					left: auto;
					right: auto;
					transform: translate(0);
				}
			}

			.banner-title {
				transform: none!important;
				top: 0;
				display: flex;
				align-items: center;
				justify-content: center;
				flex-direction: column;
				height:100%;

				&.position-bottom-mobile {
					align-items: end;
				}
			}


			@include mobile {
				min-height: 55vh;
				height: auto;

				> div {
					transform: translateY(0);
				}
			}

			@at-root section:first-child#{&} {
				@include mobile {
					height: 55vh;
				}

				> div {
					transform: translateY(-50%);
				}
			}

			&.has--video{
				@include desktop{
					min-height:720px;
				}

				@include landscapeTablet{
					min-height:720px;
				}
			}
		}

		&.section--fullheight {
			.full-bg, .full-bg.parallax-window {
				min-height: 100vh;
				height: 100vh;
			}
		}
	}



	&-video {
		display: none;
		@include desktop {
			display: block;	

			.full-bg {
				position: static;
			}

			// Hide SVG circles from spilling into the next section
			+ .section--fullheight + section {
				position: relative;
				z-index: 5;
			}
		}
		@include landscapeTablet{
			display: block;
		}
	}

	
	&-journey-explorer {
		.full-bg {
			.lazyload {
				opacity:1;
			}
		}
	}

	&--no-banner {
		min-height: 350px;
		background-color: $f-op50-grey-dark;
		@include desktop {
			padding-top: 144px;
		}
	}

	&--behind {
		box-shadow: inset 0 20px 15px -10px rgba(0, 0, 0, 0.35);

		&.section--white, &.section--light-grey {
			box-shadow: inset 0 20px 15px -10px rgba(0, 0, 0, 0.15);
		}
	}

	&--dark-grey-gradient {
		background-image: linear-gradient($f-grey-dark, $f-grey-dark-border);
	}

	&--foreign-currency {
		padding-bottom: 1em;
		text-align: center;
	}
	
	&--intro {
		margin-bottom: 2em;
	}
}

/*Alternate Colors Section*/

.alternate-section-group{

	section{

		&:nth-child(odd){
			background-color: #ffffff;
		}

		&:nth-child(even){
			background-color: $f-grey-light-border;
		}
	}

}

.half-blue {
	@include mobile {
		width: 100%;

		&:first-child {
			@include padding-right(30px, !important);
			@include padding-left(30px, !important);
			background-color: $f-pale-blue;
		}
		&:last-child {
			@include padding-right(30px, !important);
			@include padding-left(30px, !important);
			background-color: $f-pale-blue-tone;
		}
	}
}

.half-section .container {
	@include mobile {
		padding: 0;
	}
}

.ul-2-col > ul {
	padding: 0;
	column-count: 2;
	list-style-position: outside;

	@media (max-width: 380px) {
		column-count: 1;
	}
}

.ul-2-col > ul > li {
	position: relative;
	@include margin-left(1em);
	@include padding(0.5em, 0, 0.5em, 0.5em);

	&:before {
		content: none;
	}
}

.site-container.no-banner {
	main > .content {
		// Not Scrolled without banner
		> section {
			&:first-child,
			&:first-of-type {
				@include desktop{
					padding-top:220px !important;
				}
			}
		}

		.horizon-header & {
			> section {
				&:first-child, &:first-of-type {
					@include desktop {
						padding-top: 0 !important;
					}
				}
				
				&.section--ptt:first-child, &.section--ptt:first-of-type {
					@include desktop {
						padding-top: 1.5em !important;
					}
				}
			}
		}

		// Not Scrolled with banner
		.with-notice & {
			> section {
				&:first-child,
				&:first-of-type {				
					@include desktop{
						padding-top:280px !important;
					}
				}
			}
		}

		.with-notice.horizon-header & {
			> section {
				&:first-child,
				&:first-of-type {
					@include desktop{
						padding-top: 100px !important;
					}
				}
				
				&.section--ptt:first-child, &.section--ptt:first-of-type {
					@include desktop {
						padding-top: 1.5em !important;
					}
				}
			}
		}

		> section.spa-wellness:first-child {
			@include desktop{
				padding-top:188px !important;
			}

			.horizon-header & {
				@include desktop{
					padding-top:0px !important;
				}
			}
			
			.with-notice & {
				@include desktop {
					padding-top:237px !important;
				}
			}
			
			.with-notice.horizon-header & {
				@include desktop {
					padding-top:0px !important;
				}
			}
		}

		&.is-scrolled {
			// Scrolled without banner
			> section{
				&:first-child,
				&:first-of-type {
					@include desktop{
						padding-top:170px !important;
					}
				}
			}

			.horizon-header & {
				> section{
					&:first-child,
					&:first-of-type {
						@include desktop{
							padding-top:160px !important;
						}
					}
				}

				> section.spa-wellness {
					&:first-child,
					&:first-of-type {
						@include desktop{
							padding-top:0px !important;
						}
					}
				}
			}

			// Scrolled with banner
			.with-notice & {
				> section {
					&:first-child,
					&:first-of-type {
						// @include desktop{
						// 	padding-top:300px !important;
						// }
					}
				}
			}

			.with-notice.horizon-header & {
				> section {
					&:first-child,
					&:first-of-type {
						@include desktop{
							padding-top: 60px !important;
						}
					}
				}

				> section.spa-wellness {
					&:first-child,
					&:first-of-type {
						@include desktop{
							padding-top: 0px !important;
						}
					}
				}
			}
		}
	}
}

$event-banner-height:80px;

body.has-event-banner{
	.site-container.no-banner {
		main > .content {
			> section:first-child {
				@include desktop{
					padding-top:calc(220px + #{$event-banner-height}) !important;
				}
			}
		}
	}
}

.flex-container {
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
	flex-wrap: wrap;
	-webkit-justify-content: center;
    -ms-flex-pack: center;
	justify-content: center;

	&--column{
		flex-direction:column;
		-webkit-flex-direction: column;
    	-ms-flex-direction: column;
	}

	&--start{
		justify-content: flex-start;
		-webkit-justify-content: flex-start;
		-ms-flex-pack: flex-start;
		justify-content: flex-start;
	}

	&--nowrap{
		flex-wrap: nowrap;
		-ms-flex-wrap: nowrap;
	}
	
	&--reverse{
		flex-direction: row-reverse;
	}

	&--align-middle{
		align-items:center;
	}

	&--align-right{
		@include desktop{
			justify-content: flex-end;
		}

		[class^="col-"]{
			padding:10px 0;
			
			@include desktop{
				@include padding(40px, 0, 40px, 45px);
				min-height: 600px;
			}
		}
	}

	&--align-left{
		@include desktop{
			justify-content: flex-start;
		}

		[class^="col-"]{
			padding:10px 0;

			@include desktop{
				@include padding(40px, 45px, 40px, 0);
				min-height: 600px;
			}
		}
	}

}
