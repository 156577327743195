.category {
    &-listing {
        &-header {
            @include margin(4em, auto, 2em, auto);
            
            // &-wrapper {
                
            // }
            
            h3 {
                font-family: $font-oswald;
                font-size: 2.5em;
                overflow: hidden;
                text-align: center;

                &:before,
                &:after {
                    background-color: $t-standard-on-gold;
                    content: "";
                    display: inline-block;
                    height: 1px;
                    position: relative;
                    vertical-align: middle;
                    width: 50%;
                }

                &:before {
                    right: 0.5em;
                    margin-left: -50%;
                } 

                &:after {
                    left: 0.5em;
                    margin-right: -50%;
                }       
            }

            .listing-item {
                &__button {
                    a {
                        font-size: 1.25em;
                        text-decoration-line: none;
                    }
                }
            }
        }

        &__wrapper {
            width: 100%;
        }

        &__image {
            img {
                max-height: 90px;
            }
        }
    }
    &-detail {
        &-wrapper {
            max-width: 100%;
            min-width: 100%;
            .container {
                height: unset;
            }
        }
    }
}
