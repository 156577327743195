// logo styles

.logo {
	display: block;
	width: 440px;	// Stop the logo link from overlapping with rest of header
	margin: 5px auto;
	text-align: center;
	transition: margin $headerAnimation $headerEasing;
	
	@include desktop {
		min-height: 74px;
		@include margin(7px, auto, 5px, auto);

		.is-scrolled & {
			margin: 0 auto;
			min-height: 51px;
		}

		.horizon &,
		.horizon.is-scrolled &  {
			min-height: 0;
			@include text-align(left)
		}
	}
	
	@include tablet {
		min-height: 49px;
	}

	// On small Desktop screen
	// @media (min-width: #{$bpMinDesktop}) and (max-width: 1150px) {
	// 	@include margin(25px, auto, 5px, auto);
	// }

	@include mobile {
		width: 120px;
	}

	&__link {
		position: relative;
		display: inline-block;
		transition: top $quick;

		padding: 0 1em;

		@include mobile {
			width: 100%;
			height: 45px;
		}

		+ .logo__link {
			@include border-left(1px solid $f-header-bg-faint);

			@include desktop {
				border-left-color: $f-header-bg;

				.is-scrolled & {
					border-left-color: #ccc;
				}
			}
		}
	}

	a.logo__link {
		&:active {
			top: 2px;
		}
	}

	&__image {
		max-height: 45px;
		margin: 0 auto;
		transition: max-height $headerAnimation $headerEasing,
		margin $headerAnimation $headerEasing;
		width: auto !important;
		height: auto !important;

		@include mobile {
			position: absolute;
			left: -1000px;
			right: -1000px;
		}

		@include desktop {
			// max-width: 120px;
			max-height: 70px;

			.is-scrolled & {
				display: none;	// Swap between coloured and non-coloured logo
				max-height: 60px;
			}

			.horizon & {
				transition: none;
			}

			.horizon.is-scrolled {
				margin: 0;
			}
		}

		&--alternate {
			// Swap between coloured and non-coloured logo on desktop only
			display: none;

			@include desktop {
				.is-scrolled & {
					display: block;
				}
			}
		}
	}
}
