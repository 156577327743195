// presentation-item styles

.presentation-item p {
	@include mobile {
		display: none;
	}
}

.presentation-item{

	.simple-carousel{
		min-height:170px;
	}
}
