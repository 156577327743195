// text-separator styles
.text-separator {
	position: relative;
	padding: 20px 0;
	text-align: center;
	text-transform: uppercase;

	&::before {
		content: '';
		display: block;
		height: 1px;
		width: 100%;
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
	}

	&__text {
		display: inline-block;
    	padding: 0 4px;
		position: relative;
		
		html[lang=en] & {
			letter-spacing: 1px;
		}

		.section--dark-grey & {
			@extend .section--dark-grey;
		}

		.section--gold & {
			@extend .section--gold;
		}

		.section--white & {
			@extend .section--white;
		}

		.section--light-grey & {
			@extend .section--light-grey;
		}
	}

	&--dark {
		&::before {
			background-color: $f-grey-dark;
		}
	}

	&--light {
		&::before {
			background-color: #fff;
		}
	}
}
