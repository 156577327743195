// highlight-block styles
.rte-block {
	display: block;
	clear: both;
	margin-top: 30px;
	margin-bottom:1em;

	@include mobile {
		margin-top: 15px;
	}

	.listing-with-gallery & {
		margin-top: 0;
	}

	&__heading {
		margin-top: 15px;
	}

	ul {
		margin-top: 0;
		@include padding(0, 1em, 4px, 1em);

		.content-intro-complex & {
			@include padding(0, 1em, 4px, 1.5em);
			
			@include no-desktop {
				margin-bottom: 0;
			}
		}
		a {
			vertical-align: middle;

		}

		.content-intro-complex &{
			@include desktop{
				max-width:90%;
			}
			
		}
	}

	&.icon-list ul {
		list-style-type: none;
		@include padding-left(0);

		li {
			display: block;
			@include margin-right(1em);
			@include margin-left(2.4em);
			margin-bottom: 0.25em;

			a{
				color:$t-standard;
			}

			.list-item {
				margin-right: 5px;
	
				&::after {
					content: '';
					background-color: $f-icon-color;
					width: 5px;
					height: 5px;
					transform: translateY(-50%);
					display: inline-block;
					border-radius: 50%;
					margin-left: 5px;
				}
	
				&:last-child {
					margin: 0;
					padding: 0;
	
					&::after {
						width: 0;
						right: 0;
						display: none;
					}
				}
			}
		}

		[class*='glyph-'] {
			@include margin-left(-1.5em);
			@include padding-right(0.5em);
			font-size: 1.2em;
			vertical-align: top;
		}

		/*Icons styling*/

        .glyph-stargazing{

            &-fill{
                &:before{
                    content: $glyph-stargazing;
                    background: $f-gold;
                    padding: 3px;
                    border-radius: 80px;
                    color: #fff;
                    border: 1px solid transparent;
                }
            }

            &-outline{
                &:before{
                    content: $glyph-stargazing;
                    padding: 3px;
                    border-radius: 80px;
                    border: 1px solid $f-gold;
                }
            }
        }
	}

	&.amenities ul{

		li {
			@include margin-right(0em);
			font-size:0.86em;
			margin-bottom:6px;
		}
		
		[class*='glyph-'] {
			color:$t-gold;
			vertical-align: middle;
			@include padding-right(0.25em);

			&.fill{
				@include margin-left(-1.7em);
                &:before{
                    content: $glyph-stargazing;
                    background: $f-gold;
                    padding: 3px;
                    border-radius: 80px;
                    color: #fff;
                    border: 1px solid transparent;
                }
            }

            &.outline{
				@include margin-left(-1.7em);
                &:before{
                    content: $glyph-stargazing;
                    padding: 3px;
                    border-radius: 80px;
                    border: 1px solid $f-gold;
                }
            }
		}
	}

	&.no-wrap ul {
		li {
			display: block;
		}
	}

	&.extra-spacing ul {
		li {
			padding: 3px 0;
		}
	}

	[class*='glyph-'] {
		display: inline-block;
		@include margin-left(0.5em);
		vertical-align: middle;
		color: $f-icon-color;
		transition: transform $medium;
		transform: translateX(bi-app-reverse-percentage(0px));
	}

}

.hotel-highlights {
	display: block;
	margin-bottom: .5em;
	line-height: 1;
}

.menu-items-link{
	@include margin-right(25px);
	margin-bottom:15px;
	text-align:center;
	transform:translateY(0);
	transition: all .3s ease-in-out;

	i{
		font-size:1.4em;
	}

	@include desktop{
		@include margin-right(15px);
	}

	&:last-child{
		@include margin-right(0);
	}

	&:hover{
		transform:translateY(-5px);
		transition: all .3s ease-in-out;
	}

	img{
		display:block;
		max-height:140px;
		margin: 0 auto 5px auto;
		border-radius: 4px;

		@include desktop{
			max-height:120px;
		}
	}
}
