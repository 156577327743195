.member-opt-in {
	&.is-loading {
		text-align:center;

		&:after{
			content: '';
			display: inline-block;
			width: 50px;
			height: 50px;
			vertical-align: bottom;
			@include margin(0, 0, 0, 5px);
			border: 3px solid rgba(164,142,130,.3);
			border-radius: 50%;
			border-top-color: #fff;
			animation: spin 1s  infinite linear;
			-webkit-animation: spin 1s infinite linear;
		}

		.discovery-opt-in, .discovery-login-cta {
			display:none;
		}
	}
    .signup-text {
        font-size: 14px;
        text-align: center;
        margin-bottom: 0;
    }

    .discovery-opt-in {
        display: none;

        .is-members & {
            display: block;
        }

        .discovery-opt-in-success {
            margin-top: 30px;

            p.success {
                color: $f-gold;
                &::before {
                    content: "\e634";
                    color: white;
                    font-size: 15px;
                    background-color: $f-gold;
                    border-radius: 50%;
                    height: 10px;
                    width: 10px;
                    text-align: center;
                    @include margin-right(5px);
                }
            }

			p.error {
                color: $t-error;
                &::before {
                    content: "\e62a";
                    color: white;
                    font-size: 15px;
                    background-color: $t-error;
                    border-radius: 50%;
                    height: 10px;
                    width: 10px;
                    text-align: center;
                    @include margin-right(5px);
                }
            }
        }
    }

    .discovery-login-cta {
        display: block;

        .is-members & {
            display: none;
        }

        .signup-text a {
            text-decoration: underline;
            color: $f-gold;
        }
    }
}
