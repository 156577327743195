// benefit-carousel styles
.benefit-carousel {
	&-slider {
		width: 100%;
		list-style: none;
		display: flex;
		justify-content: center;
		align-items: center;
		flex-direction: row;
		margin: 0 auto;
		padding: 0;
	}

	&-item {
		margin: 0 40px;

		@mixin no-mobile {
			margin: 0 40px;
		}
	}

	&-img {
		display: block;
		width: 100%;
		max-width: 100px;
		margin: 0 auto 20px;
		color: $t-gold;
	}

	&-title {
		text-align: center;
		font-weight: 600;
		color: $t-gold;
		margin:0;
	}

	.slick-dots {
		bottom: -20px;
	}
}

