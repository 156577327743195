// fullscreen menu styles

.fullScreenMenu-container {
	position: absolute;
	top:0;
	@include left(0);
	z-index: 999;
	width: 100%;
	min-height: 40vh;
	padding: 30px 50px;
	@include text-align(left);
	background: $destination-overlay-bg;
	opacity: 0;
	visibility: hidden;
	transition: opacity 0.3s ease, visibility 0.3s ease;

	@include lgDesktop {
		font-size: 1em;
	}

	&.active {
		opacity: 1;
		visibility: visible;
	}
	

	// .with-notice & {
	// 	height: $mask-height-desktop-with-notice;
	// }

	// .horizon-header & {
	// 	height: calc(100vh - #{$horizon-header-toolbar-height} - #{$horizon-booking-mask-height});
	// }
	
	// .horizon-header.hide-booking-mask & {
	// 	height: calc(100vh - #{$horizon-header-toolbar-height});
	// }

	// .horizon-header.with-notice & {
	// 	height: calc(100vh - #{$notice-bar-height} - #{$horizon-header-toolbar-height} - #{$horizon-booking-mask-height});
	// }

	// .horizon-header.hide-booking-mask.with-notice & {
	// 	height: calc(100vh - #{$notice-bar-height} - #{$horizon-header-toolbar-height});
	// }


	// .header-primary.is-scrolled & {
	// 	position: relative;
	// 	height: $mask-height-desktop--scrolled;
	// 	background: $destination-overlay-bg;
	// }

	> .nano-content {
		padding: 40px;
/* 		@include margin-right(-17px); */
		-ms-overflow-style: scrollbar;

		html[lang="en"] &{
			padding: 20px 40px;
		}
	}

	ul {
		width: 100%;
		padding: 0;
		list-style: none;
	}

	li {
		display: block;
		width: 100%;
		border-top: 1px solid $f-grey-dark-border;
		@include desktop {
			margin-bottom: 5px;
			border: 0;
		}
	}

	a {
		display: inline-block;
		color: #fff;
		text-decoration: none;
		transition: color $quick, transform $medium;
	}

	.js-fullScreenMenu__close {
		position: absolute;
		top: 0;
		@include right(17px);
		width: 50px;
		height: 50px;
		background: $f-header-bg-dark;

		@include desktop {
			background-color: $f-grey-dark;
		}

		&:before,
		&:after {
			position: absolute;
			top: 15px;
			@include left(25px);
			width: 2px;
			height: 20px;
			content: ' ';
			background-color: $f-grey-light;
		}

		&:before {
			transform: rotate(45deg);
		}

		&:after {
			transform: rotate(-45deg);
		}
	}
}


/* Fullscreen Menu Styles */
  

  /* Hide the dimmer on desktop */
  .fullScreenMenu-dimmer{
	display: none;
  }
  
  /* Button styling */
  .fullScreenMenu-toggle{

	margin: 0 20px;

	button{
		background: transparent;
	}

  } 