.profile {
	&-wrapper {
		transition: 0.5s;
		margin-bottom: 1.5em;
		
		.overlay-wrapper {
			transition: 0.5s;
			position: absolute;
			@include left(0);
			@include right(0);
			
			.summary-wrapper {
				transition: 0.5s;
				position: relative;
				z-index: 1;
				color: $t-standard-on-gold;
				// width: 100vw;
				@include padding(16px, 16px, 16px, 16px);
				@include margin(2em, auto, 0, auto);
				border-top: 1px solid $f-header-bg;
			}
		}
		.bg-overlay {
			transition: 0.5s;
			background: $f-gold;
			@include left(0);
			@include right(0);
			display: none;
			z-index: -1;
		}
		&.active {
			z-index: 99;
			.summary-wrapper {
				display: block !important;
			}
			.bg-overlay {
				position: absolute;
				display: block;
			}
			.js-profile {
				color: $t-standard-on-gold;
				img.image-round {
					border: 3px solid $f-gold;
				}
				.text-cta {
					visibility: hidden;
				}
			}
		}
		
		img.image-round {
			border-radius: 50%;
			transition: 0.5s;
			border: 3px solid transparent;
			width: 50%;
			&:hover {
				border: 3px solid $f-gold;
			}
		}
	}

	&-circle-overlay {
		&.active {
			background: $f-black-overlay-light;
			position: absolute;
			top: 0;
			bottom: 0;
			left: 0;
			right: 0;
		}
	}
}