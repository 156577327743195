// newsletter styles

.newsletter {
	width: 100%;
	max-width: 500px;
	margin-bottom: 20px;
	overflow:hidden;

	@include mobile {
		display: block;
		margin-top: 20px;


		&:after {
			display: block;
			clear: both;
			content: '';
		}
	}

	@include tablet{
		margin-top: 20px;
	}

	.input-text {
		height: 50px;
		padding: 10px;
		color: #fff;
		background: transparent;
		border: 1px solid $f-grey-border;
		@include border-right(0);
		width: (10/12) * 100%;
		outline: none;

		&::placeholder{
			color:$f-grey-light;
		}

		&:focus {
			outline: none;
		}
	}

	.btn-submit {
		display: inline-flex;
		height: 50px;
		justify-content: center;
		align-items: center;
		border: 1px solid $f-grey-border;
		@include border-left(0);
		width: (2/12) * 100%;

		&:before {
			display: block;
			content: '';
			border-top: 10px solid transparent;
			border-bottom: 10px solid transparent;
			@include border-left(15px solid $f-gold);
		}
	}
}
