#locations{
    .table-hero__wrapper{
        margin-bottom:0;

    }
}

.table-hero.buyout{
    border:1px solid #eee;

    .table-wrapper{
        margin-bottom: 0;
    }

    td{
        &:first-child{

            @include desktop{
                width:250px;
            }
            
        }

        &.bigger-width{
            &:first-child {
                width:600px;

                @include mobile {
                    width: 100%;
                }
            }
        }
    }

    tr.country{

        b.tablesaw-cell-label {
            display: none;
        }
    
        td{
            background:#cccccc;
        }
    }

    tr:not(.country){

        td:first-child {
            b{
                display:none;
            }

            span{
                max-width:100% !important;
                width:80%!important;
            }
        }

        td:last-child {
            b{
                display:none;
            }
            
            span{
                max-width:100% !important;
                width:80%!important;
            }
        }

    }
}




