.tab-control {
    text-align: center;

    &__list {
        display: inline-flex;
        justify-content: center;
        align-items: center;
        border: 1px solid $-form-border;
        border-radius: 3px;
        list-style: none;
        padding: 0;
    }

    &__item {
        @include border-right(1px solid $-form-border);
        list-style: none;
        text-align: center;

        &:last-child {
            border: 0;
        }
    }

    &__action {
        width: 100%;
        height: 100%;
        display: inline-block;
        padding: 15px 20px;
        min-width: 115px;
        cursor: pointer;

        &.is-active {
            background-color: $f-gold;
            color: white;
        }
    }
}