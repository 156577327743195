// facebook styles
.facebook {
	@include padding(33px, 26px, 33px, 43px);
	color: #fff;

	@include mobile {
		padding: 20px 20px;
	}
	@include tablet {
		padding: 30px;
	}

	&__iframe {
		width: 100%;
		height: 100%;

		padding: 0;
		margin: 0;
		overflow: hidden;
		border: 0;
	}

	.js-facebook-vid {
		display: block;
		height: 100%;
	}

	&__container {
	}

	&__title {
		color: inherit;
		text-decoration: none;

		&:before {
			@include padding-right(10px);
			content: $glyph-facebook;
			@extend %icon-font-glyph;
		}
	}

	&__post {
		padding-bottom: 1.5em;
		margin-bottom: 1.5em;
		border-bottom: 1px solid $f-blue-tone;

		@include mobile {
			border-bottom: 0;
		}

		&:last-child {
			padding-bottom: 0;
			border-bottom: 0;
		}

		+ .facebook__post {
			@include mobile {
				display: none;
			}
		}

		a {
			color: inherit;
			text-decoration: none;
		}

		> img {
			width: 100%;
			max-width: 100%;
			margin-bottom: 1em;

			@include tablet {
				max-width: 500px;
			}
		}
	}

	&__meta {
		display: block;
		margin-top: -12px;
		color: $f-op75-white;
	}

	.btn {
		margin-bottom: 35px;

		@include mobile {
			display: none;
		}
	}

	.nano {
		> .nano-content {
			position: inherit;
			@include padding-right(17px);
		}

		> .nano-pane {
			width: 7px;
			background: none;
			border-radius: 0;
		}
	}

	.facebook-vid {
		@include aspect-ratio(16, 9);

		margin-bottom: 1em;

		&__img {
			width: 100%;
			height: 100%;
			object-fit: cover;
		}
	}

	&__play {
		@extend %play-button;
	}
}
