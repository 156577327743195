.alternate-content {
	&.with-intro {
		margin-top: 20px;
	}
	
    .container {
        display: flex;
		flex-direction: column;
        flex-wrap: nowrap;
        justify-content: center;
        align-items: center;
		text-align:center;

		@include tablet {
			flex-direction: row;
		}
		
        @include desktop {
			flex-direction: row;
        }

        &.is-swapped {
			flex-direction: column-reverse;

			@include tablet {
				flex-direction: row;
			}
			
			@include desktop {
				flex-direction: row;
			}
        }
		
		&.align {
			&--top {
				align-items: flex-start;
				
				.description {
					@include tablet {
						padding-top:0;
					}
					@include desktop {
						padding-top:0;
					}
				}
			}
			
			&--left {
				text-align:unset;
			}
		}
    }
    
    .fullwidth-image {
		width:100%;
        flex: 1 1 auto;
        min-height:320px;
        height:auto;
        background-size:cover;
        background-position: center;
        background-repeat: no-repeat;

        @include desktop {
			width:50%;
            min-height:500px;
        }

        @include tablet {
			width:50%;
            min-height:400px;
        }
    }
    
    .description {
		width:100%;
        flex: 1 1 auto;
        padding: 15px;
		
        @include tablet {
			width:50%;
            padding: 40px 5%;
        }

        @include desktop {
			width:50%;
            padding: 40px 5%;
        }
    }
}