// top currency
.panel-currency {
	@include float(right);
	position:relative;
	@include right(0);
	
	.currency-converter {
		display: inline-block;
		font-size: 0.65em;
		color: #fff;
		border: 0;
		transition: color $quick;
		@include desktop {
			padding: 4px 2px;
			font-size: 1.1em;
		}
		
		.form-main:after{
			top: 3px;


			.is--campaign &{
				border-top-color:#fff;
			}

			@include desktop {
				top: 8px;
			}	
		}
		
		select{
			border:0;
			margin:0;
			@include padding (0, 15px, 0, 0);
			font-weight:600;

			.is--campaign &{
				color:#fff;
			}

			@include mobile{
				font-size:1.1em;
			}
		}
	}
	
	.trigger {
		color:#fff;
		position: relative;
		font-size: 0.68em;
		font-weight: 600;
		@include padding-right (12px);
		cursor: pointer;
		
		&:after {
			position: absolute;
			top: 4px;
			display: inline-block;
			width: 6px;
			@include right (0);
			content: '';
			border-top: 6px solid #fff;
			border-right: 4px solid transparent;
			border-left: 4px solid transparent;
			-webkit-transition: border 0.25s;
			transition: border 0.25s;
		}
		
		&.active{
			&:after {
				border-top: 0;
				border-bottom: 6px solid #fff;
				transition: all 0.25s;
				-webkit-transition: all 0.25s;
			}
		}
	}
	
	.currencyMenu{
		display: none;
		position: absolute;
		@include right (0px);
		top: 30px;
		width: 768px;
		padding: 20px 10px;
		@include text-align(left);
		background: $f-grey-dark;
		box-sizing: border-box;
		overflow:hidden;
		
		@include lgDesktop {
			font-size: 1em;
		}
		
		@include tablet{
			@include right (-12px);
			top: 38px;
		}
	
		h4, div.t-underline {margin: 20px 0;}
		
		.currency_list {
			width:100%;
			color: #fff;
			font-weight: 500;
			list-style: none;
			margin: 0;
			padding: 0;
			overflow: hidden;

			@include no-desktop {
				// color: $t-standard;
			}
			
			
			li {
				width:182px;
				@include float(left);
				padding: 5px;
				cursor: pointer;
				@include margin-right(5px);
				margin-bottom: 5px;
				line-height:1;
			
				&:hover {
					background: rgba(0, 0, 0, 0.2);
					border-radius: 5px;
					transition: all .2s ease-in;
				}				
				
				&.active {
					background: rgba(0, 0, 0, 0.2);
					border-radius: 5px;
					transition: all .2s ease-in;
				}
				
				.currency-item {
					vertical-align: middle;
					font-size: 0.81em;
					letter-spacing: 0.03em;
				
					.currency-code{
						@include float(left);
						@include margin-right(5px);
						color:$f-gold;
						text-transform: uppercase;
						font-weight: 600;
						vertical-align: middle;
						line-height:1.2;
					}
				}

				/*Language version fixes */

				html[lang="th"] &,html[lang="ja"] &,html[lang="ru"] &,html[lang="he"] &{
					width:235px;
				}
			}
		}	
	}
} /*End*/	