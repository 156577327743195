// parallax styles

.parallax {
	position: relative;

	&.small{
		min-height:380px;
		@include mobile {
			min-height: 300px;
		}
	}

	&.medium{
		min-height:460px;
		@include mobile {
			min-height: 300px;
		}
	}

	&.large{
		min-height: 580px;
		@include mobile {
			min-height: 300px;
		}
	}

	@include mobile {
		min-height: 300px;
	}

	&-container {
		width: 100%;
		height: 550px;
		background-position: center;
		background-size: cover;
		@include mobile {
			height: 300px;
		}

		.small &{
			height:350px;
			@include mobile {
				height: 300px;
			}
		}
	
		.medium &{
			height:420px;
			@include mobile {
				height: 300px;
			}
		}
	
		.large &{
			height: 550px;
			@include mobile {
				height: 300px;
			}
		}
	}

	&-background {
		position: absolute;
		@include left(0);
		@include right(0);
		bottom: 0px;
		top: 0px;

		> img {
			object-fit: cover;
			object-position: center;
			width: 100%;
			height: 100%;
		}
	}
	&-foreground {
		position: relative;
		z-index: 2;
	}

	&-content {
		position: relative;
		display: block;
		width: 90%;
		max-width: 720px;
		margin: 0 auto;
		text-align: center;
		@include mobile {
			p {
				display: none;
			}
		}
	}

	&__title {
		display: block;
		width: 90%;
		@include margin(15vh, auto, 0, auto);
		text-align: center;

		.small &{
			@include margin(6vh, auto, 3vh, auto);
			@include tablet{
				font-size:4em;
			}

			@include desktop{
				font-size:4em;
			}
		}

		.medium &{
			@include margin(8vh, auto, 3vh, auto);
		}

		@include mobile {
			@include margin(8vh, auto, 3vh, auto);
		}
	}


	// Scroll to top button
	&__btn {
		position: relative;
		top: 25px;
		z-index: 10;
		display: block;
		width: 100px;
		margin: auto;
		color: #fff;
		text-align: center;
		background: transparent;
		border: 0;
		transition: transform $medium;
		transform: translateY(0);
		will-change: transform;

		&:hover, &:focus {
			transform: translateY(-.5em);
		}

		&:before {
			display: block;
			width: 0;
			height: 0;
			@include margin(0, auto, 10px, auto);
			content: '';
			border-color: transparent transparent $f-gold transparent;
			border-style: solid;
			border-width: 0 8px 14px 8px;
		}
	}
}
